import React, { useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppLeaveCalendar from "components/pages/human-resources/app-leave-calendar";
import AppHrViewLeaveHistoryTable from "components/pages/human-resources/app-hr-view-leave-history-table";
import AppHrViewPendingLeavesTable from "components/pages/human-resources/app-hr-view-pending-leaves-table";
import AppHrViewPendingClockoutTable from "components/pages/human-resources/app-hr-view-pending-clockout-table";
import AppHrViewAttendanceDirectoryTable from "components/pages/human-resources/app-hr-view-attendance-directory-table";

import clockIcon from "assets/images/clock-icon.svg";
import activeClockIcon from "assets/images/clock-icon blue.svg";
import gearIcon from "assets/images/pages/human-resources/blue-gear-icon.svg";
import hourglassIcon from "assets/images/pages/human-resources/hourglass-icon.svg";
import leaveApproveIcon from "assets/images/pages/human-resources/leave-approval-icon.svg";
import leaveCalendarIcon from "assets/images/pages/human-resources/leave-calendar-icon.svg";
import activeHourglassIcon from "assets/images/pages/human-resources/hourglass-active-icon.svg";
import activeLeaveApproveIcon from "assets/images/pages/human-resources/leave-approval-active-icon.svg";
import activeLeaveCalendarIcon from "assets/images/pages/human-resources/leave-calendar-active-icon.svg";

const PageLeaveManagement = () => {
	const navigate = useNavigate();

	//prettier-ignore
	const onConfigHoliday = useCallback(() => {
		navigate(pathnames.humanResources.hrViewHolidays);
	}, [navigate]);

	//prettier-ignore
	const leaveManagementTabs = useMemo(() => [
		{ label: "Leave Calendar", icon: leaveCalendarIcon, activeIcon: activeLeaveCalendarIcon, name: "LEAVE_CALENDAR", component: <AppLeaveCalendar />, accessible: true },
		{ label: "Attendance Directory", icon: clockIcon, activeIcon: activeClockIcon, name: "ATTENDANCE_DIRECTORY", component: <AppHrViewAttendanceDirectoryTable />, accessible: true },
		{ label: "Pending Clock Out", icon: hourglassIcon, activeIcon: activeHourglassIcon, name: "PENDING_CLOCKOUT", component: <AppHrViewPendingClockoutTable />, accessible: true },
		{ label: "Pending Leave", icon: leaveApproveIcon, activeIcon: activeLeaveApproveIcon, name: "PENDING_LEAVE", component: <AppHrViewPendingLeavesTable />, accessible: true },
		{ label: "Leave History", icon: clockIcon, activeIcon: activeClockIcon, name: "LEAVE_HISTORY", component: <AppHrViewLeaveHistoryTable />, accessible: true },
	], []);

	return (
		<div className="page-leave-management">
			<div className="leave-management">
				<div className="leave-management__header">
					<h1 className="leave-management__title">All Leave</h1>

					<div className="leave-management__configuration-button">
						<AppButton outline type="button" label="Configuration Holiday" icon={gearIcon} onClick={onConfigHoliday} />
					</div>
				</div>

				<div className="leave-management__body">
					<AppTabs tabs={leaveManagementTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageLeaveManagement;
