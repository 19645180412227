import React, { Fragment, useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import validateFileSize from "common/validate-file-size";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppAssetConfirmationModal from "components/pages/work-completion-report/app-asset-confirmation-modal";
import AppWorkCompletionReportTeamPicTable from "components/pages/work-completion-report/app-work-completion-report-team-pic-table";
import AppWorkCompletionReportDetailsAssetTable from "components/pages/work-completion-report/app-work-completion-report-details-asset-table";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";

const AppWorkCompletionReportDetails = (props, ref) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Next" : "Update"), [isCreate]);
	const confirmationRef = useRef();
	const signatureTypes = useMemo(() => ({ preparedBys: "preparedBys", verifiedBys: "verifiedBys", acknowledgedBys: "acknowledgedBys" }), []);

	//prettier-ignore
	const decisionOptions = useMemo(() => [
		{ label: "Proceed", value: "PROCEED" },
		{ label: "Terminate", value: "TERMINATE" }
	], []);

	const initialValues = useMemo(() => {
		const values = {
			city: "",
			email: "",
			state: "",
			status: "",
			picName: "",
			postcode: "",
			mobileNo: "",
			reportId: "123",
			customerPic: "",
			reportTitle: "",
			workInspectionReportId: "",
			workOrderId: "",
			verifiedType: "",
			customerName: "",
			addressLine1: "",
			addressLine2: "",
			completionDate: "",
			completionTime: "",
			salesOrderId: "",
			scopeOfWork: "",
			acknowledgementType: "",
			preparedBys: [{ name: "", signature: null }],
			verifiedBys: [{ name: "", signature: null }],
			acknowledgedBys: [{ name: "", signature: null }]
		};

		return values;
	}, []);

	const onHandleSubmit = (values) => {
		if (formik.values.status === STATUS.CANCELLED) {
			confirmationRef.current.onHandleShow(formik.values.reportId, () => props.onSubmit(values));
		} else {
			props.onSubmit(values);
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			city: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			picName: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			reportTitle: yup.string().required(ERRORS.REQUIRED),
			workInspectionReportId: yup.string().required(ERRORS.REQUIRED),
			workOrderId: yup.string().required(ERRORS.REQUIRED),
			customerName: yup.string().required(ERRORS.REQUIRED),
			addressLine1: yup.string().required(ERRORS.REQUIRED),
			addressLine2: yup.string().required(ERRORS.REQUIRED),
			salesOrderId: yup.string().required(ERRORS.REQUIRED),
			scopeOfWork: yup.string().required(ERRORS.REQUIRED),
			completionDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			completionTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			preparedBys: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			verifiedBys: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			acknowledgedBys: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const confirmByButtonVisible = useMemo(() => !formik.values.preparedBys.some((o) => !o.signature) && formik.values.preparedBys.length < 3, [formik.values.preparedBys]);

	//prettier-ignore
	const onHandleRemoveConfirmBy = useCallback((type, removeIndex) => {
		const nextFields = [...formik.values[type]].filter((_, i) => i !== removeIndex);

		formik.setFieldValue(type, nextFields);
	}, [formik]);

	//prettier-ignore
	const onHandleAddConfirmBy = useCallback((type) => {
		const field = { name: "", signature: null };
		const fields = [...formik.values[type]];

		fields.push(field);

		formik.setFieldValue(type, fields);
	}, [formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workCompletionReport.workCompletionReport);
	}, [navigate]);

	return (
		<div className="app-work-completion-report-details">
			<div className="work-completion-report-details">
				<form className="work-completion-report-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-completion-report-details__container">
						<p className="work-completion-report-details__label">General Details</p>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row work-completion-report-details__row--divider">
							<AppInput disabled type="text" name="reportId" label="Report ID" placeholder="" value={formik.values.reportId} error={formik.errors.reportId} touched={formik.touched.reportId} onChange={formik.handleChange} />

							<AppSelectInput required searchable={false} type="text" name="status" label="Status" placeholder="Select..." options={[{label: "Cancelled", value: STATUS.CANCELLED}]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Customer Details</p>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppInput type="text" name="customerName" label="Customer Name" placeholder="Customer Name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="customerPic" label="Customer PIC" placeholder="" value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={formik.handleChange} />
						</div>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppInput type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							<AppInput type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput name="state" label="State" placeholder="State" options={[]} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppSelectInput name="city" label="City" placeholder="City" options={[]} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--divider">
							<AppSelectInput name="postcode" label="Postcode" placeholder="Postcode" options={[]} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>

						<AppWorkCompletionReportDetailsAssetTable />
					</div>

					{/* prettier-ignore */}
					<div className="work-completion-report-details__container">
						<div className="work-completion-report-details__row">
							<AppInputDate required name="completionDate" label="Completion Date" placeholder="DD/MM/YYYY" value={formik.values.completionDate} error={formik.errors.completionDate} touched={formik.touched.completionDate} onChange={formik.setFieldValue} />

							<AppInputTime required name="completionTime" label="Completion Time" placeholder="HH:MM" value={formik.values.completionTime} error={formik.errors.completionTime} touched={formik.touched.completionTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--divider">
							<AppSelectInput required name="picName" label="Team PIC" placeholder="Select..." options={[]} value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
						</div>

						<AppWorkCompletionReportTeamPicTable />
					</div>

					{/* prettier-ignore */}
					<div className="work-completion-report-details__container">
						<div className="work-completion-report-details__row">
							<div className="work-completion-report-details__column">
								<AppInput required type="text" name="reportTitle" label="Report Title" placeholder="Enter Report Title" value={formik.values.reportTitle} error={formik.errors.reportTitle} touched={formik.touched.reportTitle} onChange={formik.handleChange} />

								<AppSelectInput required name="workOrderId" label="Work Order ID" placeholder="Select..." options={[]} value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />
							</div>

							<AppInput required multiline type="textarea" name="scopeOfWork" label="Scope of Work" placeholder="Enter Scope of Work" value={formik.values.scopeOfWork} error={formik.errors.scopeOfWork} touched={formik.touched.scopeOfWork} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--divider">
							<AppSelectInput required name="workInspectionReportId" label="Work Inspection Report ID" placeholder="Select..." options={[]} value={formik.values.workInspectionReportId} error={formik.errors.workInspectionReportId} touched={formik.touched.workInspectionReportId} onChange={formik.handleChange} />

							<AppSelectInput required multiline type="textarea" name="salesOrderId" label="Sales Order ID" placeholder="Enter Sales Order ID" value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--signature">
							<div className="work-completion-report-details__column work-completion-report-details__column--signature">
								{formik.values.preparedBys.map((o, i) => {
									const deleteButtonVisible = formik.values.preparedBys.length > 1;
									const error = formik.errors.preparedBys?.[i];
									const touched = formik.touched.preparedBys?.[i];

									return (
										//prettier-ignore
										<Fragment key={i}> 
											<AppSelectInput required name={`preparedBys[${i}]name`} label="Prepared By" placeholder="Search by User ID or Name" options={[]} value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} /> 

											<AppInputDragAndDrop name={`preparedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											{deleteButtonVisible && ( 
												<AppButton className="work-completion-report-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.preparedBys, i)} />
											)}
										</Fragment>
									);
								})}

								{confirmByButtonVisible && (
									<AppButton className="work-completion-report-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.preparedBys)} />
								)}
							</div>

							<div className="work-completion-report-details__column work-completion-report-details__column--signature">
								{formik.values.acknowledgedBys.map((o, i) => {
									const deleteButtonVisible = formik.values.acknowledgedBys.length > 1;
									const error = formik.errors.acknowledgedBys?.[i];
									const touched = formik.touched.acknowledgedBys?.[i];

									return (
										<Fragment key={i}>
											<AppInput required type="text" name={`acknowledgedBys[${i}]name`} label="Acknowledged By" placeholder="Acknowledged By" value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`acknowledgedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											<AppRadioInput required label="Acknowledgement Decision" options={decisionOptions} value={formik.values.acknowledgementType} error={formik.errors.acknowledgementType} touched={formik.touched.acknowledgementType} onChange={(v) => formik.setFieldValue("acknowledgementType", v)} />

											{/* prettier-ignore */}
											{deleteButtonVisible && (
												<AppButton className="work-completion-report-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.acknowledgedBys, i)} />
											)}
										</Fragment>
									);
								})}

								{confirmByButtonVisible && (
									<AppButton className="work-completion-report-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.acknowledgedBys)} />
								)}
							</div>

							<div className="work-completion-report-details__column work-completion-report-details__column--signature">
								{formik.values.verifiedBys.map((o, i) => {
									const error = formik.errors.verifiedBys?.[i];
									const touched = formik.touched.verifiedBys?.[i];
									const deleteButtonVisible = formik.values.verifiedBys.length > 1;

									return (
										<Fragment key={i}>
											<AppInput required type="text" name={`verifiedBys[${i}]name`} label="Verified By" placeholder="Verified By" value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`verifiedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											<AppRadioInput required label="Verified Decision" options={decisionOptions} value={formik.values.verifiedType} error={formik.errors.verifiedType} touched={formik.touched.verifiedType} onChange={(v) => formik.setFieldValue("verifiedType", v)} />

											{/* prettier-ignore */}
											{deleteButtonVisible && (
												<AppButton className="work-completion-report-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.verifiedBys, i)} />
											)}
										</Fragment>
									);
								})}

								{confirmByButtonVisible && (
									<AppButton className="work-completion-report-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.verifiedBys)} />
								)}
							</div>
						</div>
					</div>

					<div className="work-completion-report-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>

			<AppAssetConfirmationModal ref={confirmationRef} />
		</div>
	);
};
export default AppWorkCompletionReportDetails;
