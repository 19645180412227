import React from "react";

import ReactDOM from "react-dom/client";

import App from "./app";
import "./styles/index.scss";
import packageJson from "../package.json";

/* -- initalize version -- */
const dom = document.getElementById("root");

dom?.setAttribute("data-version", packageJson.version);

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_MODE) {
	const titles = document.title.split("-");

	document.title = [titles[0], "v" + packageJson.version, titles[1]].join(" - ");
}

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
