import React, { memo } from "react";

const AppPaymentIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<g id="credit card">
				<path id="Vector" d="M18.88 5.83002H5.06C4.19844 5.83002 3.5 6.52845 3.5 7.39002V16.6C3.5 17.4616 4.19844 18.16 5.06 18.16H18.88C19.7416 18.16 20.44 17.4616 20.44 16.6V7.39002C20.44 6.52845 19.7416 5.83002 18.88 5.83002Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
				<path id="Vector_2" d="M3.90039 9.64001H20.5004" stroke={color} />
				<path id="Vector_3" d="M5.74023 14.43H12.1502" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
				<path id="Vector_4" d="M15.5996 14.43H17.9796" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			</g>
		</svg>
	);
};

export default memo(AppPaymentIcon);
