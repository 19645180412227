import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import PAGE from "constants/page";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import addIcon from "assets/images/add-icon.png";
import editIcon from "assets/images/edit-icon.png";
import filterIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";

const workData = [
	{ paginationNumbers: "1", id: "001", customerAssetName: "Asset A", assetName: "Asset Alpha", workOrderId: "WO12345", quotationId: "Q12345", status: STATUS.ACKNOWLEDGED, createdBy: "User1" },
	{ paginationNumbers: "2", id: "002", customerAssetName: "Asset B", assetName: "Asset Beta", workOrderId: "WO12346", quotationId: "Q12346", status: STATUS.CANCELLED, createdBy: "User2" },
	{ paginationNumbers: "3", id: "003", customerAssetName: "Asset C", assetName: "Asset Gamma", workOrderId: "WO12347", quotationId: "Q12347", status: STATUS.OPEN, createdBy: "User3" },
	{ paginationNumbers: "4", id: "004", customerAssetName: "Asset D", assetName: "Asset Delta", workOrderId: "WO12348", quotationId: "Q12348", status: STATUS.VERIFIED, createdBy: "User4" }
];

const PageWorkCompletionReport = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(workData);

	const [menuAnchor, setMenuAnchor] = useState(null);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: {
			search: "",
			status: [STATUS.OPEN, STATUS.COMPLETED, STATUS.VERIFIED, STATUS.ACKNOWLEDGED, STATUS.CANCELLED]
		},
		onSubmit: onHandleSubmit
	});

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);

		formik.resetForm();
	}, [formik]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Open", name: STATUS.OPEN },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Verified", name: STATUS.VERIFIED },
			{ label: "Acknowledged", name: STATUS.ACKNOWLEDGED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleCreateReport = useCallback(() => {
		navigate(pathnames.workCompletionReport.workCompletionReportCreateEdit + PAGE.CREATE);
	}, [navigate]);

	//prettier-ignore
	const onHandleEditReport = useCallback((obj) => {
		const id = data[obj.rowIndex]?.id;

		navigate(pathnames.workCompletionReport.workCompletionReportCreateEdit + id);
	}, [data, navigate]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "id",
			label: "WCR ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: false,
			},
		},
		{
			name: "workOrderId",
			label: "Work Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "quotationId",
			label: "Quotation ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
        {
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />,
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__edit" onClick={() => onHandleEditReport(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditReport]);

	useEffect(() => {
		setData(workData);
	}, []);

	return (
		<div className="page-work-completion-report">
			<div className="work-completion-report">
				<h1 className="work-completion-report__title">Work Completion Report</h1>

				<div className="work-completion-report__header">
					<div className="work-completion-report__search-input">
						<AppInput type="text" name="search" placeholder="Search by Asset Name or WCR ID" endIcon={searchIcon} iconPosition="end" value={formik.values.search} onChange={formik.handleChange} />

						<div className="work-completion-report__filter-button">
							<AppButton outline type="button" label="Filter" icon={filterIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>

					<div className="work-completion-report__create-button">
						<AppButton type="button" label="Create" icon={addIcon} onClick={onHandleCreateReport} />
					</div>
				</div>

				<div className="work-completion-report__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "work-completion-report-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<div className="filter-menu__status">
							<p className="filter-menu__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex(i => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>
					</div>

					<div className="filter-menu__footer">
						{/* prettier-ignore */}
						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>Cancel</button>
							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>Apply</button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default PageWorkCompletionReport;
