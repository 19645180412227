import { useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import pathnames from "routes/pathnames";

import api from "services/api";
import getRolesListing from "services/get-roles-listing";
import getUserStatusListing from "services/get-user-status-listing";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputMultiSelect from "components/app-input-select-multiple";
import AppEditUserInformationModal from "components/pages/operations/user-access/app-edit-user-information-modal";

const AppEditUserInformation = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const editUserInformationModalRef = useRef();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.USER_ACCESS], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create || props.isSuperAdmin, [accessible, props.isSuperAdmin]);

	const initialValues = useMemo(() => {
		let data = { id: "", roles: [], userId: "", status: "", prefixNo: "", mobileNo: "", email: "" };

		if (props.user) {
			data = props.user;
		}

		return data;
	}, [props.user]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			userId: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			prefixNo: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().required(ERRORS.EMAIL),
			roles: yup.array().required(ERRORS.REQUIRED).min(1, ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			if (values.status === STATUS.ACTIVE) {
				onHandleSubmit(values);
			} else {
				onHandleDeactivateStatus();
			}
		}
	});

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				roleIds: values.roles,
				id: values.id,
				status: values.status,
			};

			await api.post.userAccess.updateUser(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "User was Edited successfully!" }));

			navigate(pathnames.operations.userAccess + "?tab=USER_LISTING");
		}
	}, [dispatch, formik, navigate]);

	// prettier-ignore
	const onHandleDeactivateStatus = useCallback(() => {
		editUserInformationModalRef.current.onHandleShow();
	}, []);

	const onHandleCancel = () => {
		navigate(`${pathnames.operations.userAccess + "?tab=USER_LISTING"}`);
	};

	return (
		<div className="app-edit-user-information">
			<div className="edit-user-information">
				<form className="edit-user-information__form" onSubmit={formik.handleSubmit}>
					<div className="edit-user-information__container">
						<div className="edit-user-information__label">Roles</div>

						<div className="edit-user-information__row">
							{/* prettier-ignore */}
							<AppInputMultiSelect searchable={false} label="Roles" name="roles" required disabled={restricted} touched={formik.touched.roles} error={formik.errors.roles} loadOptions={getRolesListing} value={formik.values.roles} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="edit-user-information__container">
						<div className="edit-user-information__label">Personal Information</div>

						<div className="edit-user-information__row">
							{/* prettier-ignore */}
							<AppInput name="userId" type="text" required disabled label="User ID" placeholder="User ID" touched={formik.touched.userId} error={formik.errors.userId} value={formik.values.userId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput searchable={false} type="text" name="status" label="Status" required disabled={restricted} loadOptions={getUserStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="edit-user-information__row">
							{/* prettier-ignore */}
							<AppMobileInput required disabled name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput name="email" type="text" disabled label="Email" placeholder="Email" value={formik.values.email} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="edit-user-information__button-container">
						<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

						<AppButton disabled={formik.isSubmitting || restricted} type="submit" label="Update" />
					</div>
				</form>

				<AppEditUserInformationModal ref={editUserInformationModalRef} values={formik.values} onHandleSubmit={onHandleSubmit} />
			</div>
		</div>
	);
};

export default AppEditUserInformation;
