import React, { useMemo } from "react";

import { useFormik } from "formik";
import { useLocation } from "react-router-dom";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import STATUS from "constants/status";

import AppTabs from "components/app-tabs";
import AppStatus from "components/app-status";
import AppCorrectiveClockOut from "components/pages/human-resources/app-corrective-clock-out-table";
import AppAttendanceSummaryCalendar from "components/pages/human-resources/app-attendance-summary-calendar";

import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import leaveIcon from "assets/images/pages/human-resources/leave-icon.svg";
import clockActiveIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import leaveactiveIcon from "assets/images/pages/human-resources/leave-active-icon.svg";

const dummyyData = { name: "NO DATA", employeeId: "000", todayStatus: STATUS.ON_LEAVE };

const PageViewAttendance = () => {
	const location = useLocation();
	const dataRef = useMemo(() => (location?.state ? location?.state : dummyyData), [location]);

	const formik = useFormik({
		initialValues: { items: dataRef }
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Leave Management", path: pathnames.humanResources.leaveManagement },
			{ label: "Leave", path: pathnames.humanResources.hrViewHolidays }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const attendanceTabs = useMemo(() => [
        { label: "Attendance Summary", icon: leaveIcon, activeIcon: leaveactiveIcon, name: "ATTENDANCE_SUMMARY", component: <AppAttendanceSummaryCalendar />, accessible: true },
        { label: "Corrective Clock Out", icon: clockIcon, activeIcon: clockActiveIcon, name: "CORRECTIVE_CLOCK_OUT", component: <AppCorrectiveClockOut />, accessible: true },
    ], []);

	return (
		<div className="page-view-attendance">
			<div className="view-attendance">
				<div className="view-attendance__header">
					<h1 className="view-attendance__title">{formik.values.items.name}</h1>

					<AppStatus status={formik.values.items.todayStatus} />
				</div>

				<div className="view-attendance__body">
					<p className="view-attendance__id">{formik.values.items.employeeId}</p>

					<AppTabs tabs={attendanceTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageViewAttendance;
