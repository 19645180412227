import React, { useMemo } from "react";

import AppTabs from "components/app-tabs";
import AppAssetTypesTable from "components/pages/customer/app-asset-types-table";
import AppWorkSpacesTable from "components/pages/customer/app-work-spaces-table";
import AppBuildingTypesTable from "components/pages/customer/app-building-types-table";

import assetTypesIcon from "assets/images/pages/customer/asset-types-icon.svg";
import buildingTypesIcon from "assets/images/pages/customer/building-types-icon.svg";
import workSpaceTypesIcon from "assets/images/pages/customer/work-space-types-icon.svg";
import activeAssetTypesIcon from "assets/images/pages/customer/asset-types-active-icon.svg";
import activeBuildingTypesIcon from "assets/images/pages/customer/building-types-active-icon.svg";
import activeWorkSpaceTypesIcon from "assets/images/pages/customer/work-space-types-active-icon.svg";

const PageCustomerConfiguration = () => {
	//prettier-ignore
	const inventoryTabs = useMemo(() => [
        { label: "Building Types", icon: buildingTypesIcon, activeIcon: activeBuildingTypesIcon, name: "BUILDING_TYPES", component: <AppBuildingTypesTable />, accessible: true },
        { label: "Work Spaces", icon: workSpaceTypesIcon, activeIcon: activeWorkSpaceTypesIcon, name: "WORK_SPACES", component: <AppWorkSpacesTable />, accessible: true },
        { label: "Asset Types", icon: assetTypesIcon, activeIcon: activeAssetTypesIcon, name: "ASSET_TYPES", component: <AppAssetTypesTable />, accessible: true }
    ], []);

	return (
		<div className="page-customer-configuration">
			<div className="customer-configuration">
				<h1 className="customer-configuration__title">Customer Configuration</h1>

				<div className="customer-configuration__body">
					<AppTabs tabs={inventoryTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageCustomerConfiguration;
