import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppCustomerAssetListFailed = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [fileName, setFileName] = useState("");
	const [errorRow, setErrorRow] = useState(0);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback((file, errorRow) => {
		setFileName(file.name);
		setErrorRow(errorRow);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-asset-list-failed-modal" }} open={visible}>
			<div className="customer-asset-list-failed-modal">
				<button type="button" className="customer-asset-list-failed-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-asset-list-failed-modal__title">Oh No!</h1>

				<p className="customer-asset-list-failed-modal__description">
					The uploaded template <b> {fileName} </b> has an <span> error. </span>
				</p>

				<p className="customer-asset-list-failed-modal__description">
					Please recheck the Asset highlighted in <span>red</span> and re-upload the template.
				</p>

				<p className="customer-asset-list-failed-modal__failed-row">Asset {errorRow}</p>

				{/* <p className="customer-asset-list-failed-modal__description">{data.length} Assets have been succcessfully uploaded.</p>

				<div className="customer-asset-list-failed-modal__success-list">
					{data?.map((dataFile, i) => (
						<p className="customer-asset-list-failed-modal__file-name" key={i}>
							{dataFile.name}
						</p>
					))}
				</div> */}

				<div className="customer-asset-list-failed-modal__button-container">
					<AppButton type="button" label="Okay" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerAssetListFailed));

AppCustomerAssetListFailed.propTypes = {
	ref: PropTypes.object.isRequired
};
