import { useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import MAX_FILE_SIZES from "constants/max-file-size";
import validateFileSize from "common/validate-file-size";
import validateImageDimension from "common/validate-image-dimension";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppNotificationFileUpload from "components/pages/operations/notifications/app-notifications-file-upload";
import AppNotificationsRecipientModal from "components/pages/operations/notifications/app-notifications-recipient-modal";
import AppNotificationsViewRecipientsModal from "components/pages/operations/notifications/app-notifications-view-recipients-modal";

import trash from "assets/images/trash-icon.png";
import copy from "assets/images/copy-white-icon.png";

const PageNotificationsCreateEdit = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const status = useMemo(() => location.state?.status, [location]);
	const isCreate = useMemo(() => status === PAGE.CREATE, [status]);

	const recipientModalRef = useRef();
	const viewRecipientModalRef = useRef();
	const deleteNotificationModalRef = useRef();
	const submitLabel = useMemo(() => (isCreate ? "Create" : "Update"), [isCreate]);
	const title = useMemo(() => (isCreate ? "Create Scheduled Notification" : "Edit Scheduled Notification"), [isCreate]);

	const rowData = useMemo(() => props.rowData, [props.rowData]);

	// prettier-ignore
	const initialValues = useMemo(() => ({ title: "", scheduledDay: null, scheduledTime: null, content: "", recipientType: [], recipients: [], file: null, pushNotification: false }), []);

	// prettier-ignore
	const formValues = useMemo(() => {
		const values = rowData ? { title: rowData.title, scheduledDay: rowData.scheduledDay, scheduledTime: rowData.scheduledTime, content: rowData.content, recipientType: rowData.recipientType, recipients: rowData.recipients, file: rowData.file, pushNotification: rowData.pushNotification } : initialValues;

		return values;
	}, [initialValues, rowData]);

	const onHandleSubmit = (values) => {};

	// prettier-ignore
	const formik = useFormik({
		initialValues: formValues,
		validationSchema: yup.object({
			title: yup.string().required(ERRORS.REQUIRED),
			scheduledDay: yup
				.date()
				.test("is-valid-date", "Please set a valid date", (value) => {

					return dayjs(value).isValid() && dayjs(value).isSameOrAfter(dayjs(), "day");
				})
				.required(ERRORS.REQUIRED),
			scheduledTime: yup
				.date()
				.test("is-valid-time", "Please set a valid time", (value) => {
					const scheduledTime = dayjs(value);
					const currentDateTime = dayjs();
					const scheduledDate = dayjs(formik.values.scheduledDay, "YYYY-MM-DD");

					if (!scheduledTime.isValid()) {

						return false;
					}

					// If scheduledDay is the same as the current date, check if scheduledTime is at least 3 minutes ahead of the current time
					if (scheduledDate.isSame(currentDateTime, "day")) {

						return scheduledTime.diff(currentDateTime, "minute") >= 3;
					}

					// If scheduledDay is not the same as the current date, there is no need to check if scheduledTime is ahead of the current time
					return true;
				})
				.required(ERRORS.REQUIRED),
			content: yup.string().required(ERRORS.REQUIRED),
			recipientType: yup.array().required(ERRORS.REQUIRED).min(1, ERRORS.REQUIRED),
			recipients: yup.array().required(ERRORS.REQUIRED).min(1, ERRORS.REQUIRED),
			file: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, (v) => validateFileSize(v, MAX_FILE_SIZES.MB_15)).test("fileDimension", ERRORS.CAMPAIGN_IMAGE_SIZE, (v) => validateImageDimension({ width: 1216, height: 480 }, v)),
			pushNotification: yup.boolean(),
		}),
		onSubmit: onHandleSubmit,
	});

	const onHandleDeleteNotificationModal = () => {
		deleteNotificationModalRef.current.onHandleShow();
	};

	const onHandleBack = useCallback(() => {
		navigate(pathnames.notification.notifications);
	}, [navigate]);

	const redirectToCreatePage = () => {
		navigate(`${pathnames.notification.notificationsCreateEdit}`, { state: { status: PAGE.CREATE }, rowData: { ...initialValues } });
	};

	// prettier-ignore
	const onHandlePushNotifications = useCallback((value) => {
		formik.setFieldValue("pushNotification", value);
	}, [formik]);

	// prettier-ignore
	const Header = useCallback((obj) => {
		if (obj.status === PAGE.EDIT) {
			
			return (
				<div className="notifications-create-edit__delete-notification">
					<button type="button" className="notifications-create-edit__delete-button" onClick={obj.deleteNotification}>
						<div className="notifications-create-edit__label">Delete</div>

						<AppIcon src={trash} />
					</button>
				</div>
			);
		}
		if (obj.status === PAGE.CREATE) {

			return (
				<div className="notifications-create-edit__clear-notification">
					<button type="button" className="notifications-create-edit__clear-button" onClick={obj.resetForm}>
						<div className="notifications-create-edit__label">Clear All</div>
					</button>
				</div>
			);
		}

		return null;
	}, []);

	return (
		<div className="page-notifications-create-edit">
			<div className="notifications-create-edit">
				<form className="notifications-create-edit__form" onSubmit={formik.handleSubmit}>
					<h1 className="notifications-create-edit__title">{title}</h1>

					<Header status={status} resetForm={formik.resetForm} deleteNotification={onHandleDeleteNotificationModal} />

					<div className="notifications-create-edit__container">
						<div className="notifications-create-edit__row">
							{/* prettier-ignore */}
							<AppInput required disabled={!isCreate} type="text" name="title" label="Title" placeholder="Title" value={formik.values.title} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />
						</div>

						<div className="notifications-create-edit__row">
							{/* prettier-ignore */}
							<AppInputDate required disabled={!isCreate} name="scheduledDay" label="Scheduled Day" placeholder="Scheduled Day" value={formik.values.scheduledDay} onChange={formik.setFieldValue}  />

							{/* prettier-ignore */}
							<AppInputTime disabled={!isCreate} required name="scheduledTime" label="Scheduled Time" helperText={"Please choose a time that's at least 3 minutes from now"} value={formik.values.scheduledTime} touched={formik.touched.scheduledTime} error={formik.errors.scheduledTime} onChange={formik.setFieldValue} />
						</div>

						<div className="notifications-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled={true} required name="content" label="Content" type="textarea" maxLength={5000} multiline={true} value={formik.values.content} touched={formik.touched.content} error={formik.errors.content} onChange={formik.handleChange} />
						</div>

						<div className="notifications-create-edit__row--divider" />

						<div className="notifications-create-edit__row notifications-create-edit__row--footer">
							{/* prettier-ignore */}
							<AppNotificationFileUpload title="Attachment" name="file" description="Please ensure that your image is in the correct format (jpg, png, 400px x 400px) with file size not exceeding 5MB." accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} disabled={!isCreate} value={formik.values.file} error={formik.errors.file} touched={formik.touched.file} />

							{/* prettier-ignore */}
							<AppCheckbox className="notifications-create-edit__push-notification" name="send-push-notification" label="Send push notification if applicable" disabled={!isCreate} value={formik.values.pushNotification} onClick={onHandlePushNotifications} />
						</div>
					</div>

					<div className="notifications-create-edit__buttons-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						{status && <AppButton type="submit" disabled={formik.isSubmiting} label={submitLabel} />}

						{!status && <AppButton className="notifications-create-edit__clone-button" type="button" icon={copy} label="Clone" onClick={redirectToCreatePage} />}
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<AppNotificationsRecipientModal ref={recipientModalRef} />

			<AppNotificationsViewRecipientsModal ref={viewRecipientModalRef} />
		</div>
	);
};

export default PageNotificationsCreateEdit;
