import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";
import AppInventoryHistoryApproveRejectComponent from "components/pages/inventory/app-inventory-history-approve-reject-component";

export const AppInventoryHistoryConfirmationModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);
	const [status, setStatus] = useState(null);

	const onHandleShow = useCallback((data, status) => {
		setData(data);

		setStatus(status);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		props.onHandleDismiss(data);

		setVisible(false);
	}, [data, props]);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-history-confirmation-modal" }} open={visible}>
			<div className="inventory-history-confirmation-modal">
				<h1 className="inventory-history-confirmation-modal__title">Are you sure?</h1>

				<AppInventoryHistoryApproveRejectComponent status={status} data={data} />

				<div className="inventory-history-confirmation-modal__button-container">
					<AppButton outline type="button" label="Back" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryHistoryConfirmationModal));

AppInventoryHistoryConfirmationModal.propTypes = {
	onHandleDismiss: PropTypes.func.isRequired
};
