import React, { memo, useCallback, useMemo, useRef, useState } from "react";

import Modal from "@mui/material/Modal";

import converReadableFileSize from "common/convert-readable-file-size";

import AppButton from "components/app-button";

import fileIcon from "assets/images/components/app-file-upload/file-icon.svg";
import removeIcon from "assets/images/components/app-file-upload/remove-icon.svg";
import expandIcon from "assets/images/components/app-file-upload/expand-file-icon.svg";

const AppNotificationsFileUpload = (props) => {
	const inputRef = useRef(null);
	const [imageModalVisible, setImageModalVisible] = useState(false);
	const [displayImage, setDisplayImage] = useState(null);
	const disabled = useMemo(() => props.disabled, [props.disabled]);
	const progress = useMemo(() => props.progress || "0%", [props.progress]);

	// prettier-ignore
	const onHandleFileChange = useCallback((event) => {
		const file = event.target.files[0];

        if (file) {
            const image = URL.createObjectURL(file);

            setDisplayImage(image);
        }

        props.onChange(props.name, file);
	}, [props]);

	const onHandleUpload = () => {
		inputRef.current.click();
	};

	const onHandleRemove = useCallback(() => {
		setDisplayImage("");
		inputRef.current.value = "";
		props.onChange(props.name, "");
	}, [props]);

	const onHandleImageVisible = () => {
		setImageModalVisible((prev) => !prev);
	};

	return (
		<div className="app-notifications-file-upload">
			<div className="file-upload">
				<div className="file-upload__title">{props.title}</div>

				<div className="file-upload__description">{props.description}</div>

				{props.value?.name && (
					<div className="file-upload__file">
						<div className="file-upload__file-wrapper">
							<div className="file-upload__icon">
								<img src={fileIcon} alt="fileIcon" />
							</div>

							<div className="file-upload__file-content">
								<p className="file-upload__name">{props.value.name}</p>

								<div className="file-upload__expand-image">
									<img src={expandIcon} alt="expandIcon" onClick={onHandleImageVisible} />
								</div>

								<p className="file-upload__size">{converReadableFileSize(props.value.size, true)}</p>

								<div className="file-upload__progress" style={{ width: progress }} />
							</div>
						</div>

						<div className="file-upload__remove-icon">
							<img src={removeIcon} alt="removeIcon" onClick={onHandleRemove} />
						</div>
					</div>
				)}

				{!props.value && <AppButton type="button" outline label="Upload" disabled={disabled} onClick={onHandleUpload} />}

				<input ref={inputRef} type="file" hidden name={props.name} onChange={onHandleFileChange} accept={props.accept} />

				<div className="file-upload__error">{props.error}</div>
			</div>

			<Modal className="app-notification-file-image-modal" open={imageModalVisible} onClose={onHandleImageVisible}>
				<div className="notification-file-image-modal">
					<img src={displayImage} alt="uploaded-file" />
				</div>
			</Modal>
		</div>
	);
};

export default memo(AppNotificationsFileUpload);
