import React, { memo } from "react";

const AppCloseIcon = ({ color = "#ffffff", width = "100%", height = "100%", viewBox = "0 0 32 32", ...props }) => {
	return (
		<svg {...props} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4 28L28 4" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4 4L28 28" stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppCloseIcon);
