import { useCallback, useRef } from "react";

import * as yup from "yup";
import COMMON from "common";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setProfile } from "store/slices/profile";
import { Link, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import stringEncryptRSA from "common/string-encrypt-rsa";
import serveRequestErrors from "common/serve-request-errors";

import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import OtpVerificationModal from "components/pages/login/app-otp-verification-modal";

import logo from "assets/images/logo.png";
import person from "assets/images/components/app-input/person-icon.png";
import passLock from "assets/images/components/app-input/passLock-icon.png";

const PageLogin = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const modalRef = useRef();

	const formik = useFormik({
		initialValues: { userId: process.env.REACT_APP_LOGIN_USER_ID, password: process.env.REACT_APP_LOGIN_PASSWORD, rememberMe: false },
		validationSchema: yup.object({
			userId: yup.string().required(ERRORS.REQUIRED),
			password: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetProfile = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.profile.profile();
		} catch (error) {
			formik.setSubmitting(false);

			serveRequestErrors(error);

			localStorage.removeItem(COMMON.AUTH_TOKEN);
			localStorage.removeItem(COMMON.REFRESH_TOKEN);
			sessionStorage.removeItem(COMMON.AUTH_TOKEN);
			sessionStorage.removeItem(COMMON.REFRESH_TOKEN);
		}

		if (response) {
			dispatch(setProfile(response));

			if (response.forceChangePassword === STATUS.FORCE_CHANGE_PASSWORD) {
				navigate(pathnames.userAccount + "?tab=RESET-PASSWORD");
			} else {
				navigate(pathnames.home);
			}
		}
	}, [formik, dispatch, navigate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			response = await api.post.authenticate.login({ ...values, password: stringEncryptRSA(values.password) });
		} catch (error) {
			formik.setSubmitting(false);
			
			serveRequestErrors(error, (message) => {
				formik.setFieldError("userId", message);
				formik.setFieldError("password", message);
			});
		}

		if (response) {
			const token = response.accessToken;
			const refreshToken = response.refreshToken;
			const requireOtp = response.requireOtp;

			if (values.rememberMe) {
				localStorage.setItem(COMMON.AUTH_TOKEN, token);
				localStorage.setItem(COMMON.REFRESH_TOKEN, refreshToken);
			} else {
				sessionStorage.setItem(COMMON.AUTH_TOKEN, token);
				sessionStorage.setItem(COMMON.REFRESH_TOKEN, refreshToken);
			}

			if(requireOtp){
				modalRef.current.onHandleShow({ email: response.email });
			}else{
				onHandleGetProfile();
			}
		}
	}, [formik, onHandleGetProfile]);

	//prettier-ignore
	const onHandleRememberMe = useCallback((boolean) => {
		formik.setFieldValue("rememberMe", boolean);
	}, [formik]);

	return (
		<div className="page-login">
			<main className="login">
				<form className="login__form" onSubmit={formik.handleSubmit}>
					<Link to={pathnames.login.login} className="login__logo">
						<img src={logo} alt="logo" />
					</Link>

					<h1 className="login__title">Log In</h1>

					{/* prettier-ignore */}
					<AppInput type="text" name="userId" placeholder="User ID" icon={person} iconInField={false} value={formik.values.userId} error={formik.errors.userId} touched={formik.touched.userId} onChange={formik.handleChange} />

					<AppInput type="password" name="password" placeholder="Password" icon={passLock} iconInField={false} value={formik.values.password} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange} />

					<div className="login__footer">
						<AppCheckbox onClick={onHandleRememberMe} label="Remember Me" value={formik.values.rememberMe} />

						<Link to={pathnames.forgotPassword.forgotPassword} className="login__forgot-password">
							Forgot Password?
						</Link>
					</div>

					<div className="login__button-container">
						<AppButton type="submit" label="Log In" disabled={formik.isSubmitting} />
					</div>
				</form>

				<OtpVerificationModal ref={modalRef} setSubmitting={formik.setSubmitting} />
			</main>
		</div>
	);
};

export default PageLogin;
