import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppCustomerTable from "components/pages/customer/app-customer-table";
import AppMobileAppUsersTable from "components/pages/customer/app-mobile-app-users-table";
import AppUserApplicationTable from "components/pages/customer/app-user-applications-table";

import phoneIcon from "assets/images/pages/customer/phone-icon.svg";
import customerIcon from "assets/images/pages/customer/customer-icon.svg";
import phoneIconActive from "assets/images/pages/customer/phone-active-icon.svg";
import pendingIcon from "assets/images/pages/customer/mobile-user-approval-icon.svg";
import customerIconActive from "assets/images/pages/customer/customer-active-icon.svg";
import pendingIconActive from "assets/images/pages/customer/mobile-user-approval-active-icon.svg";

const PageCustomer = () => {
	const profile = useSelector((state) => state.profile);

	//prettier-ignore
	const CustomerTabs = useMemo(() => [
		{ label: "Customer Table", icon: customerIcon,activeIcon: customerIconActive, name: "CUSTOMERS", component: <AppCustomerTable/>, accessible: profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE]?.view },
		{ label: "Mobile App Users", icon: phoneIcon,activeIcon: phoneIconActive, name: "MOBILE_APP_USER", component: <AppMobileAppUsersTable/>, accessible: profile?.permissions?.[ROLES.CUSTOMER_USER]?.view },
		{ label: "User Applications", icon: pendingIcon,activeIcon: pendingIconActive, name: "USER_APPLICATIONS", component: <AppUserApplicationTable/>, accessible: true },
	], [profile?.permissions]);

	return (
		<div className="page-customers">
			<div className="customers">
				<h1 className="customers__title">Customer Listing</h1>

				<div className="customers__body">
					<AppTabs tabs={CustomerTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageCustomer;
