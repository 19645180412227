import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppInvoiceVoidModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-invoice-void-modal" }} open={visible}>
			<div className="invoice-void-modal">
				<h1 className="invoice-void-modal__title">Are you sure?</h1>

				<p className="invoice-void-modal__description">After clicking confirm, the item will be voided.</p>

				<div className="invoice-void-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInvoiceVoidModal));

AppInvoiceVoidModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
