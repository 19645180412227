import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import closeIcon from "assets/images/close-icon.png";
import searchIcon from "assets/images/search-icon.png";

const temp = [{ paginationNumbers: 1, name: "Kiryu Kazuma", employeeId: "MCE0001-24" }];

export const AppReportDetailsTeamPicCreateEditModal = (props, ref) => {
	const [data, setData] = useState(temp);
	const [search, setSearch] = useState("");
	const [visible, setVisible] = useState(false);
	const [selectedServices, setSelectedServices] = useState([]);

	const onHandleShow = useCallback((values) => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	// prettier-ignore
	const onHandleAddServices = useCallback((boolean, obj) => {
		let nextId = [...selectedServices];

		const index = nextId.findIndex(o => o.serviceId === obj.serviceId);

		if(!boolean && index > -1) {
			nextId = nextId.filter(o => o.serviceId !== obj.serviceId);
		} else {
			nextId.push(obj);
		}

		setSelectedServices(nextId);
	}, [selectedServices]);

	const onHandleSearch = useCallback((event) => {
		setSearch(event.target.value);
	}, []);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
				sortThirdClickReset: true,
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = tableMeta.tableData[rowIndex];
					const selected = selectedServices.findIndex(o => o.serviceId === obj.serviceId) > -1;
					
					return <AppCheckbox label="" onClick={(v) => onHandleAddServices(v, obj)} value={selected} />;
				},
			},
		},
	], [selectedServices, onHandleAddServices]);

	useEffect(() => {
		setData(temp);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-report-details-team-pic-create-edit-modal" }} open={visible}>
			<div className="report-details-team-pic-create-edit-modal">
				<button type="button" className="report-details-team-pic-create-edit-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="report-details-team-pic-create-edit-modal__title">Add Team Member</h1>

				<div className="report-details-team-pic-create-edit-modal__form">
					<p className="report-details-team-pic-create-edit-modal__description">Select Employees from Human Resource Module</p>

					<div className="report-details-team-pic-create-edit-modal__search-input">
						<AppInput type="text" name="search" placeholder="Search by Service ID or Service Action" endIcon={searchIcon} iconPosition="end" value={search} onChange={onHandleSearch} />
					</div>

					<AppTable data={data} columns={tableColumns} />

					<div className="report-details-team-pic-create-edit-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="button" label="Add" />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppReportDetailsTeamPicCreateEditModal));

AppReportDetailsTeamPicCreateEditModal.propTypes = {
	ref: PropTypes.object
};
