import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import PAGE from "constants/page";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppClaimPendingApprovalRejectionModal from "components/pages/human-resources/app-claim-pending-approval-rejection-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import searchIcon from "assets/images/search-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const pendingAprovalData = [{ paginationNumbers: 1, name: "Giorno Giovanna", employeeId: "MCE0002-24", claimType: "Medical Claim", submitDate: "17/05/2023", amount: "90.00" }];

const AppClaimPendingApproval = () => {
	const appClaimPendingApprovalRejectionModalRef = useRef();
	const [data, setData] = useState(pendingAprovalData);
	const [searchParams] = useSearchParams();
	const [selectedPending, setSelectedPending] = useState([]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "PENDING_LEAVE" });

	const onHandlePendingApprovalReject = useCallback(() => {
		appClaimPendingApprovalRejectionModalRef.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleSelectPending = useCallback((boolean, id) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(id);
			} else {
				nextPending = nextPending.filter((pendingId) => pendingId !== id);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
        {
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
        {
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
        {
			name: "amount",
			label: "Amount(MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => {}}>
							<img src={eyeIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	//prettier-ignore
	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="claim-pending-approval-table__header-button">
				<div className="claim-pending-approval-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} onClick={onHandlePendingApprovalReject} />
				</div>

				<div className="claim-pending-approval-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} />
				</div>
			</div>
		);
	}, [onHandlePendingApprovalReject]);

	useEffect(() => {
		setData(pendingAprovalData);
	}, []);

	return (
		<div className="app-claim-pending-approval-table">
			<div className="claim-pending-approval-table">
				<div className="claim-pending-approval-table__header">
					<div className="claim-pending-approval-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name or Employee ID" endIcon={searchIcon} iconPosition="end" onChange={onHandleDebounceSearch} />
					</div>

					<HeaderApprovalButton selectedPending={selectedPending} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppClaimPendingApprovalRejectionModal ref={appClaimPendingApprovalRejectionModalRef} />
		</div>
	);
};

export default AppClaimPendingApproval;
