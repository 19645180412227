import React, { memo, useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { FormControl, InputAdornment, TextField } from "@mui/material";

import classNames from "common/class-names";

import AppIcon from "components/app-icon";

const AppMaskingInput = (props) => {
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const className = useMemo(() => {
		return classNames({
			"app-masking-input": true,
			"app-masking-input--disabled": props.disabled,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className, props.disabled]);

	const onHandleFormatPattern = useCallback((value, pattern, mask) => {
		const strippedValue = value.replace(/[^0-9]/g, "");
		const chars = strippedValue.split("");
		let count = 0;

		let formatted = "";

		for (let i = 0; i < pattern.length; i++) {
			const c = pattern[i];

			if (chars[count]) {
				if (/\*/.test(c)) {
					formatted += chars[count];
					count++;
				} else {
					formatted += c;
				}
			} else if (mask) {
				if (mask.split("")[i]) formatted += mask.split("")[i];
			}
		}
		return formatted;
	}, []);

	const onHandleChange = (event) => {
		const startCursor = event.target.selectionStart;
		const target = event.target;
		const value = target.value;
		const maskingValue = onHandleFormatPattern(target.value, props.format, props.mask);
		const endCursor = event.target.selectionEnd;

		if (value) {
			event.target.value = maskingValue;
		} else {
			event.target.value = "";
		}

		if (startCursor && startCursor === endCursor) {
			event.target.selectionEnd = maskingValue.length - value.length + startCursor + 1;
		}

		props.onChange(event);
	};

	const InputProps = useMemo(() => {
		const inputProps = {};

		if (props.icon) {
			const startAdornment = {
				startAdornment: (
					<InputAdornment position="start">
						<AppIcon src={props.icon} />
					</InputAdornment>
				)
			};

			Object.assign(inputProps, startAdornment);
		}

		return inputProps;
	}, [props.icon]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				<label className="app-masking-input__label" htmlFor={props.name}>
					{props.label}
					{props.required && <span className="app-masking-input__required">*</span>}
				</label>

				{/*prettier-ignore*/}
				<TextField autoComplete="off" defaultValue={props.defaultValue} value={props.value} type="text" name={props.name} error={isErrorField} helperText={errorMessage} disabled={props.disabled} placeholder={props.placeholder} onChange={onHandleChange} InputProps={InputProps} />
			</FormControl>
		</div>
	);
};

AppMaskingInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	mask: PropTypes.string,
	format: PropTypes.string.isRequired,
	value: PropTypes.string || PropTypes.number,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	type: PropTypes.oneOf(["text", "number"]).isRequired
};

export default memo(AppMaskingInput);
