import React, { memo } from "react";

const AppWorkOrderIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M9.37988 7.83997H14.7299" stroke={color} strokeWidth="0.82" strokeMiterlimit="10" />
			<path d="M9.37988 10.8199H14.7299" stroke={color} strokeWidth="0.82" strokeMiterlimit="10" />
			<path d="M6.99949 4.79994H19.7595V3.18994L18.5095 4.07994L17.2295 3.18994L15.9495 4.07994L14.6495 3.18994L13.3695 4.07994L12.0895 3.18994L10.7895 4.07994L9.50949 3.18994L8.22949 4.07994L6.97949 3.18994" fill="white" />
			<path d="M16.2503 8.30995C16.4003 8.46995 16.5703 8.54995 16.7703 8.54995C16.9803 8.54995 17.1703 8.46995 17.3203 8.30995C17.4703 8.14995 17.5403 7.97995 17.5403 7.77995C17.5403 7.57995 17.4703 7.39995 17.3203 7.24995C17.1703 7.08995 16.9903 7.00995 16.7703 7.00995C16.5703 7.00995 16.4003 7.08995 16.2503 7.24995C16.1003 7.40995 16.0303 7.58995 16.0303 7.77995C16.0303 7.96995 16.1003 8.15995 16.2503 8.30995Z" fill="white" />
			<path d="M16.2503 11.28C16.4003 11.44 16.5703 11.52 16.7703 11.52C16.9803 11.52 17.1703 11.44 17.3203 11.28C17.4703 11.12 17.5403 10.95 17.5403 10.75C17.5403 10.55 17.4703 10.37 17.3203 10.22C17.1703 10.06 16.9903 9.97995 16.7703 9.97995C16.5703 9.97995 16.4003 10.06 16.2503 10.22C16.1003 10.38 16.0303 10.56 16.0303 10.75C16.0303 10.94 16.1003 11.13 16.2503 11.28Z" fill="white" />
			<path d="M7.49023 17.2199V4.78995" stroke={color} strokeMiterlimit="10" />
			<path d="M19.2599 4.56995V17.7299C19.2599 17.7299 19.4899 20.0899 17.8899 20.1899H17.6299C15.9099 20.1899 16.0499 18.2199 16.0499 18.2199L16.0699 17.2699H4.6199V18.4299C4.6199 18.4299 4.4899 20.1899 6.0299 20.1899H17.4099" stroke={color} strokeMiterlimit="10" />
		</svg>
	);
};

export default memo(AppWorkOrderIcon);
