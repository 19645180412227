import { useEffect } from "react";

const useBeforeUnload = () => {
	useEffect(() => {
		const onhandleBeforeUnload = (event) => {
			event.preventDefault();
		};

		window.addEventListener("beforeunload", onhandleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", onhandleBeforeUnload);
		};
	}, []);
};

export default useBeforeUnload;
