import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppUserListingTable from "components/pages/operations/user-access/app-user-listing-table";
import AppRoleManagementTable from "components/pages/operations/user-access/app-role-management-table";

import personIcon from "assets/images/person-icon.svg";
import personActiveIcon from "assets/images/person-active-icon.svg";
import keysIcon from "assets/images/pages/user-access/keys-icon.svg";
import keysActiveIcon from "assets/images/pages/user-access/keys-active-icon.svg";

const PageGeneralConfiguration = () => {
	const profile = useSelector((state) => state.profile);

	// prettier-ignore
	const tabs = useMemo(() => {
		const data = [
			{ label: "Role Management", icon: keysIcon, activeIcon: keysActiveIcon, name: "ROLE_MANAGEMENT", component: <AppRoleManagementTable />, accessible: profile?.permissions?.[ROLES.ROLE]?.view },
			{ label: "User Listing", icon: personIcon, activeIcon: personActiveIcon, name: "USER_LISTING", component: <AppUserListingTable />,  accessible: profile?.permissions?.[ROLES.USER_ACCESS]?.view },
		];

		return data.filter(o => o.accessible);
}, [profile.permissions]);

	return (
		<div className="page-user-access">
			<div className="user-access">
				<h1 className="user-access__title">User Access</h1>

				<div className="user-access__body">
					<AppTabs tabs={tabs} />
				</div>
			</div>
		</div>
	);
};

export default PageGeneralConfiguration;
