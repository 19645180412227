import React, { memo } from "react";

const AppWorkInspectionListingIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M16.0001 17L14.0667 15.0667M15.1112 12.5556C15.1112 14.5192 13.5193 16.1111 11.5556 16.1111C9.5919 16.1111 8 14.5192 8 12.5556C8 10.5919 9.5919 9 11.5556 9C13.5193 9 15.1112 10.5919 15.1112 12.5556Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.99987 20.5L3.99987 3.69815L6.4973 5.18066L9.00465 3.5L11.9916 5.18066L15.0084 3.5L17.486 5.18066L19.9834 3.69815V20.5H3.99987Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M20.0001 4.39227V5.29141H18.0459H12.2064H7.2731L9.13569 4.14325L12.2064 5.29141L14.9248 4.14325L18.0459 5.29141L20.0001 4.39227Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M18.0459 5.29141H20.0001V4.39227L18.0459 5.29141ZM18.0459 5.29141H12.2064M18.0459 5.29141L14.9248 4.14325L12.2064 5.29141M12.2064 5.29141H7.2731M12.2064 5.29141L9.13569 4.14325L7.2731 5.29141M7.2731 5.29141H4.604L4.90062 4.68588" stroke={color} />
		</svg>
	);
};

export default memo(AppWorkInspectionListingIcon);
