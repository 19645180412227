import PageWorkCompletionReport from "pages/work-completion-report/page-work-completion-report";
import PageWorkCompletionReportCreateEdit from "pages/work-completion-report/page-work-completion-report-create-edit";

import pathnames from "routes/pathnames";

const workCompletionReportRoutes = [
	{
		path: pathnames.workCompletionReport.workCompletionReport,
		element: <PageWorkCompletionReport />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workCompletionReport.workCompletionReportCreateEdit + ":id",
		element: <PageWorkCompletionReportCreateEdit />,
		authenticated: true,
		role: null
	}
];

export default workCompletionReportRoutes;
