import React, { useCallback, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppAssetDeleteModal from "components/pages/work-completion-report/app-asset-delete-modal";
import AppAssetCreateEditModal from "components/pages/work-completion-report/app-asset-create-edit-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/add-blue-icon.png";
import searchIcon from "assets/images/search-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const assetData = [{ id: "WIR-001", customerAssetName: "Samsung ACs", assetName: "Samsung", workspace: "Hotel", assetType: "Air Conditioner" }];

const AppReportDetailsAssetTable = () => {
	const selectedItem = useRef();
	const assetRef = useRef();
	const assetDeleteRef = useRef();
	const [data, setData] = useState(assetData);
	const [holidayAnchor, setHolidayAnchor] = useState(null);
	const [searchParams] = useSearchParams();
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tabs: "REPORT_DETAILS" });

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	/* prettier-ignore */
	const onHandleEditReportDetailsAsset = useCallback(() => {
		assetRef.current.onHandleShow(selectedItem.current);
	}, []);

	const onHandleAddReportDetailsAsset = useCallback(() => {
		assetRef.current.onHandleShow();
	}, []);

	const onHandleDeleteReportDetailsAsset = useCallback(() => {
		assetDeleteRef.current.onHandleShow(selectedItem.current);
	}, []);

	//prettier-ignore
	const onHandleGetList = useCallback((values) => {
		let nextItems = [...data];

		if (!selectedItem.current) {
			const newData = { ...values, id: `WIR-${String(data.length + 1).padStart(3, '0')}` };
			
			nextItems.push(newData);
		} else {
			const indexToUpdate = selectedItem.current.rowIndex;

			nextItems[indexToUpdate] = { ...nextItems[indexToUpdate], ...values };
		}
		
		setData(nextItems);
	}, [data]);

	const onHandleDeleteHoliday = useCallback(() => {
		let nextItems = [...data];
		nextItems = nextItems.filter((_, i) => i !== selectedItem.current.rowIndex);

		setData(nextItems);

		setHolidayAnchor(null);
	}, [data]);

	const onHandleCloseHolidayTableMenu = useCallback(() => {
		selectedItem.current = null;

		setHolidayAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setHolidayAnchor(event.currentTarget);
	}, [data]);

	/* prettier-ignore */
	const tableColumns = useMemo(() => [
		{
			name: "id",
			label: "WIR ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
        {
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onToggleTableAction]);

	const emptyState = useMemo(() => {
		if (data.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Items Added. <span className="table__link">Add Items?</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data, tableColumns]);

	return (
		<div className="app-work-completion-report-details-asset-table">
			<div className="work-completion-report-details-asset-table">
				<p className="work-completion-report-details-asset-table__label">Assets</p>

				<div className="work-completion-report-details-asset-table__header">
					<div className="work-completion-report-details-asset-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.value} onChange={onHandleDebounceSearch} onBlur={() => {}} />
					</div>

					<div className="work-completion-report-details-asset-table__create-button">
						<AppButton outline type="button" label="Add Asset" icon={addIcon} onClick={onHandleAddReportDetailsAsset} components={emptyState} />
					</div>
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "table-menu" }} anchorEl={holidayAnchor} open={!!holidayAnchor} onClose={onHandleCloseHolidayTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditReportDetailsAsset}>
					<img src={editIcon} alt="asset-edit" />
					Edit
				</MenuItem>

				<MenuItem onClick={onHandleDeleteReportDetailsAsset}>
					<img src={trashIcon} alt="asset-remove" />
					Remove
				</MenuItem>
			</Menu>

			<AppAssetCreateEditModal ref={assetRef} onConfirm={onHandleGetList} />

			<AppAssetDeleteModal ref={assetDeleteRef} onConfirm={onHandleDeleteHoliday} />
		</div>
	);
};

export default AppReportDetailsAssetTable;
