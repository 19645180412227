import PageCustomers from "pages/customer/page-customers";
import PageEnquiries from "pages/customer/page-enquiries";
import PageCustomerCreateEdit from "pages/customer/page-customer-create-edit";
import PageEnquiriesCreateEdit from "pages/customer/page-enquiries-create-edit";
import PageMobileAppCreateEdit from "pages/customer/page-mobile-app-create-edit";
import PageCustomerCreateEditAsset from "pages/customer/page-customer-edit-asset";
import PageCustomerConfiguration from "pages/customer/page-customer-configuration";
import PageCustomerCreateEditSite from "pages/customer/page-customer-create-edit-site";
import PageCustomerCreateEditContract from "pages/customer/page-customer-create-edit-contract";

import pathnames from "routes/pathnames";

import ROLES from "constants/roles";

const customerRoutes = [
	{
		path: pathnames.customer.customers,
		element: <PageCustomers />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE, ROLES.CUSTOMER_USER]
	},
	{
		path: pathnames.customer.customerCreateEdit + ":id",
		element: <PageCustomerCreateEdit />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE]
	},
	{
		path: pathnames.customer.customerCreateEditContract + ":customerId/:id",
		element: <PageCustomerCreateEditContract />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE]
	},
	{
		path: pathnames.customer.customerCreateEditContract + ":id",
		element: <PageCustomerCreateEditContract />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE]
	},
	{
		path: pathnames.customer.customerCreateEditSite + ":contractId/:id",
		element: <PageCustomerCreateEditSite />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE]
	},
	{
		path: pathnames.customer.customerCreateEditSite + ":id",
		element: <PageCustomerCreateEditSite />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE]
	},
	{
		path: pathnames.customer.customerEditAsset + ":id",
		element: <PageCustomerCreateEditAsset />,
		authenticated: true,
		role: [ROLES.CUSTOMER_MAINTENANCE]
	},
	{
		path: pathnames.customer.mobileAppCreateEdit + ":id",
		element: <PageMobileAppCreateEdit />,
		authenticated: true,
		role: [ROLES.CUSTOMER_USER]
	},
	{
		path: pathnames.customer.enquiries,
		element: <PageEnquiries />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.customer.enquiriesCreateEdit + ":id",
		element: <PageEnquiriesCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.customer.customerConfiguration,
		element: <PageCustomerConfiguration />,
		authenticated: true,
		role: null
	}
];

export default customerRoutes;
