const generateRandomPassword = (length = 10) => {
	const string = "abcdefghijklmnopqrstuvwxyz";

	const numeric = "0123456789";

	const punctuation = "!@#$%^&*()_+~`|[]:;?><,./-=";

	let password = "";

	while (password.length < length) {
		const entity1 = Math.ceil(string.length * Math.random() * Math.random());

		const entity2 = Math.ceil(string.length * Math.random() * Math.random());

		const entity3 = Math.ceil(numeric.length * Math.random() * Math.random());

		const entity4 = Math.ceil(punctuation.length * Math.random() * Math.random());

		password += string.charAt(entity1);

		password += string.toUpperCase().charAt(entity2);

		password = numeric.charAt(entity2) + password;

		password += punctuation.charAt(entity3);

		password += punctuation.charAt(entity4);
	}

	password = password.substring(0, length);

	return password;
};

export default generateRandomPassword;
