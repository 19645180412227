import React, { memo, useMemo } from "react";

import PropTypes from "prop-types";

import classNames from "../common/class-names";

const AppIcon = (props) => {
	const className = useMemo(() => {
		return classNames({
			"app-icon": true,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className]);

	return (
		<div className={className}>
			<img className="app-icon__i" src={props.src} alt="icon" />
		</div>
	);
};

AppIcon.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string.isRequired
};

export default memo(AppIcon);
