import React, { useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getServiceStatusListing from "services/get-service-status-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInventoryTransferModal from "components/pages/inventory/app-inventory-transfer-modal";
import AppInventoryMinusStockModal from "components/pages/inventory/app-inventory-minus-stock-modal";
import AppInventoryAdjustStockModal from "components/pages/inventory/app-inventory-adjust-stock-modal";

import minusIcon from "assets/images/minus-icon.png";
import addIcon from "assets/images/add-blue-icon.png";
import transferIcon from "assets/images/pages/inventory/transfer-icon.png";

const PageInventoryCreateEdit = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Item" : "Edit Item"), [isCreate]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const inventoryTransferModalRef = useRef();
	const inventoryMinusStockModalRef = useRef();
	const inventoryAdjustStockModalRef = useRef();

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Inventory", path: pathnames.inventory.inventories },
			{ label: "Inventory Listing", path: pathnames.inventory.inventories }
		];

		if (isCreate) {
			data.push({ label: "Add Item", path: pathnames.inventory.inventoryCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Item " + id, path: pathnames.inventory.inventoryCreateEdit + id });
		}

		return data;
	}, [isCreate, id]);

	useBreadcrumb({ breadCrumb });

	const defaultSparePartOptions = useRef(null);

	const initialValues = useMemo(() => {
		const values = {
			isCreate: isCreate,
			barcodeId: "",
			sparePartImage: "",
			status: "",
			sparePart: "",
			sparePartCategory: "",
			sparePartSubCategory: "",
			quantity: "",
			reservedQuantity: "",
			minimumThreshold: "",
			location: "",
			costPrice: "",
			sellPrice: "",
			supplier: "",
			quantityToDeduct: "",
			remark: ""
		};

		return values;
	}, [isCreate]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			sparePart: yup.string().required(ERRORS.REQUIRED),
			sparePartCategory: yup.string().required(ERRORS.REQUIRED),
			sparePartSubCategory: yup.string().required(ERRORS.REQUIRED),
			quantity: yup.number().required(ERRORS.REQUIRED),
			minimumThreshold: yup.number().required(ERRORS.REQUIRED),
			location: yup.string().required(ERRORS.REQUIRED),
			costPrice: yup.string().when(["isCreate"], {
				is: (boolean) => boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			sellPrice: yup.string().when(["isCreate"], {
				is: (boolean) => boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			supplier: yup.string().when(["isCreate"], {
				is: (boolean) => boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			})
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	// UNCOMMENT WHEN API IS READY
	// const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	// const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	// prettier-ignore
	// const onHandleGetDetails = useCallback(async (uniqueId) => {
	// 	let response = null;

	// 	try {
	// 		// UNCOMMENT WHEN API IS READY
	// 		// response = await api.get.inventory.inventory(uniqueId);
	// 	} catch (error) {
	// 		serveLayoutRequestErrors(error);
	// 	}

	// 	if (response) {
	// 		memoSetFormValues({
	// 			barcodeId: response.barCodeId,
	// 			sparePartImage: response.sparePartImage,
	// 			status: response.status,
	// 			sparePart: response.sparePart,
	// 			sparePartCategory: response.sparePartCategory,
	// 			sparePartSubCategory: response.sparePartSubCategory,
	// 			quantity: response.quantity,
	// 			reservedQuantity: response.reservedQuantity,
	// 			minimumThreshold: response.minimumThreshold,
	// 			location: response.location,
	// 			costPrice: response.costPrice,
	// 			nextCostPrice: response.costPrice,
	// 			sellPrice: response.sellPrice,
	// 			nextSellPrice: response.sellPrice,
	// 			supplier: response.supplier,
	// 			nextSupplier: response.supplier,
	// 			quantityToAdd: response.quantityToAdd,
	// 			quantityToDeduct: response.quantityToDeduct,
	// 			remark: response.remark
	// 		});
	// 	}
	// }, [memoSetFormValues]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;
	
		try {
			let payload = { 
				sparePartImage: values.sparePartImage,
				status: values.status,
				sparePart: values.sparePart,
				sparePartCategory: values.sparePartCategory,
				sparePartSubCategory: values.sparePartSubCategory,
				quantity: values.quantity,
				minimumThreshold: values.minimumThreshold,
				location: values.location
			};

			if (isCreate) {
				payload = { ...payload, costPrice: values.costPrice, sellPrice: values.sellPrice, supplier: values.supplier };

				await api.post.inventory.createInventory(payload);
			}

			if (!isCreate) {
				payload.id = values.barcodeId;

				await api.post.inventory.updateInventory(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}
	
		if (response) {
			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Inventory was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Inventory was updated successfully!" }));
			}

			navigate(pathnames.inventory.inventories);
		}
	}, [isCreate, formik, navigate, dispatch]);

	const onHandleInventoryTransfer = () => {
		inventoryTransferModalRef.current.onHandleShow();
	};

	const onHandleShowAdjustStock = useCallback(() => {
		inventoryAdjustStockModalRef.current.onHandleShow();
	}, []);

	const onHandleShowMinusStock = useCallback(() => {
		inventoryMinusStockModalRef.current.onHandleShow();
	}, []);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.inventory.inventories);
	}, [navigate]);

	// uncomment when API is ready
	// useEffect(() => {
	// 	if (!isCreate) {
	// 		onHandleGetDetails(id);
	// 	}

	// 	return () => {
	// 		if (id) memoCancelRequest(ENDPOINT_PATH.INVENTORY.INVENTORY);
	// 	};
	// }, [isCreate, id, onHandleGetDetails, memoCancelRequest]);

	return (
		<div className="page-inventory-create-edit">
			<div className="inventory-create-edit">
				<div className="inventory-create-edit__header">
					<h1 className="inventory-create-edit__title">{title}</h1>

					{!isCreate && (
						<div className="inventory-create-edit__header inventory-create-edit__header--column">
							<div className="inventory-create-edit__transfer-button">
								<AppButton disabled={restricted} type="button" label="Transfer" icon={transferIcon} onClick={onHandleInventoryTransfer} />
							</div>

							<p className="inventory-create-edit__last-update">
								<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
							</p>
						</div>
					)}
				</div>

				<form className="inventory-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="inventory-create-edit__container">
						<p className="inventory-create-edit__label">General Details</p>

						<div className="inventory-create-edit__row">
							<div className="inventory-create-edit__image">{formik.values.sparePartImage && <img name="sparePartImage" src={formik.values.sparePartImage} alt="spare-part" />}</div>
						</div>

						<div className="inventory-create-edit__row">
							{!isCreate && /* prettier-ignore */ <AppInput disabled type="text" name="barcodeId" label="Barcode ID" placeholder="Barcode ID" value={formik.values.barcodeId} error={formik.errors.barcodeId} touched={formik.touched.barcodeId} onChange={formik.handleChange} />}

							{/* prettier-ignore */}
							<AppSelectInput required disabled={restricted} name="status" label="Status" placeholder="Select..." value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} loadOptions={getServiceStatusListing} onChange={formik.handleChange} />
						</div>

						<div className="inventory-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput required disabled={restricted} pagination type="text" name="spartPart" label="Spare Part" placeholder="Search using Barcode ID Name or Spare Part Name" defaultOptions={defaultSparePartOptions.current} options={[]} value={formik.values.sparePart} error={formik.errors.sparePart} touched={formik.touched.sparePart} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled type="text" name="sparePartCategory" label="Spare Part Category" placeholder="" value={formik.values.sparePartCategory} error={formik.errors.sparePartCategory} touched={formik.touched.sparePartCategory} onChange={formik.handleChange} />
						</div>

						<div className="inventory-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="sparePartSubCategory" label="Spare Part Sub-Category" placeholder="" value={formik.values.sparePartSubCategory} error={formik.errors.sparePartSubCategory} touched={formik.touched.sparePartSubCategory} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="inventory-create-edit__container inventory-create-edit__container--divider">
						<p className="inventory-create-edit__label">Stock Count</p>

						<div className="inventory-create-edit__row">
							<div className="inventory-create-edit__column">
								{/* prettier-ignore */}
								<AppInput required disabled={!isCreate} type="number" name="quantity" label="Quantity" placeholder="Enter Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

								{!isCreate && /* prettier-ignore */ <AppInput required disabled type="number" name="reservedQuantity" label="Reserved Quantity" value={formik.values.reservedQuantity} error={formik.errors.reservedQuantity} touched={formik.touched.reservedQuantity} onChange={formik.handleChange} />}
							</div>

							<div className="inventory-create-edit__column">
								{!isCreate && (
									<div className="inventory-create-edit__threshold-buttons">
										<AppButton disabled={restricted} type="button" label="Adjust Stock" icon={addIcon} onClick={onHandleShowAdjustStock} />

										<AppButton disabled={restricted} type="button" label="Minus Stock" icon={minusIcon} onClick={onHandleShowMinusStock} />
									</div>
								)}

								{/* prettier-ignore */}
								<AppInput required disabled={restricted} type="number" name="minimumThreshold" label="Minimum Threshold" placeholder="Enter Minimum Threshold" value={formik.values.minimumThreshold} error={formik.errors.minimumThreshold} touched={formik.touched.minimumThreshold} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="inventory-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput required disabled={restricted} name="location" label="Location" placeholder="Select..." options={[]} value={formik.values.location} error={formik.errors.location} touched={formik.touched.location} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="inventory-create-edit__container">
						<p className="inventory-create-edit__label">Supplier Section</p>

						<div className="inventory-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled={!isCreate || restricted} type="text" name="supplier" label="Supplier" placeholder="Enter Supplier Name" value={formik.values.supplier} error={formik.errors.supplier} touched={formik.touched.supplier} onChange={formik.handleChange} />

							<div className="inventory-create-edit__column">
								{/* prettier-ignore */}
								<AppInput disabled={!isCreate || restricted} type="text" name="costPrice" label="Cost Price (MYR)" placeholder="Enter Cost Price" value={formik.values.costPrice} error={formik.errors.costPrice} touched={formik.touched.costPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

								{/* prettier-ignore */}
								<AppInput disabled={!isCreate || restricted} type="text" name="sellPrice" label="Sell Price (MYR)" placeholder="Enter Sell Price" value={formik.values.sellPrice} error={formik.errors.sellPrice} touched={formik.touched.sellPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
							</div>
						</div>
					</div>

					<div className="inventory-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>

			<AppInventoryTransferModal ref={inventoryTransferModalRef} location={formik.values.location} />

			<AppInventoryAdjustStockModal ref={inventoryAdjustStockModalRef} defaultValues={formik.values} />

			<AppInventoryMinusStockModal ref={inventoryMinusStockModalRef} defaultValues={formik.values} />
		</div>
	);
};

export default PageInventoryCreateEdit;
