import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import { promptAlertMessage } from "store/slices/alert";

import PAGE from "constants/page";

import AppButton from "components/app-button";

import copy from "assets/images/copy-icon.png";

const AppUserSuccessfulModal = (props, ref) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	const [data, setData] = useState({ id: "", password: "" });
	const title = useMemo(() => (props.type === PAGE.CREATE ? "User Added" : "Password Reset Successful"), [props.type]);

	const onHandleCopy = useCallback(() => {
		navigator.permissions.query({ name: "clipboard-write" }).then(async (result) => {
			switch (result.state) {
				case "granted":
				case "prompt":
					await navigator.clipboard.writeText(`User ID: ${data.userId}, New Password: ${data.confirmNewPassword}`);

					dispatch(promptAlertMessage({ message: "Copied!" }));
					break;
				default:
					break;
			}
		});
	}, [dispatch, data]);

	const onHandleShow = useCallback((obj) => {
		setData(obj);

		setModalVisible(true);
	}, []);

	const onHandleRedirect = useCallback(() => {
		navigate(pathnames.operations.userAccess + "?tab=USER_LISTING");
	}, [navigate]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-user-successful-modal" }} open={modalVisible}>
			<div className="user-successful-modal">
				<div className="user-successful-modal__header">
					<p className="user-successful-modal__title">{title}</p>
				</div>

				<p className="user-successful-modal__description">
					<b>Reminder:</b> Make sure to share the new access details with the user.
				</p>

				<p className="user-successful-modal__description">An Email has been sent if there is an address linked to the account.</p>

				<div className="user-successful-modal__container">
					<div className="user-successful-modal__details">
						<div className="user-successful-modal__content">
							<p className="user-successful-modal__label">User ID:</p>
							<p className="user-successful-modal__value">{data.userId}</p>
						</div>

						<div className="user-successful-modal__content">
							<p className="user-successful-modal__label">New Password:</p>
							<p className="user-successful-modal__value">{data.confirmNewPassword}</p>
						</div>
					</div>

					<AppButton className="user-successful-modal__copy-button" outline type="button" label="Copy" icon={copy} onClick={onHandleCopy} />
				</div>

				<div className="user-successful-modal__footer">
					<AppButton type="button" label="Done" onClick={onHandleRedirect} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppUserSuccessfulModal));

AppUserSuccessfulModal.propTypes = {
	ref: PropTypes.object
};
