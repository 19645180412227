import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { Menu, Table, TableBody, TableCell, TableRow } from "@mui/material";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";

import STATUS from "constants/status";
import TIME_OFF from "constants/time-off";
import DATE_TIME from "constants/date-time";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";

import eyeIcon from "assets/images/eye-open-icon.png";
import filerIcon from "assets/images/filter-icon.png";
import exportIcon from "assets/images/export-icon.png";
import searchIcon from "assets/images/search-icon.png";

const claimHistoryData = [{ paginationNumbers: 1, name: "Vivienne Tang", employeeId: "MCE0002-24", claimType: "Medical Claim", status: "Approved", amount: "90.00" }];

const AppAllClaimHistory = () => {
	const [data, setData] = useState(claimHistoryData);
	const [searchParams] = useSearchParams();
	const [selectedClaim, setSelectedClaim] = useState([]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "CLAIM_HISTORY" });

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { submitDate: "", startDate: "", endDate: "", timeOff: [TIME_OFF.FULL_DAY, TIME_OFF.HALF_DAY_AM, TIME_OFF.HALF_DAY_PM], status: [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() =>classNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate
	}), [formik.errors.endDate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELEED }
		];

		return data;
	}, []);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleSelectClaim = useCallback((boolean, id) => {
		setSelectedClaim((prev) => {
			let nextClaim = [...prev];

			if (boolean) {
				nextClaim.push(id);
			} else {
				nextClaim = nextClaim.filter((nextClaimId) => nextClaimId !== id);
			}

			return nextClaim;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.id);

		setSelectedClaim(boolean ? ids : []);
	}, [data]);

	const onHandleSubmit = useCallback((values) => {
		queryParams.current["start-date"] = values.startDate && dayjs(values.startDate).isValid() ? dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
		queryParams.current["end-date"] = values.endDate && dayjs(values.endDate).isValid() ? dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedClaim.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tableClaimID = data[rowIndex]?.id;
					const selected = selectedClaim.includes(tableClaimID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectClaim(v, tableClaimID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "amount",
			label: "Amount(MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => {}}>
							<img src={eyeIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data, onHandleSelectAll, onHandleSelectClaim, selectedClaim]);

	const HeaderDetailsButton = useCallback((obj) => {
		if (!obj.selectedClaim.length) return null;

		return (
			<div className="all-claim-history-table__header-button">
				<AppButton outline type="button" label="Attachments" icon={exportIcon} />

				<AppButton type="button" label="Details" icon={exportIcon} />
			</div>
		);
	}, []);

	useEffect(() => {
		setData(claimHistoryData);
	}, []);

	return (
		<div className="app-all-claim-history-table">
			<div className="all-claim-history-table">
				<div className="all-claim-history-table__header">
					<div className="all-claim-history-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name or Employee ID" endIcon={searchIcon} iconPosition="end" onChange={onHandleDebounceSearch} />

						<div className="all-claim-history-table__filter-button">
							<AppButton outline type="button" label="Filter" icon={filerIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>

					<HeaderDetailsButton selectedClaim={selectedClaim} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-all-claim-history-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<div className="filter-menu__wrapper">
							<div className="filter-menu__date">
								<div className="filter-menu__inputs">									
									<AppInputDate name="startDate" label="Submit Date" value={formik.values.startDate} onChange={formik.setFieldValue} placeholder="Select start date" />

									<AppInputDate name="endDate" value={formik.values.endDate} onChange={formik.setFieldValue} placeholder="Select end date" />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="filter-menu__status">
								<p className="filter-menu__label">Status</p>

								<div className="filter-menu__checkboxes">
									{menuFilterStatus.map((o) => {
										const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

										return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
									})}
								</div>
							</div>
						</div>
						
						<div className="filter-menu__footer">
							<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>
								Clear All Filters
							</button>

							<div className="filter-menu__buttons">
								<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>
									Cancel
								</button>

								<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>
									Apply
								</button>
							</div>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default AppAllClaimHistory;
