import React from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import serveRequestErrors from "common/serve-request-errors";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

import logo from "assets/images/logo.png";
import personIcon from "assets/images/components/app-input/person-icon.png";

const PageForgotPassword = () => {
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: { userId: "" },
		validationSchema: yup.object({
			userId: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			navigate(pathnames.forgotPassword.requestAdminHelpSuccess);
		} catch (error) {
			serveRequestErrors(error, (message) => {
				formik.setFieldError("email", message);
			});
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			//
		}
	};

	const onHandleBack = () => {
		navigate(pathnames.login.login);
	};

	return (
		<div className="page-forgot-password-request-admin-help">
			<main className="forgot-password-request-admin-help">
				<form className="forgot-password-request-admin-help__form" onSubmit={formik.handleSubmit}>
					<Link to={pathnames.login.login} className="forgot-password-request-admin-help__logo">
						<img src={logo} alt="logo" />
					</Link>

					<h1 className="forgot-password-request-admin-help__title">Request Admin Help</h1>

					<p className="forgot-password-request-admin-help__description">Please enter your user ID.</p>

					{/* prettier-ignore */}
					<AppInput type="text" name="userId" placeholder="User Id" icon={personIcon} value={formik.values.userId} error={formik.errors.userId} touched={formik.touched.userId} onChange={formik.handleChange} />

					<div className="forgot-password-request-admin-help__button-container">
						<AppButton type="submit" label="Send Request" disabled={formik.isSubmitting} />

						<AppButton outline className="forgot-password-request-admin-help__back-button" type="button" label="Back" onClick={onHandleBack} />
					</div>
				</form>
			</main>
		</div>
	);
};

export default PageForgotPassword;
