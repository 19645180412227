import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import SERVICE_TYPE from "constants/service-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import closeIcon from "assets/images/close-icon.png";

const AppCustomerEditAssetAddServiceModal = (props, ref) => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const defaultServiceType = useMemo(() => [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET].join(","), []);
	const { id } = useParams();
	const [data, setData] = useState(convertPaginationTableData());
	const [visible, setVisible] = useState(false);
	const [selectedServices, setSelectedServices] = useState([]);
	const queryParams = useRef({ keyword: "", serviceTypes: defaultServiceType, page: 0, sort: "" });
	const [filter, setFilter] = useState([SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET]);

	const formik = useFormik({
		initialValues: { search: "", items: [] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "asset-id": id });

			response = await api.get.assetMaintenance.assetExclusiveServicesSearch(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	const onHandleSubmit = useCallback(() => {
		const selectedServicesIds = selectedServices.map((o) => o.id);

		props.onConfirm(selectedServicesIds);

		setVisible(false);

		setSelectedServices([]);
	}, [props, selectedServices]);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setSelectedServices([]);

		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleAddServices = useCallback((boolean, obj) => {
		let nextId = [...selectedServices];

		const index = nextId.findIndex((o) => o.id === obj.id);

		if (!boolean && index > -1) {
			nextId = nextId.filter((o) => o.id !== obj.id);
		} else {
			nextId.push(obj);
		}

		setSelectedServices(nextId);
	}, [selectedServices]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Asset", name: SERVICE_TYPE.ASSET },
			{ label: "Non-Asset", name: SERVICE_TYPE.NON_ASSET }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		setFilter(defaultServiceType.split(","));

		queryParams.current.serviceTypes = defaultServiceType;

		onHandleGetList();
	}, [defaultServiceType, onHandleGetList]);

	const onHandleSetLastFilter = useCallback(() => {
		setFilter(queryParams.current.serviceTypes.split(","));
	}, []);

	const onHandleApplyFilter = useCallback(() => {
		queryParams.current.serviceTypes = filter.join(",");

		onHandleGetList();
	}, [filter, onHandleGetList]);

	//prettier-ignore
	const onHandleSelectServiceTypes = useCallback((value, name) => {
		let values = [...filter];

		if (filter.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		setFilter(values);
	}, [filter]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				filter: false,
				sort: false,
				sortThirdClickReset: true
			}
		},
		{
			name: "referenceNo",
			label: "Service ID",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tableServiceId = tableMeta.tableData[rowIndex];
					const selected = selectedServices.findIndex((o) => o.id === tableServiceId.id) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleAddServices(v, tableServiceId)} value={selected} />;
				}
			}
		}
	], [onHandleAddServices, selectedServices]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.ASSET_MAINTENANCE.ADD_ASSET_SERVICES);
		};
	}, [cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
		onHandleGetExclusiveServiceList: onHandleGetList
	}));

	return (
		<Modal classes={{ root: "app-customer-edit-asset-add-service-modal" }} open={visible}>
			<div className="customer-edit-asset-add-service-modal">
				<button type="button" className="customer-edit-asset-add-service-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-edit-asset-add-service-modal__title">Add Service</h1>

				<form className="customer-edit-asset-add-service-modal__form" onSubmit={formik.handleSubmit}>
					<p className="customer-edit-asset-add-service-modal__description">
						Select from <span>In-Use</span> Services from General Configuration Module
					</p>

					<AppTableFilterHeader formik={{ handleSubmit: onHandleApplyFilter }} searchPlaceholder="Search by Service ID or Service Action" onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="customer-edit-asset-add-service-modal-filter">
							<p className="customer-edit-asset-add-service-modal-filter__label">Service Type</p>

							{menuFilterStatus.map((o) => {
								const isActive = filter.findIndex((i) => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectServiceTypes(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>
					</AppTableFilterHeader>

					<AppTable data={data.content} options={tableOptions} columns={tableColumns} />

					<div className="customer-edit-asset-add-service-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Add" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerEditAssetAddServiceModal));

AppCustomerEditAssetAddServiceModal.propTypes = {
	ref: PropTypes.object
};
