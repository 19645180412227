import { memo, useMemo, useState } from "react";

import COMMON from "common";
import PropTypes from "prop-types";
import MuiDataTable from "mui-datatables";

import classNames from "common/class-names";
import getOrdinalNumber from "common/get-ordinal-number";

import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCalendarScheduleFooter from "components/app-calendar-schedule/app-calendar-schedule-footer";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppCalendarSchedule = (props) => {
	const components = useMemo(() => props.components, [props.components]);
	const [monthTitle, setMonthTitle] = useState(new Date().getMonth());
	const [yearTitle, setYearTitle] = useState(new Date().getFullYear());
	const [dayMonth, setDayMonth] = useState(new Date(yearTitle, monthTitle + 1, 0).getDate());
	const monthName = COMMON.MONTH_LIST[monthTitle];

	const handleNextPage = () => {
		const nextMonth = monthTitle + 1;

		updateMonthAndYear(nextMonth);
	};

	const handlePrevPage = () => {
		const prevMonth = monthTitle - 1;

		updateMonthAndYear(prevMonth);
	};

	const updateMonthAndYear = (month) => {
		if (month > 11) {
			setMonthTitle(month - 12);

			setYearTitle(yearTitle + 1);
		} else if (month < 0) {
			setMonthTitle(month + 12);

			setYearTitle(yearTitle - 1);
		} else {
			setMonthTitle(month);
		}

		if (month === 1) {
			if ((yearTitle % 4 === 0 && yearTitle % 100 !== 0) || yearTitle % 400 === 0) setDayMonth(29);
			else setDayMonth(28);
		} else if ([3, 5, 8, 10].includes(month)) {
			setDayMonth(30);
		} else {
			setDayMonth(31);
		}
	};

	const data = useMemo(() => {
		return props.data.map((row, index) => {
			const firstDayOfMonth = new Date(yearTitle, monthTitle, 1);
			firstDayOfMonth.setDate(firstDayOfMonth.getDate() + index);

			if (firstDayOfMonth.getDay() !== 0 && firstDayOfMonth.getDay() !== 6) {
				if (row.status) {
					return { clockOut: <AppStatus status={row.status} /> };
				} else {
					return row;
				}
			} else {
				return {};
			}
		});
	}, [monthTitle, props.data, yearTitle]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "date",
			label: "Date",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const pageIndex = tableMeta.rowIndex % dayMonth;
					const firstDayOfMonth = new Date(yearTitle, monthTitle, 1);
					const currentDate = new Date(firstDayOfMonth);
					currentDate.setDate(currentDate.getDate() + pageIndex);

					const dayOfWeek = currentDate.toLocaleString("en-GB", { weekday: "long" });
					const suffix = getOrdinalNumber(currentDate.getDate());

					if (currentDate.toDateString() === new Date().toDateString()) {
						return suffix + ", " + dayOfWeek + " (Today)";
					} else {
						return suffix + ", " + dayOfWeek;
					}
				}
			}
		}
	], [dayMonth, monthTitle, yearTitle]);

	const columns = useMemo(() => [...tableColumns, ...props.columns], [props.columns, tableColumns]);

	//prettier-ignore
	const options = useMemo(() => ({
		...props.options,
		print: false,
		filter: false,
		search: false,
		download: false,
		viewColumns: false,
		fixedHeader: false,
		rowsPerPage: dayMonth,
		responsive: "standard",
		selectableRowsHideCheckboxes: true,
		setRowProps: (row) => {
			return {
				className: classNames({
					"calendar-row": true,
					"calendar-row--weekend": row[0].includes("Saturday") || row[0].includes("Sunday")
				})
			};
		},
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (props.options?.customFooter ? props.options.customFooter(count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) : <AppCalendarScheduleFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />)
	}), [dayMonth, props.options]);

	return (
		<div className="app-calendar-schedule">
			<div className="calendar-schedule">
				<div className="calendar-schedule__button-container">
					<AppButton outline label="" type="button" className="calendar-schedule__left-chevron" icon={chevronIcon} onClick={handlePrevPage} />

					<AppButton outline label="" type="button" icon={chevronIcon} onClick={handleNextPage} />
				</div>

				<h1 className="calendar-schedule__title">
					<span>{monthName}</span> {yearTitle}
				</h1>
			</div>

			<div className="calendar-schedule__table">
				<MuiDataTable columns={columns} data={data} options={options} components={components} />
			</div>
		</div>
	);
};

export default memo(AppCalendarSchedule);

AppCalendarSchedule.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	components: PropTypes.object,
	options: PropTypes.object
};
