import React, { useCallback, useMemo, useRef } from "react";

import { useParams } from "react-router-dom";

import useBeforeUnload from "hooks/use-before-unload";

import PAGE from "constants/page";

import AppTabs from "components/app-tabs";
import AppWorkCompletionWorkDetails from "components/pages/work-completion-report/app-work-completion-work-details";
import AppWorkCompletionReportDetails from "components/pages/work-completion-report/app-work-completion-report-details";

const TABS = {
	REPORT_DETAILS: "REPORT_DETAILS",
	WORK_DETAILS: "WORK_DETAILS"
};

const PageWorkCompletionReportCreateEdit = () => {
	useBeforeUnload();
	const { id } = useParams();
	const tabRef = useRef();
	const nextTab = useRef();
	const reportDetailsRef = useRef();
	const workDetailsRef = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Work Completion Report" : "Edit Work Completion Report"), [isCreate]);

	const onHandleSubmitReportDetails = useCallback(async (values) => {}, []);

	//prettier-ignore
	const onHandleSubmitWorkDetails = useCallback(async (payload) => {}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((obj) => {
		const currentTab = tabRef.current?.onHandleGetCurrentTab();

		switch (currentTab) {
			case TABS.REPORT_DETAILS:
				onHandleSubmitReportDetails(obj);

				if (!nextTab.current) nextTab.current = TABS.WORK_DETAILS;
				break;

			case TABS.WORK_DETAILS:
				onHandleSubmitWorkDetails(obj);
				break;
				
			default:
				break;
		}

		tabRef.current?.onHandleSelectTab(nextTab.current);

		nextTab.current = null;
	}, [onHandleSubmitReportDetails, onHandleSubmitWorkDetails]);

	const onHandleTabChange = useCallback((obj, currentTab) => {
		const tabReport_Details = currentTab === TABS.REPORT_DETAILS;
		const tabWork_Details = currentTab === TABS.WORK_DETAILS;

		if (tabReport_Details) reportDetailsRef.current?.onHandleSubmit();

		if (tabWork_Details) workDetailsRef.current?.onHandleSubmit();

		nextTab.current = obj.name;
	}, []);

	//prettier-ignore
	const tabs = useMemo(() => {
		const data = [
			{ label: "Report Details", name: "REPORT_DETAILS", component: <AppWorkCompletionReportDetails ref={reportDetailsRef} onSubmit={onHandleSubmit} />, accessible: true },
			{ label: "Work Details", name: "WORK_DETAILS", component: <AppWorkCompletionWorkDetails ref={workDetailsRef} onSubmit={onHandleSubmit} />, accessible: true },
		];

		return data;
	}, [onHandleSubmit]);

	return (
		<div className="page-work-completion-report-create-edit">
			<div className="work-completion-report-create-edit">
				<div className="work-completion-report-create-edit__header">
					<h1 className="work-completion-report-create-edit__title">{title}</h1>
				</div>

				<div className="work-completion-report-create-edit__body">
					<AppTabs ref={tabRef} tabs={tabs} onChange={onHandleTabChange} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkCompletionReportCreateEdit;
