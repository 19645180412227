import React, { memo } from "react";

const AppHomeIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M20.36 10.5399L12.26 3.59994C12.11 3.46994 11.88 3.46994 11.73 3.59994L3.64 10.5399L3.5 10.6599V18.9399C3.5 19.7999 4.2 20.4999 5.06 20.4999H9.69C9.91 20.4999 10.09 20.3199 10.09 20.0999V16.6299C10.09 15.5799 10.94 14.7199 12 14.7199C13.06 14.7199 13.91 15.5699 13.91 16.6299V20.0999C13.91 20.3199 14.09 20.4999 14.31 20.4999H18.94C19.8 20.4999 20.5 19.7999 20.5 18.9399V10.6599L20.36 10.5399Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppHomeIcon);
