import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";

import AppButton from "components/app-button";

export const AppEditUserInformationModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = () => {
		setVisible(false);
	};

	const onHandleConfirm = useCallback(() => {
		if (props.onHandleSubmit) props.onHandleSubmit(props.values);

		setVisible(false);
	}, [props]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-edit-user-information-modal" }} open={visible} aria-labelledby="confirmation-modal" aria-describedby="confirmation-modal">
			<div className="edit-user-information-modal">
				<div className="edit-user-information-modal__main">
					<div className="edit-user-information-modal__header">
						<p className="edit-user-information-modal__title">Are you sure ?</p>
					</div>

					<div className="edit-user-information-modal__body">
						<p className="edit-user-information-modal__description">After clicking confirm, this user will lose access to all platforms.</p>

						<div className="edit-user-information-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />

							<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEditUserInformationModal));

AppEditUserInformationModal.propTypes = {
	ref: PropTypes.object
};
