import { useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import PAGE from "constants/page";
import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import classNames from "common/class-names";
import stringEncryptRSA from "common/string-encrypt-rsa";
import generateRandomPassword from "common/generate-random-password";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppPasswordChecklist from "components/app-password-checklist";
import AppUserSuccessfulModal from "components/pages/operations/user-access/app-user-successful-modal";

import padLockIcon from "assets/images/pad-lock-icon.png";
import padLockWhiteIcon from "assets/images/pad-lock-white-icon.png";

const AppEditUserResetPassword = (props) => {
	const navigate = useNavigate();
	const addUserSuccessfulModal = useRef();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.USER_ACCESS], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create || props.isSuperAdmin, [accessible, props.isSuperAdmin]);
	const initialValues = useMemo(() => ({ password: "", confirmNewPassword: "" }), []);

	// prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			password: yup.string().min(8, ERRORS.LENGTH).required(ERRORS.REQUIRED).matches(REGEX.UPPERCASE, ERRORS.REQUIRED).matches(REGEX.LOWERCASE, ERRORS.REQUIRED).matches(REGEX.SYMBOL, ERRORS.REQUIRED).matches(REGEX.NUMERIC, ERRORS.REQUIRED),
			confirmNewPassword: yup.string().required(ERRORS.REQUIRED).oneOf([yup.ref("password")], ERRORS.PASSWORDS_MATCH),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { 
				id: props.user?.id,
				password: stringEncryptRSA(values.confirmNewPassword), 
			};

			await api.post.userAccess.changePassword(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			addUserSuccessfulModal.current.onHandleShow({ userId: props.user?.userId, ...values });
		}
	}, [formik, props]);

	const onHandleCancel = useCallback(() => {
		navigate(`${pathnames.operations.userAccess + "?tab=USER_LISTING"}`);
	}, [navigate]);

	const onHandleGeneratePassword = useCallback(() => {
		formik.setFieldValue("password", generateRandomPassword());
	}, [formik]);

	const generatePasswordClassNames = useMemo(() => {
		return classNames({
			"edit-user-reset-password__generate-password": true,
			"edit-user-reset-password__generate-password--disabled": restricted
		});
	}, [restricted]);

	const onHandlePadlockIcon = useCallback(() => {
		if (restricted) return padLockWhiteIcon;

		return padLockIcon;
	}, [restricted]);

	return (
		<div className="app-edit-user-reset-password">
			<div className="edit-user-reset-password">
				<form className="edit-user-reset-password__form" onSubmit={formik.handleSubmit}>
					<div className="edit-user-reset-password__container">
						<div className="edit-user-reset-password__row">
							<div className="edit-user-reset-password__column">
								{/* prettier-ignore */}
								<AppInput disabled={restricted} type="password" name="password" label="New Password" placeholder="New Password" iconInField={false} value={formik.values.password} error={formik.errors.password} touched={formik.touched.password} required onChange={formik.handleChange} />

								<AppButton disabled={restricted} className={generatePasswordClassNames} outline type="button" label="System Generate" icon={onHandlePadlockIcon()} onClick={onHandleGeneratePassword} />

								<AppPasswordChecklist value={formik.values.password} touched={formik.touched.password} confirmNewPassword={formik.values.confirmNewPassword} />
							</div>

							{/* prettier-ignore */}
							<AppInput disabled={restricted} type="password" name="confirmNewPassword" label="Confirm New Password" placeholder="Confirm New Password" iconInField={false} value={formik.values.confirmNewPassword} error={formik.errors.confirmNewPassword} touched={formik.touched.confirmNewPassword} required onChange={formik.handleChange} />
						</div>
					</div>

					<div className="edit-user-reset-password__button-container">
						<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label="Reset" />
					</div>
				</form>
			</div>

			<AppUserSuccessfulModal ref={addUserSuccessfulModal} type={PAGE.EDIT} />
		</div>
	);
};

export default AppEditUserResetPassword;
