import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import chevronIcon from "assets/images/chevron-right-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const dummyData = [
	{ clockId: 0, previousClockOut: "10.00 a.m.", correctedClockOut: "7.00 p.m.", date: "25/03/2023" },
	{ clockId: 1, previousClockOut: "11.00 a.m.", correctedClockOut: "8.30 p.m.", date: "15/05/2023" },
	{ clockId: 2, previousClockOut: "12.00 p.m.", correctedClockOut: "9.00 p.m.", date: "16/05/2023" },
	{ clockId: 3, previousClockOut: "13.00 p.m.", correctedClockOut: "10.00 p.m.", date: "04/06/2023" }
];

const AppCorrectiveClockOut = () => {
	const [data, setData] = useState(dummyData);
	const [selectedPending, setSelectedPending] = useState([]);
	const [dateTitle, setDateTitle] = useState("date");

	const updateDateTitle = useCallback((nextPending) => {
		if (nextPending.length > 0) {
			setDateTitle(nextPending.length + " Selected Row(s)");
		} else {
			setDateTitle("Date");
		}
	}, []);

	// prettier-ignore
	const onHandleSelectPending = useCallback((boolean, id) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];
			
			if (boolean) {
				nextPending.push(id);
			} else {
				nextPending = nextPending.filter((clockId) => clockId !== id);
			}
			updateDateTitle(nextPending);
			
			return nextPending;
		});
	}, [updateDateTitle]);

	// prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.clockId);

		setSelectedPending(boolean ? ids : []);
		updateDateTitle(boolean ? ids : []);
	}, [data, updateDateTitle]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.clockId;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},
		{
			name: "date",
			label: dateTitle,
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "previousClockOut",
			label: "Previous Clock Out",
			options: {
				sort: false,
			},
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />					
						</TableCell>
					);
				},
				customBodyRender: () => {
					return <AppIcon src={chevronIcon} />
				}
			},
		},
		{
			name: "correctedClockOut",
			label: "Corrected Clock Out",
			options: {
				sort: false,
			},
		},
	], [data, dateTitle, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="corrective-clock-out-table__header-button">
				<div className="corrective-clock-out-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} />
				</div>

				<div className="corrective-clock-out-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} />
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-corrective-clock-out-table">
			<div className="corrective-clock-out-table">
				<div className="corrective-clock-out-table__header">
					<HeaderApprovalButton selectedPending={selectedPending} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default AppCorrectiveClockOut;
