export const pageNotificationsOutboxTableData = [
	// { number: 1, title: "Expansion of Services", recipient: "All Users", dateAndTime: "2023-10-01:14:00" },
	// { number: 2, title: "Monthly Newsletter September `23", recipient: "All Users", dateAndTime: "2023-09-05:12:00" },
	// { number: 3, title: "Monthly Newsletter July `23", recipient: "All Users", dateAndTime: "2023-07-15:11:00" },
	// { number: 4, title: "Monthly Newsletter June `23", recipient: "All Users", dateAndTime: "2023-06-15:11:00" },
	// { number: 5, title: "Monthly Newsletter May `23", recipient: "All Users", dateAndTime: "2023-05-15:11:00" },
	// { number: 6, title: "Monthly Newsletter April `23", recipient: "All Users", dateAndTime: "2023-04-15:11:00" },
	// { number: 7, title: "Monthly Newsletter March `23", recipient: "All Users", dateAndTime: "2023-03-15:11:00" },
	// { number: 8, title: "Monthly Newsletter February `23", recipient: "All Users", dateAndTime: "2023-02-15:11:00" },
	{
		number: 1,
		title: "Safety Training Slots Open",
		recipientType: ["Engineers"],
		recipients: ["All"],
		dateAndTime: "2024-09-05T12:13:00",
		content: "Make sure to Adhere to the latest OSHA guidelines as detailed in the attachment.",
		attachment: null,
		pushNotification: false
	},
	{
		number: 2,
		title: "Work Order Training Slots Open",
		recipientType: ["Engineers", "Admins", "Clients"],
		recipients: ["All"],
		dateAndTime: "2024-09-05T12:12:00",
		content: "It is Open Now",
		attachment: null,
		pushNotification: false
	},
	{
		number: 3,
		title: "Attendance Warnings",
		recipientType: ["By Individual"],
		recipients: ["MCE0014-22 Brian Patel"],
		dateAndTime: "2024-09-05T13:11:00",
		content: "This is a warning to remind you.",
		attachment: null,
		pushNotification: false
	},
	{
		number: 4,
		title: "Newsletter #18",
		recipientType: ["All Users"],
		recipients: ["All"],
		dateAndTime: "2024-09-04T14:11:00",
		content: "News 18",
		attachment: null,
		pushNotification: false
	},
	{
		number: 5,
		title: "Reminder Raffle Winners - Collect Prizes from HR",
		recipientType: ["Engineers", "Admins"],
		recipients: ["MCE0007-23 Alexander Wong Teng Chao", "MCE0014-22 Brian Patel"],
		dateAndTime: "2024-09-03T11:00:00",
		content: "You guys won, hooray.",
		attachment: null,
		pushNotification: false
	},
	{
		number: 6,
		title: "Newsletter #17",
		recipientType: ["All Users"],
		recipients: ["All"],
		dateAndTime: "2024-08-22T11:20:00",
		content: "News 17",
		attachment: null,
		pushNotification: false
	},
	{
		number: 7,
		title: "Raffle Winners - Collect Prizes from HR",
		recipientType: ["Engineers"],
		recipients: ["NMCE0003-023 Ain Batrisyia Binti Abdul Ghani"],
		dateAndTime: "2024-08-22T13:00:00",
		content: "You guys won, apparently...",
		attachment: null,
		pushNotification: false
	},
	{
		number: 8,
		title: "Thank you for your continued support!",
		recipientType: ["Clients"],
		recipients: ["All"],
		dateAndTime: "2024-08-21T14:20:00",
		content: "Cool, thanks :)",
		attachment: null,
		pushNotification: false
	}
];
