import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppPendingApprovalRejectionModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { rejectReason: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().required(ERRORS.REQUIRED)
		}),

		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSubmit = () => {};

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-claim-pending-approval-rejection-modal" }} open={visible}>
			<div className="claim-pending-approval-rejection-modal">
				<h1 className="claim-pending-approval-rejection-modal__title">Reason for Claim Rejection</h1>

				<p className="claim-pending-approval-rejection-modal__description">The reject reason will apply to all selected claims.</p>

				{/* prettier-ignore */}
				<AppInput multiline required type="textarea" maxLength={255} name="rejectReason" label="Reject Reason" placeholder="Enter Reject Reason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />

				<div className="claim-pending-approval-rejection-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={onHandleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingApprovalRejectionModal));

AppPendingApprovalRejectionModal.propTypes = {
	ref: PropTypes.object
};
