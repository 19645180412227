import PageBulkDataHandlingCustomer from "pages/bulk-data-handling/page-bulk-data-handling-customer";
import PageBulkDataHandlingInventory from "pages/bulk-data-handling/page-bulk-data-handling-inventory";
import PageBulkDataHandlingHumanResources from "pages/bulk-data-handling/page-bulk-data-handling-human-resources";

import pathnames from "routes/pathnames";

const bulkDataHandlingRoutes = [
	{
		path: pathnames.bulkDataHandlingCustomer,
		element: <PageBulkDataHandlingCustomer />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.bulkDataHandlingHumanResources,
		element: <PageBulkDataHandlingHumanResources />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.bulkDataHandlingInventory,
		element: <PageBulkDataHandlingInventory />,
		authenticated: true,
		role: null
	}
];

export default bulkDataHandlingRoutes;
