const stringParamFormatter = (path, payload) => {
	let nextPath = path;

	Object.keys(payload).forEach((o) => {
		if (path.includes(`{${o}}`)) {
			nextPath = nextPath.replace(`{${o}}`, payload[o]);
		}
	});

	return nextPath;
};

export default stringParamFormatter;
