const ERRORS = {
	REQUIRED: "This field is required.",
	LENGTH: "Use at least 8 characters",
	NUMERIC: "Should contain at least 1 numeric",
	UPPERCASE: "Should contain at least 1 uppercase",
	LOWERCASE: "Should contain at least 1 lowercase",
	SYMBOL: "Should contain at least 1 special character",
	PASSWORDS_MATCH: "Passwords should match",
	FILE_SIZE: "File Size is too large (maximum {size}mb)",
	MININUM_ITEM: "Field must have at least 1 items",
	START_DATE: "Start Date must be before end date",
	END_DATE: "End Date must be after start date",
	EMAIL: "Please provide a valid email",
	PASSWORD: "Please provide a valid password"
};

export default ERRORS;
