import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppLeaveTypesCreateEditModal from "components/pages/human-resources/app-leave-types-create-edit-modal";

import editIcon from "assets/images/edit-icon.png";

const AppLeaveTypesTable = () => {
	const leaveTypesRef = useRef();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.LEAVE_TYPE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tab: "LEAVE_TYPES" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, status: [STATUS.ACTIVE, STATUS.INACTIVE], size: 10 });

			memoSearchParams.current(params);

			response = await api.get.humanResource.leaveTypes(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleEditLeaveTypes = useCallback((obj) => {
		leaveTypesRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	const onHandleAddLeaveTypes = useCallback(() => {
		leaveTypesRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "code",
			label: "Leave ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return null;

					return (
						<button type="button" className="table__edit" onClick={() => onHandleEditLeaveTypes(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditLeaveTypes, restricted]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_TYPES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-leave-types-table">
			<div className="leave-types-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by Leave ID or Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddLeaveTypes} />

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppLeaveTypesCreateEditModal ref={leaveTypesRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppLeaveTypesTable;
