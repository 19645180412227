import React, { useCallback, useMemo, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppSiteDetailsCreateEditTeamMembersModal from "components/pages/work-order/app-site-details-create-edit-team-members-modal";

import deleteIcon from "assets/images/trash-icon.png";

const tempData = [{ paginationNumbers: 1, id: "MC-001", name: "Henry" }];

const AppSiteDetailsTeamMembersTable = () => {
	const teamMemberRef = useRef();
	const [data, setData] = useState(tempData);
	const [searchParams] = useSearchParams();
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tabs: "SITE_DETAILS" });

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;
	}, []);

	const onHandleAddTeamMember = useCallback(() => {
		teamMemberRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleGetList = useCallback((values) => {
		const nextItems = [...data, ...values];

		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const onHandleDeleteTeamMember = useCallback((obj) => {
		let nextItems = [...data];
		nextItems = nextItems.filter((_, i) => i !== obj.rowIndex);
		
		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__delete" onClick={() => onHandleDeleteTeamMember(tableMeta)}>
							<img src={deleteIcon} alt="delete-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleDeleteTeamMember]);

	return (
		<div className="app-site-details-team-members-table">
			<div className="site-details-team-members-table">
				<p className="site-details-team-members-table__label">Team Members</p>

				<AppTableFilterHeader searchPlaceholder="Search by Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={onHandleAddTeamMember} />

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppSiteDetailsCreateEditTeamMembersModal ref={teamMemberRef} onConfirm={onHandleGetList} />
		</div>
	);
};

export default AppSiteDetailsTeamMembersTable;
