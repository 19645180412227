import React, { Fragment, forwardRef, memo, useMemo } from "react";

import PropTypes from "prop-types";

import classNames from "common/class-names";

import STATUS from "constants/status";

export const AppInventoryHistoryApproveRejectComponent = (props, ref) => {
	const isApproved = useMemo(() => props.status === STATUS.APPROVED, [props]);
	const statusLabel = useMemo(() => (isApproved ? "approved" : "rejected"), [isApproved]);
	const runningBalanceLabel = useMemo(() => (isApproved ? "Old Runnning Balance" : "Running Balance"), [isApproved]);
	const confirmationText = useMemo(() => (isApproved ? " and the balance will be updated for " : " and no balance changes will occur to "), [isApproved]);

	const statusLabelClassNames = useMemo(() => {
		return classNames({
			"inventory-history-approve-reject-component__text": true,
			"inventory-history-approve-reject-component__text--approved": props.status === STATUS.APPROVED,
			"inventory-history-approve-reject-component__text--rejected": props.status === STATUS.REJECTED
		});
	}, [props]);

	return (
		<div className="app-inventory-history-approve-reject-component">
			<p className="inventory-history-approve-reject-component__text">
				After clicking confirm, the request will be <span className={statusLabelClassNames}>{statusLabel}</span>
				{confirmationText}
				<b className="inventory-history-approve-reject-component__text">{props.data.inventoryItem}</b>
			</p>

			<div className="inventory-history-approve-reject-component__row">
				<p className="inventory-history-approve-reject-component__description inventory-history-approve-reject-component__description--title">{runningBalanceLabel}</p>

				<p className="inventory-history-approve-reject-component__description">1000</p>
			</div>

			{isApproved && (
				<Fragment>
					<div className="inventory-history-approve-reject-component__row">
						<p className="inventory-history-approve-reject-component__description inventory-history-approve-reject-component__description--title">Quantity Movement</p>

						<p className="inventory-history-approve-reject-component__description">-10</p>
					</div>

					<div className="inventory-history-approve-reject-component__row">
						<p className="inventory-history-approve-reject-component__description inventory-history-approve-reject-component__description--title">New Running Balance</p>

						<p className="inventory-history-approve-reject-component__description">900</p>
					</div>

					<p className="inventory-history-approve-reject-component__text inventory-history-approve-reject-component__text--authorization">
						<b className="inventory-history-approve-reject-component__text">{props.data?.employee}</b> will be authorised to retrieve the items as per the request.
					</p>
				</Fragment>
			)}

			<p className="inventory-history-approve-reject-component__text">Please note that to adjust the running balance after this you will need to manually adjust the quantity.</p>
		</div>
	);
};

export default memo(forwardRef(AppInventoryHistoryApproveRejectComponent));

AppInventoryHistoryApproveRejectComponent.propTypes = {
	status: PropTypes.object,
	data: PropTypes.object
};
