import { useMemo } from "react";

import AppTabs from "components/app-tabs";
import AppNotificationsOutbox from "components/pages/operations/notifications/app-notifications-outbox-table";
import AppNotificationsScheduled from "components/pages/operations/notifications/app-notifications-scheduled-table";

import outboxIcon from "assets/images/pages/notifications/outbox-icon.svg";
import alarmClockIcon from "assets/images/pages/notifications/alarm-clock-icon.svg";
import activeOutboxIcon from "assets/images/pages/notifications/outbox-active-icon.svg";
import activeAlarmClockIcon from "assets/images/pages/notifications/alarm-clock-active-icon.svg";

const PageNotifications = () => {
	//prettier-ignore
	const inventoryTabs = useMemo(() => [
		{ label: "Scheduled", icon: alarmClockIcon, activeIcon: activeAlarmClockIcon, name: "SCHEDULED", component: <AppNotificationsScheduled />, accessible: true },
		{ label: "Outbox", icon: outboxIcon, activeIcon: activeOutboxIcon, name: "OUTBOX", component: <AppNotificationsOutbox />, accessible: true },
	], []);

	return (
		<div className="page-notifications">
			<div className="notifications">
				<h1 className="notifications__title">Notifications</h1>

				<div className="notifications__options">
					<AppTabs tabs={inventoryTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageNotifications;
