import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";

import AppButton from "components/app-button";

export const AppDeleteServicesLinkageModal = (props, ref) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [visible, setVisible] = useState(false);
	const [serviceLinkage, setServiceLinkage] = useState(null);
	const partOrServiceText = useMemo(() => (props.isSparePartConfig ? "spare part" : "service checklist"), [props.isSparePartConfig]);

	// prettier-ignore
	const onHandleShow = useCallback((obj) => {
		let nextServiceLinkage = null;

		if (props.isSparePartConfig) {
			nextServiceLinkage = {
				sparePartId: id,
				serviceId: obj.id,
				serviceReferenceNo: obj.referenceNo,
				serviceAction: obj.serviceAction
			};
		} else {
			nextServiceLinkage = {
				serviceChecklistId: id,
				serviceId: obj.service?.id || obj.id,
				serviceReferenceNo: obj.referenceNo,
				serviceAction: obj.serviceAction
			};
		}

		setServiceLinkage(nextServiceLinkage);

		setVisible(true);
	}, [id, props.isSparePartConfig]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleConfirm = useCallback(async () => {
		if (isCreate) {
			props.onHandleRemoveServiceLinkage(serviceLinkage.serviceId);

			setVisible(false);
		}

		if (!isCreate) {
			let response = null;

			setIsSubmiting(true);

			try {
				let payload = null;

				if (props.isSparePartConfig) {
					payload = {
						sparePartConfigId: serviceLinkage.sparePartId,
						configServiceIds: [serviceLinkage.serviceId]
					};
				} else {
					payload = {
						configServiceChecklistId: serviceLinkage.serviceChecklistId,
						configServiceIds: [serviceLinkage.serviceId]
					};
				}

				if (props.isSparePartConfig) {
					await api.post.sparePart.removeServiceConfig(payload);
				} else {
					await api.post.serviceChecklist.removeServiceConfig(payload);
				}

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				setIsSubmiting(false);
			}

			if (response) {
				setVisible(false);

				props.onHandleGetDetails(id);

				dispatch(promptLayoutAlertMessage({ message: "Service Linkage was removed successfully!" }));
			}
		}
	}, [dispatch, props, isCreate, id, serviceLinkage]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-delete-services-linkage-modal" }} open={visible} aria-labelledby="confirmation-modal" aria-describedby="confirmation-modal">
			<div className="delete-services-linkage-modal">
				<div className="delete-services-linkage-modal__main">
					<div className="delete-services-linkage-modal__header">
						<p className="delete-services-linkage-modal__title">Are you sure</p>
					</div>

					<div className="delete-services-linkage-modal__body">
						<p className="delete-services-linkage-modal__description">
							After clicking confirm, the Service {serviceLinkage?.serviceReferenceNo} - {serviceLinkage?.serviceAction} will be removed from this {partOrServiceText}.
						</p>

						<div className="delete-services-linkage-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />

							<AppButton type="button" label="Confirm" onClick={onHandleConfirm} disabled={isSubmiting} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDeleteServicesLinkageModal));

AppDeleteServicesLinkageModal.propTypes = {
	ref: PropTypes.object,
	onHandleConfirm: PropTypes.func.isRequired
};
