import React, { useCallback, useMemo, useRef } from "react";

import useBeforeUnload from "hooks/use-before-unload";

import AppTabs from "components/app-tabs";
import AppReportDetails from "components/pages/work-inspection-listing/app-report-details";
import AppFindingDetails from "components/pages/work-inspection-listing/app-finding-details";

const PageWorkInspectionListingCreateEdit = () => {
	useBeforeUnload();
	const tabRef = useRef();

	const onHandleSubmit = useCallback(() => {}, []);

	//prettier-ignore
	const tabs = useMemo(() => {
		const data = [
			{ label: "Report Details", name: "REPORT_DETAILS", component: <AppReportDetails onSubmit={onHandleSubmit} />, accessible: true },
			{ label: "Finding Details", name: "FINDING_DETAILS", component: <AppFindingDetails onSubmit={onHandleSubmit} />, accessible: true },
		];

		return data;
	}, [onHandleSubmit]);

	return (
		<div className="page-work-inspection-listing-create-edit">
			<div className="work-inspection-listing-create-edit">
				<div className="work-inspection-listing-create-edit__header">
					<h1 className="work-inspection-listing-create-edit__title">Create Work Inspection Listing</h1>
				</div>

				<div className="work-inspection-listing-create-edit__body">
					<AppTabs ref={tabRef} tabs={tabs} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkInspectionListingCreateEdit;
