import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import closeIcon from "assets/images/close-icon.png";

const temp = [
	{ serviceNumber: 0, serviceAction: "Provide Service 1", serviceId: "GC-SCL000001", serviceName: "Checklist KLCC", serviceType: "Asset", serviceInput: "Selection 1", status: "In-Use" },
	{ serviceNumber: 1, serviceAction: "Provide Service 2", serviceId: "GC-SCL000002", serviceName: "Checklist KLCC", serviceType: "Asset", serviceInput: "Selection 1", status: "In-Use" },
	{ serviceNumber: 2, serviceAction: "Provide Service 3", serviceId: "GC-SCL000003", serviceName: "Checklist KLCC", serviceType: "Asset", serviceInput: "Selection 1", status: "In-Use" },
	{ serviceNumber: 3, serviceAction: "Provide Service 4", serviceId: "GC-SCL000004", serviceName: "Checklist KLCC", serviceType: "Asset", serviceInput: "Selection 1", status: "In-Use" }
];

export const AppAddServiceChecklistModal = (props, ref) => {
	const [data, setData] = useState(temp);
	const [visible, setVisible] = useState(false);
	const [searchParams] = useSearchParams();
	const [selectedServices, setSelectedServices] = useState([]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tab: "ASSET_TYPES" });

	const onHandleSubmit = useCallback(() => {
		props.onConfirm(selectedServices);

		setVisible(false);
	}, [props, selectedServices]);

	const onHandleShow = useCallback((values) => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleAddServices = useCallback((boolean, obj) => {
		let nextId = [...selectedServices];

		const index = nextId.findIndex((o) => o.serviceId === obj.serviceId);

		if (!boolean && index > -1) {
			nextId = nextId.filter((o) => o.serviceId !== obj.serviceId);
		} else {
			nextId.push(obj);
		}

		setSelectedServices(nextId);
	}, [selectedServices]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "serviceNumber",
			label: "#",
			options: {
				sort: true
			}
		},
		{
			name: "serviceId",
			label: "Service ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceInput",
			label: "Input",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = tableMeta.tableData[rowIndex];
					const selected = selectedServices.findIndex((o) => o.serviceId === obj.serviceId) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleAddServices(v, obj)} value={selected} />;
				}
			}
		}
	], [selectedServices, onHandleAddServices]);

	useEffect(() => {
		setData(temp);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-add-service-checklist-modal" }} open={visible}>
			<div className="add-service-checklist-modal">
				<button type="button" className="add-service-checklist-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="add-service-checklist-modal__title">Add Service</h1>

				<div className="add-service-checklist-modal__form">
					<p className="add-service-checklist-modal__description">
						Select from <span>In-Use</span> Services from General Configuration Module
					</p>

					<AppTableFilterHeader searchPlaceholder="Search by Service ID or Service Action" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

					<AppTable data={data} columns={tableColumns} />

					<div className="add-service-checklist-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="button" label="Add" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddServiceChecklistModal));

AppAddServiceChecklistModal.propTypes = {
	ref: PropTypes.object
};
