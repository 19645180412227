import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { promptAlertMessage } from "store/slices/alert";
import { useNavigate, useParams } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";

import serveRequestErrors from "common/serve-request-errors";
import formatCurrencyPattern from "common/format-currency-pattern";

import PAGE from "constants/page";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppToggleSwitch from "components/app-toggle-switch";

import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";

const PageLeavePackageCreateEdit = (props) => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [specialLeaveTypes, setSpecialLeaveTypes] = useState([]);
	const extraLeaveBenefit = useMemo(() => ({ type: "", numberOfDays: "", expiryMonths: "", approval: true }), []);

	//prettier-ignore
	const monthExpiryOptions = useMemo(() => [{ label: "3 Months", value: "3" }, { label: "6 Months", value: "6" }, { label: "9 Months", value: "9" }, { label: "12 Months", value: "12" }], []);

	//prettier-ignore
	const initialValues = useMemo(() => ({
        name: "",
        description: "",
        leaveBenefits: [
            { numberOfDays: "", expiryMonths: "", approval: true },
            { numberOfDays: "", expiryMonths: "", approval: true },
            { numberOfDays: "", expiryMonths: "", approval: true },
            { numberOfDays: "", expiryMonths: "", approval: true },
            { numberOfDays: "", expiryMonths: "", approval: true },
        ],
        extraLeaveBenefits: [],
    }), []);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			leaveBenefits: yup.array().of(yup.object({ numberOfDays: yup.string().required(ERRORS.REQUIRED) })),
			extraLeaveBenefits: yup.array().of(
				yup.object({
					numberOfDays: yup.string().when("type", {
						is: (type) => !!type,
						then: () => yup.string().required(ERRORS.REQUIRED)
					}),
					expiryMonths: yup.string().when("type", {
						is: (type) => type,
						then: () => yup.string().required(ERRORS.REQUIRED)
					})
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isDisabled = useMemo(() => formik.isSubmitting, [formik.isSubmitting]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const title = useMemo(() => (isCreate ? "Add Leave Package" : "Edit Leave Package"), [isCreate]);

	//prettier-ignore
	const leaveBenefitsField = useMemo(() => [
        { label: "Annual Leave" },
        { label: "Medical/Sick" },
        { label: "Hospitalization" },
        { label: "Maternity" },
        { label: "Paternity" },
    ], []);

	const onHandleLeaveBenefitsPayload = (leaves) => {
		//prettier-ignore
		const payload = leaves.map((o) => {
			if (!o.type) return null;

			return {
				leaveType: o.type,
				approvalFlag: o.approval,
				totalDays: o.numberOfDays,
				expiryMonths: parseInt(o.expiryMonths) || 12,
			};
		}).filter(o => o);

		return payload;
	};

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				description: values.description,
				companyBenefitLeaves: onHandleLeaveBenefitsPayload([...values.leaveBenefits, ...values.extraLeaveBenefits])
			};

			if (isCreate) {
				// await api.post.benefits.create(payload);
			} else {
				payload.id = id;
				// await api.post.benefits.update(payload);
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);
			dispatch(promptAlertMessage({ message: isCreate ? "Benefit has been created successfull" : "Benefit has been updated successfull" }));
		}
	};

	//prettier-ignore
	const onHandleSetApproval = useCallback((obj) => {
		formik.setFieldValue(obj.name, !obj.value);
	}, [formik]);

	//prettier-ignore
	const onHandleAddExtraBenefit = useCallback((arrayHelpers, data) => {
        arrayHelpers.push(data);
    }, []);

	//prettier-ignore
	const onHandleRemoveExtraBenefit = useCallback((arrayHelpers, index) => {
        arrayHelpers.remove(index);
    }, []);

	//prettier-ignore
	const onHandleGetBenefitOptions = useCallback((v, extraBenefits, list) => {
		let options = [];
		const extraBenefitTypes = extraBenefits.map(o => o.type);
		options = list.filter(o => !(extraBenefitTypes.includes(o.value) && o.value !== v));
		return options;
	}, []);

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const FeatureBenefitInput = useCallback((obj) => {

		const onHandleKeyDown = (event) => {
			const cursorPosition = event.target.selectionEnd;
			const value = event.target.value;

			if(cursorPosition !== value.length) {
				event.target.selectionEnd = value.length;
				event.target.selectionStart = value.length;
				event.preventDefault();
			}

			if (event.key.length === 1 && !event.key.match(/\d/i)) {
				event.preventDefault();
			}
		}

		return (
			<div className="leave-package-create-edit__input">
				<div className="leave-package-create-edit__input-header">
					<label className="app-input__label" htmlFor={obj.name}>{obj.label}
					<span className="app-input__required">*</span>
				</label>

				<button type="button" className="leave-package-create-edit__remove-button" disabled={isDisabled} onClick={obj.onRemoveExtraBenefit}>
					<img src={removeIcon} alt="remove-extra-benefit" />
				</button>
			</div>

				{obj.numberOfDays && <AppInput type="number" name={obj.numberOfDays.name} placeholder="Eg: 1000" value={obj.numberOfDays.value} error={obj.numberOfDays.error} touched={obj.numberOfDays.touched} disabled={isDisabled} onChange={obj.numberOfDays.onChange} maxLength={3} />}

				{obj.amount && <AppInput type="text" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} />}
			
				<div className="leave-package-create-edit__input-switch">
					<p className="leave-package-create-edit__input-label">Limit to Current Year</p>

					<Switch checked={obj.approval.value} onChange={() => obj.approval.onChange(obj.approval)} disabled={isDisabled} />

					<p className="leave-package-create-edit__input-label">Approval</p>

					<Switch checked={obj.approval.value} onChange={() => obj.approval.onChange(obj.approval)} disabled={isDisabled} />
				</div>
			</div>
		);
	}, [isDisabled]);

	//prettier-ignore
	const FeatureExtraBenefitInput = useCallback((obj) => {
        const values = obj.values;
		const isCarryForward = obj.types.value;
        const options = onHandleGetBenefitOptions(obj.types.value, values, specialLeaveTypes);

        return (
            <div className="leave-package-create-edit__input">
                <div className="leave-package-create-edit__input-section">
                    <p className="leave-package-create-edit__category">New Leave</p>

					<button type="button" className="leave-package-create-edit__remove-button" disabled={isDisabled} onClick={obj.onRemoveExtraBenefit}>
						<img src={removeIcon} alt="remove-extra-benefit" />
					</button>
                </div>


                <div className="leave-package-create-edit__input-header">
                    {/* prettier-ignore */}
                    <label className="app-input__label" htmlFor="type">Leave Type</label>

                    <div className="leave-package-create-edit__input-switch">
                        <p className="leave-package-create-edit__input-label">Approval</p>
                        <Switch checked={obj.approval.value} disabled={isDisabled} onChange={() => obj.approval.onChange(obj.approval)} />
                    </div>
                </div>

                <AppSelectInput type="text" name={obj.types.name} placeholder="Please Select" value={obj.types.value} error={obj.types.error} touched={obj.types.touched} options={options} disabled={isDisabled} onChange={obj.types.onChange} />

                {obj.numberOfDays && <AppInput type="number" name={obj.numberOfDays.name} label="No. of Days" placeholder="Eg: 1000" value={obj.numberOfDays.value} error={obj.numberOfDays.error} touched={obj.numberOfDays.touched} disabled={isDisabled} onChange={obj.numberOfDays.onChange} maxLength={3}/>}
                
                {isCarryForward && <AppSelectInput type="text" name={obj.expiryMonths.name} label="Expired Date" placeholder="Please Select" value={obj.expiryMonths.value} error={obj.expiryMonths.error} touched={obj.expiryMonths.touched} options={monthExpiryOptions} disabled={isDisabled} onChange={obj.expiryMonths.onChange} />}
             
                {obj.days && (
                    <div className="leave-package-create-edit__input-buttons">
                        <p className="leave-package-create-edit__input-label">Day Calculation</p>
                        {/* <AppButtonInput name={obj.days.name} value={obj.days.value} onChange={obj.days.onChange} options={COMMON.DROPDOWNS.DAYS_CALCULATION} disabled={isDisabled} /> */}
                    </div>
                )}
            </div>
        );
    }, [onHandleGetBenefitOptions, monthExpiryOptions, isDisabled, specialLeaveTypes]);

	useEffect(() => {
		setSpecialLeaveTypes([]);

		return () => {
			cancelRequest();
		};
	}, [cancelRequest]);

	return (
		<FormikProvider value={formik}>
			<div className="page-leave-package-create-edit">
				<div className="leave-package-create-edit">
					<h1 className="leave-package-create-edit__title">{title}</h1>

					<form className="leave-package-create-edit__form" onSubmit={formik.handleSubmit}>
						<div className="leave-package-create-edit__container">
							<div className="leave-package-create-edit__box">
								<div className="leave-package-create-edit__wrapper">
									<div className="leave-package-create-edit__box-body">
										{/* prettier-ignore */}
										<AppInput required type="text" name="name" label="Renumeration Package Name" placeholder="e.g. Package A" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />

										{/*prettier-ignore*/}
										<AppInput multiline maxLength={5000} type="text" name="description" label="Description" placeholder="Please describe this benefit package" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={isDisabled} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="leave-package-create-edit__container">
							<div className="leave-package-create-edit__box">
								<div className="leave-package-create-edit__wrapper">
									<div className="leave-package-create-edit__box-body">
										{/* prettier-ignore */}
										<AppInput required type="text" name="employedFor" label="Employed for" placeholder="e.g. 3 Years" value={formik.values.employedFor} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />

										{/* prettier-ignore */}
										<AppToggleSwitch required type="text" name="replacementLeaveToggle" label="Is this employee eligible to take replacement leave?" value={formik.values.replacementLeaveToggle} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<FieldArray
							name="extraLeaveBenefits"
							render={(arrayHelpers) => {
								const errors = formik.errors;
								const touched = formik.touched;
								const leaveBenefits = formik.values.leaveBenefits;
								const extraLeaveBenefits = formik.values.extraLeaveBenefits;
								// const disabledAddExtraLeaveBenefits = isDisabled || specialLeaveTypes?.length === extraLeaveBenefits?.length;

								return (
									<div className="leave-package-create-edit__container">
										<div className="leave-package-create-edit__box">
											<div className="leave-package-create-edit__header">
												<p className="leave-package-create-edit__input-title">Leave Benefit</p>

												<AppButton type="button" label="Add Leave" icon={addIcon} onClick={() => onHandleAddExtraBenefit(arrayHelpers, extraLeaveBenefit)} />
											</div>

											<div className="leave-package-create-edit__wrapper">
												<div className="leave-package-create-edit__box-body">
													{leaveBenefitsField.map((a, i) => {
														const numberOfDays = {
															name: `leaveBenefits.${i}.numberOfDays`,
															value: leaveBenefits[i]["numberOfDays"],
															error: errors.leaveBenefits?.[i]?.["numberOfDays"],
															touched: touched.leaveBenefits?.[i]?.["numberOfDays"],
															onChange: formik.handleChange
														};
														const approval = {
															name: `leaveBenefits.${i}.approval`,
															value: leaveBenefits[i]["approval"],
															onChange: onHandleSetApproval
														};
														return <FeatureBenefitInput key={i} {...a} numberOfDays={numberOfDays} approval={approval} />;
													})}
												</div>
											</div>

											{!!extraLeaveBenefits.length && (
												<Fragment>
													<div className="leave-package-create-edit__divider" />

													<div className="leave-package-create-edit__extra-benefit">
														<div className="leave-package-create-edit__box-body">
															{extraLeaveBenefits.map((a, i) => {
																const onRemoveExtraBenefit = () => onHandleRemoveExtraBenefit(arrayHelpers, i);
																const types = {
																	name: `extraLeaveBenefits.${i}.type`,
																	value: a["type"],
																	onChange: formik.handleChange
																};
																const numberOfDays = {
																	name: `extraLeaveBenefits.${i}.numberOfDays`,
																	value: a["numberOfDays"],
																	error: errors.extraLeaveBenefits?.[i]?.["numberOfDays"],
																	touched: touched.extraLeaveBenefits?.[i]?.["numberOfDays"],
																	onChange: formik.handleChange
																};
																const expiryMonths = {
																	name: `extraLeaveBenefits.${i}.expiryMonths`,
																	value: a["expiryMonths"],
																	error: errors.extraLeaveBenefits?.[i]?.["expiryMonths"],
																	touched: touched.extraLeaveBenefits?.[i]?.["expiryMonths"],
																	onChange: formik.handleChange
																};
																const approval = {
																	name: `extraLeaveBenefits.${i}.approval`,
																	value: a["approval"],
																	onChange: onHandleSetApproval
																};

																//prettier-ignore
																return <FeatureExtraBenefitInput key={i} index={i} {...a} onRemoveExtraBenefit={onRemoveExtraBenefit} values={extraLeaveBenefits} expiryMonths={expiryMonths} numberOfDays={numberOfDays} types={types} approval={approval} />;
															})}
														</div>
													</div>
												</Fragment>
											)}
										</div>
									</div>
								);
							}}
						/>

						<div className="leave-package-create-edit__button-container">
							<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
							<AppButton type="submit" disabled={isDisabled} label="Update" />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default memo(PageLeavePackageCreateEdit);

PageLeavePackageCreateEdit.propTypes = {
	onHandleCancelRequest: PropTypes.func
};
