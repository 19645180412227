import React, { memo, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { dismissLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";

import checkedIcon from "assets/images/components/app-alert/checked-icon.svg";

const AppLayoutAlert = () => {
	const timer = useRef();
	const resetTimer = useRef();
	const dispatch = useDispatch();
	const layoutAlert = useSelector((state) => state.layoutAlert);
	const [active, setActive] = useState(false);
	const errorCode = useMemo(() => (layoutAlert.code ? `(Error Code : ${layoutAlert.code})` : ""), [layoutAlert.code]);

	const className = useMemo(() => {
		return classNames({
			"app-layout-alert": true,
			"app-layout-alert--active": active,
			"app-layout-alert--error": layoutAlert.error
		});
	}, [layoutAlert, active]);

	useEffect(() => {
		if (layoutAlert.message) {
			clearTimeout(timer.current);
			clearTimeout(resetTimer.current);
			setActive(true);

			timer.current = setTimeout(() => {
				setActive(false);

				resetTimer.current = setTimeout(() => {
					dispatch(dismissLayoutAlertMessage());
				}, 1000);
			}, 5000);
		}
	}, [layoutAlert, dispatch]);

	if (!layoutAlert?.message || typeof layoutAlert?.message !== "string") return null;

	return (
		<div className={className} id="app-layout-alert">
			<div className="layout-alert">
				{!layoutAlert.error && <img className="layout-alert__icon" src={checkedIcon} alt="checked" />}

				<p className="layout-alert__text">
					{layoutAlert.message} {errorCode}
				</p>
			</div>
		</div>
	);
};

export default memo(AppLayoutAlert);
