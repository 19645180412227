import PageInventories from "pages/inventory/page-inventories";
import PageInventoryHistory from "pages/inventory/page-inventory-history";
import PageInventoryCreateEdit from "pages/inventory/page-inventory-create-edit";
import PageInventoryConfiguration from "pages/inventory/page-inventory-configuration";

import pathnames from "routes/pathnames";

import ROLES from "constants/roles";

const inventoryRoutes = [
	{
		path: pathnames.inventory.inventories,
		element: <PageInventories />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.inventory.inventoryHistory,
		element: <PageInventoryHistory />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.inventory.inventoryCreateEdit + ":id",
		element: <PageInventoryCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.inventory.inventoryConfiguration,
		element: <PageInventoryConfiguration />,
		authenticated: true,
		role: [ROLES.INVENTORY_LOCATION]
	}
];

export default inventoryRoutes;
