import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppCustomerDeletePicModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-delete-pic-modal" }} open={visible}>
			<div className="customer-delete-pic-modal">
				<button type="button" className="customer-delete-pic-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-delete-pic-modal__title">Are you sure?</h1>

				<p className="customer-delete-pic-modal__description">
					After clicking confirm, the Customer <b>{data?.picName}</b> will be deleted.
				</p>

				<p className="customer-delete-pic-modal__description">Do note that this will affect any Contracts or Sites linked.</p>

				<div className="customer-delete-pic-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" disabled={restricted} label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerDeletePicModal));

AppCustomerDeletePicModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
