import PageUserAccess from "pages/operations/user-access/page-user-access";
import PageNotifications from "pages/operations/notifications/page-notifications";
import PageActivityLogs from "pages/operations/page-activity-logs/page-activity-logs";
import PageUserAccessEditUser from "pages/operations/user-access/page-user-access-edit-user";
import PageUserAccessCreateUser from "pages/operations/user-access/page-user-access-create-user";
import PageCategoryCreateEdit from "pages/operations/general-configuration/page-category-create-edit";
import PageContractCreateEdit from "pages/operations/general-configuration/page-contract-create-edit";
import PageNotificationsCreateEdit from "pages/operations/notifications/page-notifications-create-edit";
import PageGeneralConfiguration from "pages/operations/general-configuration/page-general-configuration";
import PageSparePartCreateEdit from "pages/operations/general-configuration/page-spare-part-create-edit";
import PageUserAccessCreateEditRole from "pages/operations/user-access/page-user-access-create-edit-role";
import PageSubCategoryCreateEdit from "pages/operations/general-configuration/page-sub-category-create-edit";
import PageActivityLogsViewDetails from "pages/operations/page-activity-logs/page-activity-logs-view-details";
import PageServiceListingCreateEdit from "pages/operations/general-configuration/page-service-listing-create-edit";
import PageServiceChecklistCreateEdit from "pages/operations/general-configuration/page-service-checklist-create-edit";

import ROLES from "constants/roles";

import pathnames from "routes/pathnames";

const operationsRoutes = [
	{
		path: pathnames.operations.userAccess,
		element: <PageUserAccess />,
		authenticated: true,
		role: [ROLES.ROLE, ROLES.USER_ACCESS]
	},
	{
		path: pathnames.operations.userAccessCreateEditRole + ":id",
		element: <PageUserAccessCreateEditRole />,
		authenticated: true,
		role: [ROLES.ROLE]
	},
	{
		path: pathnames.operations.userAccessEditUser + ":id",
		element: <PageUserAccessEditUser />,
		authenticated: true,
		role: [ROLES.USER_ACCESS]
	},
	{
		path: pathnames.operations.userAccessCreateUser,
		element: <PageUserAccessCreateUser />,
		authenticated: true,
		role: [ROLES.USER_ACCESS]
	},
	{
		path: pathnames.activityLogs,
		element: <PageActivityLogs />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.activityLogsViewDetails + ":id",
		element: <PageActivityLogsViewDetails />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.notification.notifications,
		element: <PageNotifications />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.notification.notificationsCreateEdit,
		element: <PageNotificationsCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.operations.generalConfiguration,
		element: <PageGeneralConfiguration />,
		authenticated: true,
		role: [ROLES.SPARE_PART_CONFIG, ROLES.CONTRACT_CONFIG, ROLES.INPUT_CONFIG, ROLES.SERVICE_CONFIG, ROLES.SERVICE_CHECKLIST_CONFIG]
	},
	{
		path: pathnames.operations.sparePartCreateEdit + ":id",
		element: <PageSparePartCreateEdit />,
		authenticated: true,
		role: [ROLES.SPARE_PART_CONFIG]
	},
	{
		path: pathnames.operations.serviceListingCreateEdit + ":id",
		element: <PageServiceListingCreateEdit />,
		authenticated: true,
		role: [ROLES.SERVICE_CONFIG]
	},
	{
		path: pathnames.operations.serviceChecklistCreateEdit + ":id",
		element: <PageServiceChecklistCreateEdit />,
		authenticated: true,
		role: [ROLES.SERVICE_CHECKLIST_CONFIG]
	},
	{
		path: pathnames.operations.categoryCreateEdit + ":id",
		element: <PageCategoryCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.operations.subCategoryCreateEdit + ":id",
		element: <PageSubCategoryCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.operations.contractCreateEdit + ":id",
		element: <PageContractCreateEdit />,
		authenticated: true,
		role: [ROLES.CONTRACT_CONFIG]
	}
];

export default operationsRoutes;
