import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getEnquiryStatus from "services/get-enquiry-status";
import getRegionListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import capitalizeCharacter from "common/capitalize-character";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

const PageEnquiriesCreateEdit = (props) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const downloadingFile = useRef();
	const closedEnquiriesStatus = [STATUS.CLOSED, STATUS.RESOLVED, STATUS.CANCELLED];
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [currentStatus, setCurrentStatus] = useState();
	const enquiryStatus = useRef("");

	const initialValues = useMemo(() => {
		const values = {
			referenceNo: "",
			customerName: "",
			status: "",
			email: "",
			created: "",
			asset: "",
			prefixNo: "",
			mobileNo: "",
			subject: "",
			description: "",
			firstTimeResponseDuration: "",
			resolutionTimeDuration: "",
			remark: "",
			address1: "",
			address2: "",
			postcode: "",
			state: "",
			city: "",
			files: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => onHandleSubmit(values)
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Enquiries", path: pathnames.customer.enquiries },
			{ label: "Edit Enquiry " + formik.values.referenceNo, path: pathnames.customer.enquiriesCreateEdit + id }
		];

		return data;
	}, [id, formik.values]);

	useBreadcrumb({ breadCrumb });

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.state }), [formik.values.state]);

	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { 
				address1: values.address1,
				address2: values.address2,
				postcode: values.postcode,
				city: values.city,
				state: values.state,
				remark: values.remark,
				status: values.status
			};

			payload.id = id;

			await api.post.customerEnquiry.updateEnquiry(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Enquiry was updated successfully!" }));
		}
	}, [dispatch, formik, id]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.customerEnquiry.enquiry(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			enquiryStatus.current = response.status;

			setCurrentStatus(response.status);

			memoSetFormValues({
				referenceNo: response.referenceNo,
				customerName: response.customerPicContact.picName,
				status: response.status,
				email: response.customerPicContact.email,
				created: dayjs(response.createdDate).format(DATE_TIME.DD_MM_YYYY_HH_MM_A),
				asset: response.assetName,
				prefixNo: response.customerPicContact.mobileNoPrefix,
				mobileNo: response.customerPicContact.mobileNo,
				subject: response.subject,
				description: response.description,
				firstTimeResponseDuration: response.firstTimeResponseDuration,
				resolutionTimeDuration: response.resolutionTimeDuration,
				remark: response.remark,
				address1: response.address1,
				address2: response.address2,
				postcode: response.postcode,
				state: response.state,
				city: response.city,
				files: response.docs
			});
		}
	}, [memoSetFormValues]);

	const onHandleGetEnquiryStatus = useCallback(() => {
		return getEnquiryStatus(currentStatus);
	}, [currentStatus]);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.customerEnquiry.downloadDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = fileObject.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.enquiries);
	}, [navigate]);

	useEffect(() => {
		onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.CUSTOMER_ENQUIRY.UPDATE_ENQUIRY);
		};
	}, [id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-enquiries-create-edit">
			<div className="enquiries-create-edit">
				<div className="enquiries-create-edit__header">
					<h1 className="enquiries-create-edit__title">Edit Enquiries</h1>

					<div className="enquiries-create-edit__actions">
						{closedEnquiriesStatus.includes(enquiryStatus.current) && (
							<p className="enquiries-create-edit__last-update">
								<span>{capitalizeCharacter(enquiryStatus.current)} By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
							</p>
						)}

						<p className="enquiries-create-edit__last-update">
							<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
						</p>
					</div>
				</div>

				<form className="enquiries-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="enquiries-create-edit__container">
						<p className="enquiries-create-edit__label">General</p>

						<div className="enquiries-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="customerName" label="Customer Name" placeholder="Customer Name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput disabled={restricted} required searchable={false} name="status" label="Status" placeholder="Select..." loadOptions={onHandleGetEnquiryStatus} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="enquiries-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="email" label="Email" placeholder="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled type="text" name="created" label="Created" placeholder="Created" value={formik.values.created} error={formik.errors.created} touched={formik.touched.created} onChange={formik.handleChange} />
						</div>

						<div className="enquiries-create-edit__row enquiries-create-edit__row--divider">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="asset" label="Asset" placeholder="Asset" value={formik.values.asset} error={formik.errors.asset} touched={formik.touched.asset} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />
						</div>

						<div className="enquiries-create-edit__row enquiries-create-edit__row--divider">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="subject" label="Subject" placeholder="Subject" value={formik.values.subject} error={formik.errors.subject} touched={formik.touched.subject} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled multiline type="textarea" name="description" label="Description" placeholder="Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
						</div>

						<div className="enquiries-create-edit__row enquiries-create-edit__row--divider">
							{/* prettier-ignore */}
							<AppInput disabled type="number" name="firstTimeResponseDuration" label="First Response Time (Minutes)" placeholder="Minutes" value={formik.values.firstTimeResponseDuration || ""} error={formik.errors.firstTimeResponseDuration} touched={formik.touched.firstTimeResponseDuration} onChange={formik.setFieldValue} />

							{/* prettier-ignore */}
							<AppInput disabled type="number" name="resolutionTimeDuration" label="Resolution Time (Minutes)" placeholder="Minutes" value={formik.values.resolutionTimeDuration || ""} error={formik.errors.resolutionTimeDuration} touched={formik.touched.resolutionTimeDuration} onChange={formik.setFieldValue} />
						</div>

						<div className="enquiries-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} className="app-input app-input--full-width" multiline type="textarea" name="remark" label="Remarks" placeholder="Remarks" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="enquiries-create-edit__container">
						<p className="enquiries-create-edit__label">Address</p>

						<div className="enquiries-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} type="text" name="address1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.address1} error={formik.errors.address1} touched={formik.touched.address1} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled={restricted} type="text" name="address2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.address2} error={formik.errors.address2} touched={formik.touched.address2} onChange={formik.handleChange} />
						</div>

						<div className="enquiries-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput disabled={restricted} name="state" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput disabled={!formik.values.state || restricted} name="city" label="City" placeholder="City" loadOptions={getRegionLoadOptions} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="enquiries-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput disabled={!formik.values.city || restricted} name="postcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="enquiries-create-edit__container">
						<p className="enquiries-create-edit__label">Attachments</p>

						{/* prettier-ignore */}
						<AppInputDragAndDropFiles disabled length={5} name="files" accept="image/png, image/jpeg, image/jpg, .doc, .docx, .pdf" onChange={formik.setFieldValue} error={formik.errors.files} value={formik.values.files} onHandleDownloadFile={!restricted && onHandleDownloadFile} />
					</div>

					<div className="enquiries-create-edit__button-container">
						{/* prettier-ignore */}
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						{/* prettier-ignore */}
						<AppButton type="submit" label="Update" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</div>
	);
};
export default PageEnquiriesCreateEdit;
