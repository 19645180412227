import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Chip, Modal } from "@mui/material";

import classNames from "common/class-names";

import ERRORS from "constants/errors";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppCheckbox from "components/app-checkbox";

import closeModal from "assets/images/close-icon.png";
import searchIcon from "assets/images/search-icon.png";
import checkerIcon from "assets/images/components/app-checkbox/checker-icon.svg";

const AppNotificationsRecipientModal = ({ ...props }, ref) => {
	const className = useMemo(() => {
		return classNames({
			"app-notifications-recipient-modal": true,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className]);

	// All the possible users that can be selected (receive from API)
	const selectableUsersList = useMemo(() => {
		return [];
	}, []);

	const onHandleFilterSubmit = (values) => {};

	const onHandleSubmit = (values) => {};

	const formikFilter = useFormik({ initialValues: { search: "" }, onSubmit: onHandleFilterSubmit });

	// prettier-ignore
	const formik = useFormik({
		initialValues: { recipients: {}},
		validationSchema: yup.object({
			recipients: yup.array().required(ERRORS.REQUIRED).min(1, ERRORS.REQUIRED),
		}),
		onSubmit: onHandleSubmit,
	});

	const [selectedChipsList, setSelectedChipsList] = useState([]);

	const [allUsersSelected, setAllUsersSelected] = useState(true);

	const [modalVisible, setModalVisible] = useState(false);

	const onHandleShow = useCallback(() => {
		setModalVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		formik.resetForm();

		formikFilter.resetForm();

		setModalVisible(false);
	}, [formik, formikFilter]);

	// prettier-ignore
	const onHandleSetSelectedUsers = useCallback((user) => {
		formik.setFieldValue("recipients", { ...formik.values.recipients, [user]: !formik.values.recipients[user] });

		const allSelectableUsersSelected = selectableUsersList.every((user) => {
			
			return formik.values.recipients[user];
		});

		if (allSelectableUsersSelected) {
			setAllUsersSelected(true);
		} else {
			setAllUsersSelected(false);
		}
	}, [formik, selectableUsersList]);

	const onHandleSelectAllUsers = useCallback(() => {
		setAllUsersSelected((prev) => {
			selectableUsersList.map((user) => {
				return formik.setFieldValue("recipients", { ...formik.values.recipients, [user]: true });
			});

			return !prev;
		});
	}, [formik, selectableUsersList]);

	const handleDeselectChip = (chipToDelete) => () => {
		setSelectedChipsList((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));

		onHandleSetSelectedUsers(chipToDelete.label);
	};

	useEffect(() => {
		setSelectedChipsList(() => {
			const newSelectedChipsList = [];

			Object.keys(formik.values.recipients).forEach((user) => {
				if (formik.values.recipients[user]) {
					newSelectedChipsList.push({ key: user, label: user });
				}
			});

			return newSelectedChipsList;
		});
	}, [formik.values.recipients]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: className }} open={modalVisible}>
			<div className="recipient-modal">
				<div className="recipient-modal__close-container">
					<button className="recipient-modal__close-button" onClick={onHandleDismiss}>
						<AppIcon src={closeModal} />
					</button>
				</div>

				<div className="recipient-modal__search">
					<AppInput type="text" name="search" placeholder="Search by User Id or Name" endIcon={searchIcon} iconPosition="end" value={formikFilter.values.search} onChange={formikFilter.handleChange} />
				</div>

				<div className="recipient-modal__chips">
					{selectedChipsList.map((chip) => {
						return <Chip key={chip.key} label={chip.label} onDelete={handleDeselectChip(chip)} />;
					})}
				</div>

				<div className="recipient-modal__users">
					<AppCheckbox className="recipient-modal__user" value={allUsersSelected} onClick={onHandleSelectAllUsers} icon={checkerIcon} label={"All"} />

					{selectableUsersList.map((user, index) => {
						return (
							<div key={index} className="recipient-modal__user--individual">
								<AppCheckbox value={formik.values.recipients[user]} onClick={() => onHandleSetSelectedUsers(user)} icon={checkerIcon} label={user} />
							</div>
						);
					})}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppNotificationsRecipientModal));

AppNotificationsRecipientModal.propTypes = {
	className: PropTypes.string,
	active: PropTypes.bool.isRequired,
	onClose: PropTypes.func
};
