import React from "react";

import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import AppButton from "components/app-button";

import logo from "assets/images/logo.png";
import requestAdminHelpSuccess from "assets/images/pages/forgot-password/request-admin-help-success.png";

const PageForgotPasswordRequestAdminHelpSuccess = () => {
	const navigate = useNavigate();

	const onHandleBack = () => {
		navigate(pathnames.login.login);
	};

	return (
		<div className="page-forgot-password-request-admin-help-success">
			<main className="forgot-password-request-admin-help-success">
				<div className="forgot-password-request-admin-help-success__container">
					<div className="forgot-password-request-admin-help-success__logo">
						<img src={logo} alt="logo" />
					</div>

					<h1 className="forgot-password-request-admin-help-success__title">Request Admin Help</h1>

					<p className="forgot-password-request-admin-help-success__description">We've sent a request to your internal team. Please check with your admin to approve this request.</p>

					<div className="forgot-password-request-admin-help-success__hero">
						<img src={requestAdminHelpSuccess} alt="hero" />
					</div>

					<div className="forgot-password-request-admin-help-success__button-container">
						<AppButton type="button" label="Back to Log In" onClick={onHandleBack} />
					</div>
				</div>
			</main>
		</div>
	);
};

export default PageForgotPasswordRequestAdminHelpSuccess;
