import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppAssetDeleteModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-asset-delete-modal" }} open={visible}>
			<div className="asset-delete-modal">
				<h1 className="asset-delete-modal__title">Are you sure?</h1>

				<p className="asset-delete-modal__description">
					After clicking confirm, the Asset <b>{data?.customerAssetName}</b> will be removed from this Work Inspection Report.
				</p>

				<div className="asset-delete-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAssetDeleteModal));

AppAssetDeleteModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
