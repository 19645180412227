import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import STATUS from "constants/status";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppAttendanceSummaryViewContact from "components/pages/human-resources/app-attendance-summary-view-contact-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import phoneIcon from "assets/images/pages/human-resources/phone-icon.svg";

const dummyData = [
	{
		paginationNumbers: 1,
		name: "Dio",
		employeeId: "123",
		mobileNo: "0123456789",
		todayStatus: "WORKING",
		email: "email@email.com",
		firstMobileNumber: "0123456789",
		secondMobileNumber: "01234567899",
		contactFullName: "Bro",
		contactRelationship: "sister",
		contactMobileNumber: "012121212"
	},
	{ paginationNumbers: 2, name: "Jojo", employeeId: "124", mobileNo: "0123456789", todayStatus: "ON_LEAVE" },
	{ paginationNumbers: 3, name: "Davai Lama", employeeId: "125", mobileNo: "0123456789", todayStatus: "UNACCOUNTED_ABSENCE" }
];

const AppHrViewAttendanceDirectoryTable = () => {
	const selectedItem = useRef();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [holidayAnchor, setHolidayAnchor] = useState(null);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "" });
	const [data, setData] = useState(dummyData);
	const viewContactRef = useRef();

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "", status: [STATUS.WORKING, STATUS.ON_LEAVE, STATUS.UNACCOUNTED_ABSENCE] }
	});

	const onViewAttendance = useCallback(() => {
		navigate(pathnames.humanResources.viewAttendance, { state: selectedItem.current });
	}, [navigate]);

	const onViewEmployeeLeave = useCallback(() => {
		navigate(pathnames.humanResources.viewEmployeeLeave, { state: selectedItem.current });
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Working", name: STATUS.WORKING },
			{ label: "On Leave", name: STATUS.ON_LEAVE },
			{ label: "Unacounted Absence", name: STATUS.UNACCOUNTED_ABSENCE }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;
	}, []);

	const onHandleCloseHolidayTableMenu = useCallback(() => {
		selectedItem.current = null;
		setHolidayAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };
		setHolidayAnchor(event.currentTarget);
	}, [data]);

	const onHandleViewContact = useCallback(() => {
		viewContactRef.current.onHandleShow(selectedItem.current);
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false,
			},
		},
		{
			name: "mobileNo",
			label: "Mobile Number",
			options: {
				sort: false,
			},
		},
		{
			name: "todayStatus",
			label: "Today's Status",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => <AppStatus status={value} />

			},
		},
        {
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onToggleTableAction]);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-hr-view-attendance-directory-table">
			<div className="hr-view-attendance-directory-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Employee ID or Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="hr-view-attendance-directory-table-filter">
						<div className="hr-view-attendance-directory-table-filter__container">
							<p className="hr-view-attendance-directory-table-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data} columns={tableColumns} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "hr-view-attendance-directory-table-menu" }} anchorEl={holidayAnchor} open={!!holidayAnchor} onClose={onHandleCloseHolidayTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onViewAttendance}><img src={eyeIcon} alt="hr-view-holidays-view-attandance" />View Attendance</MenuItem>
				<MenuItem onClick={onViewEmployeeLeave}><img src={eyeIcon} alt="hr-view-holidays-view-employee-leave" />View Employee Leave</MenuItem>
				<MenuItem onClick={onHandleViewContact}><img src={phoneIcon} alt="hr-view-holidays-view-employee-contract" />View Employee Contract</MenuItem>
			</Menu>

			<AppAttendanceSummaryViewContact ref={viewContactRef} />
		</div>
	);
};

export default AppHrViewAttendanceDirectoryTable;
