import PageDataInquiry from "pages/reports-and-inquiry/page-data-inquiry";
import PageReportExtraction from "pages/reports-and-inquiry/page-report-extraction";

import pathnames from "routes/pathnames";

const reportsAndInquiryRoutes = [
	{
		path: pathnames.reportExtraction,
		element: <PageReportExtraction />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.dataInquiry,
		element: <PageDataInquiry />,
		authenticated: true,
		role: null
	}
];

export default reportsAndInquiryRoutes;
