import React, { useCallback, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInputMultiSelect from "components/app-input-select-multiple";
import AppInventoryRetireModal from "components/pages/inventory/app-inventory-retire-modal";
import AppInventoryTransferModal from "components/pages/inventory/app-inventory-transfer-modal";

import editIcon from "assets/images/edit-icon.png";
import closeIcon from "assets/images/add-blue-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import transferIcon from "assets/images/pages/inventory/transfer-icon.png";

const locationOptions = [
	{ value: "All", label: "ALL" },
	{ value: "KLCC", label: "KLCC" },
	{ value: "HQ", label: "HQ" }
];

const inventoryData = [{ paginationNumbers: 1, id: "INV-001", sparePart: "PAN X-PREM AC", location: "HQ", quantity: 20, available: 50, costPrice: "100.00", sellingPrice: "190.00", status: "IN_USE" }];

const PageInventories = (props) => {
	const navigate = useNavigate();
	const inventoryRetireModalRef = useRef();
	const inventoryTransferModalRef = useRef();
	const [data, setData] = useState(inventoryData);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [inventoryTableAnchor, setInventoryTableAnchor] = useState(null);
	const defaultLocation = useMemo(() => [], []);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.IN_USE, STATUS.RETIRED].join(","), []);
	// UNCOMMENT WHEN API IS READY
	// const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", status: searchParams.get("status") || defaultStatus, location: searchParams.get("location") || defaultLocation, sort: searchParams.get("sort") || "" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.inventory.inventories(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const formik = useFormik({ initialValues: { search: "", location: [], status: [STATUS.IN_USE, STATUS.RETIRED] }, onSubmit: (values) => onHandleSubmit(values) });

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current.location = values.location;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("location", queryParams.current.location);
	}, [formik]);

	const onHandleCloseInventoryTableMenu = useCallback(() => {
		setInventoryTableAnchor(null);
	}, []);

	const onToggleTableAction = useCallback((event) => {
		setInventoryTableAnchor(event.currentTarget);
	}, []);

	const onHandleCreateInventory = useCallback(() => {
		navigate(pathnames.inventory.inventoryCreateEdit + PAGE.CREATE);
	}, [navigate]);

	const onHandleInventoryTableMenu = useCallback(() => {
		navigate(pathnames.inventory.inventoryCreateEdit + "INV-001");
	}, [navigate]);

	const onHandleInventoryTransfer = () => {
		inventoryTransferModalRef.current.onHandleShow();
	};

	const onHandleInventoryRetire = () => {
		inventoryRetireModalRef.current.onHandleShow();
	};

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current.location = defaultLocation;
	}, [formik, defaultStatus, defaultLocation]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "In-Use", name: STATUS.IN_USE },
			{ label: "Retired", name: STATUS.RETIRED }
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "id",
			label: "Inventory ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "inventoryItem",
			label: "Inventory Item",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "location",
			label: "Location",
			options: {
				sort: false,
			},
		},
		{
			name: "quantity",
			label: "Quantity",
			options: {
				sort: false,
			},
		},
		{
			name: "available",
			label: "Available",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "costPrice",
			label: "Cost Price (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "sellingPrice",
			label: "Selling Price (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			},
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return null;

					return (
						<button type="button" className="table__action" onClick={onToggleTableAction}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onToggleTableAction, restricted]);

	// UNCOMMENT WHEN API IS READY
	// useEffect(() => {
	// 	onHandleGetList();

	// 	return () => {
	// 		memoCancelRequest(ENDPOINT_PATH.INVENTORY.INVENTORIES);
	// 	};
	// }, [onHandleGetList, memoCancelRequest]);

	return (
		<div className="page-inventory">
			<div className="inventory">
				<h1 className="inventory__title">Inventory Listing</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Inventory ID or Inventory Item" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateInventory} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="inventory-filter">
						{/* prettier-ignore */}
						<AppInputMultiSelect menuPropsRootClasses={["inventory-filter-multi-select-popup"]} label="Location" name="location" placeholder="Select..." options={locationOptions} value={formik.values.location} error={formik.errors.location} touched={formik.touched.location} onChange={formik.handleChange} />

						<div className="inventory-filter__container">
							<p className="inventory-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="inventory__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "inventory-table-menu" }} anchorEl={inventoryTableAnchor} open={!!inventoryTableAnchor} onClose={onHandleCloseInventoryTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleInventoryTableMenu}><img src={editIcon} alt="inventory-edit"/>Edit</MenuItem>
				<MenuItem onClick={onHandleInventoryRetire}><img className="inventory-table-menu__close-icon" src={closeIcon} alt="inventory-close"/>Retire</MenuItem>
				<MenuItem onClick={onHandleInventoryTransfer}><img src={transferIcon} alt="inventory-transfer"/>Transfer</MenuItem>
			</Menu>

			<AppInventoryTransferModal ref={inventoryTransferModalRef} />

			<AppInventoryRetireModal ref={inventoryRetireModalRef} />
		</div>
	);
};

export default PageInventories;
