import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppAddServicesModal from "components/pages/work-order/app-add-services-modal";
import AppCreateEditServiceModal from "components/pages/work-order/app-create-edit-service-modal";
import AppEditServiceListingModal from "components/pages/work-order/app-edit-service-listing-modal";
import AppAddServiceChecklistModal from "components/pages/work-order/app-add-service-checklist-modal";
import AppReorderServiceLinesModal from "components/pages/work-order/app-reorder-service-lines-modal";
import AppDeleteServiceListingModal from "components/pages/work-order/app-delete-service-listing-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import reorderingIcon from "assets/images/reordering-icon.svg";
import verticalBreadCrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppWorkOrderServices = (props, ref) => {
	const navigate = useNavigate();
	const selectedItem = useRef();
	const addServicesModal = useRef();
	const createEditServiceModalRef = useRef();
	const editServiceListingModal = useRef();
	const serviceChecklistModalRef = useRef();
	const reorderServiceLinesModal = useRef();
	const deleteServiceListingModal = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [data, setData] = useState(convertPaginationTableData());
	const [inventoryTableAnchor, setInventoryTableAnchor] = useState(null);
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Create"), [isCreate]);
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, value: searchParams.get("value") || "", sort: searchParams.get("sort") || "", tab: "SERVICES" });

	const formik = useFormik({ initialValues: { items: data, serviceRemarks: "" } });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.sparePart.spareParts(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		props.onSubmit(values);
	}, [props]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workOrder.preventiveMaintenance);
	}, [navigate]);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setInventoryTableAnchor(event.currentTarget);
	}, [data]);

	const onHandleAddChecklist = () => {
		serviceChecklistModalRef.current.onHandleShow();
	};

	const onHandleReorderServiceLines = useCallback(() => {
		reorderServiceLinesModal.current.onHandleShow(data.content);
	}, [data.content]);

	const onHandleAddServices = () => {
		addServicesModal.current.onHandleShow();
	};

	const onHandleCreateService = () => {
		createEditServiceModalRef.current.onHandleShow();
	};

	const onHandleInventoryTableMenu = useCallback(() => {
		editServiceListingModal.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	const onHandleDeleteServiceListing = () => {
		deleteServiceListingModal.current.onHandleShow(() => {});
	};

	const onHandleCloseInventoryTableMenu = useCallback(() => {
		setInventoryTableAnchor(null);
	}, []);

	//prettier-ignore
	const onHandleCreateItem = useCallback((values) => {
		const nextItems = [...data.content, ...values].map((o, i) => ({ ...o, serviceNumber: i + 1 }));

		setData(nextItems);

		formik.setFieldValue("items", nextItems);
	}, [data, formik]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = formik.values.items[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [formik.values.items]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "serviceNumber",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: false
			}
		},
		{
			name: "serviceInput",
			label: "Input",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={verticalBreadCrumbsIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		onCellClick: onHandleCellSelect
	}), [onHandleCellSelect]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		return (
			<Fragment>
				<div className="services__item-table">
					<div className="item-table">
						<div className="item-table__content">
							<div className="item-table__item">
								<p className="item-table__label">Service ID</p>

								<p className="item-table__value">{obj.selectedRowItem.serviceId}</p>
							</div>

							<div className="item-table__item">
								<p className="item-table__label">Service Action</p>

								<p className="item-table__value">{obj.selectedRowItem.serviceAction}</p>
							</div>

							<div className="item-table__item">
								<p className="item-table__label">Service Input</p>

								<p className="item-table__value">{obj.selectedRowItem.serviceInput}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="item-table__remark">
					<AppInput multiline type="textarea" name="serviceRemarks" label="Service Remarks" placeholder="Enter Service Remarks" value={obj.values.serviceRemarks} error={obj.errors.serviceRemarks} touched={obj.touched.serviceRemarks} onChange={obj.onChange} />
				</div>
			</Fragment>
		);
	}, []);

	return (
		<div className="app-work-order-services">
			<div className="work-order-services">
				<div className="work-order-services__container">
					<div className="work-order-services__row work-order-services__row--divider">
						<div className="work-order-services__column">
							<p className="work-order-services__label">
								Add Service Checklists <span className="work-order-services__required">*</span>
							</p>

							<AppButton outline type="button" label="Add Existing Checklist(s)" onClick={onHandleAddChecklist} />
						</div>

						<div className="work-order-services__column">
							<p className="work-order-services__label work-order-services__label--services">
								Add Services <span className="work-order-services__required">*</span>
							</p>

							<AppButton outline type="button" label="Add Existing Service(s)" onClick={onHandleAddServices} />

							<p className="work-order-services__text">Or</p>

							<AppButton outline type="button" label="Add New Service" icon={addIcon} onClick={onHandleCreateService} />
						</div>
					</div>

					<p className="work-order-services__label work-order-services__label--services">Service Lines</p>

					<div className="work-order-services__column">
						<div className="work-order-services__header">
							<AppTableFilterHeader searchPlaceholder="Search by Service ID or Service Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

							<AppButton outline type="button" label="Reorder" icon={reorderingIcon} onClick={onHandleReorderServiceLines} />
						</div>

						<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

						<SelectedItemDetails selectedRowItem={selectedRowItem} values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="work-order-services__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

					<AppButton type="button" label={submitLabel} onClick={onHandleSubmit} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "work-order-services-table-menu" }} anchorEl={inventoryTableAnchor} open={!!inventoryTableAnchor} onClose={onHandleCloseInventoryTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleInventoryTableMenu}><img src={editIcon} alt="services-edit" />Edit</MenuItem>

				<MenuItem onClick={onHandleDeleteServiceListing}><img className="work-order-services-table-menu__close-icon" src={trashIcon} alt="services-close" />Delete</MenuItem>
			</Menu>

			<AppAddServicesModal ref={addServicesModal} onConfirm={onHandleCreateItem} />

			<AppDeleteServiceListingModal ref={deleteServiceListingModal} onConfirm={onHandleDeleteServiceListing} />

			<AppEditServiceListingModal ref={editServiceListingModal} />

			<AppReorderServiceLinesModal ref={reorderServiceLinesModal} onConfirm={() => {}} />

			<AppCreateEditServiceModal ref={createEditServiceModalRef} onConfirm={onHandleCreateItem} />

			<AppAddServiceChecklistModal ref={serviceChecklistModalRef} onConfirm={onHandleCreateItem} />
		</div>
	);
};

export default AppWorkOrderServices;
