import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";

const getEnquiryStatus = async (currentStatus) => {
	let response = null;

	let errorMessage = null;

	try {
		const res = await api.get.general.enquiryStatus();
		let content = [];

		res.forEach(({ lookupTitle, lookupValue, ...o }) => {
			const preventPushOptionCondition = (currentStatus === STATUS.OPEN && lookupValue === STATUS.RESOLVED) || (currentStatus === STATUS.IN_PROGRESS && lookupValue === STATUS.OPEN) || (currentStatus === STATUS.CLOSED && lookupValue !== STATUS.CLOSED) || (currentStatus === STATUS.RESOLVED && lookupValue !== STATUS.RESOLVED);

			if (preventPushOptionCondition) {
				return;
			}

			content.push({ label: lookupTitle, value: lookupValue });
		});

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getEnquiryStatus;
