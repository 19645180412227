import { useCallback, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
//TEMP
import { pageNotificationsOutboxTableData } from "temp/data/page-notifications/outbox/page-notifications-outbox-table-data";

import pathnames from "routes/pathnames";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFooter from "components/app-table/app-table-footer";

import eyeIcon from "assets/images/eye-open-icon.png";
import filterIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";

const AppNotificationsOutboxTable = () => {
	const navigate = useNavigate();

	const [menuAnchor, setMenuAnchor] = useState(null);

	const onHandleSubmit = () => {};

	const formik = useFormik({ initialValues: { search: "", startDate: "", endDate: "", recipient: "" }, onSubmit: onHandleSubmit });

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);

		formik.resetForm();
	}, [formik]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	// prettier-ignore
	const onHandleViewNotification = useCallback((obj) => {
		const pathName = `${pathnames.notification.notificationsCreateEdit}`;

		navigate(pathName, { state: { rowData: { index: obj.rowIndex, ...obj.tableData[obj.rowIndex] } } });
	}, [navigate]);

	// prettier-ignore
	let tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				filter: true,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "title",
			label: "Title",
			options: {
				filterList: [],
				filter: true,
				sort: false,
			},
		},
		{
			name: "recipients",
			label: "To",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "dateAndTime",
			label: "Date & Time",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					return dayjs(value).format("DD/MM/YYYY h:mm a").toString();
				},
			},
		},
		{
			name: "view",
			label: "View",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleViewNotification(tableMeta)}>
							<img src={eyeIcon} alt="eye-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleViewNotification]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
			return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
		},
	}), []);

	return (
		<div className="app-notifications-outbox-table">
			<div className="notifications-outbox-table">
				<div className="notifications-outbox-table__header">
					<div className="notifications-outbox-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Title..." endIcon={searchIcon} iconPosition="end" value={formik.values.search} onChange={formik.handleChange} />

						<div className="notifications-outbox-table__filter-button">
							<AppButton outline type="button" label="Filter" icon={filterIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>
				</div>

				<div className="notifications-outbox__table">
					<AppTable data={pageNotificationsOutboxTableData} columns={tableColumns} options={tableOptions} />
				</div>

				{/* prettier-ignore */}
				<Menu classes={{ root: "notifications-outbox-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<div className="filter-menu">
						<div className="filter-menu__body">
							<div className="filter-menu__row">
								<div className="filter-menu__container">
										<div className="filter-menu__inputs filter-menu__inputs--dates">
											<AppInputDate name="startDate" label="Created Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

											<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
										</div>

										<p className="filter-menu__instruction">Please select a date or a date range of up to 90 days.</p>
									</div>

								<div className="filter-menu__container">
									<div className="filter-menu__inputs">
										{/* prettier-ignore */}
										<AppInput label="To" type="text" name="recipient" className="recipient" placeholder="Search by User ID or Name" value={formik.values.recipient} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="filter-menu__footer">
							{/* prettier-ignore */}
							<button type="button" className="filter-menu__button" onClick={formik.resetForm}>Clear All Filters</button>

							{/* prettier-ignore */}
							<div className="filter-menu__buttons">
								<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>Cancel</button>
								<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>Apply</button>
							</div>
						</div>
					</div>
				</Menu>
			</div>
		</div>
	);
};

export default AppNotificationsOutboxTable;
