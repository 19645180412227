import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getUserIdListing = async (payload) => {
	let response = null;

	let errorMessage = null;

	try {
		const params = { page: payload?.page || 0, size: 10, val: payload?.keyword || "" };

		const res = await api.get.humanResource.unassignedUserId(params);

		const content = res.content.map(({ employeeDetail, ...o }) => ({ label: employeeDetail.employeeId, value: employeeDetail.employeeId, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getUserIdListing;
