import React, { useCallback, useMemo, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppSalesOrderDetails from "components/pages/payment/app-sales-order-details";
import AppSalesOrderInvoice from "components/pages/payment/app-sales-order-invoice";
import AppSalesOrderDeliveryOrder from "components/pages/payment/app-sales-order-delivery-order";

import exportIcon from "assets/images/export-icon.png";
import detailsIcon from "assets/images/pages/payment/details-icon.svg";
import invoiceIcon from "assets/images/pages/payment/invoice-icon.svg";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import deliveryOrderIcon from "assets/images/pages/payment/delivery-order-icon.svg";
import activeDetailsIcon from "assets/images/pages/payment/details-active-icon.svg";
import activeInvoiceIcon from "assets/images/pages/payment/invoice-active-icon.svg";
import activeDeliveryOrderIcon from "assets/images/pages/payment/delivery-order-active-icon.svg";

const PageSalesOrderCreateEdit = () => {
	const [menuAnchor, setMenuAnchor] = useState(null);

	//prettier-ignore
	const salesOrderTabs = useMemo(() => [
		{ label: "Detials", icon: detailsIcon, activeIcon: activeDetailsIcon, name: "DETAILS", component: <AppSalesOrderDetails />, accessible: true },
		{ label: "Delivery Order", icon: deliveryOrderIcon, activeIcon: activeDeliveryOrderIcon, name: "DELIVERY_ORDER", component: <AppSalesOrderDeliveryOrder />, accessible: true },
		{ label: "Invoice", icon: invoiceIcon, activeIcon: activeInvoiceIcon, name: "INVOICE", component: <AppSalesOrderInvoice />, accessible: true },
	], []);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Payment", path: pathnames.payment.quotations },
			{ label: "Sales Order", path: pathnames.payment.salesOrders },
			{ label: "Edit Sales Order", path: pathnames.payment.salesOrderCreateEdit }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	const onHandleSetComplete = () => {
		setMenuAnchor(null);
	};

	const onHandleSetDropOff = () => {
		setMenuAnchor(null);
	};

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	return (
		<div className="page-sales-order-create-edit">
			<div className="sales-order-create-edit">
				<div className="sales-order-create-edit__header">
					<h1 className="sales-order-create-edit__title">Edit Sales Order</h1>

					<div className="sales-order-create-edit__header sales-order-create-edit__header--column">
						<div className="sales-order-create-edit__header-buttons">
							<AppButton outline className="sales-order-create-editt__action-button" type="button" label="Actions" icon={chevronIcon} onClick={onToggleFilterMenu} />

							<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={() => {}} />
						</div>

						<p className="sales-order-create-edit__last-update">
							<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
						</p>
					</div>
				</div>

				<div className="sales-order-create-edits__body">
					<AppTabs tabs={salesOrderTabs} />
				</div>
			</div>

			<Menu classes={{ root: "sales-order-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleSetComplete}>Set As Complete with Exception</MenuItem>

				<MenuItem onClick={onHandleSetDropOff}>Set As Drop Off</MenuItem>
			</Menu>
		</div>
	);
};

export default PageSalesOrderCreateEdit;
