import PageWorkInspectionListing from "pages/page-work-inspection-listing";
import PageWorkInspectionListingCreateEdit from "pages/page-work-inspection-listing-create-edit";

import pathnames from "routes/pathnames";

const workInspectionListingRoutes = [
	{
		path: pathnames.workInspectionListing.workInspectionListing,
		element: <PageWorkInspectionListing />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.workInspectionListing.workInspectionListingCreateEdit,
		element: <PageWorkInspectionListingCreateEdit />,
		authenticated: true,
		role: null
	}
];

export default workInspectionListingRoutes;
