import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppInventoryTransferModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const location = useMemo(() => props.location, [props.location]);

	const initialValues = useMemo(() => {
		const values = { destination: "", quantity: "", quantityToTransfer: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			destination: yup.string().required(ERRORS.REQUIRED),
			quantityToTransfer: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;
	
		try {
			const payload = { id: id, quantity: values.quantity, destinationId: values.destinationId };

			await api.post.inventory.transferInventory(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			dispatch(promptLayoutAlertMessage({ message: "Inventory was transfered successfully!" }));
		}
	}, [formik, id, dispatch]);

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-transfer-modal" }} open={visible}>
			<div className="inventory-transfer-modal">
				<button type="button" className="inventory-transfer-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-transfer-modal__title">Transfer from {location || `[HQ]`}</h1>

				<form className="inventory-transfer-modal__form" onSubmit={formik.handleSubmit}>
					{/* prettier-ignore */}
					<AppSelectInput required name="destination" label="Location" placeholder="Select..." options={[]} value={formik.values.destination} error={formik.errors.destination} touched={formik.touched.destination} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput disabled type="text" name="quantity" label="Available Quantity" placeholder="Available Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required type="number" name="quantityToTransfer" label="Quantity to Transfer" placeholder="Enter a number that is higher than 0" value={formik.values.quantityToTransfer} error={formik.errors.quantityToTransfer} touched={formik.touched.quantityToTransfer} onChange={formik.handleChange} />

					<div className="inventory-transfer-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryTransferModal));

AppInventoryTransferModal.propTypes = {
	ref: PropTypes.object
};
