import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import sanitizeObject from "common/sanitize-object";
import ENDPOINT_PATH from "constants/end-point-path";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppContractsTable = () => {
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CONTRACT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, value: searchParams.get("value") || "", sort: searchParams.get("sort") || "", tab: "CONTRACTS" });
	const [data, setData] = useState(convertPaginationTableData());

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.contract.contracts(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current["value"] = event.target.value;
		
		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleAddContract = useCallback(() => {
		navigate(pathnames.operations.contractCreateEdit + PAGE.CREATE);
	}, [navigate]);

	// prettier-ignore
	const onHandleEditContract = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.operations.contractCreateEdit + id);
	}, [data, navigate]);

	// prettier-ignore
	let tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "referenceNo",
			label: "Contract ID",
			options: {
				sort: false,
			},
		},
		{
			name: "contractName",
			label: "Contract Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "contractType",
			label: "Contract Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditContract(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditContract]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch(action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

					onHandleGetList();
					break;
				default:
					break;    
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CONTRACT.CONTRACTS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-contracts-table">
			<div className="contracts-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by Contract ID or Contract Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddContract} />

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppContractsTable;
