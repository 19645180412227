import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppReportDetailsCreateEditModal from "components/pages/work-inspection-listing/app-report-details-create-edit-modal";

import editIcon from "assets/images/edit-icon.png";

const assetData = [{ id: "WIR-001", customerAssetName: "Samsung AC", assetName: "Samsung", workspace: "Hotel", assetType: "Air Conditioner" }];

const AppReportDetailsAssetTable = () => {
	const reportDetailsAssetRef = useRef();
	const [data, setData] = useState(assetData);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	//prettier-ignore
	const onHandleEditReportDetailsAsset = useCallback((obj) => {
		reportDetailsAssetRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	const onHandleAddReportDetailsAsset = useCallback(() => {
		reportDetailsAssetRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "id",
			label: "WIR ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditReportDetailsAsset(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditReportDetailsAsset]);

	useEffect(() => {
		setData(assetData);
	}, []);

	return (
		<div className="app-report-details-asset-table">
			<div className="report-details-asset-table">
				<AppTableFilterHeader searchPlaceholder="Search by Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleAdd={onHandleAddReportDetailsAsset} />

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppReportDetailsCreateEditModal ref={reportDetailsAssetRef} />
		</div>
	);
};

export default AppReportDetailsAssetTable;
