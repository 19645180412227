import store from "store/store";

const getPermission = (routeRoles) => {
	const profile = store.getState()?.profile;
	const profilePermissionNames = profile.roles[0].permission.map((o) => o.name);

	const permissionFound = profilePermissionNames.some((profilePermission) => routeRoles.some((routeRole) => routeRole === profilePermission));

	return permissionFound;
};

export default getPermission;
