import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

export const AppClaimSummaryModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { claimType: "", amount: "", id: "", description: "", files: [] };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			amount: yup.string().required(ERRORS.REQUIRED),
			claimType: yup.string().required(ERRORS.REQUIRED)
		}),

		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ claimType: obj?.claimType || "", amount: obj?.amount || "", id: obj?.id, description: obj?.description || "",  files: obj?.files || [], });
		
		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			// const payload = { id: values.id, amount: values.amount, claimType: values.claimType, description: values.description};

			if (isCreate) {
				// await api.post.inventory.createConfiguration(payload);
			}

			if (!isCreate) {
				// await api.post.inventory.updateConfiguration(payload);
			}
			
			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Claim was added successfully!" }));
			}
		}
	}, [isCreate, formik, dispatch]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-claim-summary-modal" }} open={visible}>
			<div className="claim-summary-modal">
				<h1 className="claim-summary-modal__title">New Claim</h1>

				<form className="claim-summary-modal__form" onSubmit={formik.handleSubmit}>
					{/* prettier-ignore */}
					<AppSelectInput required searchable={false} name="claimType" label="Claim Type" placeholder="Select..." value={formik.values.claimType} error={formik.errors.claimType} touched={formik.touched.claimType} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required type="text" name="amount" label="Bill Amount (MYR)" placeholder="Enter Amount" value={formik.values.amount} error={formik.errors.amount} touched={formik.touched.amount} onChange={formik.handleChange} />

					<p className="claim-summary-modal__label">Attachment(s)</p>

					<h1 className="claim-summary-modal__description">Please ensure that all images are in either jpg or png format with file size not exceeding 5MB.</h1>

					{/* prettier-ignore */}
					{formik.values.files /* prettier-ignore */ && <AppInputDragAndDropFiles length={1} name="files" accept="image/png, image/jpeg, image/jpg,.doc,.docx,.pdf" onChange={formik.setFieldValue} error={formik.errors.files} touched={formik.touched.files} value={formik.values.files} />}

					{/* prettier-ignore */}
					<AppInput multiline type="textarea" maxLength={500} name="description" label="Description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					<div className="claim-summary-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppClaimSummaryModal));

AppClaimSummaryModal.propTypes = {
	ref: PropTypes.object
};
