import forge from "node-forge";

const stringEncryptRSA = (string = "", key) => {
	// Convert base64-encoded public key to ASN.1 representation
	const publicKeyAsn1 = forge.asn1.fromDer(forge.util.decode64(key || process.env.REACT_APP_RSA_PUBLIC_KEY));

	// Convert ASN.1 public key to Forge public key object
	const publicKey = forge.pki.publicKeyFromAsn1(publicKeyAsn1);

	// Encrypt a message with RSA-OAEP and the public key
	const encryptedMessage = publicKey.encrypt(string, "RSA-OAEP", {
		md: forge.md.sha256.create(),
		mgf1: {
			md: forge.md.sha1.create()
		}
	});

	const stringEncryptRSA = forge.util.encode64(encryptedMessage);

	return stringEncryptRSA;
};

export default stringEncryptRSA;
