import COMMON from "common";
import store from "store/store";
import { redirect } from "react-router-dom";

import pathnames from "routes/pathnames";
import enrichedRoutes from "routes/routes";

import STATUS from "constants/status";
import getPermission from "common/get-permission";

export const protectedRoutes = ({ request, params }) => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);
	const direction = onHandlePermissionRoutes(request, params);

	if (direction) {
		return redirect(direction);
	} else if (token) {
		return true;
	} else {
		return redirect(pathnames.login);
	}
};

export const publicRoutes = () => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	if (token) return redirect(pathnames.home);

	return true;
};

const onHandlePermissionRoutes = (request, params) => {
	const profile = store.getState()?.profile;
	const isForceChangePassword = profile.forceChangePassword === STATUS.FORCE_CHANGE_PASSWORD;

	const id = params?.id;
	const url = new URL(request.url);
	let sanitizedUrl = new URL(url);

	const route = enrichedRoutes.find((o) => {
		let path = o.path;

		Object.keys(params).forEach((k) => {
			const key = `:${k}`;

			if (path.includes(key) && params[k]) {
				path = path.replace(key, params[k]);
			}
		});

		return url.pathname === path;
	});

	if (id !== undefined && sanitizedUrl) {
		sanitizedUrl = sanitizedUrl.pathname.split("/");
		sanitizedUrl.pop();
		sanitizedUrl = sanitizedUrl.join("/");
	} else {
		sanitizedUrl = sanitizedUrl.pathname + sanitizedUrl.search;
	}

	if (isForceChangePassword) {
		switch (sanitizedUrl) {
			case pathnames.userAccount + "?tab=RESET-PASSWORD":
				return false;
			default:
				return pathnames.userAccount + "?tab=RESET-PASSWORD";
		}
	}

	if (!route) return "*";

	if (route.role) {
		const permissionFound = getPermission(route.role);

		if (!permissionFound) return "*";
	}

	return false;
};
