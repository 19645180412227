import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import ENDPOINT_PATH from "constants/end-point-path";
import ENQUIRY_CONFIGURATION_TYPE from "constants/enquiry-configuration-type";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppEnquiriesKpiConfigurationModal = (props, ref) => {
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { firstResponseGoalTime: "", resolutionGoalTime: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			firstResponseGoalTime: yup.number().required(ERRORS.REQUIRED),
			resolutionGoalTime: yup.number().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetDetails = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.customerEnquiry.configuration();
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const firstResponseGoalTime = response[response.findIndex((o) => o.name === ENQUIRY_CONFIGURATION_TYPE.FIRST_RESPONSE_TIME)]?.goalTime;
			const resolutionGoalTime = response[response.findIndex((o) => o.name === ENQUIRY_CONFIGURATION_TYPE.RESOLUTION_TIME)]?.goalTime;

			memoSetFormValues({ firstResponseGoalTime, resolutionGoalTime });
		}
	}, [memoSetFormValues]);

	const onHandleShow = useCallback(() => {
		setVisible(true);

		onHandleGetDetails();
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const params = { ...values };

			await api.post.customerEnquiry.updateConfiguration(params);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();

			onHandleGetDetails();

			dispatch(promptLayoutAlertMessage({ message: "Enquiry was updated successfully!" }));
		}
	}, [dispatch, onHandleDismiss, onHandleGetDetails, formik]);

	useEffect(() => {
		onHandleGetDetails();
	}, [onHandleGetDetails]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION);
		};
	}, [cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-enquiries-kpi-configuration-modal" }} open={visible}>
			<div className="enquiries-kpi-configuration-modal">
				<button type="button" className="enquiries-kpi-configuration-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="enquiries-kpi-configuration-modal__title">Enquiry KPI Configuration</h1>

				<form className="enquiries-kpi-configuration-modal__form" onSubmit={formik.handleSubmit}>
					<h2 className="enquiries-kpi-configuration-modal__header">First Response Time</h2>

					<h3 className="enquiries-kpi-configuration-modal__description">The time elapsed between a customer sending in an enquiry and when it's status is changed to In Progress.</h3>

					<div className="enquiries-kpi-configuration-modal__time-picker">
						{/* prettier-ignore */}
						<AppInput required disabled={restricted} type="number" name="firstResponseGoalTime" label="Goal Time (Minutes)" value={formik.values.firstResponseGoalTime} error={formik.errors.firstResponseGoalTime} touched={formik.touched.firstResponseGoalTime} onChange={formik.handleChange} />
					</div>

					<h2 className="enquiries-kpi-configuration-modal__header">Resolution Time</h2>

					{/* prettier-ignore */}
					<h4 className="enquiries-kpi-configuration-modal__description">The time elapsed between the enquiry's status is changed from In Progress to any of the following: <span>Resolved, Closed,</span> or <span>Cancelled.</span></h4>

					<div className="enquiries-kpi-configuration-modal__time-picker">
						{/* prettier-ignore */}
						<AppInput required disabled={restricted} type="number" name="resolutionGoalTime" label="Goal Time (Minutes)" value={formik.values.resolutionGoalTime} error={formik.errors.resolutionGoalTime} touched={formik.touched.resolutionGoalTime} onChange={formik.handleChange} />
					</div>

					<div className="enquiries-kpi-configuration-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEnquiriesKpiConfigurationModal));

AppEnquiriesKpiConfigurationModal.propTypes = {
	ref: PropTypes.object
};
