import React, { memo } from "react";

const AppOperationsIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<g id="Artboard 1_1 2">
				<g id="Group 14711">
					<path id="Vector" d="M10.4609 14.0613C10.4609 15.2036 9.57094 16.1382 8.46094 16.1382C7.35094 16.1382 6.46094 15.2036 6.46094 14.0613C6.46094 12.919 7.36094 11.9844 8.46094 11.9844C9.56094 11.9844 10.4609 12.919 10.4609 14.0613Z" stroke={color} strokeWidth="0.9" />
					<path id="Vector_2" d="M13.4716 14.1451C13.4916 13.7193 13.5516 13.2935 13.5516 13.2935L14.7116 12.3382C14.9116 12.1824 14.9616 11.8916 14.8316 11.6735L13.5916 9.45125C13.5216 9.31625 13.3616 9.26432 13.2316 9.31625L11.6616 9.92894C11.5716 9.97048 11.4616 9.94971 11.3816 9.8874L10.4716 9.20201C10.4016 9.15009 10.3616 9.0774 10.3516 8.99432L10.1316 7.42625C10.1116 7.27048 9.98162 7.15625 9.83162 7.15625H7.18162C7.03162 7.15625 6.91162 7.27048 6.88162 7.41586L6.62162 9.02548C6.59162 9.20201 6.48162 9.3474 6.33162 9.40971L6.03162 9.59663C5.91162 9.65894 5.46162 9.97048 5.34162 9.91855L3.75162 9.26432C3.61162 9.2124 3.45162 9.26432 3.38162 9.39932L2.06162 11.7774C1.99162 11.9124 2.02162 12.0785 2.13162 12.172L3.43162 13.2832C3.50162 13.3455 3.54162 13.4285 3.54162 13.5116C3.54162 13.7505 3.58162 14.1762 3.61162 14.467C3.63162 14.6539 3.55162 14.8305 3.41162 14.9447L2.12162 15.9208C2.00162 16.0143 1.96162 16.1908 2.04162 16.3258L3.36162 18.6935C3.43162 18.8285 3.59162 18.8805 3.72162 18.8285L5.21162 18.2574C5.31162 18.2158 5.41162 18.2366 5.49162 18.2989C5.81162 18.5481 6.26162 18.8285 6.47162 18.9531C6.55162 19.0051 6.60162 19.0778 6.61162 19.1712L6.83162 20.7289C6.85162 20.8847 6.98162 20.9989 7.13162 20.9989H9.78162C9.93162 20.9989 10.0516 20.8847 10.0816 20.7393L10.3416 19.1297C10.3616 19.0258 10.4216 18.9428 10.5216 18.9012C10.9016 18.7247 11.3516 18.4651 11.5616 18.3301C11.6416 18.2781 11.7316 18.2781 11.8216 18.3093L13.2216 18.8908C13.3616 18.9428 13.5216 18.8908 13.5916 18.7558L14.9116 16.3778C14.9816 16.2428 14.9516 16.0766 14.8416 15.9832L13.4516 14.7889C13.4516 14.7889 13.4816 14.4255 13.4916 14.1555L13.4716 14.1451Z" stroke={color} />
					<path id="Vector_3" d="M13.7617 9.46432L13.8117 9.29817C14.0317 8.57125 14.6917 8.03125 15.4617 8.03125C16.4217 8.03125 17.1917 8.83086 17.1917 9.82778C17.1917 10.8247 16.4217 11.6243 15.4617 11.6243H14.9617" stroke={color} strokeWidth="0.9" />
					<path id="Vector_4" d="M15.4725 14.655C15.4725 14.655 15.9425 14.655 16.1625 14.6446C16.2425 14.6446 16.3225 14.6758 16.3725 14.7381L17.2525 15.7869C17.3425 15.8908 17.4825 15.9115 17.5925 15.8388L19.5625 14.5304C19.6725 14.4577 19.7125 14.3123 19.6625 14.1877L19.0425 12.6611C19.0425 12.6611 19.2025 12.3911 19.3225 12.1835C19.5025 11.8511 19.7225 11.5396 19.7225 11.5396L21.0025 11.28C21.2225 11.2385 21.3725 11.0308 21.3625 10.8023L21.2625 8.53846C21.2625 8.40346 21.1625 8.29962 21.0325 8.27885L19.5625 8.09193C19.4725 8.08154 19.4025 8.02962 19.3625 7.94654L18.9125 7.02231C18.8825 6.96 18.8725 6.87693 18.9025 6.80424L19.3425 5.47501C19.3825 5.35039 19.3325 5.20501 19.2125 5.1427L17.1425 4.03155C17.0325 3.96924 16.8825 4.0004 16.8125 4.11463L15.9825 5.26732C15.8925 5.39193 15.7525 5.45424 15.6025 5.45424L15.2925 5.47501C15.1725 5.47501 14.7025 5.52693 14.6225 5.43347L13.6325 4.24963C13.5425 4.14578 13.4025 4.12501 13.2925 4.1977L11.3225 5.50616C11.2125 5.57885 11.1725 5.72424 11.2225 5.84885L11.8025 7.27154C11.8325 7.34423 11.8025 7.5 11.8025 7.5L11.6725 7.89462" stroke={color} strokeLinecap="round" />
				</g>
			</g>
		</svg>
	);
};

export default memo(AppOperationsIcon);
