import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import SERVICE_TYPE from "constants/service-type";
import ENDPOINT_PATH from "constants/end-point-path";
import CONFIGURATION_TYPE from "constants/configuration-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppAddServicesLinkageModal from "components/pages/operations/general-configuration/app-add-services-linkage-modal";
import AppDeleteServicesLinkageModal from "components/pages/operations/general-configuration/app-delete-services-linkage-modal";
import AppReorderServiceLinkageModal from "components/pages/operations/general-configuration/app-reorder-service-linkage-modal";

import trashIcon from "assets/images/trash-icon.png";
import filterIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";

const AppServicesLinkageTable = (props) => {
	const { id } = useParams();
	const addServicesLinkageModalRef = useRef();
	const deleteServicesLinkageModalRef = useRef();
	const reorderServiceLinkageModalRef = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const showHeader = useMemo(() => (isCreate ? props.formValues.configServices.length : !isCreate), [isCreate, props.formValues.configServices?.length]);
	const profile = useSelector((state) => state.profile);
	const isSparePartConfig = useMemo(() => props.pageType === CONFIGURATION_TYPE.SPARE_PART, [props.pageType]);
	const accessible = useMemo(() => (isSparePartConfig ? profile?.permissions?.[ROLES.SPARE_PART_CONFIG] : profile?.permissions?.[ROLES.SERVICE_CHECKLIST_CONFIG]), [isSparePartConfig, profile?.permissions]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const defaultStatus = useMemo(() => [STATUS.IN_USE, STATUS.RETIRED].join(","), []);
	const defaultServiceType = useMemo(() => [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET].join(","), []);
	const defaultParams = useMemo(() => {
		const nextParams = {
			page: 0,
			"service-action": searchParams.get("service-action") || "",
			sort: searchParams.get("sort") || "",
			status: searchParams.get("status") || defaultStatus,
			"service-type": searchParams.get("service-type") || defaultServiceType
		};

		if (isSparePartConfig) {
			nextParams["config-spare-part-id"] = id;
		} else {
			nextParams["config-service-check-list-id"] = id;
		}

		return nextParams;
	}, [defaultServiceType, defaultStatus, id, isSparePartConfig, searchParams]);
	// prettier-ignore
	const queryParams = useRef(defaultParams);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({ initialValues: { serviceAction: "", status: [STATUS.IN_USE, STATUS.RETIRED], "service-type": [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET] }, onSubmit: (values) => onHandleSubmit(values) });

	const onHandleGetData = useCallback(() => {
		let currentData = structuredClone(props.formValues.configServices);
		let nextData = [];

		const params = sanitizeObject({ ...queryParams.current, size: 10 });

		memoSearchParams.current(params);

		const lowerCaseSearch = queryParams.current["service-action"]?.toLowerCase();

		nextData = currentData.filter((o) => {
			return queryParams.current.status.split(",").includes(o.status) && queryParams.current["service-type"].split(",").includes(o.serviceType);
		});

		nextData = nextData.filter((o) => {
			return o.serviceAction.toLowerCase().includes(lowerCaseSearch);
		});

		if (!isSparePartConfig) {
			nextData = nextData.sort((a, b) => a.seq - b.seq);
		}

		setData({ content: nextData });
	}, [isSparePartConfig, props.formValues.configServices]);

	const onHandleGetList = useCallback(async () => {
		if (isCreate) {
			onHandleGetData();
		}

		if (!isCreate) {
			let response = null;

			try {
				const params = sanitizeObject({ ...queryParams.current, size: 10 });

				memoSearchParams.current(params);

				if (isSparePartConfig) {
					response = await api.get.sparePart.serviceConfigs(params);
				} else {
					response = await api.get.serviceChecklist.serviceConfigs(params);
				}
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const obj = convertPaginationTableData(response);

				if (!isSparePartConfig) {
					obj.content = obj.content.sort((a, b) => a.seq - b.seq);
				}

				setData(obj);
			}
		}
	}, [isCreate, isSparePartConfig, onHandleGetData]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");
		queryParams.current["service-type"] = values["service-type"].join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current["service-action"] = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current["service-type"] = defaultServiceType;

		onHandleGetList();
	}, [formik, defaultStatus, defaultServiceType, onHandleGetList]);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	//prettier-ignore
	const onHandleDeleteServicesLinkage = useCallback((obj) => {
		if (!restricted) deleteServicesLinkageModalRef.current.onHandleShow(obj);
	}, [restricted]);

	const onHandleAddServiceModal = useCallback(() => {
		if (!restricted) addServicesLinkageModalRef.current.onHandleShow(props.formValues.configServiceIds, props.formValues.configServices);
	}, [props, restricted]);

	const onHandleReorderServiceModal = useCallback(() => {
		if (!restricted) reorderServiceLinkageModalRef.current.onHandleShow(props.formValues.configServices);
	}, [props, restricted]);

	//prettier-ignore
	const onHandleSelectServiceType = useCallback((value, name) => {
		let values = [...formik.values["service-type"]];

		if (formik.values["service-type"].length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("service-type", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterServiceType = useMemo(() => {
		const data = [
			{ label: "Asset", name: SERVICE_TYPE.ASSET },
			{ label: "Non-Asset", name: SERVICE_TYPE.NON_ASSET }
		];

		return data;
	}, []);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "In-Use", name: STATUS.IN_USE },
			{ label: "Retired", name: STATUS.RETIRED }
		];

		return data;
	}, []);

	const tableColumnNames = useMemo(() => {
		if (isSparePartConfig) {
			return { number: "paginationNumbers", referenceNo: "referenceNo", serviceAction: "serviceAction", serviceType: "serviceType", input: "input.label", status: "status" };
		} else {
			if (isCreate) return { number: "seq", referenceNo: "referenceNo", serviceAction: "serviceAction", serviceType: "serviceType", input: "input.label", status: "status" };

			if (!isCreate) return { number: "seq", referenceNo: "service.referenceNo", serviceAction: "service.serviceAction", serviceType: "service.serviceType", input: "service.input.label", status: "service.status" };
		}
	}, [isSparePartConfig, isCreate]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: tableColumnNames.number,
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: tableColumnNames.referenceNo,
			label: "Service ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: tableColumnNames.serviceAction,
			label: "Service Action",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: tableColumnNames.serviceType,
			label: "Service Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: tableColumnNames.input,
			label: "Input",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: tableColumnNames.status,
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const selectedServiceData = data.content.filter((o) => (o.referenceNo || o.service.referenceNo) === tableMeta.rowData[1]);

					return (
						<button type="button" className="table__action" onClick={() => onHandleDeleteServicesLinkage(selectedServiceData[0])}>
							<img src={trashIcon} alt="trash-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, tableColumnNames, onHandleDeleteServicesLinkage]);

	const emptyState = useMemo(() => {
		if (data?.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Services Linked.
							<span className="table__link" onClick={onHandleAddServiceModal}>
								Link Services?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data, tableColumns, onHandleAddServiceModal]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			isSparePartConfig ? cancelRequest(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS) : cancelRequest(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS);
		};
	}, [isSparePartConfig, cancelRequest]);

	return (
		<div className="app-services-linkage-table">
			<div className="services-linkage-table">
				{!!showHeader && (
					<div className="services-linkage-table__header">
						<div className="services-linkage-table__search-input">
							{/* prettier-ignore */}
							<AppInput type="text" name="serviceAction" placeholder="Search by Service Action" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current["service-action"]} onChange={onHandleDebounceSearch} onBlur={() => {}} />

							<div className="services-linkage-table__filter-button">
								<AppButton outline type="button" label="Filter" icon={filterIcon} onClick={onToggleFilterMenu} />
							</div>
						</div>

						<div className="services-linkage-table__interact">
							{!isSparePartConfig && (
								<div className="services-linkage-table__interact-button">
									<AppButton type="button" label="Reorder" icon={addBlueIcon} disabled={restricted} onClick={onHandleReorderServiceModal} />
								</div>
							)}

							<div className="services-linkage-table__interact-button">
								<AppButton type="button" label="Add" icon={addBlueIcon} disabled={restricted} onClick={onHandleAddServiceModal} />
							</div>
						</div>
					</div>
				)}

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} components={emptyState} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "services-linkage-table-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">

						<div className="filter-menu__content">
							<p className="filter-menu__label">Service Type</p>

							{menuFilterServiceType.map((o) => {
								const isActive = formik.values["service-type"].findIndex(i => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectServiceType(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>

						<div className="filter-menu__content">
							<p className="filter-menu__label">Status</p>

								{menuFilterStatus.map((o) => {
									const isActive = formik.values.status.findIndex(i => i === o.name) > -1;

									return (
										<div className="filter-menu__checkbox" key={o.label}>
											<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />
										</div>
									);
								})}
						</div>
					</div>

					<div className="filter-menu__footer">
						<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>Clear All Filters</button>

						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>Cancel</button>
							
							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>Apply</button>
						</div>
					</div>
				</div>
			</Menu>

			{/* prettier-ignore */}
			<AppAddServicesLinkageModal ref={addServicesLinkageModalRef} isSparePartConfig={isSparePartConfig} formValues={props.formValues} onHandleUpdateConfigServices={props.onHandleUpdateConfigServices} onHandleUpdateConfigServiceIds={props.onHandleUpdateConfigServiceIds} onHandleGetDetails={props.onHandleGetDetails} />

			{/* prettier-ignore */}
			<AppDeleteServicesLinkageModal ref={deleteServicesLinkageModalRef} isSparePartConfig={isSparePartConfig} onHandleGetDetails={props.onHandleGetDetails} onHandleRemoveServiceLinkage={props.onHandleRemoveServiceLinkage} />

			{/* prettier-ignore */}
			<AppReorderServiceLinkageModal ref={reorderServiceLinkageModalRef} isSparePartConfig={isSparePartConfig} onHandleUpdateConfigServices={props.onHandleUpdateConfigServices} onHandleUpdateConfigServiceIds={props.onHandleUpdateConfigServiceIds} onHandleGetDetails={props.onHandleGetDetails} onHandleRefreshList={onHandleGetList} />
		</div>
	);
};

export default AppServicesLinkageTable;
