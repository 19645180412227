import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { Menu } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import ClassNames from "common/class-names";
import getTotalDays from "common/get-total-days";

import STATUS from "constants/status";
import TIME_OFF from "constants/time-off";
import DATE_TIME from "constants/date-time";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppViewLeaveDetailsModal from "components/pages/human-resources/app-view-leave-details-modal";

import filerIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";
import expandIcon from "assets/images/pages/human-resources/expand-icon.svg";

const dummyData = [
	{ paginationNumbers: 1, name: "Dio", employeeId: "123", leaveType: "Annual Leave", days: 1, startDate: new Date("2023-03-27"), endDate: new Date("2023-03-27"), timeOff: "Half Day", status: STATUS.REJECTED, description: "NaN" },
	{ paginationNumbers: 2, name: "Ali", employeeId: "234", leaveType: "Medical Leave", days: 1, startDate: new Date("2023-03-28"), endDate: new Date("2023-03-28"), timeOff: "Full Day", status: STATUS.APPROVED, description: "NaN" },
	{ paginationNumbers: 3, name: "Joestar", employeeId: "345", leaveType: "Emergency Leave", days: 2, startDate: new Date("2023-03-29"), endDate: new Date("2023-03-29"), timeOff: "Full Day", status: STATUS.CANCELLED, description: "NaN" }
];

const AppViewEmployeeLeaveHistoryTable = () => {
	const leaveHistoryRef = useRef();
	const [data, setData] = useState(dummyData);
	const [searchParams] = useSearchParams();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "LEAVE_HISTORY" });

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "", timeOff: [TIME_OFF.FULL_DAY, TIME_OFF.HALF_DAY_AM, TIME_OFF.HALF_DAY_PM], status: [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => ClassNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate,
	}), [formik.errors.endDate]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["submit-date"] = "";
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
	}, [formik]);

	const menuFilterTimeOff = useMemo(() => {
		const data = [
			{ label: "Full Day", name: TIME_OFF.FULL_DAY },
			{ label: "Half Day AM", name: TIME_OFF.HALF_DAY_AM },
			{ label: "Half Day PM", name: TIME_OFF.HALF_DAY_PM }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectTimeOff = useCallback((value, name) => {
		let values = [...formik.values.timeOff];

		if (formik.values.timeOff.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("timeOff", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleViewLeaveHistory = useCallback((obj) => {
		leaveHistoryRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	const onHandleSubmit = useCallback((values) => {
		queryParams.current["start-date"] = values.startDate && dayjs(values.startDate).isValid() ? dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
		queryParams.current["end-date"] = values.endDate && dayjs(values.endDate).isValid() ? dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "leaveType",
			label: "leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false,
			},
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "timeOff",
			label: "Time Off",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />,
			},
		},
		{
			name: "attachment",
			label: "Attachment",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleViewLeaveHistory(tableMeta)}>
							<img src={expandIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleViewLeaveHistory]);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-view-employee-leave-history-table">
			<div className="view-employee-leave-history-table">
				<div className="view-employee-leave-history-table__header">
					<div className="view-employee-leave-history-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Leave Type" endIcon={searchIcon} iconPosition="end" onChange={onHandleDebounceSearch} />

						<div className="view-employee-leave-history-table__filter-button">
							<AppButton outline type="button" label="Filter" icon={filerIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-view-employee-leave-history-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<div className="filter-menu__date-inputs">
							<AppInputDate name="startDate" label="Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

							<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
						</div>

						<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
					</div>

					<div className="filter-menu__checkboxes">
						<div className="filter-menu__row">
							<p className="filter-menu__label">Time Off</p>

							{menuFilterTimeOff.map((o) => {
								const isActive = formik.values.timeOff.findIndex((i) => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectTimeOff(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>

						<div className="filter-menu__row">
							<p className="filter-menu__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>
					</div>

					<div className="filter-menu__footer">
						<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>
							Clear All Filters
						</button>

						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>
								Cancel
							</button>

							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>
								Apply
							</button>
						</div>
					</div>
				</div>
			</Menu>

			<AppViewLeaveDetailsModal ref={leaveHistoryRef} />
		</div>
	);
};

export default AppViewEmployeeLeaveHistoryTable;
