import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import STATUS from "constants/status";
import TRANSFER_TYPE from "constants/transfer-type";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInventoryHistoryViewModal from "components/pages/inventory/app-inventory-history-view-modal";
import AppInventoryHistoryConfirmationModal from "components/pages/inventory/app-inventory-history-confirmation-modal";

import eyeIcon from "assets/images/eye-open-icon.png";

const tempData = [
	{ id: 1, employee: "Employee 1", inventoryItem: "Inventory 1", dateTime: "2024-07-01", transactionType: "", from: "", to: "b", workOrderId: "work order 1", quantityMovement: "10", runningBalance: "1000", status: STATUS.PENDING },
	{ id: 2, employee: "Employee 2", inventoryItem: "Inventory 2", dateTime: "2024-07-02", transactionType: "Transaction Type 2", from: "a", to: "b", workOrderId: "work order 2", quantityMovement: "10", runningBalance: "1000", status: STATUS.APPROVED },
	{ id: 3, employee: "Employee 3", inventoryItem: "Inventory 3", dateTime: "2024-07-03", transactionType: "Transaction Type 3", from: "a", to: "b", workOrderId: "work order 3", quantityMovement: "10", runningBalance: "1000", status: STATUS.REJECTED },
	{ id: 4, employee: "Employee 4", inventoryItem: "Inventory 4", dateTime: "2024-07-04", transactionType: "Transaction Type 4", from: "a", to: "b", workOrderId: "work order 4", quantityMovement: "10", runningBalance: "1000", status: STATUS.ISSUED },
	{ id: 5, employee: "Employee 5", inventoryItem: "Inventory 5", dateTime: "2024-07-05", transactionType: "Transaction Type 5", from: "a", to: "b", workOrderId: "work order 5", quantityMovement: "10", runningBalance: "1000", status: STATUS.RECEIVED },
	{ id: 6, employee: "Employee 6", inventoryItem: "Inventory 6", dateTime: "2024-07-06", transactionType: "Transaction Type 6", from: "a", to: "b", workOrderId: "work order 6", quantityMovement: "10", runningBalance: "1000", status: STATUS.APPROVED }
];

const PageInventoryHistory = () => {
	const inventoryRef = useRef();
	const confirmRef = useRef();
	const [searchParams] = useSearchParams();
	const [data, setData] = useState(tempData);
	const [selectedPending, setSelectedPending] = useState([]);
	const defaultStatus = useMemo(() => [STATUS.PENDING, STATUS.ISSUED, STATUS.APPROVED, STATUS.RECEIVED, STATUS.REJECTED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const defaultTransferType = useMemo(() => [TRANSFER_TYPE.TRANSFER, TRANSFER_TYPE.STOCK_IN].join(","), []);
	const transferType = useMemo(() => {
		const currentTransferType = searchParams.get("transfer-type")?.split(",");
		const relevantTransferType = currentTransferType?.every((e) => defaultTransferType.split(",").includes(e));

		if (!relevantTransferType) {
			return defaultTransferType;
		} else {
			return searchParams.get("transfer-type");
		}
	}, [defaultTransferType, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: status, transferType: transferType, sort: searchParams.get("sort") || "" });

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		queryParams.current.transferType = values.transferType.join(",");
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;
	}, []);

	const formik = useFormik({
		initialValues: {
			status: [STATUS.PENDING, STATUS.ISSUED, STATUS.APPROVED, STATUS.RECEIVED, STATUS.REJECTED],
			transferType: [TRANSFER_TYPE.STOCK_IN, TRANSFER_TYPE.TRANSFER]
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("transferType", queryParams.current.transferType.split(","));
	}, [formik]);

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((id) => id !== idNo);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const onHandleView = useCallback((tableMeta) => {
		inventoryRef.current.onHandleShow(data[tableMeta.rowIndex]);
	}, [data]);

	const onHandleApproveReject = useCallback((data, status) => {
		confirmRef.current.onHandleShow(data, status);
	}, []);

	const onHandleDismiss = useCallback((data) => {
		inventoryRef.current.onHandleShow(data);
	}, []);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Pending", name: STATUS.PENDING },
			{ label: "Issued", name: STATUS.ISSUED },
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Received", name: STATUS.RECEIVED },
			{ label: "Rejected", name: STATUS.REJECTED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterTransferType = useMemo(() => {
		const data = [
			{ label: "Stock In", name: TRANSFER_TYPE.STOCK_IN },
			{ label: "Transfer", name: TRANSFER_TYPE.TRANSFER }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectTransferType = useCallback((value, name) => {
		let values = [...formik.values.transferType];

		if (formik.values.transferType.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("transferType", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current.transferType = defaultTransferType;
	}, [defaultStatus, defaultTransferType, formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "employee",
			label: "Employee",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryItem",
			label: "Inventory Item",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "dateTime",
			label: "Date Time",
			options: {
				sort: false
			}
		},
		{
			name: "transactionType",
			label: "Transaction Type",
			options: {
				sort: false
			}
		},
		{
			name: "from",
			label: "From",
			options: {
				sort: false
			}
		},
		{
			name: "to",
			label: "To",
			options: {
				sort: false
			}
		},
		{
			name: "workOrderId",
			label: "Work Order Id",
			options: {
				sort: false
			}
		},
		{
			name: "quantityMovement",
			label: "Quantity Movement",
			options: {
				sort: false
			}
		},
		{
			name: "runningBalance",
			label: "Running Balance",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleView(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [data, onHandleView, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	useEffect(() => {
		setData(tempData);
	}, []);

	return (
		<div className="page-inventory-history">
			<div className="inventory-history">
				<h1 className="inventory-history__title">Inventory History</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Employee Name, Inventory Item or Work Order ID" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="inventory-history-filter">
						<div className="inventory-history-filter__container">
							<p className="inventory-history-filter__label">Transfer Type</p>

							<div className="inventory-history-filter__checkbox-container">
								{menuFilterTransferType.map((o) => {
									const isActiveType = formik.values.transferType.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectTransferType(v, o.name)} label={o.label} value={isActiveType} />;
								})}
							</div>
						</div>

						<div className="inventory-history-filter__container">
							<p className="inventory-history-filter__label">Status</p>

							<div className="inventory-history-filter__checkbox-container">
								{menuFilterStatus.map((o) => {
									const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />;
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="inventory-history__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>

			<AppInventoryHistoryViewModal ref={inventoryRef} onHandleApproveReject={onHandleApproveReject} />

			<AppInventoryHistoryConfirmationModal ref={confirmRef} onHandleDismiss={onHandleDismiss} />
		</div>
	);
};

export default PageInventoryHistory;
