import { useMemo } from "react";

import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppInputsTable from "components/pages/operations/general-configuration/app-inputs-table";
import AppCategoryTable from "components/pages/operations/general-configuration/app-category-table";
import AppContractsTable from "components/pages/operations/general-configuration/app-contracts-table";
import AppSparePartTable from "components/pages/operations/general-configuration/app-spare-parts-table";
import AppSubCategoryTable from "components/pages/operations/general-configuration/app-sub-category-table";
import AppServiceListingTable from "components/pages/operations/general-configuration/app-service-listing-table";
import AppServiceChecklistTable from "components/pages/operations/general-configuration/app-service-checklist-table";

import listIcon from "assets/images/pages/general-configuration/list-icon.svg";
import toolsIcon from "assets/images/pages/general-configuration/tools-icon.svg";
import inputIcon from "assets/images/pages/general-configuration/inputs-icon.svg";
import categoryIcon from "assets/images/pages/general-configuration/category-icon.png";
import contractIcon from "assets/images/pages/general-configuration/contract-icon.svg";
import checklistIcon from "assets/images/pages/general-configuration/checklist-icon.svg";
import activeListIcon from "assets/images/pages/general-configuration/list-active-icon.svg";
import subCategoryIcon from "assets/images/pages/general-configuration/sub-category_icon.png";
import activeToolsIcon from "assets/images/pages/general-configuration/tools-active-icon.svg";
import activeInputIcon from "assets/images/pages/general-configuration/inputs-active-icon.svg";
import activeCategoryIcon from "assets/images/pages/general-configuration/category-active-icon.png";
import activeContractIcon from "assets/images/pages/general-configuration/contract-active-icon.svg";
import activeChecklistIcon from "assets/images/pages/general-configuration/checklist-active-icon.svg";
import activeSubCategoryIcon from "assets/images/pages/general-configuration/sub-category-active-icon.png";

const PageGeneralConfiguration = () => {
	const profile = useSelector((state) => state.profile);

	// prettier-ignore
	const GeneralConfigurationTabs = useMemo(() => {
		const data =  [
			{ label: "Spare Parts", icon: toolsIcon, activeIcon: activeToolsIcon, name: "SPARE_PARTS", component: <AppSparePartTable />, accessible: profile?.permissions?.[ROLES.SPARE_PART_CONFIG]?.view },
			{ label: "Service Listing", icon: listIcon, activeIcon: activeListIcon, name: "SERVICE_LISTING", component: <AppServiceListingTable />, accessible: profile?.permissions?.[ROLES.SERVICE_CONFIG]?.view },
			{ label: "Service Checklist", icon: checklistIcon, activeIcon: activeChecklistIcon, name: "SERVICE_CHECKLIST", component: <AppServiceChecklistTable />, accessible: profile?.permissions?.[ROLES.SERVICE_CHECKLIST_CONFIG]?.view},
			{ label: "Contracts", icon: contractIcon, activeIcon: activeContractIcon, name: "CONTRACTS", component: <AppContractsTable />, accessible: profile?.permissions?.[ROLES.CONTRACT_CONFIG]?.view},
			{ label: "Inputs", icon: inputIcon, activeIcon: activeInputIcon, name: "INPUTS", component: <AppInputsTable />, accessible: profile?.permissions?.[ROLES.INPUT_CONFIG]?.view},
			{ label: "Category", icon: categoryIcon, activeIcon: activeCategoryIcon, name: "CATEGORY", component: <AppCategoryTable />, accessible: profile?.permissions?.[ROLES.INPUT_CONFIG]?.view},
			{ label: "Sub-Category", icon: subCategoryIcon, activeIcon: activeSubCategoryIcon, name: "SUB_CATEGORY", component: <AppSubCategoryTable />, accessible: profile?.permissions?.[ROLES.INPUT_CONFIG]?.view},
		];

		return data.filter(o => o.accessible);
	}, [profile]);

	return (
		<div className="page-general-configuration">
			<div className="general-configuration">
				<h1 className="general-configuration__title">General Configuration</h1>

				<div className="general-configuration__body">
					<AppTabs tabs={GeneralConfigurationTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageGeneralConfiguration;
