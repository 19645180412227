import React, { memo, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { dismissAlertMessage } from "store/slices/alert";

import classNames from "common/class-names";

import checkedIcon from "assets/images/components/app-alert/checked-icon.svg";

const AppAlert = () => {
	const timer = useRef();
	const resetTimer = useRef();
	const dispatch = useDispatch();
	const alert = useSelector((state) => state.alert);
	const [active, setActive] = useState(false);
	const errorCode = useMemo(() => (alert.code ? `(Error Code : ${alert.code})` : ""), [alert.code]);

	const className = useMemo(() => {
		return classNames({
			"app-alert": true,
			"app-alert--active": active,
			"app-alert--error": alert.error
		});
	}, [alert, active]);

	useEffect(() => {
		if (alert.message) {
			clearTimeout(timer.current);
			clearTimeout(resetTimer.current);
			setActive(true);

			timer.current = setTimeout(() => {
				setActive(false);

				resetTimer.current = setTimeout(() => {
					dispatch(dismissAlertMessage());
				}, 1000);
			}, 5000);
		}
	}, [alert, dispatch]);

	if (!alert?.message || typeof alert?.message !== "string") return null;

	return (
		<div className={className} id="app-alert">
			<div className="alert">
				{!alert.error && <img className="alert__icon" src={checkedIcon} alt="checked" />}

				<p className="alert__text">
					{alert.message} {errorCode}
				</p>
			</div>
		</div>
	);
};

export default memo(AppAlert);
