import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppRadioInput from "components/app-radio-input";

export const AppHolidaysCreateEditModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const holidayTypeOptions = useMemo(() => [
        { label: "Repeated Yearly", value: "yearly" },
        { label: "One-Time", value: "oneTime" },
    ], []);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			name: "",
			type: "",
			description: "",
			startDate: "",
			endDate: "",
			items: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.string().required(ERRORS.REQUIRED),
			endDate: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik]);

	const title = useMemo(() => (isCreate ? "Add Holiday" : "Edit Holiday"), [isCreate]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ id: obj?.id || "", name: obj?.name || "", current: obj?.name, description: obj?.description || "", startDate: "", endDate: "" });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleSubmit = (values) => {
		const startDate = new Date(values.startDate).toLocaleDateString("en-GB");
		const endDate = new Date(values.endDate).toLocaleDateString("en-GB");
		let formattedValues = {};

		if (startDate !== endDate) {
			formattedValues = { ...values, date: `${startDate} ${endDate}` };
		} else {
			formattedValues = { ...values, date: startDate };
		}

		props.onConfirm(formattedValues);
		onHandleDismiss();
	};

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-hr-view-holidays-create-edit-modal" }} open={visible}>
			<div className="hr-view-holidays-create-edit-modal">
				<h1 className="hr-view-holidays-create-edit-modal__title">{title}</h1>

				<form className="hr-view-holidays-create-edit-modal__form" onSubmit={formik.handleSubmit}>
					{!isCreate /* prettier-ignore */ && <AppInput disabled type="text" name="current" label="Current Name" value={formik.values.current} onChange={formik.handleChange} />}

					{/* prettier-ignore */}
					<AppInput required type="text" name="name" label="New Holiday" placeholder="Enter Holiday Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

					<div className="hr-view-holidays-create-edit-modal__form--row">
						{/* prettier-ignore */}
						<AppInputDate name="startDate" label="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} placeholder="Select start date" />

						{/* prettier-ignore */}
						<AppInputDate name="endDate" label="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} placeholder="Select end date" />
					</div>

					<AppRadioInput required label="Holiday Type" options={holidayTypeOptions} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={(v) => formik.setFieldValue("type", v)} />

					{/* prettier-ignore */}
					<AppInput multiline type="textarea" maxLength={500} name="description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					<div className="hr-view-holidays-create-edit-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppHolidaysCreateEditModal));

AppHolidaysCreateEditModal.propTypes = {
	ref: PropTypes.object.isRequired
};
