import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

export const AppViewLeaveDetailsModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = {
			name: "",
			employeeId: "",
			leaveType: "",
			timeOff: "",
			startDate: "",
			endDate: "",
			submitDate: "",
			days: "",
			description: "",
			rejectReason: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			name: obj?.name || "",
			employeeId: obj?.employeeId || "",
			leaveType: obj?.leaveType || "",
			status: obj?.status || "",
			timeOff: obj?.timeOff || "",
			startDate: dayjs(obj?.startDate) || "",
			endDate: dayjs(obj?.endDate) || "",
			submitDate: dayjs(obj?.submitDate) || "",
			days: obj?.days || "",
			description: obj?.description || "",
			rejectReason: obj?.rejectReason || "",
		});
		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleSubmit = useCallback(async (values) => {}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-view-leave-details-modal" }} open={visible}>
			<div className="view-leave-details-modal">
				<div className="view-leave-details-modal__header">
					<h1 className="view-leave-details-modal__title">Leave Details</h1>

					<AppStatus status={formik.values.status} />
				</div>

				<form className="view-leave-details-modal__form" onSubmit={formik.handleSubmit}>
					<div className="view-leave-details-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInput disabled type="text" name="leaveType" label="Leave Type" value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="timeOff" label="Time Off" value={formik.values.timeOff} error={formik.errors.timeOff} touched={formik.touched.timeOff} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInputDate disabled type="text" name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="days" label="Days" value={formik.values.days} error={formik.errors.days} touched={formik.touched.days} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="text" label="Description" name="description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					{formik.values.status === STATUS.REJECTED && <AppInput disabled type="textarea" label="Reject Reason" name="rejectReason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />}

					<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onChange={formik.setFieldValue} />
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppViewLeaveDetailsModal));

AppViewLeaveDetailsModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
