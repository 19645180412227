import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppSubCategoryTable = () => {
	const isMounted = useRef(true);
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const defaultStatus = useMemo(() => [STATUS.IN_USE, STATUS.RETIRED].join(","), []);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, name: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", status: searchParams.get("status") || defaultStatus, tab: "SUB_CATEGORY" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { status: [STATUS.IN_USE, STATUS.RETIRED] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.sparePartSubCategory.sparePartSubCategories(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current["keyword"] = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, defaultStatus, onHandleGetList]);

	const onHandleAddSubCategory = useCallback(() => {
		navigate(pathnames.operations.subCategoryCreateEdit + PAGE.CREATE);
	}, [navigate]);

	//prettier-ignore
	const onHandleEditSubCategory = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.operations.subCategoryCreateEdit + id);
	}, [data.content, navigate]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "In-Use", name: STATUS.IN_USE },
			{ label: "Retired", name: STATUS.RETIRED }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
	}, [formik]);

	//prettier-ignore
	let tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Sub Category ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Sub Category Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditSubCategory(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditSubCategory]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let status = searchParams.get("status");

			if (status) {
				status = status.split(",");

				memoSetFormValues({ status });
			}
		}
	}, [isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORIES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-sub-category-table">
			<div className="sub-category-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Sub Category name or Sub Category ID" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleAddSubCategory} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="sub-category-table-filter">
						<div className="sub-category-table-filter__container">
							<p className="sub-category-table__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppSubCategoryTable;
