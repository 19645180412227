import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import getWorkspaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

export const AppReportDetailsCreateEditModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			assetName: "",
			workspace: "",
			assetType: "",
			customerAssetName: "",
			items: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			assetName: yup.string().required(ERRORS.REQUIRED),
			workspace: yup.string().required(ERRORS.REQUIRED),
			assetType: yup.string().required(ERRORS.REQUIRED),
			customerAssetName: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);

	const title = useMemo(() => (isCreate ? "Add Asset Type" : "Edit Asset Type"), [isCreate]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ workspace: obj?.workspace || "", customerAssetName: obj?.customerAssetName || "", assetName: obj?.assetName, assetType: obj?.assetType || "", id: obj?.id, });
		
		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async () => {}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-report-details-create-edit-modal" }} open={visible}>
			<div className="report-details-create-edit-modal">
				<h1 className="report-details-create-edit-modal__title">{title}</h1>

				<form className="report-details-create-edit-modal__form" onSubmit={formik.handleSubmit}>
					{/* prettier-ignore */}
					<AppInput required type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Enter Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required type="text" name="assetName" label="Asset Name" placeholder="Enter Asset Name" value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppSelectInput required searchable={false} name="workspace" label="Workspace" placeholder="Select..." loadOptions={getWorkspaceListing} value={formik.values.workspace} error={formik.errors.workspace} touched={formik.touched.workspace} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppSelectInput required searchable={false} name="assetType" label="Asset Type" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.assetType} error={formik.errors.assetType} touched={formik.touched.assetType} onChange={formik.handleChange} />

					<div className="report-details-create-edit-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppReportDetailsCreateEditModal));

AppReportDetailsCreateEditModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
