const REGEX = {
	SYMBOL: /(\W|_)/,
	NUMERIC: /(?=.*\d)/,
	LOWERCASE: /(?=.*[a-z])/,
	UPPERCASE: /(?=.*[A-Z])/,
	FIRST_LETTER_UPPERCASE: /\b[a-z]/g,
	EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{8,}$/
};

export default REGEX;
