import React, { useMemo } from "react";

import AppTabs from "components/app-tabs";
import AppAllClaimHistory from "components/pages/human-resources/app-all-claim-history-table";
import AppClaimDirectoryTable from "components/pages/human-resources/app-claim-directory-table";
import AppClaimPendingAprroval from "components/pages/human-resources/app-claim-pending-approval-table";

import claimIcon from "assets/images/pages/human-resources/claim.svg";
import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import activeClaimIcon from "assets/images/pages/human-resources/claim-active.svg";
import activeClockIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import claimApprovalIcon from "assets/images/pages/human-resources/claim-approval-icon.svg";
import activeClaimApprovalIcon from "assets/images/pages/human-resources/claim-approval-active-icon.svg";

const PageClaimManagement = () => {
	//prettier-ignore
	const claimManagementTabs = useMemo(() => [
		{ label: "Claim Directory", icon: claimIcon, activeIcon: activeClaimIcon, name: "CLAIM_DIRECTORY", component: <AppClaimDirectoryTable />, accessible: true },
		{ label: "Pending Approval", icon: claimApprovalIcon, activeIcon: activeClaimApprovalIcon, name: "CLAIM_PENDING_APPROVAL", component: <AppClaimPendingAprroval />, accessible: true },
		{ label: "Claim History", icon: clockIcon, activeIcon: activeClockIcon, name: "CLAIM_HISTORY", component: <AppAllClaimHistory />, accessible: true },
	], []);

	return (
		<div className="page-claim-management">
			<div className="claim-management">
				<div className="claim-management__header">
					<h1 className="claim-management__title">All Claims</h1>
				</div>

				<div className="claim-management__body">
					<AppTabs tabs={claimManagementTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageClaimManagement;
