import { createSlice } from "@reduxjs/toolkit";

import sanitizeError from "common/sanitize-error";

const initialState = {
	code: "",
	messages: "",
	error: false
};

export const layoutAlertSlice = createSlice({
	name: "layoutAlert",
	initialState,
	reducers: {
		promptLayoutAlertMessage: (state, action) => {
			const payload = action.payload?.message || "";
			const message = sanitizeError(payload);

			return { message, error: !!action.payload?.error, code: action.payload?.code };
		},
		dismissLayoutAlertMessage: () => initialState
	}
});

export const { promptLayoutAlertMessage, dismissLayoutAlertMessage } = layoutAlertSlice.actions;

export default layoutAlertSlice.reducer;
