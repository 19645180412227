import pathnames from "routes/pathnames";

import manHorse from "../assets/images/temp/man-horse.webp";

const Page404 = () => {
	return (
		<div className="page404">
			<p className="page404__title">Even the 404 page is having a hard time today...</p>
			<div className="page404__img">
				<img src={manHorse} alt="" />
			</div>
			<div className="page404__reroute">
				<p>
					You can always go home{" "}
					<a className="text" href={pathnames.home}>
						here
					</a>{" "}
					when you're ready...
				</p>
			</div>
		</div>
	);
};

export default Page404;
