// import { useCallback, useEffect } from "react";

// import dayjs from "dayjs";
// import { TableCell } from "@mui/material";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// // import createCustomEndPointObject from "common/create-custom-end-point-object";
// import { pageActivityLogsTableData } from "temp/data/page-activity-logs/page-activity-logs-table-data";

// import pathnames from "routes/pathnames";

// import AppIcon from "components/app-icon";
// import AppTable from "components/app-table/app-table";
// import AppTableFooter from "components/app-table/app-table-footer";

// import chevronRight from "assets/images/chevron-right-icon.png";

const PageActivityLogsViewDetails = ({ onHandleCustomEndpoint }) => {
	// const navigate = useNavigate();
	// const location = useLocation();
	// const endPointParam = useParams();

	// // TODO: Replace this with API Data as this data is incomplete, currently just getting the data either from the row or directly from the table's data
	// let activityData = location.state ? location.state.rowData : pageActivityLogsTableData[Number(endPointParam.activityId) - 1];

	// const { profilePic, activityType, action, details, user, date, extraData } = activityData;

	// const getUserName = (str) => {
	// 	const parts = str.split(" ");
	// 	return parts.slice(1).join(" ");
	// };

	// const getUserId = (str) => {
	// 	return str.split(" ")[0];
	// };

	// let activityDetailsTableColumns = [
	// 	{
	// 		name: "module",
	// 		label: "Module",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellHeaderProps: () => ({ style: { width: "215px", paddingLeft: "40px" } }),
	// 			setCellProps: () => ({ style: { width: "215px", paddingLeft: "40px", background: "#F3FBFF" } })
	// 		}
	// 	},
	// 	{
	// 		name: "action",
	// 		label: "Action",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "white", width: "312px" } })
	// 		}
	// 	},
	// 	{
	// 		name: "details",
	// 		label: "Details",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "white", width: "383px" } })
	// 		}
	// 	}
	// ];

	// let activityDetailsTableData = [{ module: activityType, action: `${action[0].toUpperCase()}${action.slice(1)}`, details: details }];

	// const activityDetailsTableOptions = {
	// 	filter: false,
	// 	search: false,
	// 	download: false,
	// 	print: false,
	// 	viewColumns: false,
	// 	selectableRowsHideCheckboxes: true,
	// 	rowsPerPage: 15,
	// 	fixedHeader: false,
	// 	customToolbarSelect: () => {},
	// 	customFooter: () => {
	// 		return;
	// 	}
	// };

	// let addActivityTableColumns = [
	// 	{
	// 		name: "number",
	// 		label: "#",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "#F3FBFF" } })
	// 		}
	// 	},
	// 	{
	// 		name: "field",
	// 		label: "Field",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellHeaderProps: () => ({ style: { width: "180px", paddingLeft: "40px" } }),
	// 			setCellProps: () => ({ style: { width: "180px", paddingLeft: "40px", background: "#F3FBFF" } })
	// 		}
	// 	},
	// 	{
	// 		name: "input",
	// 		label: "Input",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "white" } })
	// 		}
	// 	}
	// ];

	// let addActivityTableData = () => {
	// 	const listOfCurrentData = Object.entries(extraData.currentData);
	// 	const addActivityTableRowList = listOfCurrentData.map((activityList, index) => {
	// 		const activityKey = `${activityList[0][0].toUpperCase()}${activityList[0].slice(1)}`.match(/[A-Z][a-z]*/g).join(" ");
	// 		const activityValue = activityList[1];
	// 		return { number: index + 1, field: activityKey, input: activityValue };
	// 	});
	// 	return addActivityTableRowList;
	// };

	// let addActivityTableOptions = {
	// 	filter: false,
	// 	search: false,
	// 	download: false,
	// 	print: false,
	// 	viewColumns: false,
	// 	selectableRowsHideCheckboxes: true,
	// 	rowsPerPage: 15,
	// 	fixedHeader: false,
	// 	customToolbarSelect: () => {},
	// 	customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
	// 		return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
	// 	}
	// };

	// let editActivityTableColumns = [
	// 	{
	// 		name: "number",
	// 		label: "#",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "#F3FBFF" } })
	// 		}
	// 	},
	// 	{
	// 		name: "field",
	// 		label: "Field",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellHeaderProps: () => ({ style: { width: "180px", paddingLeft: "40px" } }),
	// 			setCellProps: () => ({ style: { width: "180px", paddingLeft: "40px", background: "#F3FBFF" } })
	// 		}
	// 	},
	// 	{
	// 		name: "previousFieldContents",
	// 		label: "Previous Field Contents",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "white", width: "272px" } })
	// 		}
	// 	},
	// 	{
	// 		name: "chevron",
	// 		label: "",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			customHeadRender: ({ index, ...column }) => {
	// 				return (
	// 					<TableCell className="chevron-right__header" key={index}>
	// 						<AppIcon src={chevronRight} />
	// 					</TableCell>
	// 				);
	// 			},
	// 			customBodyRender: () => {
	// 				return (
	// 					<div className="chevron-right__body">
	// 						<AppIcon src={chevronRight} />
	// 					</div>
	// 				);
	// 			},
	// 			setCellProps: () => ({ style: { background: "white" } })
	// 		}
	// 	},
	// 	{
	// 		name: "currentFieldContents",
	// 		label: "Current Field Contents",
	// 		options: {
	// 			filter: false,
	// 			sort: false,
	// 			setCellProps: () => ({ style: { background: "white", width: "396px" } })
	// 		}
	// 	}
	// ];

	// let editActivityTableData = () => {
	// 	const listOfPreviousData = Object.entries(extraData.previousData);
	// 	const listOfCurrentData = Object.entries(extraData.currentData);
	// 	let editActivityTableRowList = listOfPreviousData.map((activityList, index) => {
	// 		const activityKeyPrevious = `${activityList[0][0].toUpperCase()}${activityList[0].slice(1)}`.match(/[A-Z][a-z]*/g).join(" ");
	// 		const activityValuePrevious = activityList[1];
	// 		return { number: index + 1, field: activityKeyPrevious, previousFieldContents: activityValuePrevious };
	// 	});
	// 	editActivityTableRowList = editActivityTableRowList.map((activityList, index) => {
	// 		activityList.currentFieldContents = listOfCurrentData[index][1];
	// 		return activityList;
	// 	});
	// 	return editActivityTableRowList;
	// };

	// let editActivityTableOptions = {
	// 	filter: false,
	// 	search: false,
	// 	download: false,
	// 	print: false,
	// 	viewColumns: false,
	// 	selectableRowsHideCheckboxes: true,
	// 	rowsPerPage: 15,
	// 	fixedHeader: false,
	// 	customToolbarSelect: () => {},
	// 	customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
	// 		return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
	// 	}
	// };

	// const activityDataTable = () => {
	// 	if (activityData.action === "add") {
	// 		return (
	// 			<AppTable
	// 				// Set a key based on filteredData.length to re-render the table & to prevent page out of data bounds warning
	// 				//(this means the table is recreated with the new data and the page count resets to 0 with correspondence to correct data)
	// 				// key={activityData.length}
	// 				data={addActivityTableData()}
	// 				columns={addActivityTableColumns}
	// 				options={addActivityTableOptions}
	// 			/>
	// 		);
	// 	} else if (activityData.action === "edit") {
	// 		return (
	// 			<AppTable
	// 				// Set a key based on filteredData.length to re-render the table & to prevent page out of data bounds warning
	// 				//(this means the table is recreated with the new data and the page count resets to 0 with correspondence to correct data)
	// 				// key={activityData.length}
	// 				data={editActivityTableData()}
	// 				columns={editActivityTableColumns}
	// 				options={editActivityTableOptions}
	// 			/>
	// 		);
	// 	}
	// };

	// /* BREADCRUMB RELATED*/

	// const customEndPoint = useCallback(() => {
		// if (activityData) {
		// 	const pathSchema = `${pathnames.activityLogsViewDetails}/:activityId`;
		// 	const breadCrumbRouteNameObj = {
		// 		activityId: `View ${activityData.activityId} Details`,
		// 	};
		// 	// const customEndPointsObject = createCustomEndPointObject(pathSchema, endPointParam, breadCrumbRouteNameObj);
		// 	onHandleCustomEndpoint(customEndPointsObject);
		// }
	// }, []);
	// const customEndPoint = useCallback(() => {
	// 	// if (activityData) {
	// 	// 	const pathSchema = `${pathnames.activityLogsViewDetails}/:activityId`;
	// 	// 	const breadCrumbRouteNameObj = {
	// 	// 		activityId: `View ${activityData.activityId} Details`,
	// 	// 	};
	// 	// 	// const customEndPointsObject = createCustomEndPointObject(pathSchema, endPointParam, breadCrumbRouteNameObj);
	// 	// 	onHandleCustomEndpoint(customEndPointsObject);
	// 	// }
	// }, [endPointParam, onHandleCustomEndpoint, activityData]);

	// // TODO: Might not need this as server may throw error and help to prompt to 404 page instead
	// useEffect(() => {
	// 	if (!activityData) {
	// 		navigate(pathnames.activityLogs);
	// 	} else {
	// 		customEndPoint();
	// 	}
	// }, [customEndPoint, activityData, navigate]);

	// if (!activityData) {
	// 	return null;
	// }

	// REFER TO PAGE-USER-ACCOUNT TO SEE HOW TO IMPLEMENT THE ADD OR EDIT ACTION IN HERE DYNAMICALLY
	return (
		<div className="page-activity-logs-view-details">
			{/* <div className="activity-logs-view-details">
				<div className="activity-logs__activity-details">
					<div className="details-header">
						<div className="details-header__left">
							<div className="profile-picture">
								<img className="picture" src={profilePic} alt="profile" />
							</div>
							<div className="user-details">
								<div className="user-details__user-name">
									<b>{getUserName(user)}</b>
								</div>
								<div className="user-details__user-id">
									<b>User ID : </b>
									{getUserId(user)}
								</div>
								<div className="user-details__user-role">
									<b>Role : </b>
									{activityData.role}
								</div>
							</div>
						</div>
						<div className="details-header__right">
							<div className="activity-date">{dayjs(date).format("DD/MM/YYYY")}</div>
							<div className="activity-time">{dayjs(date).format("h:mm a")}</div>
						</div>
					</div>
					<div className="details-table">
						<AppTable
							// Set a key based on filteredData.length to re-render the table & to prevent page out of data bounds warning
							//(this means the table is recreated with the new data and the page count resets to 0 with correspondence to correct data)
							// key={activityData.length}
							data={activityDetailsTableData}
							columns={activityDetailsTableColumns}
							options={activityDetailsTableOptions}
						/>
					</div>
				</div>
				<div className="activity-logs__activity-data">{activityDataTable()}</div>
			</div> */}
		</div>
	);
};

export default PageActivityLogsViewDetails;
