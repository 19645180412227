import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import reorderArrayElement from "common/reorder-array-element";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppReorderServiceLinkageModal = forwardRef((props, ref) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const [visible, setVisible] = useState(false);
	const [services, setServices] = useState([]);
	const seqOptions = useMemo(() => services.map((_, i) => ({ label: i + 1, value: i })), [services]);
	const onHandleRefreshList = useMemo(() => props.onHandleRefreshList, [props.onHandleRefreshList]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.serviceChecklist.serviceConfigsList({ id });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const formattedResponse = response.map((o) => {
				return { ...o, referenceNo: o.service.referenceNo, serviceAction: o.service.serviceAction };
			});

			formattedResponse.sort((a, b) => a.seq - b.seq);

			setServices(formattedResponse);
		}
	}, [id]);

	//prettier-ignore
	const onHandleShow = useCallback((configServices) => {
		if (isCreate) {
			setServices(configServices);
		}

		if (!isCreate) {
			onHandleGetList();
		}

		setVisible(true);
	}, [isCreate, onHandleGetList]);

	const onHandleDismiss = useCallback(() => {
		setServices([]);

		setVisible(false);
	}, []);

	const onHandleSubmit = useCallback(async () => {
		const servicesLinkages = services.map((o) => ({ id: o.id, seq: o.seq }));

		if (isCreate) {
			const servicesLinkagesIds = servicesLinkages.map((o) => o.id);

			props.onHandleUpdateConfigServices(services);

			props.onHandleUpdateConfigServiceIds(servicesLinkagesIds);

			setVisible(false);
		}

		if (!isCreate) {
			let response = null;

			const payload = {
				configServiceChecklistId: id,
				servicesLinkages
			};

			try {
				await api.post.serviceChecklist.reorderServiceConfig(payload);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				onHandleRefreshList();

				dispatch(promptLayoutAlertMessage({ message: "Service Checklist was reordered successfully!" }));

				setVisible(false);
			}
		}
	}, [dispatch, id, isCreate, onHandleRefreshList, props, services]);

	// prettier-ignore
	const onHandleReorderSeq = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;
		let nextServices = structuredClone(services);

		reorderArrayElement(nextServices, name, value);

		nextServices = nextServices.map((o, i) => {
			o.seq = i + 1;

			return o;
		});

		setServices(nextServices);
	}, [services]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "seq",
			label: "#",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => {
					const { rowIndex } = tableMeta;

					return (
						<div className="reorder-service-linkage-modal__table-row-service-number">
							<AppSelectInput type="text" searchable={false} name={rowIndex.toString()} placeholder="" options={seqOptions} value={value - 1} onChange={onHandleReorderSeq} />
						</div>
					);
				}
			}
		},
		{
			name: "referenceNo",
			label: "Service ID",
			options: {
				sort: false
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		}
	], [seqOptions, onHandleReorderSeq]);

	const tableOptions = { customFooter: () => null };

	useImperativeHandle(ref, () => ({
		onHandleShow,
		onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-reorder-service-linkage-modal" }} open={visible}>
			<div className="reorder-service-linkage-modal">
				<button type="button" className="reorder-service-linkage-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="reorder-service-linkage-modal__title">Reorder Service Action</h1>

				<div className="reorder-service-linkage-modal__form">
					<AppTable data={services} options={tableOptions} columns={tableColumns} />

					<div className="reorder-service-linkage-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Update" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
});

export default memo(AppReorderServiceLinkageModal);
