import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";
import getUserStatusListing from "services/get-user-status-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppWorkSpaceTypesCreateEditModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { status: "", current: "", description: "", name: "", id: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik.values.id]);

	const title = useMemo(() => (isCreate ? "Add New Work Space Type" : "Edit Work Space Type"), [isCreate]);

	// prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ status: obj?.status || "", current: obj?.name || "", name: "", id: obj?.id, description: obj?.description || "" });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { id: values.id, name: values.name, status: values.status, description: values.description };

			if (isCreate) {
				await api.post.customerConfiguration.createWorkspace(payload);
			}

			if (!isCreate) {
				await api.post.customerConfiguration.updateWorkspace(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Workspace Type was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Workspace Type was updated successfully!" }));
			}

			props.onHandleGetList();
		}
	}, [isCreate, formik, dispatch, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-work-space-types-create-edit-modal" }} open={visible}>
			<div className="work-space-types-create-edit-modal">
				<button type="button" className="work-space-types-create-edit-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="work-space-types-create-edit-modal__title">{title}</h1>

				<form className="work-space-types-create-edit-modal__form" onSubmit={formik.handleSubmit}>
					{!isCreate /* prettier-ignore */ && <AppInput disabled type="text" name="current" label="Current Work Space Name" placeholder="Enter Current Work Space Name" value={formik.values.current} onChange={formik.handleChange} />}

					{/* prettier-ignore */}
					<AppInput required type="text" name="name" label="Name" placeholder="Work Space type name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppSelectInput required searchable={false} type="text" name="status" label="Status" placeholder="Select..." loadOptions={getUserStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required name="description" label="Description" placeholder="Enter Description" type="textarea" multiline maxLength={255} value={formik.values.description} touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange} />

					<div className="work-space-types-create-edit-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkSpaceTypesCreateEditModal));

AppWorkSpaceTypesCreateEditModal.propTypes = {
	ref: PropTypes.object
};
