import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppInventoryRetireModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleSubmit = (values) => {};

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-retire-modal" }} open={visible}>
			<div className="inventory-retire-modal">
				<button type="button" className="inventory-retire-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-retire-modal__title">Confirmation to Retire</h1>

				<p className="inventory-retire-modal__description">
					After clicking confirm, the status of <span className="inventory-retire-modal__inventory-id">INV-004</span> will be changed to <span className="inventory-retire-modal__status">Retired</span>.
				</p>

				<div className="inventory-retire-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" disabled={onHandleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryRetireModal));

AppInventoryRetireModal.propTypes = {
	ref: PropTypes.object
};
