import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { pageActivityLogsTableData } from "temp/data/page-activity-logs/page-activity-logs-table-data";

import pathnames from "routes/pathnames";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFooter from "components/app-table/app-table-footer";

import filter from "assets/images/filter-icon.png";
import search from "assets/images/search-icon.png";
import eyeIcon from "assets/images/eye-open-icon.png";
import exportIcon from "assets/images/export-icon.png";
import chevronRight from "assets/images/chevron-right-icon.png";
import checkerIcon from "assets/images/components/app-checkbox/checker-icon.svg";
import { ReactComponent as calendar } from "assets/images/components/app-input-date/calendar-icon.svg";

const PageActivityLogs = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// TODO: Replace pageActivityLogsTableData with API data
	const [filteredData, setFilteredData] = useState([...pageActivityLogsTableData]);

	const [filters, setFilters] = useState({});

	const [filterMenuActive, setFilterMenuActive] = useState(false);

	const [userQueryValue, setUserQueryValue] = useState("");

	// onClickFilterObject represents the current filters that are to be applied on filteredData when onExecuteFilter is triggered
	const onClickFilterObjectDefault = useMemo(() => {
		return {};
	}, []);
	const onClickFilterObject = useRef({ ...onClickFilterObjectDefault });

	const filterRefButton = useRef();
	const filterRef = useRef();
	const appInputStartDateRef = useRef();
	const appInputEndDateRef = useRef();

	const [startDateOpen, setStartDateOpen] = useState(false);
	const [endDateOpen, setEndDateOpen] = useState(false);

	const [startDateValue, setStartDateValue] = useState(null);
	const [endDateValue, setEndDateValue] = useState(null);

	const [disableApplyFilter, setDisableApplyFilter] = useState(false);

	const activityClickableRef = useRef();

	const activityCheckBoxListRef = useRef();

	/* TABLE COLUMNS RELATED */

	let tableColumns = [
		{
			name: "number",
			label: "#",
			options: {
				filter: true,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "activityId",
			label: "Activity ID",
			options: {
				filter: true,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "user",
			label: "User",
			options: {
				filterList: [],
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "activityType",
			label: "Activity Type",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "details",
			label: "Details",
			options: {
				filter: false,
				sort: false
			}
		},
		{
			name: "date",
			label: "Date",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					const dateObject = dayjs(value);
					return dateObject.format("DD/MM/YYYY h:mm a").toString();
				}
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const { rowIndex, tableData } = tableMeta;
					const { number, ...activityData } = tableData[rowIndex];
					if (tableData[rowIndex] && tableData[rowIndex].view) {
						return (
							<div
								className="view-activity"
								onClick={() => {
									redirectToViewActivity(number, activityData);
								}}>
								<AppIcon src={eyeIcon} />
							</div>
						);
					}
				}
			}
		}
	];

	/* TABLE OPTIONS RELATED */

	// Set footerPage to replace page param from customFooter func to avoid getting page out of data bounds warning
	// const [footerPage, setFooterPage] = useState(0);

	// useEffect(()=>{
	//   setFooterPage(0);
	// },[filteredData])

	const tableOptions = {
		filter: false,
		search: false,
		download: false,
		print: false,
		viewColumns: false,
		selectableRowsHideCheckboxes: true,
		rowsPerPage: 15,
		fixedHeader: false,
		customToolbarSelect: () => {},
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
			// const adjustedCount = filteredData.length;
			// console.log((rowsPerPage * (footerPage + 1)) > adjustedCount)
			// const adjustedPage = (rowsPerPage * (footerPage + 1)) > adjustedCount ? 0 : footerPage
			// console.log(`footerPage:`,footerPage)
			// console.log('page from the view all roles page:', adjustedPage);
			// console.log('count from the view all roles page:', adjustedCount);
			return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
		}
	};

	// TODO: Might consider to replace the userNumber with the user's real ID instead
	// TODO: Might replace the rowData with API Data as data from this row is currently insufficient for the edit page
	const redirectToViewActivity = (activityNumber, activityData) => {
		navigate(`${pathnames.activityLogsViewDetails}/${activityNumber}`, { state: { rowData: { activityNumber, ...activityData } } });
	};

	const exportAsExcel = () => {
		console.log("TODO: Export Table Data to Excel");
	};

	/* SEARCH BAR FILTER RELATED */

	// Deals with behaviour of the filters when the search filter value is altered since it overrides all other filters from filter menu
	// prettier-ignore
	const autoSearchFilter = useCallback((event) => {
		console.log(event.target.value);
		console.log(filters);
		console.log(filters.details);
		if (event.target.value.toLowerCase() === "" && !filters.details) {
			console.log("no details filter, click and click away from search details field should retain all other filters made (click & click away will still trigger an event value with an empty str)");
			setFilters((prev) => (prev = { ...prev }));
		}
		// UNCOMMENT THE CODE UNDERNEATH IF THE ONCLICK FILTERS SHOULD BE CLEARED WHEN CHANGES ARE MADE ON THE SEARCH BAR FILTER
		// else if((event.target.value.toLowerCase() !== '' && !filters.details) || (event.target.value.toLowerCase() === '' && filters.details)){
		//   console.log('should overwrite all other filters if input is not empty str and details filter not set previously (first time input) or if input is an empty str and details filter was set previously (converting filter from something to empty str)')
		//   onClickFilterObject.current={...onClickFilterObjectDefault}
		//   if(event.target.value.toLowerCase() === ''){
		//     setFilters({});
		//     navigate(`${location.pathname}`);
		//   }else{
		//     setFilters({details: event.target.value.toLowerCase()});
		//   }
		// }
		else if (event.target.value.toLowerCase() && filters.details && event.target.value.toLowerCase() === filters.details) {
			console.log("same scenario as the first condition except now details filter has been set (prevent previous filters to be cleared if search details field is clicked on and clicked away)");
			setFilters((prev) => (prev = { ...prev, details: event.target.value.toLowerCase() }));
		} else {
			// UNCOMMENT THE CODE & REMOVE THE CURRENT CONDITIONS UNDERNEATH IF THE ONCLICK FILTERS SHOULD BE CLEARED WHEN CHANGES ARE MADE ON THE SEARCH BAR FILTER
			// console.log('should allow continuous editing of details filter value when 2nd condition has been met & overwrite all previous filters')
			// onClickFilterObject.current={...onClickFilterObjectDefault}
			// setFilters({details:event.target.value.toLowerCase()});
			console.log("should allow continuous editing of details filter");
			if (event.target.value.toLowerCase() === "" && filters.details) {
				const { details, ...otherFilters } = filters;
				Object.keys(otherFilters).length ? setFilters((prev) => (prev = { ...otherFilters })) : setFilters((prev) => (prev = {}));
			} else {
				setFilters((prev) => (prev = { ...prev, details: event.target.value.toLowerCase() }));
			}
		}
	},[filters]);

	/* FILTER TIME RANGE RELATED */

	// const timeRangeOptions = [
	//   {
	//     label:'Past 7 days',
	//     value: 7
	//   },
	//   {
	//     label:'Past 30 days',
	//     value: 30
	//   },
	//   {
	//     label:'Past 90 days',
	//     value: 90
	//   },
	// ];

	// const [selectedTimeRange, setSelectedTimeRange] = useState(timeRangeOptions[0].value);

	// const onChangeTimeRange = useCallback((value) => {
	//   setSelectedTimeRange(value);
	//   console.log('TODO: SET THE TIME RANGE TO BE', value)
	//   // need the start & end dates states
	//   // need to disable the dates beyond the selected time range
	//   // need to update the time range for the "please select a time range ..." with the correct time range set
	//   // update the start & end dates, and apply the new time range selected via the filter
	//   // TODO: If the current time range is beyond the newly selected time range, set the time to be of the newly selected time range
	//   // if(){

	//   // }
	// },[]);

	/* FILTER MENU RELATED */

	// Related to Activities and their respective checkboxes in the filter

	const [loginSelected, setLoginSelected] = useState(false);
	const [operationsSelected, setOperationsSelected] = useState(false);
	const [customerSelected, setCustomerSelected] = useState(false);
	const [humanResourcesSelected, setHumanResourcesSelected] = useState(false);
	const [workOrderSelected, setWorkOrderSelected] = useState(false);
	const [inventorySelected, setInventorySelected] = useState(false);
	const [paymentSelected, setPaymentSelected] = useState(false);
	const [reportsAndInquirySelected, setReportsAndInquirySelected] = useState(false);
	const [bulkDataHandlingSelected, setBulkDataHandlingSelected] = useState(false);

	// For Login Activity
	const [checkBoxLoginAll, setCheckBoxLoginAll] = useState(false);
	const [checkBoxIsLogin, setCheckBoxIsLogin] = useState(false);
	const [checkBoxIsLogout, setCheckBoxIsLogout] = useState(false);
	const [loginCheckBoxCount, setLoginCheckBoxCount] = useState(0);
	const loginSubModules = useMemo(
		() => [
			{ filter: "checkBoxIsLogin", checked: checkBoxIsLogin, action: setCheckBoxIsLogin },
			{ filter: "checkBoxIsLogout", checked: checkBoxIsLogout, action: setCheckBoxIsLogout }
		],
		[checkBoxIsLogin, checkBoxIsLogout]
	);

	// For Human Resources Activity
	const [checkBoxHumanResourcesAll, setCheckBoxHumanResourcesAll] = useState(false);
	const [checkBoxRemunerationPackages, setCheckBoxRemunerationPackages] = useState(false);
	const [checkBoxEmployeeMaintenance, setCheckBoxEmployeeMaintenance] = useState(false);
	const [checkBoxLeaveManagement, setCheckBoxLeaveManagement] = useState(false);
	const [checkBoxClaimManagement, setCheckBoxClaimManagement] = useState(false);
	const [checkBoxHumanResourcesConfiguration, setCheckBoxHumanResourcesConfiguration] = useState(false);
	const [humanResourcesCheckBoxCount, setHumanResourcesCheckBoxCount] = useState(0);
	const humanResourcesSubModules = useMemo(
		() => [
			{ filter: "checkBoxRemunerationPackages", checked: checkBoxRemunerationPackages, action: setCheckBoxRemunerationPackages },
			{ filter: "checkBoxEmployeeMaintenance", checked: checkBoxEmployeeMaintenance, action: setCheckBoxEmployeeMaintenance },
			{ filter: "checkBoxLeaveManagement", checked: checkBoxLeaveManagement, action: setCheckBoxLeaveManagement },
			{ filter: "checkBoxClaimManagement", checked: checkBoxClaimManagement, action: setCheckBoxClaimManagement },
			{ filter: "checkBoxHumanResourcesConfiguration", checked: checkBoxHumanResourcesConfiguration, action: setCheckBoxHumanResourcesConfiguration }
		],
		[checkBoxClaimManagement, checkBoxEmployeeMaintenance, checkBoxHumanResourcesConfiguration, checkBoxLeaveManagement, checkBoxRemunerationPackages]
	);

	// For Payment Activity
	const [checkBoxPaymentAll, setCheckBoxPaymentAll] = useState(false);
	const [checkBoxQuotation, setCheckBoxQuotation] = useState(false);
	const [checkBoxSalesOrder, setCheckBoxSalesOrder] = useState(false);
	const [checkBoxInvoices, setCheckBoxInvoices] = useState(false);
	const [paymentCheckBoxCount, setPaymentCheckBoxCount] = useState(0);
	const paymentSubModules = useMemo(
		() => [
			{ filter: "checkBoxQuotation", checked: checkBoxQuotation, action: setCheckBoxQuotation },
			{ filter: "checkBoxSalesOrder", checked: checkBoxSalesOrder, action: setCheckBoxSalesOrder },
			{ filter: "checkBoxInvoices", checked: checkBoxInvoices, action: setCheckBoxInvoices }
		],
		[checkBoxInvoices, checkBoxQuotation, checkBoxSalesOrder]
	);

	// For Operations Activity
	const [checkBoxOperationsAll, setCheckBoxOperationsAll] = useState(false);
	const [checkBoxUserAccess, setCheckBoxUserAccess] = useState(false);
	const [checkBoxActivityLogs, setCheckBoxActivityLogs] = useState(false);
	const [checkBoxNotifications, setCheckBoxNotifications] = useState(false);
	const [checkBoxAlertConfiguration, setCheckBoxAlertConfiguration] = useState(false);
	const [checkBoxGeneralConfiguration, setCheckBoxGeneralConfiguration] = useState(false);
	const [operationsCheckBoxCount, setOperationsCheckBoxCount] = useState(0);
	const operationsSubModules = useMemo(
		() => [
			{ filter: "checkBoxUserAccess", checked: checkBoxUserAccess, action: setCheckBoxUserAccess },
			{ filter: "checkBoxActivityLogs", checked: checkBoxActivityLogs, action: setCheckBoxActivityLogs },
			{ filter: "checkBoxNotifications", checked: checkBoxNotifications, action: setCheckBoxNotifications },
			{ filter: "checkBoxAlertConfiguration", checked: checkBoxAlertConfiguration, action: setCheckBoxAlertConfiguration },
			{ filter: "checkBoxGeneralConfiguration", checked: checkBoxGeneralConfiguration, action: setCheckBoxGeneralConfiguration }
		],
		[checkBoxActivityLogs, checkBoxAlertConfiguration, checkBoxGeneralConfiguration, checkBoxNotifications, checkBoxUserAccess]
	);

	// For Work Order Activity
	const [checkBoxWorkOrderAll, setCheckBoxWorkOrderAll] = useState(false);
	const [checkBoxServiceWorkOrderListing, setCheckBoxServiceWorkOrderListing] = useState(false);
	const [checkBoxCorrectiveWorkOrderListing, setCheckBoxCorrectiveWorkOrderListing] = useState(false);
	const [workOrderCheckBoxCount, setWorkOrderCheckBoxCount] = useState(0);
	const workOrderSubModules = useMemo(
		() => [
			{ filter: "checkBoxServiceWorkOrderListing", checked: checkBoxServiceWorkOrderListing, action: setCheckBoxServiceWorkOrderListing },
			{ filter: "checkBoxCorrectiveWorkOrderListing", checked: checkBoxCorrectiveWorkOrderListing, action: setCheckBoxCorrectiveWorkOrderListing }
		],
		[checkBoxCorrectiveWorkOrderListing, checkBoxServiceWorkOrderListing]
	);

	// For Reports & Inquiry Activity
	const [checkBoxReportsAndInquiryAll, setCheckBoxReportsAndInquiryAll] = useState(false);
	const [checkBoxReportExtraction, setCheckBoxReportExtraction] = useState(false);
	const [checkBoxDataInquiry, setCheckBoxDataInquiry] = useState(false);
	const [reportsAndInquiryCheckBoxCount, setReportsAndInquiryCheckBoxCount] = useState(0);
	const reportsAndInquirySubModules = useMemo(
		() => [
			{ filter: "checkBoxReportExtraction", checked: checkBoxReportExtraction, action: setCheckBoxReportExtraction },
			{ filter: "checkBoxDataInquiry", checked: checkBoxDataInquiry, action: setCheckBoxDataInquiry }
		],
		[checkBoxDataInquiry, checkBoxReportExtraction]
	);

	// For Customer Activity
	const [checkBoxCustomerAll, setCheckBoxCustomerAll] = useState(false);
	const [checkBoxCustomerListing, setCheckBoxCustomerListing] = useState(false);
	const [checkBoxCustomerConfiguration, setCheckBoxCustomerConfiguration] = useState(false);
	const [customerCheckBoxCount, setCustomerCheckBoxCount] = useState(0);
	const customerSubModules = useMemo(
		() => [
			{ filter: "checkBoxCustomerListing", checked: checkBoxCustomerListing, action: setCheckBoxCustomerListing },
			{ filter: "checkBoxCustomerConfiguration", checked: checkBoxCustomerConfiguration, action: setCheckBoxCustomerConfiguration }
		],
		[checkBoxCustomerConfiguration, checkBoxCustomerListing]
	);

	// For Inventory Activity
	const [checkBoxInventoryAll, setCheckBoxInventoryAll] = useState(false);
	const [checkBoxInventoryListing, setCheckBoxInventoryListing] = useState(false);
	const [checkBoxInventoryConfiguration, setCheckBoxInventoryConfiguration] = useState(false);
	const [inventoryCheckBoxCount, setInventoryCheckBoxCount] = useState(0);
	const inventorySubModules = useMemo(
		() => [
			{ filter: "checkBoxInventoryListing", checked: checkBoxInventoryListing, action: setCheckBoxInventoryListing },
			{ filter: "checkBoxInventoryConfiguration", checked: checkBoxInventoryConfiguration, action: setCheckBoxInventoryConfiguration }
		],
		[checkBoxInventoryConfiguration, checkBoxInventoryListing]
	);

	// For Bulk Data Handling
	const [checkBoxBulkDataHandlingAll, setCheckBoxBulkDataHandlingAll] = useState(false);
	const [checkBoxCustomer, setCheckBoxCustomer] = useState(false);
	const [checkBoxHumanResources, setCheckBoxHumanResources] = useState(false);
	const [checkBoxInventory, setCheckBoxInventory] = useState(false);
	const [bulkDataHandlingCheckBoxCount, setBulkDataHandlingCheckBoxCount] = useState(0);
	const bulkDataHandlingSubModules = useMemo(
		() => [
			{ filter: "checkBoxCustomer", checked: checkBoxCustomer, action: setCheckBoxCustomer },
			{ filter: "checkBoxHumanResources", checked: checkBoxHumanResources, action: setCheckBoxHumanResources },
			{ filter: "checkBoxInventory", checked: checkBoxInventory, action: setCheckBoxInventory }
		],
		[checkBoxCustomer, checkBoxHumanResources, checkBoxInventory]
	);

	// List of activity's functionalities and data condensed
	const selectableActivityObjectList = useMemo(() => {
		return [
			{ activityState: loginSelected, setActivityState: setLoginSelected, setCheckBoxActivityAll: setCheckBoxLoginAll, activitySubModules: loginSubModules, setActivityCheckBoxCount: setLoginCheckBoxCount },
			{ activityState: operationsSelected, setActivityState: setOperationsSelected, setCheckBoxActivityAll: setCheckBoxOperationsAll, activitySubModules: operationsSubModules, setActivityCheckBoxCount: setOperationsCheckBoxCount },
			{ activityState: customerSelected, setActivityState: setCustomerSelected, setCheckBoxActivityAll: setCheckBoxCustomerAll, activitySubModules: customerSubModules, setActivityCheckBoxCount: setCustomerCheckBoxCount },
			{
				activityState: humanResourcesSelected,
				setActivityState: setHumanResourcesSelected,
				setCheckBoxActivityAll: setCheckBoxHumanResourcesAll,
				activitySubModules: humanResourcesSubModules,
				setActivityCheckBoxCount: setHumanResourcesCheckBoxCount
			},
			{ activityState: workOrderSelected, setActivityState: setWorkOrderSelected, setCheckBoxActivityAll: setCheckBoxWorkOrderAll, activitySubModules: workOrderSubModules, setActivityCheckBoxCount: setWorkOrderCheckBoxCount },
			{ activityState: inventorySelected, setActivityState: setInventorySelected, setCheckBoxActivityAll: setCheckBoxInventoryAll, activitySubModules: inventorySubModules, setActivityCheckBoxCount: setInventoryCheckBoxCount },
			{ activityState: paymentSelected, setActivityState: setPaymentSelected, setCheckBoxActivityAll: setCheckBoxPaymentAll, activitySubModules: paymentSubModules, setActivityCheckBoxCount: setPaymentCheckBoxCount },
			{
				activityState: reportsAndInquirySelected,
				setActivityState: setReportsAndInquirySelected,
				setCheckBoxActivityAll: setCheckBoxReportsAndInquiryAll,
				activitySubModules: reportsAndInquirySubModules,
				setActivityCheckBoxCount: setReportsAndInquiryCheckBoxCount
			},
			{
				activityState: bulkDataHandlingSelected,
				setActivityState: setBulkDataHandlingSelected,
				setCheckBoxActivityAll: setCheckBoxBulkDataHandlingAll,
				activitySubModules: bulkDataHandlingSubModules,
				setActivityCheckBoxCount: setBulkDataHandlingCheckBoxCount
			}
		];
	}, [bulkDataHandlingSelected, bulkDataHandlingSubModules, customerSelected, customerSubModules, humanResourcesSelected, humanResourcesSubModules, inventorySelected, inventorySubModules, loginSelected, loginSubModules, operationsSelected, operationsSubModules, paymentSelected, paymentSubModules, reportsAndInquirySelected, reportsAndInquirySubModules, workOrderSelected, workOrderSubModules]);

	// Handles the activity when the filter menu is toggled (for e.g.: Uncheck all checkboxes that are not applied)
	const handleActivityBehaviour = useCallback(
		(setCheckBoxActivityAll, activitySubModulesList, setActivityCheckBoxCount) => {
			setCheckBoxActivityAll(() => {
				let newCount = 0;
				activitySubModulesList.forEach((subModule) => {
					if (filters[subModule.filter]) {
						subModule.action(true);
						newCount += 1;
					} else {
						subModule.action(false);
					}
				});
				setActivityCheckBoxCount(newCount);
				if (newCount === activitySubModulesList.length) {
					return true;
				} else {
					return false;
				}
			});
		},
		[filters]
	);

	const onToggleFilterMenu = useCallback(() => {
		console.log("toggle filter menu");
		console.log(filters);

		const { details, ...onClickFilters } = filters;
		if (!Object.keys(onClickFilters).length) {
			console.log("no on-click filters present");
			onClickFilterObject.current = { ...onClickFilterObjectDefault };
		} else {
			console.log("on-click filters present, retain the onClickFilterObject");
			onClickFilterObject.current = { ...onClickFilters };
		}

		console.log(`onClickFilterObject`, onClickFilterObject.current);

		setUserQueryValue((prev) => (prev = filters.user ? filters.user : ""));
		setStartDateValue((prev) => (prev = filters.startDateValue ? filters.startDateValue : null));
		setEndDateValue((prev) => (prev = filters.endDateValue ? filters.endDateValue : null));
		setFilterMenuActive((prev) => !prev);
		// Close the checkbox menus if they were opened
		for (let activity of selectableActivityObjectList) {
			activity.setActivityState(false);
		}

		// Uncheck the checked checkboxes that were not applied in the filter
		for (let activity of selectableActivityObjectList) {
			handleActivityBehaviour(activity.setCheckBoxActivityAll, activity.activitySubModules, activity.setActivityCheckBoxCount);
		}

		if (!onClickFilterObject.current.startDateValue && !onClickFilterObject.current.endDateValue) {
			setDisableApplyFilter((prev) => (prev = false));
		}
	}, [filters, handleActivityBehaviour, onClickFilterObjectDefault, selectableActivityObjectList]);

	// When filters from the filters menu are applied, the filters state will be updated here
	const onExecuteFilter = useCallback(async () => {
		console.log("onExecuteFilter");

		console.log(onClickFilterObject.current);

		// set async & await to ensure setFilters completes first before triggering the onToggleFilterMenu
		// (if async & await removed, the onToggleFilterMenu can intercept setFilters especially after checking checkboxes and immediately applying the filters (while the checkbox filter list is open), which prevents the filters to be applied)
		await setFilters((prev) => {
			if (!filters.details && !Object.keys(onClickFilterObject.current).length) {
				return { ...onClickFilterObjectDefault };
			} else if (filters.details && !Object.keys(onClickFilterObject.current).length) {
				return { details: prev.details };
			} else if (!filters.details && Object.keys(onClickFilterObject.current).length) {
				return { ...onClickFilterObject.current };
			} else if (filters.details && Object.keys(onClickFilterObject.current).length) {
				return { details: prev.details, ...onClickFilterObject.current };
			}
		});

		onToggleFilterMenu();
	}, [onToggleFilterMenu, filters.details, onClickFilterObjectDefault]);

	const uncheckAllCheckBoxFilters = useCallback((setCheckBoxActivityAll, activitySubModules, setActivityCheckBoxCount) => {
		setCheckBoxActivityAll(() => {
			activitySubModules.forEach((subModule) => {
				subModule.action(false);
			});
			setActivityCheckBoxCount(0);
			return false;
		});
	}, []);

	// Clear all filters (reset table data, filters state, the filter placeholder object (onClickFilterObject) and the individual filter states)
	const handleClearFilter = useCallback(() => {
		setFilteredData([...pageActivityLogsTableData]);
		setFilters((prev) => (prev = {}));
		onClickFilterObject.current = { ...onClickFilterObjectDefault };
		setUserQueryValue((prev) => (prev = ""));
		setStartDateValue((prev) => (prev = null));
		setEndDateValue((prev) => (prev = null));
		for (let activity of selectableActivityObjectList) {
			uncheckAllCheckBoxFilters(activity.setCheckBoxActivityAll, activity.activitySubModules, activity.setActivityCheckBoxCount);
		}
	}, [onClickFilterObjectDefault, selectableActivityObjectList, uncheckAllCheckBoxFilters]);

	// Toggling filter menu behaviour
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (filterRef.current && !filterRef.current.contains(event.target) && !filterRefButton.current.contains(event.target) && !endDateOpen && !startDateOpen) {
				onToggleFilterMenu();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [startDateOpen, endDateOpen, onToggleFilterMenu]);

	// Conditions for enabling/disabling apply filter button
	useEffect(() => {
		if (!startDateValue || !endDateValue) {
			if ((startDateValue && !endDateValue) || (!startDateValue && endDateValue)) {
				setDisableApplyFilter((prev) => (prev = true));
			}
			return;
		}

		if (!startDateValue.isValid() || !endDateValue.isValid()) {
			console.log(startDateValue.isValid());
			console.log(endDateValue.isValid());
			setDisableApplyFilter((prev) => (prev = true));
			return;
		}

		const isEndDateBefore = endDateValue.isBefore(startDateValue);
		if (isEndDateBefore) {
			console.log(`Start date must be before end date`);
			setDisableApplyFilter((prev) => (prev = true));
			return;
		}

		// const dateDifference = endDateValue.diff(startDateValue,'day');
		// if(dateDifference > selectedTimeRange){
		//   console.log(`Date range exceeds ${selectedTimeRange} days`)
		//   setDisableApplyFilter(prev=>prev=true);
		//   return;
		// }

		// if(startDateValue.isBefore(dayjs().subtract(selectedTimeRange, 'days'))){
		//   console.log(`Start date selected is out of bounds`);
		//   setDisableApplyFilter(prev=>prev=true);
		//   return;
		// }

		// if(endDateValue.isAfter(dayjs())){
		//   console.log(`End date selected is out of bounds`);
		//   setDisableApplyFilter(prev=>prev=true);
		//   return;
		// }

		setDisableApplyFilter((prev) => (prev = false));
	}, [startDateValue, endDateValue]);

	// Function that deals with select all logic and checkbox count for an activity
	const handleSelectAllAndCount = (checkBoxAllSelected, setCheckBoxAllSelected, checkBoxCount, setCheckBoxCount, subModulesLength, checkBoxNewStatus) => {
		if (!checkBoxAllSelected && checkBoxCount === subModulesLength - 1 && checkBoxNewStatus) {
			setCheckBoxAllSelected(true);
			setCheckBoxCount(subModulesLength);
		} else if (checkBoxAllSelected && checkBoxCount === subModulesLength && !checkBoxNewStatus) {
			setCheckBoxAllSelected(false);
			setCheckBoxCount(subModulesLength - 1);
		} else {
			if (checkBoxNewStatus) {
				setCheckBoxCount((prev) => prev + 1);
			} else {
				setCheckBoxCount((prev) => prev - 1);
			}
		}
	};

	// Function that checks if another activity has been opened. If so, close it.
	const handleCheckActivityOpened = useCallback(() => {
		for (let activity of selectableActivityObjectList) {
			if (activity.activityState) {
				activity.setActivityState(false);
			}
		}
	}, [selectableActivityObjectList]);

	// Toggle Activity Checkbox List when click outside of it
	useEffect(() => {
		const handleClickOutsideCheckBoxList = (event) => {
			if (activityCheckBoxListRef.current && !activityCheckBoxListRef.current.contains(event.target) && !activityClickableRef.current.contains(event.target)) {
				handleCheckActivityOpened();
			}
		};

		document.addEventListener("mousedown", handleClickOutsideCheckBoxList);

		return () => {
			document.removeEventListener("mousedown", handleClickOutsideCheckBoxList);
		};
	}, [handleCheckActivityOpened]);

	const activityList = useMemo(
		() => [
			{
				label: "Login",
				name: "activityLogin",
				selected: loginSelected,
				count: loginCheckBoxCount,
				onClick: () =>
					setLoginSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxLoginAll",
						value: checkBoxLoginAll,
						onClick: () =>
							setCheckBoxLoginAll((prev) => {
								loginSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setLoginCheckBoxCount(loginSubModules.length);
								} else {
									setLoginCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Log In",
						name: "checkBoxIsLogin",
						value: checkBoxIsLogin,
						onClick: () =>
							setCheckBoxIsLogin((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxIsLogin = !prev) : delete onClickFilterObject.current.checkBoxIsLogin;
								handleSelectAllAndCount(checkBoxLoginAll, setCheckBoxLoginAll, loginCheckBoxCount, setLoginCheckBoxCount, loginSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Log Out",
						name: "checkBoxIsLogout",
						value: checkBoxIsLogout,
						onClick: () =>
							setCheckBoxIsLogout((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxIsLogout = !prev) : delete onClickFilterObject.current.checkBoxIsLogout;
								handleSelectAllAndCount(checkBoxLoginAll, setCheckBoxLoginAll, loginCheckBoxCount, setLoginCheckBoxCount, loginSubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Human Resources",
				name: "activityHumanResources",
				selected: humanResourcesSelected,
				count: humanResourcesCheckBoxCount,
				onClick: () =>
					setHumanResourcesSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxHumanResourcesAll",
						value: checkBoxHumanResourcesAll,
						onClick: () =>
							setCheckBoxHumanResourcesAll((prev) => {
								humanResourcesSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setHumanResourcesCheckBoxCount(humanResourcesSubModules.length);
								} else {
									setHumanResourcesCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Remuneration Packages",
						name: "checkBoxRemunerationPackages",
						value: checkBoxRemunerationPackages,
						onClick: () =>
							setCheckBoxRemunerationPackages((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxRemunerationPackages = !prev) : delete onClickFilterObject.current.checkBoxRemunerationPackages;
								handleSelectAllAndCount(checkBoxHumanResourcesAll, setCheckBoxHumanResourcesAll, humanResourcesCheckBoxCount, setHumanResourcesCheckBoxCount, humanResourcesSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Employee Maintenance",
						name: "checkBoxEmployeeMaintenance",
						value: checkBoxEmployeeMaintenance,
						onClick: () =>
							setCheckBoxEmployeeMaintenance((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxEmployeeMaintenance = !prev) : delete onClickFilterObject.current.checkBoxEmployeeMaintenance;
								handleSelectAllAndCount(checkBoxHumanResourcesAll, setCheckBoxHumanResourcesAll, humanResourcesCheckBoxCount, setHumanResourcesCheckBoxCount, humanResourcesSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Leave Management",
						name: "checkBoxLeaveManagement",
						value: checkBoxLeaveManagement,
						onClick: () =>
							setCheckBoxLeaveManagement((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxLeaveManagement = !prev) : delete onClickFilterObject.current.checkBoxLeaveManagement;
								handleSelectAllAndCount(checkBoxHumanResourcesAll, setCheckBoxHumanResourcesAll, humanResourcesCheckBoxCount, setHumanResourcesCheckBoxCount, humanResourcesSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Claim Management",
						name: "checkBoxClaimManagement",
						value: checkBoxClaimManagement,
						onClick: () =>
							setCheckBoxClaimManagement((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxClaimManagement = !prev) : delete onClickFilterObject.current.checkBoxClaimManagement;
								handleSelectAllAndCount(checkBoxHumanResourcesAll, setCheckBoxHumanResourcesAll, humanResourcesCheckBoxCount, setHumanResourcesCheckBoxCount, humanResourcesSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Human Resources Configuration",
						name: "checkBoxHumanResourcesConfiguration",
						value: checkBoxHumanResourcesConfiguration,
						onClick: () =>
							setCheckBoxHumanResourcesConfiguration((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxHumanResourcesConfiguration = !prev) : delete onClickFilterObject.current.checkBoxHumanResourcesConfiguration;
								handleSelectAllAndCount(checkBoxHumanResourcesAll, setCheckBoxHumanResourcesAll, humanResourcesCheckBoxCount, setHumanResourcesCheckBoxCount, humanResourcesSubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Payment",
				name: "activityPayment",
				selected: paymentSelected,
				count: paymentCheckBoxCount,
				onClick: () =>
					setPaymentSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxPaymentAll",
						value: checkBoxPaymentAll,
						onClick: () =>
							setCheckBoxPaymentAll((prev) => {
								paymentSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setPaymentCheckBoxCount(paymentSubModules.length);
								} else {
									setPaymentCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Quotation",
						name: "checkBoxQuotation",
						value: checkBoxQuotation,
						onClick: () =>
							setCheckBoxQuotation((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxQuotation = !prev) : delete onClickFilterObject.current.checkBoxQuotation;
								handleSelectAllAndCount(checkBoxPaymentAll, setCheckBoxPaymentAll, paymentCheckBoxCount, setPaymentCheckBoxCount, paymentSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Sales Order",
						name: "checkBoxSalesOrder",
						value: checkBoxSalesOrder,
						onClick: () =>
							setCheckBoxSalesOrder((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxSalesOrder = !prev) : delete onClickFilterObject.current.checkBoxSalesOrder;
								handleSelectAllAndCount(checkBoxPaymentAll, setCheckBoxPaymentAll, paymentCheckBoxCount, setPaymentCheckBoxCount, paymentSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Invoices",
						name: "checkBoxInvoices",
						value: checkBoxInvoices,
						onClick: () =>
							setCheckBoxInvoices((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxInvoices = !prev) : delete onClickFilterObject.current.checkBoxInvoices;
								handleSelectAllAndCount(checkBoxPaymentAll, setCheckBoxPaymentAll, paymentCheckBoxCount, setPaymentCheckBoxCount, paymentSubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Operations",
				name: "activityOperations",
				selected: operationsSelected,
				count: operationsCheckBoxCount,
				onClick: () =>
					setOperationsSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxOperationsAll",
						value: checkBoxOperationsAll,
						onClick: () =>
							setCheckBoxOperationsAll((prev) => {
								operationsSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setOperationsCheckBoxCount(operationsSubModules.length);
								} else {
									setOperationsCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "User Access",
						name: "checkBoxUserAccess",
						value: checkBoxUserAccess,
						onClick: () =>
							setCheckBoxUserAccess((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxUserAccess = !prev) : delete onClickFilterObject.current.checkBoxUserAccess;
								handleSelectAllAndCount(checkBoxOperationsAll, setCheckBoxOperationsAll, operationsCheckBoxCount, setOperationsCheckBoxCount, operationsSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Activity Logs",
						name: "checkBoxActivityLogs",
						value: checkBoxActivityLogs,
						onClick: () =>
							setCheckBoxActivityLogs((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxActivityLogs = !prev) : delete onClickFilterObject.current.checkBoxActivityLogs;
								handleSelectAllAndCount(checkBoxOperationsAll, setCheckBoxOperationsAll, operationsCheckBoxCount, setOperationsCheckBoxCount, operationsSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Notifications",
						name: "checkBoxNotifications",
						value: checkBoxNotifications,
						onClick: () =>
							setCheckBoxNotifications((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxNotifications = !prev) : delete onClickFilterObject.current.checkBoxNotifications;
								handleSelectAllAndCount(checkBoxOperationsAll, setCheckBoxOperationsAll, operationsCheckBoxCount, setOperationsCheckBoxCount, operationsSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Alert Configuration",
						name: "checkBoxAlertConfiguration",
						value: checkBoxAlertConfiguration,
						onClick: () =>
							setCheckBoxAlertConfiguration((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxAlertConfiguration = !prev) : delete onClickFilterObject.current.checkBoxAlertConfiguration;
								handleSelectAllAndCount(checkBoxOperationsAll, setCheckBoxOperationsAll, operationsCheckBoxCount, setOperationsCheckBoxCount, operationsSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "General Configuration",
						name: "checkBoxGeneralConfiguration",
						value: checkBoxGeneralConfiguration,
						onClick: () =>
							setCheckBoxGeneralConfiguration((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxGeneralConfiguration = !prev) : delete onClickFilterObject.current.checkBoxGeneralConfiguration;
								handleSelectAllAndCount(checkBoxOperationsAll, setCheckBoxOperationsAll, operationsCheckBoxCount, setOperationsCheckBoxCount, operationsSubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Work Order",
				name: "activityWorkOrder",
				selected: workOrderSelected,
				count: workOrderCheckBoxCount,
				onClick: () =>
					setWorkOrderSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxWorkOrderAll",
						value: checkBoxWorkOrderAll,
						onClick: () =>
							setCheckBoxWorkOrderAll((prev) => {
								workOrderSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setWorkOrderCheckBoxCount(workOrderSubModules.length);
								} else {
									setWorkOrderCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Service Work Order Listing",
						name: "checkBoxServiceWorkOrderListing",
						value: checkBoxServiceWorkOrderListing,
						onClick: () =>
							setCheckBoxServiceWorkOrderListing((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxServiceWorkOrderListing = !prev) : delete onClickFilterObject.current.checkBoxServiceWorkOrderListing;
								handleSelectAllAndCount(checkBoxWorkOrderAll, setCheckBoxWorkOrderAll, workOrderCheckBoxCount, setWorkOrderCheckBoxCount, workOrderSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Corrective Work Order Listing",
						name: "checkBoxCorrectiveWorkOrderListing",
						value: checkBoxCorrectiveWorkOrderListing,
						onClick: () =>
							setCheckBoxCorrectiveWorkOrderListing((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxCorrectiveWorkOrderListing = !prev) : delete onClickFilterObject.current.checkBoxCorrectiveWorkOrderListing;
								handleSelectAllAndCount(checkBoxWorkOrderAll, setCheckBoxWorkOrderAll, workOrderCheckBoxCount, setWorkOrderCheckBoxCount, workOrderSubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Reports & Inquiry",
				name: "activityReportsAndInquiry",
				selected: reportsAndInquirySelected,
				count: reportsAndInquiryCheckBoxCount,
				onClick: () =>
					setReportsAndInquirySelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxReportsAndInquiryAll",
						value: checkBoxReportsAndInquiryAll,
						onClick: () =>
							setCheckBoxReportsAndInquiryAll((prev) => {
								reportsAndInquirySubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setReportsAndInquiryCheckBoxCount(reportsAndInquirySubModules.length);
								} else {
									setReportsAndInquiryCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Report Extraction",
						name: "checkBoxReportExtraction",
						value: checkBoxReportExtraction,
						onClick: () =>
							setCheckBoxReportExtraction((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxReportExtraction = !prev) : delete onClickFilterObject.current.checkBoxReportExtraction;
								handleSelectAllAndCount(checkBoxReportsAndInquiryAll, setCheckBoxReportsAndInquiryAll, reportsAndInquiryCheckBoxCount, setReportsAndInquiryCheckBoxCount, reportsAndInquirySubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Data Inquiry",
						name: "checkBoxDataInquiry",
						value: checkBoxDataInquiry,
						onClick: () =>
							setCheckBoxDataInquiry((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxDataInquiry = !prev) : delete onClickFilterObject.current.checkBoxDataInquiry;
								handleSelectAllAndCount(checkBoxReportsAndInquiryAll, setCheckBoxReportsAndInquiryAll, reportsAndInquiryCheckBoxCount, setReportsAndInquiryCheckBoxCount, reportsAndInquirySubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Customer",
				name: "activityCustomer",
				selected: customerSelected,
				count: customerCheckBoxCount,
				onClick: () =>
					setCustomerSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxCustomerAll",
						value: checkBoxCustomerAll,
						onClick: () =>
							setCheckBoxCustomerAll((prev) => {
								customerSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setCustomerCheckBoxCount(customerSubModules.length);
								} else {
									setCustomerCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Customer Listing",
						name: "checkBoxCustomerListing",
						value: checkBoxCustomerListing,
						onClick: () =>
							setCheckBoxCustomerListing((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxCustomerListing = !prev) : delete onClickFilterObject.current.checkBoxCustomerListing;
								handleSelectAllAndCount(checkBoxCustomerAll, setCheckBoxCustomerAll, customerCheckBoxCount, setCustomerCheckBoxCount, customerSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Customer Configuration",
						name: "checkBoxCustomerConfiguration",
						value: checkBoxCustomerConfiguration,
						onClick: () =>
							setCheckBoxCustomerConfiguration((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxCustomerConfiguration = !prev) : delete onClickFilterObject.current.checkBoxCustomerConfiguration;
								handleSelectAllAndCount(checkBoxCustomerAll, setCheckBoxCustomerAll, customerCheckBoxCount, setCustomerCheckBoxCount, customerSubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Inventory",
				name: "activityInventory",
				selected: inventorySelected,
				count: inventoryCheckBoxCount,
				onClick: () =>
					setInventorySelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxInventoryAll",
						value: checkBoxInventoryAll,
						onClick: () =>
							setCheckBoxInventoryAll((prev) => {
								inventorySubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setInventoryCheckBoxCount(inventorySubModules.length);
								} else {
									setInventoryCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Inventory Listing",
						name: "checkBoxInventoryListing",
						value: checkBoxInventoryListing,
						onClick: () =>
							setCheckBoxInventoryListing((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxInventoryListing = !prev) : delete onClickFilterObject.current.checkBoxInventoryListing;
								handleSelectAllAndCount(checkBoxInventoryAll, setCheckBoxInventoryAll, inventoryCheckBoxCount, setInventoryCheckBoxCount, inventorySubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Inventory Configuration",
						name: "checkBoxInventoryConfiguration",
						value: checkBoxInventoryConfiguration,
						onClick: () =>
							setCheckBoxInventoryConfiguration((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxInventoryConfiguration = !prev) : delete onClickFilterObject.current.checkBoxInventoryConfiguration;
								handleSelectAllAndCount(checkBoxInventoryAll, setCheckBoxInventoryAll, inventoryCheckBoxCount, setInventoryCheckBoxCount, inventorySubModules.length, !prev);
								return !prev;
							})
					}
				]
			},
			{
				label: "Bulk Data Handling",
				name: "activityBulkDataHandling",
				selected: bulkDataHandlingSelected,
				count: bulkDataHandlingCheckBoxCount,
				onClick: () =>
					setBulkDataHandlingSelected((prev) => {
						handleCheckActivityOpened();
						return !prev;
					}),
				subModulesCheckboxes: [
					{
						label: "All",
						name: "checkBoxBulkDataHandlingAll",
						value: checkBoxBulkDataHandlingAll,
						onClick: () =>
							setCheckBoxBulkDataHandlingAll((prev) => {
								bulkDataHandlingSubModules.forEach((subModule) => {
									if (!prev) {
										onClickFilterObject.current[subModule.filter] = !prev;
									} else {
										delete onClickFilterObject.current[subModule.filter];
									}
									subModule.action(!prev);
								});
								if (!prev) {
									setBulkDataHandlingCheckBoxCount(bulkDataHandlingSubModules.length);
								} else {
									setBulkDataHandlingCheckBoxCount(0);
								}
								return !prev;
							})
					},
					{
						label: "Customer",
						name: "checkBoxCustomer",
						value: checkBoxCustomer,
						onClick: () =>
							setCheckBoxCustomer((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxCustomer = !prev) : delete onClickFilterObject.current.checkBoxCustomer;
								handleSelectAllAndCount(checkBoxBulkDataHandlingAll, setCheckBoxBulkDataHandlingAll, bulkDataHandlingCheckBoxCount, setBulkDataHandlingCheckBoxCount, bulkDataHandlingSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Human Resources",
						name: "checkBoxHumanResources",
						value: checkBoxHumanResources,
						onClick: () =>
							setCheckBoxHumanResources((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxHumanResources = !prev) : delete onClickFilterObject.current.checkBoxHumanResources;
								handleSelectAllAndCount(checkBoxBulkDataHandlingAll, setCheckBoxBulkDataHandlingAll, bulkDataHandlingCheckBoxCount, setBulkDataHandlingCheckBoxCount, bulkDataHandlingSubModules.length, !prev);
								return !prev;
							})
					},
					{
						label: "Inventory",
						name: "checkBoxInventory",
						value: checkBoxInventory,
						onClick: () =>
							setCheckBoxInventory((prev) => {
								!prev ? (onClickFilterObject.current.checkBoxInventory = !prev) : delete onClickFilterObject.current.checkBoxInventory;
								handleSelectAllAndCount(checkBoxBulkDataHandlingAll, setCheckBoxBulkDataHandlingAll, bulkDataHandlingCheckBoxCount, setBulkDataHandlingCheckBoxCount, bulkDataHandlingSubModules.length, !prev);
								return !prev;
							})
					}
				]
			}
		],
		[bulkDataHandlingCheckBoxCount, bulkDataHandlingSelected, bulkDataHandlingSubModules, checkBoxActivityLogs, checkBoxAlertConfiguration, checkBoxBulkDataHandlingAll, checkBoxClaimManagement, checkBoxCorrectiveWorkOrderListing, checkBoxCustomer, checkBoxCustomerAll, checkBoxCustomerConfiguration, checkBoxCustomerListing, checkBoxDataInquiry, checkBoxEmployeeMaintenance, checkBoxGeneralConfiguration, checkBoxHumanResources, checkBoxHumanResourcesAll, checkBoxHumanResourcesConfiguration, checkBoxInventory, checkBoxInventoryAll, checkBoxInventoryConfiguration, checkBoxInventoryListing, checkBoxInvoices, checkBoxIsLogin, checkBoxIsLogout, checkBoxLeaveManagement, checkBoxLoginAll, checkBoxNotifications, checkBoxOperationsAll, checkBoxPaymentAll, checkBoxQuotation, checkBoxRemunerationPackages, checkBoxReportExtraction, checkBoxReportsAndInquiryAll, checkBoxSalesOrder, checkBoxServiceWorkOrderListing, checkBoxUserAccess, checkBoxWorkOrderAll, customerCheckBoxCount, customerSelected, customerSubModules, handleCheckActivityOpened, humanResourcesCheckBoxCount, humanResourcesSelected, humanResourcesSubModules, inventoryCheckBoxCount, inventorySelected, inventorySubModules, loginCheckBoxCount, loginSelected, loginSubModules, operationsCheckBoxCount, operationsSelected, operationsSubModules, paymentCheckBoxCount, paymentSelected, paymentSubModules, reportsAndInquiryCheckBoxCount, reportsAndInquirySelected, reportsAndInquirySubModules, workOrderCheckBoxCount, workOrderSelected, workOrderSubModules]
	);

	// Get every activity's sub-module checkbox object
	const checkBoxFilterInputList = useMemo(() => {
		let newActivityList = [];
		let newActivityListObject = {};
		// Exclude the "All" checkbox from each submodule and set the remainder to the newActivityListObject
		for (let activity of activityList) {
			newActivityListObject[activity.name] = activity.subModulesCheckboxes.slice(1);
		}
		for (let activityName in newActivityListObject) {
			newActivityList.push(...newActivityListObject[activityName]);
		}
		return newActivityList;
	}, [activityList]);

	// prettier-ignore
	const onHandleUserChange = useCallback((event) => {
		onClickFilterObject.current.user = event.target.value;
		if (!event.target.value) {
			const { user, ...remainderOnClickFilterObject } = onClickFilterObject.current;
			onClickFilterObject.current = { ...remainderOnClickFilterObject };
			console.log(onClickFilterObject.current);
		}
		setUserQueryValue(event.target.value);
	}, []);

	const handleActivityFilterInputs = useCallback(() => {
		return activityList.forEach((activity, index) => {
			return (
				// <div className="checkbox-filter" key={index}>
				//   <AppCheckbox label={input.label} icon={checkerIcon} value={input.value} onClick={input.onClick}/>
				// </div>
				<div className="activity" key={index}>
					<div className="activity__title">{activity.label}</div>
					<div className="activity__clickable" onClick={activity.onClick} ref={activity.selected ? activityClickableRef : null}>
						<div className="selected-amount">{activity.count}</div>
						<div className="chevron">
							<AppIcon src={chevronRight} />
						</div>
					</div>
					{activity.selected && (
						<div className="activity__checkbox-list" ref={activityCheckBoxListRef}>
							{activity.subModulesCheckboxes.forEach((subModule, index) => {
								return (
									<div className="checkbox-filter" key={index}>
										<AppCheckbox label={subModule.label} icon={checkerIcon} value={subModule.value} onClick={subModule.onClick} />
									</div>
								);
							})}
						</div>
					)}
				</div>
			);
		});
	}, [activityList]);

	/* URL PARAMS RELATED */

	// Navigate to the filtered page when filters are applied
	useEffect(() => {
		navigate({
			pathname: location.pathname,
			search: new URLSearchParams(filters).toString()
		});
	}, [filters, location.pathname, navigate]);

	/* CENTRALIZED FILTER */

	// Plan:
	// 1. Every filter applied will be shown in the url as a query param (each query param from the url will be passed to the API for server side data filtering)
	// 2. All active filters will be set in a filters object to keep track of them
	// 3. When the filters object has changes, it will trigger the setFilteredData to update the shown data
	// 4. The search details param will filter based on the previously set filters from the dropdown (if any)

	// Search Role Param:
	// 1. Param will filter based on pageUserAccessRolesData (dummy data replicating data from server)
	// 2. When param is updated, update the filter object with details key

	// Dropdown Filters Params:
	// 1. Since it is not an automatic filter update, will need to keep these filter settings in another object (onClickFilterObject)
	// 2. When setting the changes in each param, will update their respective state and also onClickFilterObject
	// 3. Only when applying filter, the filter object will be updated with the extra filter params from onClickFilterObject
	// 4. Clear All Filters will clear every filter even the search details param filter, reverting the filters object to be an empty object

	useEffect(() => {
		// TODO: Replace newlyFilteredData with data retrieved from API + the filter params instead
		// KIV: See if it is required to cache data retrieved from DB, for now every change in filters object should make a new API call, regardless of how big or small the change is

		// e.g.
		// const fetchData = async () => {
		//   const response = await fetch(`/api/data?${new URLSearchParams(filters)}`);
		//   const data = await response.json();
		//   // Now you can use data to update your data grid
		// };

		console.log(`filters`, filters);

		let newFilteredData = [...pageActivityLogsTableData];

		if (Object.keys(filters).length === 0 || filters.details === "") {
			setFilteredData([...newFilteredData]);
		}

		if (filters.details) {
			newFilteredData = pageActivityLogsTableData.filter((entry) => {
				const searchRoleParamValue = filters.details.toLowerCase();
				return entry.details.toLowerCase().includes(searchRoleParamValue);
			});
		}

		if (filters.user) {
			newFilteredData = newFilteredData.filter((entry) => {
				const userParamValue = filters.user.toLowerCase();
				return entry.user.toLowerCase().includes(userParamValue);
			});
		}

		if (filters.startDateValue && filters.endDateValue) {
			newFilteredData = newFilteredData.filter((entry) => {
				console.log(dayjs(entry.date).format("DD/MM/YYYY h:mm a"));
				console.log(dayjs(entry.date).isBetween(filters.startDateValue, filters.endDateValue, "day", "[]"));
				return dayjs(entry.date).isBetween(filters.startDateValue, filters.endDateValue, "day", "[]");
			});
		}

		const checkedCheckBoxesArray = checkBoxFilterInputList.filter((input) => {
			return filters[input.name];
		});

		if (checkedCheckBoxesArray.length) {
			let checkedCheckBoxesObject = {};
			for (let i = 0; i < checkedCheckBoxesArray.length; i++) {
				checkedCheckBoxesObject[checkedCheckBoxesArray[i].name] = true;
			}

			console.log(checkedCheckBoxesObject);

			newFilteredData = newFilteredData.filter((entry) => {
				const modifiedActivityType = `checkBox${entry.activityType.replace(" ", "")}`;
				return checkedCheckBoxesObject[modifiedActivityType];
			});
		}

		setFilteredData([...newFilteredData]);
	}, [checkBoxFilterInputList, filters]);

	return (
		<div className="page-activity-logs">
			<div className="activity-logs">
				<div className="activity-logs__title">Activity Logs</div>
				<div className="activity-logs__header">
					<div className="filter-section">
						<div className="filter-section__search">
							<AppInput icon={search} iconInField={true} iconPosition="end" type="text" name="search" placeholder="Search by Details Keywords here" value={filters.details ? filters.details : ""} onChange={(e) => autoSearchFilter(e)} />
						</div>
						<div className="filter-section__filter">
							<div ref={filterRefButton} className={filterMenuActive ? "app-button-light active" : ""}>
								<AppButton className="app-button-light" type="button" label="Filter" icon={filter} onClick={() => onToggleFilterMenu()} />
							</div>
							{filterMenuActive && (
								<div ref={filterRef} className="filter-menu">
									<div className="filter-menu__inputs">
										<div className="first-row">
											<div className="date-pickers">
												<div className="date-pickers__title">Date</div>
												<div className="date-pickers__inputs">
													<AppInputDate
														ref={appInputStartDateRef}
														views={["year", "month", "day"]}
														openTo={"year"}
														setIsOpen={setStartDateOpen}
														setDateValue={(time) => {
															onClickFilterObject.current.startDateValue = time;
															setStartDateValue(time);
														}}
														value={startDateValue}
														label={"Start Date"}
														slotProps={{ textField: { size: "small" } }}
														slots={{ openPickerIcon: calendar }}
														disabled={false}
														// minDate={dayjs().subtract(selectedTimeRange, 'days')}
														// maxDate={dayjs()}
														error={disableApplyFilter}
													/>
													<AppInputDate
														ref={appInputEndDateRef}
														views={["year", "month", "day"]}
														openTo={"year"}
														setIsOpen={setEndDateOpen}
														setDateValue={(time) => {
															onClickFilterObject.current.endDateValue = time;
															setEndDateValue(time);
														}}
														value={endDateValue}
														label={"End Date"}
														slotProps={{ textField: { size: "small" } }}
														slots={{ openPickerIcon: calendar }}
														disabled={false}
														// minDate={dayjs().subtract(selectedTimeRange, 'days')}
														// maxDate={dayjs()}
														error={disableApplyFilter}
													/>
												</div>
												<div className={`date-pickers__instruction ${disableApplyFilter ? "error" : ""}`}>Please select a date or a date range of up to 90 days.</div>
											</div>
											<AppInput type="text" name="user" className="user" label="User" placeholder="Search by User ID or Name" value={userQueryValue} onChange={(e) => onHandleUserChange(e)} />
										</div>
										<div className="second-row">
											<div className="checkBoxFilterInputs">
												<div className="checkBoxFilterInputs__title">Activity Type</div>
												<div className="checkBoxFilterInputs__activities">{handleActivityFilterInputs()}</div>
											</div>
										</div>
									</div>
									<div className="filter-menu__options">
										<div className="left">
											<div className="left__clear" onClick={() => handleClearFilter()}>
												Clear All Filters
											</div>
										</div>
										<div className="right">
											<div className="right__cancel" onClick={() => onToggleFilterMenu()}>
												Cancel
											</div>
											<div className={`right__apply ${disableApplyFilter ? "disabled" : ""}`}>
												<div className={`text ${disableApplyFilter ? "disabled" : ""}`} onClick={() => onExecuteFilter()}>
													Apply
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="export-excel">
						<AppButton className="app-button-light" type="button" label="Export as Excel" icon={exportIcon} onClick={exportAsExcel} />
					</div>
				</div>
				<div className="activity-logs__table">
					<AppTable
						// Set a key based on filteredData.length to re-render the table & to prevent page out of data bounds warning
						//(this means the table is recreated with the new data and the page count resets to 0 with correspondence to correct data)
						key={filteredData.length}
						data={filteredData}
						columns={tableColumns}
						options={tableOptions}
					/>
				</div>
			</div>
		</div>
	);
};

export default PageActivityLogs;
