import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppCustomerSiteDetailsPicDeleteModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const isCreate = useMemo(() => props.isCreate, [props.isCreate]);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(async () => {
		if (isCreate) {
			props.onHandleRemovePicContact(data.picId);

			setVisible(false);
		}

		if (!isCreate) {
			let response = null;
			setIsSubmiting(true);

			try {
				const params = sanitizeObject({ "customer-contract-site-id": data.siteId, "pic-id": data.picId });

				await api.post.customerSiteMaintenance.deleteSitePic(params);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				setIsSubmiting(false);
			}

			if (response) {
				if (!isCreate) {
					dispatch(promptLayoutAlertMessage({ message: "Site PIC was removed successfully!" }));

					props.onHandleGetPicTableList();
				}

				setVisible(false);
			}
		}
	}, [dispatch, props, isCreate, data]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-site-details-pic-delete-modal" }} open={visible}>
			<div className="customer-site-details-pic-delete-modal">
				<button type="button" className="customer-site-details-pic-delete-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-site-details-pic-delete-modal__title">Are you sure?</h1>

				<p className="customer-site-details-pic-delete-modal__description">
					After clicking confirm, the PIC <b>{data?.PIC}</b> will be removed from this Site.
				</p>

				<div className="customer-site-details-pic-delete-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" disabled={isSubmiting} onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerSiteDetailsPicDeleteModal));

AppCustomerSiteDetailsPicDeleteModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
