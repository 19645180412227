import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import sanitizeObject from "common/sanitize-object";
import { formatCurrency } from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";

import AppInput from "components/app-input";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInputMultiSelect from "components/app-input-select-multiple";

import editIcon from "assets/images/edit-icon.png";

const locationOptions = [
	{ value: "All", label: "ALL" },
	{ value: "KLCC", label: "KLCC" },
	{ value: "HQ", label: "HQ" }
];

const quotationsData = [{ number: 1, id: "INV-001", customerName: "PAN X-PREM AC", location: "HQ", total: 20, createdBy: "ADM0001 Ahmad Muhammad Ali", status: "In-Use" }];

const PageQuotations = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(quotationsData);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", status: searchParams.get("status") });

	const onHandleSubmit = () => {};

	const formik = useFormik({ initialValues: { search: "", location: [], createdBy: "", inUser: false, retired: false }, onSubmit: onHandleSubmit });

	const onHandleCreateQuotations = useCallback(() => {
		navigate(pathnames.payment.quotationCreateEdit + PAGE.CREATE);
	}, [navigate]);

	const onHandleEditQuotations = useCallback(() => {
		navigate(pathnames.payment.quotationCreateEdit + "INV-001");
	}, [navigate]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();
	}, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Open", name: "OPEN" },
			{ label: "Drafted", name: "DRAFTED" },
			{ label: "Won", name: "WON" },
			{ label: "Closed", name: "CLOSED" }
		];

		return data;
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "id",
			label: "Quotation ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "location",
			label: "Location",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "total",
			label: "Total (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				}
			}
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={onHandleEditQuotations}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditQuotations]);

	useEffect(() => {
		setData(quotationsData);
	}, []);

	return (
		<div className="page-quotations">
			<div className="quotations">
				<h1 className="quotations__title">Quotations</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Quotation ID or Customer Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateQuotations} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="quotations-filter-menu">
						<div className="quotations-filter-menu__container">
							<div className="quotations-filter-menu__row">
								<AppInputMultiSelect label="location" name="location" placeholder="Select..." options={locationOptions} value={formik.values.location} error={formik.errors.location} touched={formik.touched.location} onChange={formik.handleChange} />

								<AppInput type="text" label="Created by" name="createdBy" placeholder="User ID or Name" value={formik.values.createdBy} onChange={formik.handleChange} />
							</div>

							<p className="quotations-filter-menu__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = (formik.values.status || []).findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="quotations__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default PageQuotations;
