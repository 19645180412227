import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppInventoryMinusStockModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultValues = useMemo(() => props.defaultValues, [props.defaultValues]);
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = {
			quantityToDeduct: "",
			remarks: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			quantityToDeduct: yup
				.number()
				.test("exceedReservedQuantity", "Resulting quantity cannot be less than reserved quantity", function (value) {
					return defaultValues.quantity - value >= defaultValues.reservedQuantity;
				})
				.test("quantityToDeductNotZero", "Quantity to deduct cannot be 0", function (value) {
					return value;
				})
				.required(ERRORS.REQUIRED),
			remarks: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleSubmit = useCallback(async () => {
		let response = null;

		try {
			// ADD API WHEN READY
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();

			dispatch(promptLayoutAlertMessage({ message: "Stock count was updated successfully!" }));

			// WILL ADD PROPS WHEN API READY
			props.onHandleGetDetails(id);
		}
	}, [formik, onHandleDismiss, dispatch, props, id]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-inventory-minus-stock-modal" }} open={visible}>
			<div className="inventory-minus-stock-modal">
				<h1 className="inventory-minus-stock-modal__title">Minus Stock</h1>

				<div className="inventory-minus-stock-modal__text">Current Quantity {defaultValues.quantity}</div>

				<form className="inventory-minus-stock-modal__form" onSubmit={formik.handleSubmit}>
					{/* prettier-ignore */}
					<AppInput type="number" required disabled={restricted} name="quantityToDeduct" label="Quantity to Deduct" placeholder="Enter Quantity to Deduct" value={formik.values.quantityToDeduct} error={formik.errors.quantityToDeduct} touched={formik.touched.quantityToDeduct} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput type="textarea" required multiline maxLength={255} disabled={restricted} name="remarks" label="Remarks" placeholder="Enter Remarks" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

					<div className="inventory-minus-stock-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryMinusStockModal));

AppInventoryMinusStockModal.propTypes = {
	defaultValues: PropTypes.object
};
