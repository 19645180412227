import { memo, useMemo } from "react";

import PropTypes from "prop-types";

import REGEX from "constants/regex";
import ERRORS from "constants/errors";
import classNames from "common/class-names";

import AppCheckIcon from "components/icons/app-check-icon";
import AppCloseIcon from "components/icons/app-close-icon";

const AppPasswordChecklist = ({ ...props }) => {
	const value = useMemo(() => props.value, [props.value]);
	const touched = useMemo(() => props.touched, [props.touched]);
	const confirmNewPassword = useMemo(() => props.confirmNewPassword, [props.confirmNewPassword]);

	const className = useMemo(() => {
		return classNames({
			"app-password-checklist": true,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className]);

	const length = 8;

	// prettier-ignore
	const requirement = useMemo(() => [
			{
				valid: value.length >= length,
				label: ERRORS.LENGTH,
			},
			{
				valid: value.match(REGEX.UPPERCASE),
				label: ERRORS.UPPERCASE,
			},
			{
				valid: value.match(REGEX.LOWERCASE),
				label: ERRORS.LOWERCASE,
			},
			{
				valid: value.match(REGEX.NUMERIC),
				label: ERRORS.NUMERIC,
			},
			{
				valid: value.match(REGEX.SYMBOL),
				label: ERRORS.SYMBOL,
			},
			{
				valid: value === confirmNewPassword,
				label: ERRORS.PASSWORDS_MATCH,
			},
		],
		[confirmNewPassword, value]
	);

	const validationItem = requirement.map((item, index) => {
		const initStatus = !value && !touched;
		const icon = item.valid && !initStatus ? <AppCheckIcon color="#00CF9C" /> : <AppCloseIcon color="#DC4C4C" width="12" height="12" viewBox="0 0 16 11" />;

		if (item.label === ERRORS.PASSWORDS_MATCH && !props.hasOwnProperty("confirmNewPassword")) {
			return null;
		}

		return (
			<div key={index} className="password-checklist">
				<p className="password-checklist__description">
					<span className="password-checklist__icon">{icon}</span>
					{item.label}
				</p>
			</div>
		);
	});

	return <div className={className}>{validationItem}</div>;
};

AppPasswordChecklist.propTypes = {
	classNames: PropTypes.string,
	value: PropTypes.string,
	touched: PropTypes.bool,
	confirmNewPassword: PropTypes.string
};

export default memo(AppPasswordChecklist);
