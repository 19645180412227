import React, { Fragment, useCallback, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import getAssetTypeListing from "services/get-asset-type-listing";

import validateFileSize from "common/validate-file-size";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";

const AppFindingDetails = () => {
	const navigate = useNavigate();
	const [formVisible, setFormVisible] = useState(false);
	const signatureTypes = useMemo(() => ({ attachment: "attachment" }), []);

	const initialValues = useMemo(() => {
		const values = {
			problem: "",
			assetName: "",
			spareParts: "",
			description: "",
			proposedAction: "",
			attachment: [{ name: "", signature: null }]
		};

		return values;
	}, []);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			problem: yup.string().required(ERRORS.REQUIRED),
			assetName: yup.string().required(ERRORS.REQUIRED),
			spareParts: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			proposedAction: yup.string().required(ERRORS.REQUIRED),
			attachment: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		}),
		onSubmit: onHandleSubmit
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workInspectionListing.workInspectionListing);
	}, [navigate]);

	//prettier-ignore
	const onHandleRemoveConfirmBy = useCallback((type, removeIndex) => {
        const nextFields = [...formik.values[type]].filter((_, i) => i !== removeIndex);

        formik.setFieldValue(type, nextFields);
    }, [formik]);

	//prettier-ignore
	const onHandleAddConfirmBy = useCallback((type) => {
        if (!formVisible) {
            setFormVisible(true);
        } else {
            const field = { name: "", signature: null };
            const fields = [...formik.values[type]];

            fields.push(field);

            formik.setFieldValue(type, fields);
        }
    }, [formik, formVisible]);

	return (
		<div className="app-finding-details">
			<div className="finding-details">
				<div className="finding-details__container">
					<div className="finding-details__row finding-details__row--divider">
						<p className="finding-details__label">Finding Details</p>

						<AppButton className="finding-details__add-record-button" outline type="button" label="Add Record" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.attachment)} />
					</div>

					{formVisible && (
						<form className="finding-details__form" onSubmit={formik.handleSubmit}>
							{formik.values.attachment.map((o, i) => {
								const error = formik.errors.attachment?.[i];
								const touched = formik.touched.attachment?.[i];

								return (
									<Fragment key={i}>
										<div className="finding-details__row">
											<p className="finding-details__label">New Record</p>

											<AppButton className="finding-details__delete-record-button" outline type="button" label="Remove Record" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.attachment, i)} />
										</div>

										<div className="finding-details__row">
											{/* prettier-ignore */}
											<AppSelectInput required name="assetName" label="Asset Name" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppInput disabled type="text" name="assetType" label="Asset Type" placeholder="" value={formik.values.assetType} error={formik.errors.assetType} touched={formik.touched.assetType} onChange={formik.handleChange} />
										</div>

										<div className="finding-details__row">
											{/* prettier-ignore */}
											<AppSelectInput required name="spareParts" label="Spare Parts" placeholder="Select..." options={[]} value={formik.values.spareParts} error={formik.errors.spareParts} touched={formik.touched.spareParts} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppInput required type="text" name="problem" label="Problem" placeholder="" value={formik.values.problem} error={formik.errors.problem} touched={formik.touched.problem} onChange={formik.handleChange} />
										</div>

										<div className="finding-details__row">
											{/* prettier-ignore */}
											<AppInput required type="text" name="proposedAction" label="Proposed Action" placeholder="Enter Proposed Action" value={formik.values.proposedAction} error={formik.errors.proposedAction} touched={formik.touched.proposedAction} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppInput required multiline type="textarea" name="description" label="Finding Description" placeholder="Enter Finding Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
										</div>

										<p className="finding-details__label">Attachments</p>

										<div className="finding-details__row">
											{/* prettier-ignore */}
											<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />
										</div>

										<div className="finding-details__signature">
											{/* prettier-ignore */}
											<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											<AppInputDragAndDrop name={`attachment[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />
										</div>
									</Fragment>
								);
							})}
						</form>
					)}
				</div>

				<div className="finding-details__button-container">
					{/* prettier-ignore */}
					<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

					{/* prettier-ignore */}
					<AppButton type="submit" label="Create" disabled={formik.isSubmitting} />
				</div>
			</div>
		</div>
	);
};
export default AppFindingDetails;
