import React, { Fragment, memo, useCallback, useMemo, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";

import useWindowSize from "hooks/use-window-size";

import AppChevronIcon from "components/icons/app-chevron-icon";

import moreIcon from "assets/images/components/app-route-breadcrumb/more-icon.svg";

const AppRouteBreadCrumb = (props) => {
	const navigate = useNavigate();
	const [width] = useWindowSize();
	const isMobileSize = useMemo(() => width <= 640, [width]);
	const [breadCrumbAnchor, setBreadCrumbAnchor] = useState();

	const breadCrumb = useMemo(() => {
		const data = [...props.data];

		if (isMobileSize) {
			return [data.pop()].filter((o) => o);
		}

		return data;
	}, [props.data, isMobileSize]);

	const onHandleCloseMenu = useCallback(() => {
		setBreadCrumbAnchor(null);
	}, []);

	const onHandleOpenMenu = useCallback((event) => {
		setBreadCrumbAnchor(event.currentTarget);
	}, []);

	//prettier-ignore
	const onHandleMenuBreadCrumb = useCallback((obj) => {
		onHandleCloseMenu();

		navigate(obj.path);
	}, [navigate, onHandleCloseMenu]);

	return (
		<div className="app-route-breadcrumb">
			<div className="route-breadcrumb">
				{!!(isMobileSize && breadCrumb.length) && (
					<Fragment>
						<button type="button" className="route-breadcrumb__menu" onClick={onHandleOpenMenu}>
							<img src={moreIcon} alt="more-icon" />
						</button>

						<AppChevronIcon className="route-breadcrumb__icon" color="#4F4F4F" />
					</Fragment>
				)}

				<ul className="route-breadcrumb__list">
					{breadCrumb?.map((o, i) => {
						const showChevron = breadCrumb.length !== i + 1;

						return (
							<li key={o.label} className="route-breadcrumb__item">
								<Link className="route-breadcrumb__link" to={o.path}>
									<p className="route-breadcrumb__label">{o.label}</p>

									{showChevron && <AppChevronIcon className="route-breadcrumb__icon" color="#4F4F4F" />}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "route-breadcrumb-menu" }} anchorEl={breadCrumbAnchor} open={!!breadCrumbAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} transformOrigin={{ vertical: "top", horizontal: "left" }}>
				{props.data.map((o, i) => {
					const lastItem = props.data.length === i + 1;

					if (lastItem) return null;

					return (
						<MenuItem key={o.label} onClick={() => onHandleMenuBreadCrumb(o)}>
							{o.label}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

export default memo(AppRouteBreadCrumb);
