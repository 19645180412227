import React, { useMemo } from "react";

import AppTable from "components/app-table/app-table";
import AppViewUpcomingOngoingEmployeeLeaveTable from "components/pages/human-resources/app-view-upcoming-ongoing-employee-leave-table";

const data = [
	{ leaveType: "Medical Claim", entitledDays: "1000", balanceDays: "900", daysTaken: "100" },
	{ leaveType: "Parking Claim", entitledDays: "500", balanceDays: "450", daysTaken: "50" },
	{ leaveType: "Overtime Claim", entitledDays: "1500", balanceDays: "1000", daysTaken: "500" }
];

const AppViewEmployeeLeaveSummaryTable = () => {
	const tableColumns = [
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: false
			}
		},
		{
			name: "entitledDays",
			label: "Entitled Days",
			options: {
				sort: false
			}
		},
		{
			name: "balanceDays",
			label: "Balance Days",
			options: {
				sort: false
			}
		},
		{
			name: "daysTaken",
			label: "Days Taken",
			options: {
				sort: false
			}
		}
	];

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null,
	}), []);

	return (
		<div className="app-view-employee-leave-summary-table">
			<div className="view-employee-leave-summary-table">
				<div className="view-employee-leave-summary-table__body">
					<AppTable data={data} columns={tableColumns} options={tableOptions} />
				</div>

				<AppViewUpcomingOngoingEmployeeLeaveTable />
			</div>
		</div>
	);
};

export default AppViewEmployeeLeaveSummaryTable;
