import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppCustomerAssetListSuccess = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [addedAssetsLength, setAddedAssetsLength] = useState([]);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback((length) => {
		setAddedAssetsLength(length);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-asset-list-success-modal" }} open={visible}>
			<div className="customer-asset-list-success-modal">
				<button type="button" className="customer-asset-list-success-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="customer-asset-list-success-modal__title">Update Successful</h1>

				<p className="customer-asset-list-success-modal__description">You have successfully uploaded {addedAssetsLength} Assets to this Site</p>

				<div className="customer-asset-list-success-modal__button-container">
					<AppButton type="button" label="Okay" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerAssetListSuccess));

AppCustomerAssetListSuccess.propTypes = {
	ref: PropTypes.object.isRequired
};
