import React, { memo } from "react";

const AppHumanResourcesIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M7.2002 11.0001C7.2002 9.42005 8.4802 8.14005 10.0602 8.14005L10.2702 8.12005C10.2102 8.12005 10.1502 8.12005 10.0902 8.12005C9.1402 8.12005 8.3702 7.35005 8.3702 6.40005C8.3702 5.45005 9.1402 4.68005 10.0902 4.68005C10.6402 4.68005 11.1202 4.93005 11.4402 5.33005" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M17.2199 11.48C16.7099 10.08 15.3699 9.08005 13.7999 9.08005C12.2299 9.08005 10.8899 10.08 10.3799 11.47" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13.8004 9.08004C15.0099 9.08004 15.9904 8.09955 15.9904 6.89004C15.9904 5.68054 15.0099 4.70004 13.8004 4.70004C12.5908 4.70004 11.6104 5.68054 11.6104 6.89004C11.6104 8.09955 12.5908 9.08004 13.8004 9.08004Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.51973 19.8499C5.51973 20.2099 5.10973 20.4999 4.61973 20.4999C4.12973 20.4999 3.71973 20.2099 3.71973 19.8499V14.6499C3.71973 14.2899 4.12973 13.9999 4.61973 13.9999H5.51973V19.8499Z" stroke={color} />
			<path d="M14.3095 15.7499L18.4995 12.3699C19.1495 11.8499 20.3295 11.7399 20.6595 12.6799C20.8595 13.2699 20.4395 13.8699 19.9995 14.2299L14.4795 18.8399C14.3295 18.9799 14.1195 19.0599 13.9095 19.0599H5.51953V13.9399C7.02953 13.0299 9.64953 12.8899 11.0995 13.8799C11.1695 13.9299 11.2495 13.9499 11.3395 13.9499H13.3195C14.2795 13.9499 14.6395 14.4499 14.6395 15.0399C14.6395 15.5199 14.0595 16.0299 13.5595 16.0299H11.3295" stroke={color} />
		</svg>
	);
};

export default memo(AppHumanResourcesIcon);
