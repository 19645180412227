import PageInvoice from "pages/payment/page-invoice";
import PageQuotations from "pages/payment/page-quotations";
import PageSalesOrders from "pages/payment/page-sales-orders";
import PageDeliveryOrders from "pages/payment/page-delivery-orders";
import PageInvoiceCreateEdit from "pages/payment/page-invoice-create-edit";
import PageQuotationCreateEdit from "pages/payment/page-quotation-create-edit";
import PageSalesOrderCreateEdit from "pages/payment/page-sales-order-create-edit";
import PageDeliveryOrdersViewEdit from "pages/payment/page-delivery-orders-view-edit";
import PageSalesOrderInvoicesCreateEdit from "pages/payment/page-sales-order-invoices-create-edit";
import PageSalesOrderDeliveryOrderCreateEdit from "pages/payment/page-sales-order-delivery-order-create-edit";

import pathnames from "routes/pathnames";

const paymentRoutes = [
	{
		path: pathnames.payment.quotations,
		element: <PageQuotations />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.quotationCreateEdit + ":id",
		element: <PageQuotationCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.salesOrders,
		element: <PageSalesOrders />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.salesOrderCreateEdit + ":id",
		element: <PageSalesOrderCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.salesOrderDeliveryOrderCreateEdit,
		element: <PageSalesOrderDeliveryOrderCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.salesOrderInvoicesCreateEdit,
		element: <PageSalesOrderInvoicesCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.invoice,
		element: <PageInvoice />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.invoiceCreateEdit + ":id",
		element: <PageInvoiceCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.deliveryOrders,
		element: <PageDeliveryOrders />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.payment.deliveryOrdersViewEdit + ":id",
		element: <PageDeliveryOrdersViewEdit />,
		authenticated: true,
		role: null
	}
];

export default paymentRoutes;
