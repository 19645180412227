import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";

import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";
import AppTableFilterHeader from "components/app-table-filter-header";

import expandIcon from "assets/images/pages/human-resources/expand-icon.svg";

const claimHistoryData = [{ paginationNumbers: 1, claimType: "Medical Claim", date: "17/05/2023", amount: "90.00", status: "Approved" }];

const AppClaimHistoryTable = () => {
	const isMounted = useRef(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const defaultStatus = useMemo(() => [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED].join(","), []);
	// prettier-ignore
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");

		const relevantStatus = currentStatuses?.every(e => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	// prettier-ignore
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status, sort: searchParams.get("sort") || "", tab: "OPEN", });
	const [data, setData] = useState(convertPaginationTableData());

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({ initialValues: { startDate: "", endDate: "", status: [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED] }, validationSchema: validationSchema, onSubmit: (values) => onHandleSubmit(values) });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	/* prettier-ignore */
	const intructionClassName = useMemo(() => classNames({
		"claim-history-table-filter__instruction": true,
		"claim-history-table-filter__instruction--error": formik.errors.endDate,
	}), [formik.errors.endDate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELEED }
		];

		return data;
	}, []);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS);
		}

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [defaultStatus, formik, onHandleGetList]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];
		
		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "date",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "amount",
			label: "Amount (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "attachment",
			label: "Attachment",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" >
							<img src={expandIcon} alt="expand-icon" />
						</button>
					);
				},
			},
		},
	], []);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess:".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		},
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isMounted.current) {
			isMounted.current = false;

			let tab = searchParams.get("tab");
			let status = searchParams.get("status");
			let startDate = dayjs(searchParams.get("start-date"));
			let endDate = dayjs(searchParams.get("end-date"));

			const nextFormValues = {};

			if (status) {
				const currentStatuses = status.split(",");
				const relevantStatus = currentStatuses.every((e) => defaultStatus.split(",").includes(e));

				if (tab !== STATUS.CLOSED && !relevantStatus) {
					status = defaultStatus.split(",");
				} else {
					status = currentStatuses;
				}

				nextFormValues.status = status;
			}

			if (startDate && dayjs(startDate).isValid()) {
				nextFormValues.startDate = startDate;
			}

			if (endDate && dayjs(endDate).isValid()) {
				nextFormValues.endDate = endDate;
			}

			if (Object.keys(nextFormValues).length) {
				memoSetFormValues(nextFormValues);
			}
		}
	}, [defaultStatus, isMounted, memoSetFormValues, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	return (
		<div className="app-claim-history-table">
			<div className="claim-history-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Claim Type" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="claim-history-table-filter">
						<div className="claim-history-table-filter__container">
							{/* prettier-ignore */}
							<AppSelectInput searchable={false} name="claimType" label="Claim Type" placeholder="Select..." value={formik.values.claimType} error={formik.errors.claimType} touched={formik.touched.claimType} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInputDate name="startDate" label="Date" value={formik.values.startDate} onChange={formik.setFieldValue} placeholder="Select start date" />

							{/* prettier-ignore */}
							<AppInputDate name="endDate" value={formik.values.endDate} onChange={formik.setFieldValue} placeholder="Select end date" />

							<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
						</div>

						<div className="claim-history-table-filter__container">
							<p className="claim-history-table-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={claimHistoryData} columns={tableColumns} options={tableOptions} />
			</div>
		</div>
	);
};

export default AppClaimHistoryTable;
