import React, { memo } from "react";

const AppWorkCompletionReportIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M8 12.9277L10.7914 15.6895L16 10.3086" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4 20.5L4 3.69814L6.49742 5.18066L9.00477 3.5L11.9918 5.18066L15.0085 3.5L17.4861 5.18066L19.9835 3.69814V20.5H4Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M20.0001 4.37012V5.26925H18.0459H12.2064H7.2731L9.13569 4.12109L12.2064 5.26925L14.9248 4.12109L18.0459 5.26925L20.0001 4.37012Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M18.0459 5.26925H20.0001V4.37012L18.0459 5.26925ZM18.0459 5.26925H12.2064M18.0459 5.26925L14.9248 4.12109L12.2064 5.26925M12.2064 5.26925H7.2731M12.2064 5.26925L9.13569 4.12109L7.2731 5.26925M7.2731 5.26925H4.604L4.90062 4.66372" stroke={color} />
		</svg>
	);
};

export default memo(AppWorkCompletionReportIcon);
