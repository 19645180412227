import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TableCell, TableRow } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import classNames from "common/class-names";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";

import PAGE from "constants/page";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppTableFooter from "components/app-table/app-table-footer";
import AppQuotationAddItemModal from "components/pages/payment/app-quotation-add-item-modal";
import AppQuotationDeleteItemModal from "components/pages/payment/app-quotation-delete-item-modal";

import editIcon from "assets/images/edit-icon.png";
import addIcon from "assets/images/add-blue-icon.png";
import trashhIcon from "assets/images/trash-icon.png";
import downloadIcon from "assets/images/download-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageQuotationCreateEdit = () => {
	const { id } = useParams();
	const quotationAddItemModalRef = useRef();
	const quotationDeleteItemModalRef = useRef();
	const navigate = useNavigate();
	const selectedItem = useRef();
	const selectedSubItem = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Quotation" : "Edit Quotation"), [isCreate]);
	const [itemTableAnchor, setItemTableAnchor] = useState(null);
	const [subItemTableAnchor, setSubItemTableAnchor] = useState(null);
	const [rowsExpanded, setRowsExpanded] = useState([]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Payment", path: pathnames.payment.quotations },
			{ label: "Quotations", path: pathnames.payment.quotations }
		];

		if (isCreate) {
			data.push({ label: "Add Item", path: pathnames.payment.quotationCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Item " + id, path: pathnames.payment.quotationCreateEdit + id });
		}

		return data;
	}, [isCreate, id]);

	useBreadcrumb({ breadCrumb });

	const initialValues = useMemo(() => {
		const values = {
			tax: "",
			city: "",
			state: "",
			email: "",
			total: "",
			status: "",
			remarks: "",
			picName: "",
			currency: "",
			warranty: "",
			quantity: "",
			prefixNo: "",
			subtotal: "",
			postcode: "",
			mobileNo: "",
			approved: "",
			createdBy: "",
			quotationId: "",
			partsLeadTime: "",
			customerName: "",
			addressLine1: "",
			addressLine2: "",
			quotationTitle: "",
			workInspectionId: "",
			workCompletionId: "",
			mobilizationLeadTime: "",
			items: []
		};

		return values;
	}, []);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			tax: yup.string().required(ERRORS.REQUIRED),
			city: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			total: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			picName: yup.string().required(ERRORS.REQUIRED),
			remarks: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			discount: yup.string().required(ERRORS.REQUIRED),
			currency: yup.string().required(ERRORS.REQUIRED),
			warranty: yup.string().required(ERRORS.REQUIRED),
			customerName: yup.string().required(ERRORS.REQUIRED),
			addressLine1: yup.string().required(ERRORS.REQUIRED),
			partLeadTime: yup.string().required(ERRORS.REQUIRED),
			quotationTitle: yup.string().required(ERRORS.REQUIRED),
			workCompletionId: yup.string().required(ERRORS.REQUIRED),
			workInspectionId: yup.string().required(ERRORS.REQUIRED),
			mobilizationLeadTime: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: onHandleSubmit
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.payment.quotations);
	}, [navigate]);

	const onToggleTableAction = useCallback((event, data) => {
		selectedItem.current = { ...data.tableData[data.rowIndex], rowIndex: data.rowIndex };

		selectedSubItem.current = null;

		setItemTableAnchor(event.currentTarget);
	}, []);

	//prettier-ignore
	const onToggleSubTableAnchor = useCallback((event, tableData, subItemIndex) => {
		selectedItem.current = { ...formik.values.items[tableData.rowIndex], rowIndex: tableData.rowIndex };

		selectedSubItem.current = subItemIndex;

		setSubItemTableAnchor(event.currentTarget);
	}, [formik]);

	const onHandleCloseMenu = useCallback(() => {
		setItemTableAnchor(false);

		setSubItemTableAnchor(false);

		selectedItem.current = null;

		selectedSubItem.current = null;
	}, []);

	const onHandleAddQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		quotationAddItemModalRef.current.onHandleShow();
	}, []);

	const onHandleEditQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		setSubItemTableAnchor(false);

		quotationAddItemModalRef.current.onHandleShow();
	}, []);

	const onHandleAddSubQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		quotationAddItemModalRef.current.onHandleShowSubItem();
	}, []);

	const onHandleConfirmRemoveQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		setSubItemTableAnchor(false);

		let payload = {};

		if (typeof selectedSubItem.current === "number") {
			payload = selectedItem.current.subItems[selectedSubItem.current];
		} else {
			payload = selectedItem.current;
		}

		quotationDeleteItemModalRef.current.onHandleShow(payload);
	}, []);

	const onHandleRemoveQuotationItem = useCallback(() => {
		let nextItems = [...formik.values.items];

		if (typeof selectedSubItem.current === "number") {
			nextItems[selectedItem.current.rowIndex].subItems.splice(selectedSubItem.current, 1);
		} else {
			nextItems = nextItems.filter((_, i) => i !== selectedItem.current.rowIndex);
		}

		formik.setFieldValue("items", nextItems);

		onHandleCloseMenu();
	}, [onHandleCloseMenu, formik]);

	//prettier-ignore
	const onHandleCreateSubItem = useCallback((values) => {
		const mainItemIndex = selectedItem.current.rowIndex;

		const nextItems = [...formik.values.items];

		if (!nextItems[mainItemIndex].subItems) {
			nextItems[mainItemIndex].subItems = [];
		}

		nextItems[mainItemIndex].subItems.push(values);

		formik.setFieldValue("items", nextItems);
	}, [formik]);

	//prettier-ignore
	const onHandleCreateItem = useCallback((values) => {
		const nextItems = [...formik.values.items, values].map((o, i) => ({ number: i + 1, ...o }));

		formik.setFieldValue("items", nextItems);
	}, [formik]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = formik.values.items[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [formik]);

	const onHandleExpandCell = useCallback((index) => {
		setRowsExpanded((prev) => (prev.includes(index) ? [] : [index]));
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const item = tableMeta.tableData[tableMeta.rowIndex];

					const expandedButtonClassNames = classNames({
						"table__expandable-button": true,
						"table__expandable-button--expanded": rowsExpanded.includes(tableMeta.rowIndex),
					});

					if (item.subItems?.length) {
						return (
							<div className="table__expandable">
								{value}

								<button type="button" className={expandedButtonClassNames} onClick={() => onHandleExpandCell(tableMeta.rowIndex)}>
									<div className="table__expandable-icon" />
								</button>
							</div>
						);
					}

					return value;
				},
			},
		},
		{
			name: "title",
			label: "Title",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "serviceId",
			label: "Service ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "quantity",
			label: "Quantity",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "unit",
			label: "Unit",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "price",
			label: "Price Per Unit (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				},
			},
		},
		{
			name: "discount",
			label: "Discount (%)",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "amount",
			label: "Amount (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				},
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [rowsExpanded, onToggleTableAction, onHandleExpandCell]);

	//prettier-ignore
	const onHandleRenderExpandedRow = useCallback((rowData, tableMeta) => {
		const items = formik.values.items?.[tableMeta.rowIndex]?.subItems;

		return items?.map((o, i) => {
			return (
				<TableRow key={i} className="table__sub-item">
					<TableCell>{String.fromCharCode(i + "a".charCodeAt(0)) + "."}</TableCell>

					<TableCell>{o.title}</TableCell>

					<TableCell>{o.serviceId}</TableCell>

					<TableCell>{o.quantity}</TableCell>

					<TableCell>{o.unit}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.price)}</TableCell>

					<TableCell>{o.discount}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.amount)}</TableCell>
					
					<TableCell>
						<button type="button" className="table__action" onClick={(event) => onToggleSubTableAnchor(event, tableMeta, i)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					</TableCell>
				</TableRow>
			);
		});
	}, [onToggleSubTableAnchor, formik.values.items]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		rowsExpanded: rowsExpanded,
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		renderExpandableRow: onHandleRenderExpandedRow,
		onCellClick: onHandleCellSelect,
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
			return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
		},
	}), [rowsExpanded, onHandleCellSelect, onHandleRenderExpandedRow]);

	const emptyState = useMemo(() => {
		if (formik.values.items.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Items Added.
							<span className="table__link" onClick={onHandleAddQuotationItem}>
								Add Items?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [formik.values.items, tableColumns, onHandleAddQuotationItem]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.items.length === obj.selectedRowItem.number;

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number - 2]);
		};

		return (
			<div className="quotation-create-edit__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value item-table__value--underline">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceId}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Type</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceType}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							item of {obj.selectedRowItem.number} of {obj.items.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	return (
		<div className="page-quotation-create-edit">
			<div className="quotation-create-edit">
				<div className="quotation-create-edit__header">
					<h1 className="quotation-create-edit__title">{title}</h1>

					<div className="quotation-create-edit__header-buttons">
						<AppButton outline type="button" label="Export as PDF" icon={downloadIcon} onClick={() => {}} />

						<AppButton outline className="quotation-create-edit__clone-button" type="button" label="Clone" icon={chevronIcon} onClick={() => {}} />
					</div>
				</div>

				<form className="quotation-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="quotation-create-edit__container">
						<div className="quotation-create-edit__wrapper quotation-create-edit__wrapper--divider">
							<p className="quotation-create-edit__label">General Details</p>

							<div className="quotation-create-edit__row quotation-create-edit__row--general-details">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="quotationId" label="Quotation ID" placeholder="Quotation ID" value={formik.values.quotationId} error={formik.errors.quotationId} touched={formik.touched.quotationId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput disabled name="status" label="Status" placeholder="Select..." options={[]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row quotation-create-edit__row--general-details">
								{/* prettier-ignore */}
								<AppSelectInput required name="workInspectionId" label="Work Inspection Id" placeholder="Select..." options={[]} value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput required name="workCompletionId" label="Work Completion Id" placeholder="Select..." options={[]} value={formik.values.workCompletionId} error={formik.errors.workCompletionId} touched={formik.touched.workCompletionId} onChange={formik.handleChange} />

								{!isCreate && (
									<Fragment>
										{/* prettier-ignore */}
										<AppInput disabled type="text" name="createdBy" label="Created" placeholder="Created By" value={formik.values.createdBy} error={formik.errors.createdBy} touched={formik.touched.createdBy} onChange={formik.handleChange} />
									</Fragment>
								)}
							</div>
						</div>

						<div className="quotation-create-edit__wrapper">
							<p className="quotation-create-edit__label">Customer Details</p>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppInput required type="text" name="customerName" label="Customer Name" placeholder="Please input customer name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput required name="picName" label="PIC Name" placeholder="Select..." options={[]} value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="email" label="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppInput required type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppSelectInput required name="state" label="State" placeholder="Select..." options={[]} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput required name="city" label="City" placeholder="Select..." options={[]} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppSelectInput required name="postcode" label="Postcode" placeholder="Select..." options={[]} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="quotation-create-edit__container">
						<div className="quotation-create-edit__wrapper quotation-create-edit__wrapper--divider">
							{/* prettier-ignore */}
							<AppInput required type="text" name="quotationTitle" label="Quotation Title" placeholder="Please input quotation title" value={formik.values.quotationTitle} error={formik.errors.quotationTitle} touched={formik.touched.quotationTitle} onChange={formik.handleChange} />

							<div className="quotation-create-edit__table-header">
								<p className="quotation-create-edit__label">Item Lines</p>

								<AppButton outline type="button" label="Add" icon={addIcon} onClick={onHandleAddQuotationItem} />
							</div>

							<AppTable data={formik.values.items} columns={tableColumns} options={tableOptions} components={emptyState} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={formik.values.items} />

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppInput multiline type="textarea" name="internalNote" label="Internal Note" placeholder="Type Note here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="quotation-create-edit__column quotation-create-edit__column--divider">
									{/* prettier-ignore */}
									<AppInput disabled type="text" name="subtotal" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotal} touched={formik.touched.subtotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									{/* prettier-ignore */}
									<AppInput required type="number" maxLength={1} name="tax" label="Tax (%)" placeholder="Please input tax percent" value={formik.values.tax} error={formik.errors.tax} touched={formik.touched.tax} onChange={formik.handleChange} />
								</div>
							</div>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppInput multiline type="textarea" name="remarks" label="Customer Note" placeholder="Type Note here" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

								<div className="quotation-create-edit__column quotation-create-edit__column--divider">
									{/* prettier-ignore */}
									<AppInput required type="text" name="discount" label="Discount (MYR)" value={formik.values.discount} error={formik.errors.discount} touched={formik.touched.discount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									{/* prettier-ignore */}
									<AppInput disabled type="text" name="grandTotal" label="Grand Total (MYR)" value={formik.values.grandTotal} error={formik.errors.grandTotal} touched={formik.touched.grandTotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>

						<div className="quotation-create-edit__wrapper">
							<p className="quotation-create-edit__label">Standard Terms and Conditions</p>

							<div className="quotation-create-edit__row quotation-create-edit__row--general-details">
								{/* prettier-ignore */}
								<AppInput required type="text" name="partsLeadTime" label="Parts Lead Time" placeholder="Enter Parts Lead Time" value={formik.values.partsLeadTime} error={formik.errors.partsLeadTime} touched={formik.touched.partsLeadTime} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled name="approved" label="Approved Payment Term" placeholder="Select..." value={formik.values.approved} touched={formik.touched.approved} onChange={formik.handleChange} />
							</div>

							<div className="quotation-create-edit__row">
								{/* prettier-ignore */}
								<AppInput required type="text" name="mobilizationLeadTime" label="Mobilization Lead Time" placeholder="Enter Mobilization Lead Time" value={formik.values.mobilizationLeadTime} error={formik.errors.mobilizationLeadTime} touched={formik.touched.mobilizationLeadTime} onChange={formik.handleChange} />

								<div className="quotation-create-edit__column">
									{/* prettier-ignore */}
									<AppInput required type="text" name="warranty" label="Warranty" placeholder="Enter Warranty" value={formik.values.warranty} error={formik.errors.warranty} touched={formik.touched.warranty} onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="currency" label="Payment Currency" placeholder="Enter Payment Currency" value={formik.values.currency} error={formik.errors.currency} touched={formik.touched.currency} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="quotation-create-edit__button-container">
						<AppButton outline className="quotation-create-edit__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton outline type="button" label="Save as Draft" onClick={() => {}} />

						<AppButton type="submit" label="Add" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "quotation-create-edit-table-menu" }} anchorEl={itemTableAnchor || subItemTableAnchor} open={!!itemTableAnchor || !!subItemTableAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditQuotationItem}><img src={editIcon} alt="inventory-edit" />Edit</MenuItem>

				{itemTableAnchor && <MenuItem onClick={onHandleAddSubQuotationItem}><img src={addIcon} alt="inventory-close" />Add Sub-Item</MenuItem>}

				<MenuItem onClick={onHandleConfirmRemoveQuotationItem}><img src={trashhIcon} alt="inventory-transfer" />Remove</MenuItem>
			</Menu>

			<AppQuotationAddItemModal ref={quotationAddItemModalRef} onConfirm={onHandleCreateItem} onConfirmSubItem={onHandleCreateSubItem} />

			<AppQuotationDeleteItemModal ref={quotationDeleteItemModalRef} onConfirm={onHandleRemoveQuotationItem} />
		</div>
	);
};

export default PageQuotationCreateEdit;
