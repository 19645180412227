import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppInventoryLocationModal from "components/pages/inventory/app-inventory-location-modal";

import addIcon from "assets/images/add-icon.png";
import editIcon from "assets/images/edit-icon.png";
import searchIcon from "assets/images/search-icon.png";
import calendarIcon from "assets/images/pages/inventory/calendar-icon.svg";
import activeCalendarIcon from "assets/images/pages/inventory/calendar-active-icon.svg";

const PageInventoryConfiguration = () => {
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const inventoryConfigurationModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tab: "LOCATION" });
	const [data, setData] = useState(convertPaginationTableData());

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.inventory.configurations(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
        queryParams.current.name = event.target.value;

        onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleCreateInventoryConfiguration = useCallback(() => {
		inventoryConfigurationModalRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleEditInventoryConfiguration = useCallback((obj) => {
		inventoryConfigurationModalRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},
		{
			name: "referenceNo",
			label: "Location ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "name",
			label: "Location Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEditInventoryConfiguration(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditInventoryConfiguration]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch(action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

					onHandleGetList();
					break;
				default:
					break;    
			}
		}
	}), [data, onHandleGetList]);

	//prettier-ignore
	const Table = useCallback((obj) => {
		return (
			<Fragment>
				<div className="inventory-configuration__header">
					<div className="inventory-configuration__search-input">
						<AppInput type="text" name="search" placeholder="Search by Location ID or Location Name" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.name} onChange={onHandleDebounceSearch} onBlur={() => {}} />
					</div>

					<div className="inventory-configuration__create-button">
						<AppButton type="button" label="Add" icon={addIcon} onClick={obj.onHandleCreateInventoryConfiguration} />
					</div>
				</div>

				<AppTable data={obj.data} columns={obj.tableColumns} options={obj.tableOptions} />
			</Fragment>
		);
	}, [onHandleDebounceSearch]);

	//prettier-ignore
	const inventoryTabs = useMemo(() => [
		{ label: "Location", icon: calendarIcon, activeIcon: activeCalendarIcon, name: "LOCATION", component: <Table data={data.content} tableColumns={tableColumns} options={tableOptions} onHandleCreateInventoryConfiguration={onHandleCreateInventoryConfiguration} /> }
	], [data.content, tableColumns, tableOptions, onHandleCreateInventoryConfiguration]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVENTORY.CONFIGURATIONS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-inventory-configuration">
			<div className="inventory-configuration">
				<h1 className="inventory-configuration__title">Inventory Configuration</h1>

				<div className="inventory-configuration__body">
					<AppTabs tabs={inventoryTabs} />
				</div>
			</div>

			<AppInventoryLocationModal ref={inventoryConfigurationModalRef} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default PageInventoryConfiguration;
