import PageLogin from "pages/login/page-login";
import PageOtpEmailConfirmation from "pages/login/page-otp-email-confirmation";

import pathnames from "routes/pathnames";
import { publicRoutes } from "../common/auth-routes";

const loginRoutes = [
	{
		path: pathnames.login.login,
		element: <PageLogin />,
		authenticated: false,
		loader: publicRoutes,
		role: null
	},
	{
		path: pathnames.login.otpEmailConfirmation,
		element: <PageOtpEmailConfirmation />,
		authenticated: false,
		role: null
	}
];

export default loginRoutes;
