import { createSlice } from "@reduxjs/toolkit";

import roleAccessible from "common/role-accessible";

const initialState = {};

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfile(state, action) {
			const permissions = roleAccessible(action.payload.roles);

			return Object.assign(state, action.payload, permissions);
		}
	}
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
