import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getWeekdaysListing = async () => {
	let response = null;

	let errorMessage = null;

	try {
		const res = await api.get.general.weekdays();

		const content = res.map(({ lookupTitle, lookupValue, ...o }) => ({ label: lookupTitle, value: lookupValue, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getWeekdaysListing;
