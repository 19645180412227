import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";
import manHorse from "assets/images/temp/man-horse.webp";

export const AppInventoryHistoryViewModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const onHandleSubmit = useCallback((value) => {
		setVisible(false);

		props.onHandleApproveReject(value, STATUS.APPROVED);
	}, [props]);

	const formik = useFormik({
		initialValues: {
			inventoryItem: "",
			status: "",
			employee: "",
			dateTime: "",
			workOrderId: "",
			to: "",
			quantityMovement: "",
			runningBalance: "",
			from: "",
			transactionType: "",
			remarks: ""
		},
		validationSchema: yup.object({
			from: yup.string().required(ERRORS.REQUIRED),
			transactionType: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((data) => {
		formik.setValues({ ...data });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleApproveReject(formik.values, STATUS.REJECTED);
	}, [formik, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-history-view-modal" }} open={visible}>
			<div className="inventory-history-view-modal">
				<button type="button" className="inventory-history-view-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-history-view-modal__title">Item Request</h1>

				<div className="inventory-history-view-modal__image">
					<img src={manHorse} alt="inventory" />
				</div>

				<form className="inventory-history-view-modal__form" onSubmit={formik.handleSubmit}>
					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="inventoryItem" label="Inventory Item" value={formik.values.inventoryItem} error={formik.errors.inventoryItem} touched={formik.touched.inventoryItem} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="employee" label="Employee" value={formik.values.employee} error={formik.errors.employee} touched={formik.touched.employee} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="dateTime" label="Date & Time" value={formik.values.dateTime} error={formik.errors.dateTime} touched={formik.touched.dateTime} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="workOrderId" label="Work Order ID" value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="to" label="To" value={formik.values.to} error={formik.errors.to} touched={formik.touched.to} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="quantityMovement" label="Quantity" value={formik.values.quantityMovement} error={formik.errors.quantityMovement} touched={formik.touched.quantityMovement} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="runningBalance" label="Running Balance" value={formik.values.runningBalance} error={formik.errors.runningBalance} touched={formik.touched.runningBalance} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppSelectInput required type="text" name="from" label="From" value={formik.values.from} error={formik.errors.from} touched={formik.touched.from} onChange={formik.handleChange} />

						<AppSelectInput required type="text" name="transactionType" label="Transaction Type" value={formik.values.transactionType} error={formik.errors.transactionType} touched={formik.touched.transactionType} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="textarea" name="remarks" label="Remarks" multiline maxLength={255} value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />

					<div className="inventory-history-view-modal__button-container">
						<div className="inventory-history-view-modal__reject-button">
							<AppButton outline type="button" label="Reject" onClick={onHandleReject} />
						</div>

						<div className="inventory-history-view-modal__approve-button">
							<AppButton type="submit" label="Approve" />
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryHistoryViewModal));

AppInventoryHistoryViewModal.propTypes = {
	onHandleApproveReject: PropTypes.func
};
