import React, { Fragment } from "react";

import { useSelector } from "react-redux";

import getOrdinalNumber from "common/get-ordinal-number";

import AppInput from "components/app-input";
import AppMobileInput from "components/app-mobile-input";

const AppUserInformation = () => {
	const profile = useSelector((state) => state.profile);

	return (
		<div className="app-user-information">
			<div className="user-information">
				<div className="user-information__container user-information__container--border">
					<p className="user-information__label">Personal Information</p>

					<p className="user-information__description">If you would like to change these details, please contact your admin.</p>

					<div className="user-information__row">
						{/* prettier-ignore */}
						<AppInput disabled name="userId" type="text" label="User ID" placeholder="User ID" value={profile.userId} onChange={() => {}} />
					</div>

					<div className="user-information__row">
						{/* prettier-ignore */}
						<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={profile.mobileNo} prefixNo={profile.mobileNoPrefix} onChange={() => {}} />

						<AppInput disabled className="email" name="email" type="text" label="Email" placeholder="Email" value={profile.email} onChange={() => {}} />
					</div>
				</div>

				{profile.emergencyContacts && (
					<div className="user-information__container">
						{profile.emergencyContacts.map((o, i) => {
							const ordinalNumber = getOrdinalNumber(i + 1) + " Emergency Contact";

							return (
								<Fragment key={i}>
									<p className="user-information__label">{ordinalNumber}</p>

									<div className="user-information__row">
										{/* prettier-ignore */}
										<AppInput disabled name="emergencyContactName" type="text" label="Emergency Contact Name" placeholder="Emergency Contact Name" value={o.fullName} onChange={() => {}} />

										<AppMobileInput disabled type="number" name="emergencyContactNumber" label="Emergency Contact Number" value={o.mobileNo} prefixNo={o.mobileNoPrefix} onChange={() => {}} />
									</div>

									<div className="user-information__row">
										<AppInput disabled name="emergencyContactRelationship" type="text" label="Relationship" placeholder="Relationship" value={o.relationship} onChange={() => {}} />
									</div>
								</Fragment>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default AppUserInformation;
