import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";

const AppCustomerConfirmMobileAccountModal = (props, ref) => {
	const [data, setData] = useState([]);
	const [visible, setVisible] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const onConfirm = useMemo(() => props.onConfirm, [props.onConfirm]);

	const onHandleConfirm = useCallback(async () => {
		setIsSubmitting(true);

		await onConfirm({ ...data, createAppUserConfirmation: true });

		setVisible(false);

		setIsSubmitting(false);
	}, [data, onConfirm]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		const nextData = { ...data, createAppUser: false };

		props.onHandleAddEditCustomerItem(nextData);

		setVisible(false);
	}, [data, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-customer-confirm-mobile-account-modal" }} open={visible}>
			<div className="customer-confirm-mobile-account-modal">
				<h1 className="customer-confirm-mobile-account-modal__title">Unable to link Mobile App Access</h1>

				<p className="customer-confirm-mobile-account-modal__description">No matching Mobile App Users were found.</p>

				<p className="customer-confirm-mobile-account-modal__description">Would you like to grant this PIC access to the Client App?</p>

				<p className="customer-confirm-mobile-account-modal__description">Upon clicking confirm, an account will be created in the Mobile App User listing.</p>

				<div className="customer-confirm-mobile-account-modal__button-container">
					<AppButton disabled={isSubmitting} outline type="button" label="Back" onClick={onHandleDismiss} />

					<AppButton disabled={isSubmitting} type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCustomerConfirmMobileAccountModal));

AppCustomerConfirmMobileAccountModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
