import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import reorderArrayElement from "common/reorder-array-element";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppReorderServiceLinesModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [services, setServices] = useState([]);
	const seqOptions = useMemo(() => services.map((_, i) => ({ label: i + 1, value: i })), [services]);

	const onHandleSubmit = useCallback(() => {
		props.onConfirm(services);

		setVisible(false);
	}, [props, services]);

	const onHandleShow = useCallback((data) => {
		setServices(data);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setServices([]);

		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleReorderSeq = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;
		const nextServices = structuredClone(services);

		reorderArrayElement(nextServices, name, value);

		setServices(nextServices);
	}, [services]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "serviceNumber",
			label: "#",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => {
					const { rowIndex } = tableMeta;

					return (
						<div className="reorder-service-lines-modal__table-row-service-number">
							<AppSelectInput type="text" searchable={false} name={rowIndex.toString()} placeholder="" options={seqOptions} value={rowIndex} onChange={onHandleReorderSeq} />
						</div>
					);
				}
			}
		},
		{
			name: "serviceId",
			label: "Service ID",
			options: {
				sort: false
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		}
	], [seqOptions, onHandleReorderSeq]);

	useImperativeHandle(ref, () => ({
		onHandleShow,
		onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-reorder-service-lines-modal" }} open={visible}>
			<div className="reorder-service-lines-modal">
				<button type="button" className="reorder-service-lines-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="reorder-service-lines-modal__title">Reorder Service Lines</h1>

				<div className="reorder-service-lines-modal__form">
					<AppTable data={services} columns={tableColumns} />

					<div className="reorder-service-lines-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Update" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppReorderServiceLinesModal));

AppReorderServiceLinesModal.propTypes = {
	onConfirm: PropTypes.func.isRequired
};
