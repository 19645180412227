import React, { useCallback, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import pathnames from "routes/pathnames";

import api from "services/api";
import getRolesListing from "services/get-roles-listing";
import getUserIdListing from "services/get-user-id-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import stringEncryptRSA from "common/string-encrypt-rsa";
import generateRandomPassword from "common/generate-random-password";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppPasswordChecklist from "components/app-password-checklist";
import AppInputMultiSelect from "components/app-input-select-multiple";

import padLockIcon from "assets/images/pad-lock-icon.png";
import padLockWhiteIcon from "assets/images/pad-lock-white-icon.png";

const PageUserAccessCreateUser = () => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const accessible = useMemo(() => profile?.permissions?.[ROLES.USER_ACCESS], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [userDetails, setUserDetails] = useState({});

	const icon = useMemo(() => {
		if (restricted) return padLockWhiteIcon;

		return padLockIcon;
	}, [restricted]);

	const initialValues = useMemo(() => {
		const values = {
			roles: [],
			userId: "",
			name: "",
			prefixNo: "",
			mobileNo: "",
			email: "",
			password: ""
		};

		return values;
	}, []);

	//prettier-ignore
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			userId: yup.string().required(ERRORS.REQUIRED),
			roles: yup.array().min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			name: yup.string().required(ERRORS.REQUIRED),
			prefixNo: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().matches(REGEX.EMAIL, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			password: yup.string().min(8, ERRORS.LENGTH).required(ERRORS.REQUIRED).matches(REGEX.UPPERCASE, ERRORS.REQUIRED).matches(REGEX.LOWERCASE, ERRORS.REQUIRED).matches(REGEX.SYMBOL, ERRORS.REQUIRED).matches(REGEX.NUMERIC, ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.userAccess + "?tab=USER_LISTING" },
			{ label: "User Access", path: pathnames.operations.userAccess + "?tab=USER_LISTING" },
			{ label: "Add User", path: pathnames.operations.userAccess + "?tab=USER_LISTING" }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				roleIds: values.roles,
				staffUserId: userDetails.id,
				password: stringEncryptRSA(values.password),
			};

			await api.post.userAccess.createUser(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "User was added successfully!" }));

			navigate(pathnames.operations.userAccess + "?tab=USER_LISTING");
		}
	}, [dispatch, formik, navigate, userDetails.id]);

	//prettier-ignore
	const onHandleSelectUserId = useCallback(async (event) => {
		const value = event.target.value;

		try {
			const userIdListing = await getUserIdListing({ keyword: value });

			const nextUserDetails = userIdListing.content[0];

			setUserDetails(nextUserDetails);

			formik.setFieldValue("userId", nextUserDetails.value);
			formik.setFieldValue("name", nextUserDetails.personalInfo.fullName);
			formik.setFieldValue("prefixNo", nextUserDetails.personalInfoContact.primaryMobileNoPrefix);
			formik.setFieldValue("mobileNo", nextUserDetails.personalInfoContact.primaryMobileNo);
			formik.setFieldValue("email", nextUserDetails.personalInfoContact.email);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

	}, [formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.operations.userAccess + "?tab=USER_LISTING");
	}, [navigate]);

	const onHandleGeneratePassword = useCallback(() => {
		formik.setFieldValue("password", generateRandomPassword());
	}, [formik]);

	return (
		<div className="page-user-access-create-user">
			<div className="user-access-create-user">
				<div className="user-access-create-user__header">
					<h1 className="user-access-create-user__title">Add User</h1>
				</div>

				<form className="user-access-create-user__form" onSubmit={formik.handleSubmit}>
					<div className="user-access-create-user__container">
						<div className="user-access-create-user__wrapper">
							<p className="user-access-create-user__sub-title">
								<span>Step 1</span> - Select Role(s)
							</p>
							<p className="user-access-create-user__description">Only 1 role can be added if selecting Client or Admin.</p>
						</div>

						<div className="user-access-create-user__row user-access-create-user__row--divider">
							<div className="user-access-create-user__column">
								{/* prettier-ignore */}
								<AppInputMultiSelect disabled={restricted} searchable={false} label="Role" name="roles" placeholder="Please Select..." required loadOptions={getRolesListing} value={formik.values.roles} touched={formik.touched.roles} error={formik.errors.roles} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="user-access-create-user__wrapper">
							<p className="user-access-create-user__sub-title">
								<span>Step 2</span> - Enter User Details
							</p>
						</div>

						<div className="user-access-create-user__row">
							<div className="user-access-create-user__column">
								{/* prettier-ignore */}
								<AppSelectInput required disabled={restricted} name="userId" label="User ID" placeholder="Select..." loadOptions={getUserIdListing} value={formik.values.userId} error={formik.errors.userId} touched={formik.touched.userId} onChange={onHandleSelectUserId} />

								{/* prettier-ignore */}
								<AppInput required disabled type="text" name="name" label="Name" placeholder="Enter Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppMobileInput required disabled name="mobileNo" label="Mobile No." placeholder="eg: 12345678" value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								{/* prettier-ignore */}
								<AppInput required disabled type="text" name="email" label="Email" placeholder="Enter Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

								<div className="user-access-create-user__password-input">
									{/* prettier-ignore */}
									<AppInput disabled={restricted} type="password" name="password" label="New Password" placeholder="New Password" iconInField={false} value={formik.values.password} error={formik.errors.password} touched={formik.touched.password} required onChange={formik.handleChange} />

									<AppButton disabled={restricted} className="user-access-create-user__generate-password" outline type="button" label="System Generate" icon={icon} onClick={onHandleGeneratePassword} />

									<AppPasswordChecklist value={formik.values.password} touched={formik.touched.password} />
								</div>
							</div>
						</div>
					</div>

					<div className="user-access-create-user__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label="Add" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageUserAccessCreateUser;
