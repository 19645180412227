import { Fragment, memo, useCallback, useEffect, useMemo } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import CONFIGURATION_TYPE from "constants/configuration-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppServicesLinkageTable from "components/pages/operations/general-configuration/app-services-linkage-table";

const PageServiceChecklistCreateEdit = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Service Checklist" : "Edit Service Checklist"), [isCreate]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Add"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.SERVICE_CHECKLIST_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	// prettier-ignore
	const initialValues = useMemo(() => ({ serviceChecklistId: "", name: "", description: "", configServices: [], configServiceIds: [] }), []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			configServiceIds: isCreate && yup.array().min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.generalConfiguration + "?tab=SERVICE_CHECKLIST" },
			{ label: "General Configuration", path: pathnames.operations.generalConfiguration + "?tab=SERVICE_CHECKLIST" }
		];

		if (isCreate) {
			data.push({ label: "Add Service Checklist", path: pathnames.operations.serviceChecklistCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Service Checklist " + formik.values.serviceChecklistId, path: pathnames.operations.serviceChecklistCreateEdit + id });
		}

		return data;
	}, [isCreate, formik, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const configServiceLinkages = values.configServiceIds.map((o) => ({ id: o }));

			const payload = {
				name: values.name,
				description: values.description,
				configServiceLinkages
			};

			if (isCreate) {
				await api.post.serviceChecklist.createServiceChecklist(payload);
			}

			if (!isCreate) {
				payload.id = id;

				await api.post.serviceChecklist.updateServiceChecklist(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service Checklist was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service Checklist was updated successfully!" }));
			}

			navigate(pathnames.operations.generalConfiguration + "?tab=SERVICE_CHECKLIST");
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.serviceChecklist.serviceChecklist(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				serviceChecklistId: response.referenceNo,
				name: response.name,
				description: response.description,
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedByName: response.lastModifiedByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
				configServices: [],
				configServiceIds: []
			});
		}
	}, [memoSetFormValues]);

	// prettier-ignore
	const onHandleUpdateConfigServices = useCallback((values) => {
		formik.setFieldValue("configServices", values);
	}, [formik]);

	// prettier-ignore
	const onHandleUpdateConfigServiceIds = useCallback((values) => {
		formik.setFieldValue("configServiceIds", values);
	}, [formik]);

	// prettier-ignore
	const onHandleRemoveServiceLinkage = useCallback((id) => {
		let nextConfigServiceIds = structuredClone(formik.values.configServiceIds);
		let nextConfigServices = structuredClone(formik.values.configServices);

		nextConfigServiceIds = nextConfigServiceIds.filter((o) => id !== o);
		nextConfigServices = nextConfigServices.filter((o) => id !== o.id);

		formik.setFieldValue("configServiceIds", nextConfigServiceIds);
		formik.setFieldValue("configServices", nextConfigServices);
	},[formik]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.operations.generalConfiguration + "?tab=SERVICE_CHECKLIST");
	}, [navigate]);

	const ServicesLinkageSection = useCallback((obj) => {
		return (
			<Fragment>
				<div className="service-checklist-create-edit__label">Services Linkage</div>

				<div className="service-checklist-create-edit__table">
					{/* prettier-ignore */}
					<AppServicesLinkageTable pageType={obj.pageType} formValues={obj.values} onHandleUpdateConfigServices={obj.onHandleUpdateConfigServices} onHandleUpdateConfigServiceIds={obj.onHandleUpdateConfigServiceIds} onHandleRemoveServiceLinkage={obj.onHandleRemoveServiceLinkage} onHandleGetDetails={obj.onHandleGetDetails} />
				</div>
			</Fragment>
		);
	}, []);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLIST);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-service-checklist-create-edit">
			<div className="service-checklist-create-edit">
				<div className="service-checklist-create-edit__header">
					<h1 className="service-checklist-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="service-checklist-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="service-checklist-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="service-checklist-create-edit__container">
						<div className="service-checklist-create-edit__label">Details</div>

						<div className="service-checklist-create-edit__row service-checklist-create-edit__row--divider">
							<div className="service-checklist-create-edit__column">
								{!isCreate && /* prettier-ignore */ <AppInput name="serviceChecklistId" label="Checklist ID" type="text" disabled value={formik.values.serviceChecklistId} error={formik.errors.serviceChecklistId} touched={formik.touched.serviceChecklistId} onChange={formik.handleChange} />}

								{/* prettier-ignore */}
								<AppInput required name="name" label="Checklist Name" placeholder="Enter Checklist Name" type="text" disabled={restricted} value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />
							</div>

							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="description" label="Description" placeholder="Enter Description" type="textarea" multiline maxLength={255} value={formik.values.description} required touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange} />
						</div>

						{/* prettier-ignore */}
						<ServicesLinkageSection pageType={CONFIGURATION_TYPE.SERVICE_CHECKLIST} values={formik.values} onHandleUpdateConfigServices={onHandleUpdateConfigServices} onHandleUpdateConfigServiceIds={onHandleUpdateConfigServiceIds} onHandleRemoveServiceLinkage={onHandleRemoveServiceLinkage} onHandleGetDetails={onHandleGetDetails} />
					</div>

					<div className="service-checklist-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(PageServiceChecklistCreateEdit);

PageServiceChecklistCreateEdit.propTypes = {
	onHandleCancelRequest: PropTypes.func
};
