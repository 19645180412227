import { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import { promptAlertMessage } from "store/slices/alert";

import AppButton from "components/app-button";

import copy from "assets/images/copy-icon.png";

const AppMobileAppResetPasswordSuccessfulModal = (props, ref) => {
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	const [data, setData] = useState({});

	const onHandleCopy = useCallback(() => {
		navigator.permissions.query({ name: "clipboard-write" }).then(async (result) => {
			switch (result.state) {
				case "granted":
				case "prompt":
					await navigator.clipboard.writeText(`Password: ${data.confirmNewPassword}`);

					dispatch(promptAlertMessage({ message: "Copied!" }));
					break;
				default:
					break;
			}
		});
	}, [dispatch, data]);

	const onHandleShow = useCallback((obj) => {
		setData(obj);

		setModalVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setModalVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-mobile-app-reset-password-successful-modal" }} open={modalVisible}>
			<div className="mobile-app-reset-password-successful-modal">
				<p className="mobile-app-reset-password-successful-modal__title">Password Reset Successful</p>

				<p className="mobile-app-reset-password-successful-modal__description">
					<b>Reminder:</b> Make sure to share this new password with the user.
				</p>

				<p className="mobile-app-reset-password-successful-modal__description">An Email has been sent if there is an address linked to the account.</p>

				<div className="mobile-app-reset-password-successful-modal__container">
					<div className="mobile-app-reset-password-successful-modal__details">
						<div className="mobile-app-reset-password-successful-modal__content">
							<p className="mobile-app-reset-password-successful-modal__label">Password:</p>

							<p className="mobile-app-reset-password-successful-modal__value">{data.confirmNewPassword}</p>
						</div>
					</div>

					<AppButton className="mobile-app-reset-password-successful-modal__copy-button" outline type="button" label="Copy" icon={copy} onClick={onHandleCopy} />
				</div>

				<div className="mobile-app-reset-password-successful-modal__footer">
					<AppButton type="button" label="Done" onClick={onHandleDismiss} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppMobileAppResetPasswordSuccessfulModal));

AppMobileAppResetPasswordSuccessfulModal.propTypes = {
	ref: PropTypes.object
};
