import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import classNames from "common/class-names";

import AppIcon from "components/app-icon";
import AppTable from "components/app-table/app-table";
import AppTableFooter from "components/app-table/app-table-footer";

import closeModal from "assets/images/close-icon.png";

const AppNotificationsViewRecipientsModal = ({ ...props }, ref) => {
	const className = useMemo(() => {
		return classNames({
			"app-notifications-view-recipients-modal": true,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className]);

	const [usersTableData, setUsersTableData] = useState([]);

	const [modalVisible, setModalVisible] = useState(false);

	const onHandleShow = useCallback((selectedRecipientUsers) => {
		setUsersTableData(
			Object.keys(selectedRecipientUsers).map((user, index) => {
				const userNameSplit = user.split(" ");

				const name = userNameSplit.slice(1).toString().replaceAll(",", " ");

				const userId = userNameSplit.slice(0, 1).toString();

				return { number: index + 1, name, userId };
			})
		);

		setModalVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setModalVisible(false);
	}, []);

	let tableColumns = [
		{
			name: "number",
			label: "#",
			options: {
				filter: true,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "userId",
			label: "User ID",
			options: {
				sort: false
			}
		}
	];

	const tableOptions = {
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
			return <AppTableFooter count={count} page={page} rowsPerPage={rowsPerPage} changeRowsPerPage={changeRowsPerPage} changePage={changePage} textLabels={textLabels} />;
		}
	};

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: className }} open={modalVisible}>
			<div className="view-recipients-modal">
				<div className="view-recipients-modal__close-container">
					<button className="view-recipients-modal__close-button" onClick={onHandleDismiss}>
						<AppIcon src={closeModal} />
					</button>
				</div>

				<div className="view-recipients-modal__title">View Recipients</div>

				<div className="view-recipients-modal__table">
					<AppTable
						// Set a key based on usersTableData.length to re-render the table & to prevent page out of data bounds warning
						//(this means the table is recreated with the new data and the page count resets to 0 with correspondence to correct data)
						key={usersTableData.length}
						data={usersTableData}
						columns={tableColumns}
						options={tableOptions}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppNotificationsViewRecipientsModal));

AppNotificationsViewRecipientsModal.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	selectedRecipientUsers: PropTypes.object
};
