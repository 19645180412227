const STATUS_CODE = {
	TOKEN_INVALID: "BO_BASE_000010",
	TOKEN_EXPIRED: "BO_BASE_000011",
	INVALID_USER: "BO_AUTH_0000002",
	TOKEN_NO_CLAIMS: "BO_BASE_000012",
	TOKEN_MISSING_SCOPE_INFORMATION: "BO_BASE_000013",
	JWT_OR_JSON_INVALID: "BO_BASE_000014",
	TOKEN_MISSING_EXPIRED: "BO_BASE_000015",
	TOKEN_INVALID_SIGNATURE: "BO_BASE_000016",
	TOKEN_REFRESH_NOT_ACTIVE: "BO_BASE_000017",
	INVALID_TOKEN: "BO_BASE_000019",
	TOKEN_AUTHORIZATION_MISSING: "BO_BASE_000020",
	DB_ERROR_ACCOUNT_LOCKED: "BO_BASE_000034",
	DB_ERROR_ACCOUNT_DEFAULT: "BO_BASE_000031",
	DB_ERROR_ACCOUNT_DELETED: "BO_BASE_000032",
	DB_ERROR_ACCOUNT_INACTIVE: "BO_BASE_000033",
	DB_ERROR_ACCOUNT_DISABLED: "BO_BASE_000018",
	DB_ERROR_ACCOUNT_LOGGED_IN: "BO_BASE_000006",
	DB_ERROR_ACCOUNT_IS_INACTIVE: "BO_BASE_000007"
};

export default STATUS_CODE;
