import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import reorderArrayElement from "common/reorder-array-element";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppReorderSubtaskModal = forwardRef((props, ref) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const [visible, setVisible] = useState(false);
	const [subtasks, setSubtasks] = useState([]);
	const seqOptions = useMemo(() => subtasks.map((_, i) => ({ label: i + 1, value: i })), [subtasks]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.serviceListing.subtaskList(id);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setSubtasks(response);
		}
	}, [id]);

	//prettier-ignore
	const onHandleShow = useCallback((data) => {
		if (isCreate){
			setSubtasks(data.subtasks);
		}

		if (!isCreate) {
			onHandleGetList();
		}

		setVisible(true);
	}, [isCreate, onHandleGetList]);

	const onHandleDismiss = useCallback(() => {
		setSubtasks([]);

		setVisible(false);
	}, []);

	const onHandleSubmit = useCallback(async () => {
		const formattedSubtasks = subtasks.map((o) => {
			const { id, ...relevantFields } = o;

			return relevantFields;
		});

		if (isCreate) {
			props.onHandleUpdateSubtasks(formattedSubtasks);

			dispatch(promptLayoutAlertMessage({ message: "Subtasks reordered successfully!" }));

			setVisible(false);
		}

		if (!isCreate) {
			let response = null;

			const orders = subtasks.map((o) => ({ id: o.id, seq: o.seq }));

			const payload = {
				"service-id": id,
				orders
			};

			try {
				response = await api.post.serviceListing.reorderServiceSubtask(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "Subtasks reordered successfully!" }));

				props.onHandleRefreshTable();

				props.onHandleGetDetails(id);

				setVisible(false);
			}
		}
	}, [dispatch, id, isCreate, props, subtasks]);

	// prettier-ignore
	const onHandleReorderSeq = useCallback((event) => {
		const name = event.target.name;
		const value = event.target.value;
		let nextSubtasks = structuredClone(subtasks);

		reorderArrayElement(nextSubtasks, name, value);

		nextSubtasks = nextSubtasks.map((o, i) => {
			o.seq = i + 1;

			return o;
		});

		setSubtasks(nextSubtasks);
	}, [subtasks]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "seq",
			label: "#",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => {
					const { rowIndex } = tableMeta;

					return (
						<div className="reorder-subtask-modal__table-row-service-number">
							<AppSelectInput type="text" searchable={false} name={rowIndex.toString()} placeholder="" options={seqOptions} value={value - 1} onChange={onHandleReorderSeq} />
						</div>
					);
				}
			}
		},
		{
			name: "subtaskAction",
			label: "Subtask Action",
			options: {
				sort: false
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				sort: false
			}
		}
	], [seqOptions, onHandleReorderSeq]);

	const tableOptions = { enableNestedDataAccess: ".", customFooter: () => null };

	useImperativeHandle(ref, () => ({
		onHandleShow,
		onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-reorder-subtask-modal" }} open={visible}>
			<div className="reorder-subtask-modal">
				<button type="button" className="reorder-subtask-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="reorder-subtask-modal__title">Reorder Subtasks</h1>

				<div className="reorder-subtask-modal__form">
					<AppTable data={subtasks} options={tableOptions} columns={tableColumns} />

					<div className="reorder-subtask-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Update" onClick={onHandleSubmit} />
					</div>
				</div>
			</div>
		</Modal>
	);
});

export default memo(AppReorderSubtaskModal);
