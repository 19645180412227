const secondsToTime = (e) => {
	const mins = Math.floor((e % 3600) / 60)
		.toString()
		.padStart(2, "0");
	const secs = Math.floor(e % 60)
		.toString()
		.padStart(2, "0");
	return mins + ":" + secs;
};

export default secondsToTime;
