import React, { memo } from "react";

const AppBulkDataHandlingIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M12 6.81994L12 20.4999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8.21004 17.0699L12 20.4999L15.79 17.0699" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M20.2797 7.38V3.5H3.71973V7.38" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppBulkDataHandlingIcon);
