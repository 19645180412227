import React, { useCallback } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

import REGEX from "constants/regex";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

import logo from "assets/images/logo.png";
import mailIcon from "assets/images/components/app-input/mail-icon.png";

const PageForgotPassword = () => {
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: { email: "" },
		validationSchema: yup.object({
			email: yup.string().matches(REGEX.EMAIL, ERRORS.REQUIRED).required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			await api.post.forgotPassword.forgotPassword(values);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(pathnames.forgotPassword.emailSent, { state: values });
		}
	}, [navigate, formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.login.login);
	}, [navigate]);

	// const onHandleRequestAdminHelp = useCallback(() => {
	// 	navigate(pathnames.forgotPassword.requestAdminHelp);
	// }, [navigate]);

	return (
		<div className="page-forgot-password">
			<main className="forgot-password">
				<form className="forgot-password__form" onSubmit={formik.handleSubmit}>
					<Link to={pathnames.login.login} className="forgot-password__logo">
						<img src={logo} alt="logo" />
					</Link>

					<h1 className="forgot-password__title">Forgot Password?</h1>

					<p className="forgot-password__description">Please enter your email address and we will send you a link to reset your password. </p>

					{/* prettier-ignore */}
					<AppInput type="text" name="email" placeholder="Email Address" icon={mailIcon} value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />

					<div className="forgot-password__button-container">
						<AppButton type="submit" label="Send Link" disabled={formik.isSubmitting} />

						<AppButton outline className="forgot-password__back-button" type="button" label="Back" onClick={onHandleBack} />
					</div>
				</form>

				{/* <div className="forgot-password__footer">
					<p className="forgot-password__text">No email?</p>

					<p className="forgot-password__link" onClick={onHandleRequestAdminHelp}>Request Admin Help</p>
				</div> */}
			</main>
		</div>
	);
};

export default PageForgotPassword;
