import React, { useEffect, useMemo, useState } from "react";

import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

const dummyData = [
	{ clockIn: "", clockOut: "", timeWorked: "", status: "ON_LEAVE" },
	{ clockIn: "10.00 a.m.", clockOut: "7.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "11.00 a.m.", clockOut: "8.30 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "12.00 p.m.", clockOut: "9.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "13.00 p.m.", clockOut: "10.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "14.00 p.m.", clockOut: "11.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "15.00 p.m.", clockOut: "12.00 a.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "", clockOut: "", timeWorked: "", status: "ON_LEAVE" },
	{ clockIn: "10.00 a.m.", clockOut: "7.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "11.00 a.m.", clockOut: "8.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "12.00 p.m.", clockOut: "9.30 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "13.00 p.m.", clockOut: "10.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "", clockOut: "", timeWorked: "", status: "ON_LEAVE" },
	{ clockIn: "15.00 p.m.", clockOut: "12.00 a.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "9.00 a.m.", clockOut: "6.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "10.00 a.m.", clockOut: "7.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "11.00 a.m.", clockOut: "8.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "12.00 p.m.", clockOut: "9.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "13.00 p.m.", clockOut: "10.30 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "14.00 p.m.", clockOut: "11.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "15.00 p.m.", clockOut: "12.00 a.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "", clockOut: "", timeWorked: "", status: "ON_LEAVE" },
	{ clockIn: "10.00 a.m.", clockOut: "7.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "11.00 a.m.", clockOut: "8.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "12.00 p.m.", clockOut: "9.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "13.00 p.m.", clockOut: "10.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "14.00 p.m.", clockOut: "11.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "", clockOut: "", timeWorked: "", status: "ON_LEAVE" },
	{ clockIn: "9.00 a.m.", clockOut: "6.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "10.00 a.m.", clockOut: "7.30 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" },
	{ clockIn: "11.00 a.m.", clockOut: "8.00 p.m.", timeWorked: "08 Hours and 01 Minutes", status: "" }
];

const AppAttendanceSummaryCalendar = () => {
	const [data, setData] = useState(dummyData);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "clockIn",
			label: "Clock-In",
			options: {
				sort: false
			}
		},
		{
			name: "clockOut",
			label: "Clock-Out",
			options: {
				sort: false
			}
		},
		{
			name: "timeWorked",
			label: "Time Worked",
			options: {
				sort: false
			}
		}
	], []);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-attendance-summary-calendar">
			<div className="attendance-summary-calendar">
				<AppCalendarSchedule data={data} columns={tableColumns} />
			</div>
		</div>
	);
};

export default AppAttendanceSummaryCalendar;
