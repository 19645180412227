import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

export const AppDeliveryOptionDeleteModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	const onHandleSubmit = (values) => {};

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-delivery-option-delete-modal" }} open={visible}>
			<div className="delivery-option-delete-modal">
				<button type="button" className="delivery-option-delete-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="delivery-option-delete-modal__title">Confirmation to Retire</h1>

				<p className="delivery-option-delete-modal__description">
					After clicking confirm, the status of <span className="delivery-option-delete-modal__invoice-id">DO0000001</span> will be <span className="delivery-option-delete-modal__status">Deleted</span>.
				</p>

				<div className="delivery-option-delete-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="submit" label="Confirm" disabled={onHandleSubmit} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDeliveryOptionDeleteModal));

AppDeliveryOptionDeleteModal.propTypes = {
	ref: PropTypes.object
};
