const STATUS = {
	IN_USE: "IN_USE",
	RETIRED: "RETIRED",
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE",
	CLOSED: "CLOSED",
	RESOLVED: "RESOLVED",
	OPEN: "OPEN",
	ASSIGNED: "ASSIGNED",
	DRAFT: "DRAFT",
	IN_PROGRESS: "IN_PROGRESS",
	ON_HOLD: "ON_HOLD",
	PENDING: "PENDING",
	ISSUED: "ISSUED",
	RECEIVED: "RECEIVED",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED",
	CANCELLED: "CANCELLED",
	PERMANENT: "PERMANENT",
	INTERN: "INTERN",
	PROBATION: "PROBATION",
	TERMINATED: "TERMINATED",
	WORKING: "WORKING",
	ON_LEAVE: "ON_LEAVE",
	UNACCOUNTED_ABSENCE: "UNACCOUNTED_ABSENCE",
	COMPLETED: "COMPLETED",
	VERIFIED: "VERIFIED",
	ACKNOWLEDGED: "ACKNOWLEDGED",
	SCHEDULED: "SCHEDULED",
	OVERDUE: "OVERDUE",
	RESCHEDULED: "RESCHEDULED",
	FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD"
};

export default STATUS;
