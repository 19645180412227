import { memo, useCallback, useEffect, useMemo } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getServiceStatusListing from "services/get-service-status-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const PageSubCategoryCreateEdit = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Sub Category" : "Edit Sub Category"), [isCreate]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Add"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CONTRACT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	// prettier-ignore
	const initialValues = useMemo(() => ({ referenceNo: "", name: "", status: "" }), []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.generalConfiguration + "?tab=SUB_CATEGORY" },
			{ label: "General Configuration", path: pathnames.operations.generalConfiguration + "?tab=SUB_CATEGORY" }
		];

		if (isCreate) {
			data.push({ label: "Add Sub Category", path: pathnames.operations.subCategoryCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Sub Category " + formik.values.referenceNo, path: pathnames.operations.subCategoryCreateEdit + id });
		}

		return data;
	}, [isCreate, formik, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				status: values.status,
				referenceNo: values.referenceNo
			};

			if (isCreate) {
				await api.post.sparePartSubCategory.createSparePartSubCategory(payload);
			}

			if (!isCreate) {
				payload.id = id;

				await api.post.sparePartSubCategory.updateSparePartSubCategory(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Sub Category was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Sub Category was updated successfully!" }));
			}

			navigate(pathnames.operations.generalConfiguration + "?tab=SUB_CATEGORY");
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.sparePartSubCategory.sparePartSubCategory(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				referenceNo: response.referenceNo,
				name: response.name,
				status: response.status,
			});
		}
	}, [memoSetFormValues]);

	// Revert to previous data
	const onHandleCancel = useCallback(() => {
		navigate(pathnames.operations.generalConfiguration + "?tab=SUB_CATEGORY");
	}, [navigate]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORY);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-sub-category-create-edit">
			<div className="sub-category-create-edit">
				<div className="sub-category-create-edit__header">
					<h1 className="sub-category-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="sub-category-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="sub-category-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="sub-category-create-edit__container">
						<div className="sub-category-create-edit__label">Details</div>

						<div className="sub-category-create-edit__row">
							<div className="sub-category-create-edit__column">
								{/* prettier-ignore */}
								<AppInput disabled name="referenceNo" label="Sub Category ID" type="text" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />
							</div>

							{/* prettier-ignore */}
							<AppSelectInput searchable={false} required disabled={restricted} type="text" name="status" label="Sub Category Status" placeholder="Select..." loadOptions={getServiceStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="sub-category-create-edit__row">
							{/* prettier-ignore */}
							<AppInput required name="name" label="Sub Category Name" type="text" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="sub-category-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(PageSubCategoryCreateEdit);
