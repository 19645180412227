import PageResetPassword from "pages/forgot-password/page-reset-password";
import PageForgotPassword from "pages/forgot-password/page-forgot-password";
import PageResetPasswordSuccess from "pages/forgot-password/page-reset-password-success";
import PageForgotPasswordEmailSent from "pages/forgot-password/page-forgot-password-email-sent";
import PageForgotPasswordLinkExpired from "pages/forgot-password/page-forgot-password-link-expired";
import PageForgotPasswordRequestAdminHelp from "pages/forgot-password/page-forgot-password-request-admin-help";
import PageForgotPasswordRequestAdminHelpSuccess from "pages/forgot-password/page-forgot-password-request-admin-help-success";

import pathnames from "routes/pathnames";

const forgotPasswordRoutes = [
	{
		path: pathnames.forgotPassword.forgotPassword,
		element: <PageForgotPassword />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.forgotPassword.emailSent,
		element: <PageForgotPasswordEmailSent />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.forgotPassword.resetPassword,
		element: <PageResetPassword />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.forgotPassword.linkExpired,
		element: <PageForgotPasswordLinkExpired />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.forgotPassword.requestAdminHelp,
		element: <PageForgotPasswordRequestAdminHelp />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.forgotPassword.requestAdminHelpSuccess,
		element: <PageForgotPasswordRequestAdminHelpSuccess />,
		authenticated: false,
		role: null
	},
	{
		path: pathnames.forgotPassword.resetPasswordSuccess,
		element: <PageResetPasswordSuccess />,
		authenticated: false,
		role: null
	}
];

export default forgotPasswordRoutes;
