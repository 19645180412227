import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";

import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const PageWorkInspectionListing = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", status: searchParams.get("status") });

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: {
			open: false,
			verified: false,
			cancelled: false,
			acknowledged: false
		},
		onSubmit: onHandleSubmit
	});

	//prettier-ignore
	const onHandleEdit = useCallback((event) => {
		navigate(pathnames.workInspectionListing.workInspectionListingCreateEdit, { state: { status: PAGE.EDIT } });
	}, [navigate]);

	const onHandleCreateReport = useCallback(() => {
		navigate(pathnames.workInspectionListing.workInspectionListingCreateEdit, { state: { status: PAGE.CREATE } });
	}, [navigate]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();
	}, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Open", name: "open" },
			{ label: "Verified", name: "verified" },
			{ label: "Cancelled", name: "cancelled" },
			{ label: "Acknowledged", name: "acknowledged" }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "id",
			label: "WIR ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "name",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "workOrderId",
			label: "Work Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "qoutationId",
			label: "Qoutation ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={onHandleEdit}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEdit]);

	useEffect(() => {
		setData([]);
	}, []);

	return (
		<div className="page-work-inspection-listing">
			<div className="work-inspection-listing">
				<h1 className="work-inspection-listing__title">Work Inspection Listing</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Work Order ID or Customer Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateReport} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="work-inspection-listing-filter">
						<div className="work-inspection-listing-filter__container">
							<p className="work-inspection-listing-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = (formik.values.status || []).findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="work-inspection-listing__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkInspectionListing;
