import React, { useCallback, useMemo, useRef } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import convertSortingQuery from "common/convert-sorting-query";

import AppTable from "components/app-table/app-table";

import editIcon from "assets/images/edit-icon.png";

const AppCustomerAssetListTable = (props) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const data = useMemo(() => props.data, [props.data]);
	const queryParams = useRef({ page: 0, sort: searchParams.get("sort") || "" });

	// prettier-ignore
	const onHandleEditAsset = useCallback((obj) => {
		const assetId = data.content[obj.rowIndex]?.id;

		navigate(pathnames.customer.customerEditAsset + assetId);
	}, [data, navigate]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "frequency",
			label: "Frequency",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "lastService",
			label: "Last Service",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => value || "-"
			}
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (props.disabled) return;

					return (
						<button type="button" className="table__action">
							<img src={editIcon} alt="edit-icon" onClick={() => onHandleEditAsset(tableMeta)} />
						</button>
					);
				}
			}
		}
	], [onHandleEditAsset, props.disabled]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					props.onHandleGetAssetList(id, queryParams.current);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					props.onHandleGetAssetList(id, queryParams.current);
					break;
				default:
					break;
			}
		}
	}), [data, id, props]);

	return (
		<div className="app-customer-asset-list-table">
			<div className="customer-asset-list-table">
				<AppTable data={props?.data?.content || []} columns={tableColumns} tableOptions={tableOptions} />
			</div>
		</div>
	);
};

export default AppCustomerAssetListTable;
