import React, { useCallback } from "react";

import AppButton from "components/app-button";

import ticketIcon from "assets/images/ticket-icon.svg";
import claimIcon from "assets/images/claim-approval-icon.svg";
import leaveIcon from "assets/images/leave-approval-icon.svg";
import creditCardIcon from "assets/images/credit-card-icon.svg";
import notificationIcon from "assets/images/notification-icon.svg";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import mobileCustomersIcon from "assets/images/mobile-customers-icon.svg";

const PageHomePage = (props) => {
	//prettier-ignore
	const Status = useCallback((obj) => (
		<div className="home__amount">
			<p className="home__text">{obj.text}</p>
			
			<p className={`home__value home__value--${obj.text.toLowerCase().replace(" ", "-")}`}>{obj.total}</p>
		</div>
	), []);

	return (
		<div className="page-home">
			<div className="home">
				<div className="home__main-header">
					<h1 className="home__greeting">Welcome Back, Ahmad Muhammad Ali!</h1>
				</div>

				<p className="home__last-update">Wednesday, 17 April 2024</p>

				<div className="home__wrapper">
					<div className="home__container">
						<div className="home__column home__column--work-order">
							<div className="home__row home__row--work-order home__row--divider">
								<div className="home__header">
									<h1 className="home__title">Today's Work Orders</h1>

									<div className="home__header-button">
										<AppButton outline className="home__view-button" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>

								<p className="home__total">299</p>

								<Status text="Open" total="9" />

								<Status text="Scheduled" total="290" />
							</div>

							<div className="home__row home__row--work-order home__row--divider">
								<div className="home__header">
									<h1 className="home__title">Preventative Work Orders</h1>

									<div className="home__header-button">
										<AppButton outline className="home__view-button" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>

								<Status text="Open" total="2000" />

								<Status text="Scheduled" total="680" />

								<Status text="Completed" total="1230" />

								<Status text="Verify" total="1990" />
							</div>

							<div className="home__row home__row--work-order">
								<div className="home__header">
									<h1 className="home__title">Service Work Orders</h1>

									<div className="home__header-button">
										<AppButton outline className="home__view-button" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>

								<Status text="Open" total="680" />

								<Status text="Scheduled" total="680" />

								<Status text="Completed" total="1230" />

								<Status text="Verify" total="1990" />
							</div>
						</div>
					</div>

					<div className="home__wrapper home__wrapper--main">
						<div className="home__wrapper home__wrapper--updates-enquiries-invoices">
							<div className="home__container">
								<div className="home__header">
									<div className="home__header-button">
										<AppButton className="home__button--notification" type="button" label="" icon={notificationIcon} onClick={() => {}} />
									</div>

									<h1 className="home__title">Recent Updates</h1>
								</div>

								<div className="home__column home__column--divider">
									<div className="home__header">
										<h1 className="home__update-title">Annual Event Details</h1>
									</div>

									<div className="home__header-button">
										{/* prettier-ignore */}
										<AppButton outline className="home__view-button home__view-button--recent-updates" type="button" label="Get ready for our Annual Employee Event on December 20th! It's a day to honour your hard work! See more details in the attached poster" icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>

								<div className="home__column">
									<div className="home__header">
										<h1 className="home__update-title home__update-title--alert">Alert</h1>
									</div>

									<div className="home__header-button">
										{/* prettier-ignore */}
										<AppButton outline className="home__view-button home__view-button--recent-updates" type="button" label="Abnormal Login Detected From EID-0001 Chun Man at 10:00PM. Failed attempt to login has exceeded 5 times after 8:00 PM. " icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>
							</div>

							<div className="home__wrapper home__wrapper--enquiries-invoices">
								<div className="home__container home__container--open-enquiries-invoices">
									<div className="home__header">
										<div className="home__header-button">
											<AppButton className="home__button" type="button" label="" icon={ticketIcon} onClick={() => {}} />
										</div>

										<h1 className="home__title">Open Enquiries</h1>
									</div>

									<Status text="On-Hold" total="300" />

									<Status text="Open-Enquiries" total="680" />

									<div className="home__header-button">
										<AppButton outline className="home__view-button home__view-button--enquiries-invoices" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>

								<div className="home__container home__container--open-enquiries-invoices">
									<div className="home__header">
										<div className="home__header-button">
											<AppButton className="home__button" type="button" label="" icon={creditCardIcon} onClick={() => {}} />
										</div>

										<h1 className="home__title">Invoices</h1>
									</div>

									<Status text="Overdue" total="680" />

									<Status text="In Progress" total="680" />

									<div className="home__header-button">
										<AppButton outline className="home__view-button home__view-button--enquiries-invoices" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
									</div>
								</div>
							</div>
						</div>

						<div className="home__wrapper home__wrapper--pending-approval">
							<div className="home__column home__column--container-divider"> </div>
							<p className="home__text home__text--approval">Pending HR Approval</p>

							<div className="home__container home__container--leave-claim ">
								<div className="home__wrapper home__wrapper--total">
									<div className="home__wrapper">
										<div className="home__header">
											<div className="home__header-button">
												<AppButton className="home__button" type="button" label="" icon={leaveIcon} onClick={() => {}} />
											</div>

											<h1 className="home__title">Your Leaves</h1>
										</div>

										<div className="home__footer">
											<AppButton outline className="home__view-button home__view-button--enquiries-invoices" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
										</div>
									</div>

									<p className="home__total">2</p>
								</div>
							</div>
							<div className="home__container home__container--leave-claim ">
								<div className="home__wrapper home__wrapper--total">
									<div className="home__wrapper">
										<div className="home__header">
											<div className="home__header-button">
												<AppButton className="home__button" type="button" label="" icon={claimIcon} onClick={() => {}} />
											</div>

											<h1 className="home__title">Your Claims</h1>
										</div>

										<div className="home__footer home__footer--leave-claims">
											<AppButton outline className="home__view-button home__view-button--enquiries-invoices" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
										</div>
									</div>

									<p className="home__total">1</p>
								</div>
							</div>

							<div className="home__column home__column--container-divider"> </div>
							<p className="home__text home__text--approval">Pending Your Approval</p>

							<div className="home__container home__container--leave-claim ">
								<div className="home__wrapper home__wrapper--total">
									<div className="home__wrapper">
										<div className="home__header">
											<div className="home__header-button">
												<AppButton className="home__button" type="button" label="" icon={mobileCustomersIcon} onClick={() => {}} />
											</div>

											<h1 className="home__title">Mobile Customers</h1>
										</div>

										<div className="home__footer home__footer--mobile-customers">
											<AppButton outline className="home__view-button home__view-button--enquiries-invoices" type="button" label="View" icon={chevronIcon} onClick={() => {}} />
										</div>
									</div>

									<p className="home__total">5</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageHomePage;
