const sanitizeUserPermissions = (roles) => {
	const permissions = [];

	roles?.forEach((a) => {
		a.permission?.forEach((b) => {
			permissions.push(...b.child);
		});
	});

	return permissions;
};

const roleAccessible = (roles) => {
	const userPermissions = sanitizeUserPermissions(roles).map((o) => o.name);

	const permissions = {};

	roles?.[0]?.permission?.forEach((o) => {
		permissions[o.name] = {
			view: userPermissions.includes(o.name + ":view"),
			create: userPermissions.includes(o.name + ":create"),
			update: userPermissions.includes(o.name + ":update"),
			delete: userPermissions.includes(o.name + ":delete"),
			export: userPermissions.includes(o.name + ":export"),
			download: userPermissions.includes(o.name + ":download")
		};
	});

	return { permissions };
};

export default roleAccessible;
