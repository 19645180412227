import { useContext, useEffect } from "react";

import { AppLayoutContext } from "components/app-layout";

const useBreadcrumb = (props) => {
	const context = useContext(AppLayoutContext);

	useEffect(() => {
		context.onHandleSetBreadcrumb(props.breadCrumb);

		return () => {
			context.onHandleSetBreadcrumb([]);
		};
	}, [context, props]);
};

export default useBreadcrumb;
