import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { Menu, Table, TableBody, TableCell, TableRow } from "@mui/material";

import ClassNames from "common/class-names";
import getTotalDays from "common/get-total-days";

import STATUS from "constants/status";
import TIME_OFF from "constants/time-off";
import DATE_TIME from "constants/date-time";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppPendingLeavesViewModal from "components/pages/human-resources/app-pending-leaves-view-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import filerIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const dummyData = [
	{ employeeId: 1, name: "Ali", days: 0.5, leaveType: "Annual Leave", submitDate: new Date("2023-03-25"), startDate: new Date("2023-03-27"), endDate: new Date("2023-03-27"), timeOff: "Full Day", description: "N/A" },
	{ employeeId: 2, name: "Abu", days: 2, leaveType: "Annual Leave", submitDate: new Date("2023-03-25"), startDate: new Date("2023-03-27"), endDate: new Date("2023-03-29"), timeOff: "Half Day", description: "N/A" }
];

const AppHrViewPendingLeavesTable = () => {
	const pendingLeavesRef = useRef();
	const [data, setData] = useState(dummyData);
	const [searchParams] = useSearchParams();
	const [selectedPending, setSelectedPending] = useState([]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "PENDING_LEAVE" });

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { submitDate: "", startDate: "", endDate: "", timeOff: [TIME_OFF.FULL_DAY, TIME_OFF.HALF_DAY_AM, TIME_OFF.HALF_DAY_PM], status: [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => ClassNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate,
	}), [formik.errors.endDate]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["submit-date"] = "";
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
	}, [formik]);

	const menuFilterTimeOff = useMemo(() => {
		const data = [
			{ label: "Full Day", name: TIME_OFF.FULL_DAY },
			{ label: "Half Day AM", name: TIME_OFF.HALF_DAY_AM },
			{ label: "Half Day PM", name: TIME_OFF.HALF_DAY_PM }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectTimeOff = useCallback((value, name) => {
		let values = [...formik.values.timeOff];

		if (formik.values.timeOff.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("timeOff", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((employeeId) => employeeId !== idNo);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.employeeId);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const onHandleViewPendingLeaves = useCallback((obj) => {
		pendingLeavesRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	const onHandleSubmit = useCallback((values) => {
		queryParams.current["submit-date"] = values.submitDate && dayjs(values.submitDate).isValid() ? dayjs(values.submitDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
		queryParams.current["start-date"] = values.startDate && dayjs(values.startDate).isValid() ? dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
		queryParams.current["end-date"] = values.endDate && dayjs(values.endDate).isValid() ? dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.employeeId;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false,
			},
		},
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "view",
			label: "View",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewPendingLeaves(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleViewPendingLeaves, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="hr-view-pending-leaves-table__header-button">
				<div className="hr-view-pending-leaves-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} />
				</div>

				<div className="hr-view-pending-leaves-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} />
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-hr-view-pending-leaves-table">
			<div className="hr-view-pending-leaves-table">
				<div className="hr-view-pending-leaves-table__header">
					<div className="hr-view-pending-leaves-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name or Email Address" endIcon={searchIcon} iconPosition="end" onChange={onHandleDebounceSearch} />

						<div className="hr-view-pending-leaves-table__filter-button">
							<AppButton outline type="button" label="Filter" icon={filerIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>

					<HeaderApprovalButton selectedPending={selectedPending} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-hr-view-pending-leaves-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<AppInputDate name="submitDate" label="Submit Date" placeholder="DD/MM/YYYY" value={formik.values.submitDate} onChange={formik.setFieldValue} />

						<div className="filter-menu__date-inputs">
							<AppInputDate name="startDate" label="Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

							<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
						</div>

						<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
					</div>

					<div className="filter-menu__checkboxes">
						<div className="filter-menu__checkboxes--row">
							<p className="filter-menu__checkboxes--label">Time Off</p>

							{menuFilterTimeOff.map((o) => {
								const isActive = formik.values.timeOff.findIndex((i) => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectTimeOff(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>

						<div className="filter-menu__checkboxes--row">
							<p className="filter-menu__checkboxes--label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

								return (
									<div className="filter-menu__checkbox" key={o.label}>
										<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />
									</div>
								);
							})}
						</div>
					</div>

					<div className="filter-menu__footer">
						<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>
							Clear All Filters
						</button>

						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>
								Cancel
							</button>

							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>
								Apply
							</button>
						</div>
					</div>
				</div>
			</Menu>

			<AppPendingLeavesViewModal ref={pendingLeavesRef} />
		</div>
	);
};

export default AppHrViewPendingLeavesTable;
