import React, { useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "@mui/material/Modal";

import converReadableFileSize from "common/convert-readable-file-size";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import fileIcon from "assets/images/components/app-file-upload/file-icon.svg";
import expandIcon from "assets/images/components/app-file-upload/expand-file-icon.svg";

const AppWorkOrderNotes = (props, ref) => {
	const [displayImage, setDisplayImage] = useState("");
	const [notesAndFiles, setNotesAndFiles] = useState([]);
	const [imageModalVisible, setImageModalVisible] = useState(false);
	const progress = useMemo(() => props.progress || 0, [props.progress]);

	const initialValues = useMemo(() => {
		let values = {
			addNote: "",
			files: []
		};

		if (props.values) {
			values = props.values;
		}

		return values;
	}, [props.values]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			addNote: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleSubmit = (values) => {
		const newNoteAndFiles = {
			note: values.addNote,
			files: values.files
		};

		setNotesAndFiles((prevNotesAndFiles) => [...prevNotesAndFiles, newNoteAndFiles]);

		formik.resetForm();
	};

	const onHandleImageVisible = (file) => {
		setImageModalVisible((prev) => !prev);

		const isImage = file?.type?.includes("image");

		if (isImage) {
			const image = URL.createObjectURL(file);

			setDisplayImage(image);
		}
	};

	return (
		<div className="app-work-order-notes">
			<div className="work-order-notes">
				<form className="work-order-notes__form" onSubmit={formik.handleSubmit}>
					<div className="work-order-notes__container">
						<p className="work-order-notes__label">Add Notes</p>

						{/* prettier-ignore */}
						<AppInput required multiline type="textarea" name="addNote" label="Add Note" placeholder="Enter Note" value={formik.values.addNote} error={formik.errors.addNote} touched={formik.touched.addNote} onChange={formik.handleChange} />

						<p className="work-order-notes__label">Attachments</p>

						<h1 className="work-order-notes__description">Please ensure that all images are in either jpg or png format with file size not exceeding 5MB.</h1>

						{/* prettier-ignore */}
						<AppInputDragAndDropFiles length={1} name="files" accept="image/png, image/jpeg, image/jpg,.doc,.docx,.pdf" onChange={formik.setFieldValue} error={formik.errors.files} touched={formik.touched.files} value={formik.values.files} />
					</div>

					<div className="work-order-notes__button-container">
						<AppButton type="submit" label="Add" onSubmit={formik.handleSubmit} />
					</div>
				</form>
			</div>

			<div className="work-order-notes__container">
				<p className="work-order-notes__label">Note</p>

				{notesAndFiles.map((o, i) => (
					<div key={i} className="work-order-notes__column">
						<div className="work-order-notes__file">{o.note}</div>

						{o.files.map((file, fileIndex) => (
							<div className="work-order-notes__file" key={fileIndex}>
								<div className="work-order-notes__icon">
									<img src={fileIcon} alt="fileIcon" />
								</div>

								<div className="work-order-notes__file-content">
									<p className="work-order-notes__name">{file.name}</p>

									<img src={expandIcon} alt="expandIcon" onClick={() => onHandleImageVisible(file)} />
								</div>

								<span className="work-order-notes__file-size">{converReadableFileSize(file.size)}</span>

								<div className="work-order-notes__expand-image">
									<div className="work-order-notes__progress" style={{ width: progress }} />
								</div>

								<Modal className="app-work-order-notes-modal" open={imageModalVisible} onClose={onHandleImageVisible}>
									<div className="work-order-notes-modal">
										<img src={displayImage} alt="uploaded-file" />
									</div>
								</Modal>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default AppWorkOrderNotes;
