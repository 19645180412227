import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { FormHelperText, Modal } from "@mui/material";

import classNames from "common/class-names";
import convertReadableFileSize from "common/convert-readable-file-size";

import importIcon from "assets/images/export-icon.png";
import downloadIcon from "assets/images/download-icon.png";
import fileIcon from "assets/images/components/app-file-upload/file-icon.svg";
import removeIcon from "assets/images/components/app-file-upload/remove-icon.svg";
import expandIcon from "assets/images/components/app-file-upload/expand-file-icon.svg";

const AppInputDragAndDropFiles = (props) => {
	const inputRef = useRef();
	const dropAreaRef = useRef();
	const [displayImage, setDisplayImage] = useState("");
	const [imageModalVisible, setImageModalVisible] = useState(false);
	const progress = useMemo(() => props.progress || 0, [props.progress]);
	const isErrorField = useMemo(() => !!props.error, [props.error]);
	const errorMessage = useMemo(() => (isErrorField ? props.error[0] : ""), [props.error, isErrorField]);

	// prettier-ignore
	const inputClassName = useMemo(() => classNames({
		"input-drag-and-drop-files": true,
		"input-drag-and-drop-files--error": !!props.error,
		"input-drag-and-drop-files--disabled": props.disabled,
		"input-drag-and-drop-files--hide": props.length && props.length <= props.value.length
	}), [props]);

	// prettier-ignore
	const onHandleDragEnter = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.classList.remove("input-drag-and-drop-files--dragover");
		event.preventDefault();
		event.stopPropagation();
	}, [props]);

	// prettier-ignore
	const onHandleDragLeave = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.classList.remove("input-drag-and-drop-files--dragover");
		event.preventDefault();
		event.stopPropagation();
	}, [props]);

	// prettier-ignore
	const onHandleDragOver = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.classList.add("input-drag-and-drop-files--dragover");
		event.preventDefault();
		event.stopPropagation();
	}, [props]);

	// prettier-ignore
	const onHandleDrop = useCallback((event) => {
		if (props.disabled) return;

		dropAreaRef.current.classList.remove("input-drag-and-drop-files--dragover");
		event.preventDefault();
		event.stopPropagation();

		const files = [...event.dataTransfer.files];

		const nextValues = [...props.value];

		if (props.length) {
			const slicedFiles = files.slice(0, props.length - nextValues.length);

			nextValues.push(...slicedFiles);
		} else {
			nextValues.push(...files);
		}

		props.onChange(props.name, nextValues);
	}, [props]);

	// prettier-ignore
	const onHandleChange = useCallback((event) => {
		const files = [...event.target.files];

		const nextValues = [...props.value];

		if (props.length) {
			const slicedFiles = files.slice(0, props.length - nextValues.length);
			nextValues.push(...slicedFiles);
		} else {
			nextValues.push(...files);
		}

		props.onChange(props.name, nextValues);
	}, [props]);

	const onHandleSelectFile = useCallback(() => {
		if (props.disabled) return;

		inputRef.current.click();
	}, [props]);

	const onHandleImageVisible = (file) => {
		setImageModalVisible((prev) => !prev);

		const isImage = file?.type?.includes("image");

		if (isImage) {
			const image = URL.createObjectURL(file);

			setDisplayImage(image);
		}
	};

	const onHandleFileSize = useCallback((obj) => {
		if (obj.fileSizeUnit) {
			return `${obj.fileSize} ${obj.fileSizeUnit}`;
		} else {
			return convertReadableFileSize(obj.fileSize || obj.size);
		}
	}, []);

	const Placeholder = useCallback((obj) => {
		if (obj.placeholder) return obj.placeholder;

		return (
			<Fragment>
				Drag & Drop Files Here or
				<span type="button" className="input-drag-and-drop-files__button" onClick={obj.onHandleSelectFile}>
					{" "}
					Select Files
				</span>
			</Fragment>
		);
	}, []);

	useEffect(() => {
		const droparea = dropAreaRef.current;

		droparea.addEventListener("dragenter", onHandleDragEnter);
		droparea.addEventListener("dragleave", onHandleDragLeave);
		droparea.addEventListener("dragover", onHandleDragOver);
		droparea.addEventListener("drop", onHandleDrop);

		return () => {
			if (droparea) {
				droparea.removeEventListener("dragenter", onHandleDragEnter);
				droparea.removeEventListener("dragleave", onHandleDragLeave);
				droparea.removeEventListener("dragover", onHandleDragOver);
				droparea.removeEventListener("drop", onHandleDrop);
			}
		};
	}, [onHandleDragEnter, onHandleDragLeave, onHandleDragOver, onHandleDrop]);

	return (
		<div className="app-input-drag-and-drop-files">
			{props.value?.map((o, i) => {
				return (
					<div className="input-drag-and-drop-files__file" key={i}>
						<div className="input-drag-and-drop-files__icon">
							<img src={fileIcon} alt="fileIcon" />
						</div>

						<div className="input-drag-and-drop-files__file-content">
							<div className="input-drag-and-drop-files__files">
								<p className="input-drag-and-drop-files__name">{o.name || o.fileName}</p>

								{props.allowShowImage && (
									<div className="input-drag-and-drop-files__expand-image">
										<img src={expandIcon} alt="expandIcon" onClick={() => onHandleImageVisible(o)} />
									</div>
								)}

								{props.onHandleDownloadFile && (
									<div className="input-drag-and-drop-files__download">
										<img src={downloadIcon} alt="downloadIcon" onClick={() => props.onHandleDownloadFile(o)} />
									</div>
								)}

								{o.localFileText && <p className="input-drag-and-drop-files__local-file">{o.localFileText}</p>}

								<span className="input-drag-and-drop-files__file-size">{onHandleFileSize(o)}</span>
							</div>

							<div className="input-drag-and-drop-files__progress" style={{ width: progress }} />
						</div>

						{props.onHandleRemoveFile && (
							<div className="input-drag-and-drop-files__remove-icon">
								<img src={removeIcon} alt="removeIcon" onClick={() => props.onHandleRemoveFile(i)} />
							</div>
						)}
					</div>
				);
			})}

			<div className={inputClassName} ref={dropAreaRef} hidden={props.disabled}>
				<input ref={inputRef} hidden name={props.name} type="file" accept={props.accept} onChange={onHandleChange} />

				<div className="input-drag-and-drop-files__placeholder">
					<img src={importIcon} alt="upload-icon" />
				</div>

				<p className="input-drag-and-drop-files__placeholder">
					<Placeholder placeholder={props.placeholder} onHandleSelectFile={onHandleSelectFile} />
				</p>
			</div>

			<Modal className="app-input-drag-and-drop-files-image-modal" open={imageModalVisible} onClose={() => onHandleImageVisible()}>
				<div className="input-drag-and-drop-files-image-modal">
					<img src={displayImage} alt="uploaded-file" />
				</div>
			</Modal>

			<FormHelperText>{errorMessage}</FormHelperText>
		</div>
	);
};

export default AppInputDragAndDropFiles;

AppInputDragAndDropFiles.propTypes = {
	length: PropTypes.number,
	disabled: PropTypes.bool,
	value: PropTypes.array,
	error: PropTypes.array,
	touched: PropTypes.array,
	placeholder: PropTypes.string,
	accept: PropTypes.string,
	onHandleDownloadFile: PropTypes.func,
	onHandleRemoveFile: PropTypes.func,
	onChange: PropTypes.func.isRequired
};
