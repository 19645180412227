import React, { useCallback, useMemo, useRef } from "react";

import AppTabs from "components/app-tabs";
import AppClaimPackagesTable from "components/pages/human-resources/app-claim-packages-table";
import AppLeavePackagesTable from "components/pages/human-resources/app-leave-packages-table";

import claimIcon from "assets/images/pages/human-resources/claim.svg";
import leaveIcon from "assets/images/pages/human-resources/leave-icon.svg";
import activeClaimIcon from "assets/images/pages/human-resources/claim-active.svg";
import activeLeaveIcon from "assets/images/pages/human-resources/leave-active-icon.svg";

const PageRenumerationPackages = () => {
	const tabRef = useRef();

	const onHandleSubmit = useCallback(() => {}, []);

	//prettier-ignore
	const tabs = useMemo(() => {
			const data = [
				{ label: "Leave Packages", icon: leaveIcon, activeIcon: activeLeaveIcon, name: "LEAVE_PACKAGES", component: <AppLeavePackagesTable onSubmit={onHandleSubmit} /> },
				{ label: "Claim Packages", icon: claimIcon, activeIcon: activeClaimIcon, name: "CLAIM_PACKAGES", component: <AppClaimPackagesTable onSubmit={onHandleSubmit} /> },
			];
	
			return data;
		}, [onHandleSubmit]);

	return (
		<div className="page-remuneration-packages">
			<div className="remuneration-packages">
				<h1 className="remuneration-packages__title">Renumeration Packages</h1>

				<div className="remuneration-packages__body">
					<AppTabs ref={tabRef} tabs={tabs} />
				</div>
			</div>
		</div>
	);
};

export default PageRenumerationPackages;
