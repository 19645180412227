const pathnames = {
	home: "/home",
	login: {
		login: "/",
		otpEmailConfirmation: "/otp-email-confirmation"
	},
	forgotPassword: {
		forgotPassword: "/forgot-password",
		emailSent: "/forgot-password/email-sent",
		linkExpired: "/forgot-password/link-expired",
		resetPassword: "/forgot-password/reset-password",
		requestAdminHelp: "/forgot-password/request-admin-help",
		requestAdminHelpSuccess: "/forgot-password/request-admin-help/success",
		resetPasswordSuccess: "/reset-password/success"
	},
	userAccount: "/user",
	paymentPage: "/payment",
	activityLogs: "/operations/activity-logs/",
	activityLogsViewDetails: "/operations/activity-logs/view-activity/",
	notification: {
		notifications: "/operations/notifications",
		notificationsCreateEdit: "/operations/notifications/create-edit-scheduled-notification"
	},
	alertConfiguration: "/operations/alert-configuration",
	operations: {
		userAccess: "/operations/user-access",
		userAccessCreateEditRole: "/operations/user-access/create-edit-role/",
		userAccessEditUser: "/operations/user-access/edit-user/",
		userAccessCreateUser: "/operations/user-access/create-user",
		generalConfiguration: "/operations/general-configuration",
		sparePartCreateEdit: "/operations/spare-part-create-edit/",
		serviceListingCreateEdit: "/operations/service-listing-create-edit/",
		serviceChecklistCreateEdit: "/operations/service-checklist-create-edit/",
		contractCreateEdit: "/operations/contract-create-edit/",
		categoryCreateEdit: "/operations/category-create-edit/",
		subCategoryCreateEdit: "/operations/sub-category-create-edit/"
	},
	humanResources: {
		remunerationPackages: "/human-resources/remuneration-packages",
		leavePackageCreateEdit: "/human-resources/leave-package-create-edit/",
		claimPackageCreateEdit: "/human-resources/claim-package-create-edit/",
		employeeMaintenance: "/human-resources/employee-maintenance",
		employeeMaintenanceCreateEdit: "/human-resources/employee-maintenance/create-edit/",
		hrConfiguration: "/human-resources/hr-configuration",
		leaveManagement: "/human-resources/leave-management",
		viewAttendance: "/human-resources/view-attendance",
		viewEmployeeLeave: "/human-resources/view-employee-leave",
		viewClaims: "/human-resources/view-claims",
		hrViewHolidays: "/human-resources/hr-view-holidays",
		claimManagement: "/human-resources/claim-management"
	},
	workInspectionListing: {
		workInspectionListing: "/workInspectionListing/work-inspection-listing",
		workInspectionListingCreateEdit: "/workInspectionListing/work-inspection-listing/create-edit"
	},
	workOrder: {
		breakdownWorkOrder: "/work-order/breakdown-work-order",
		correctiveWorkOrder: "/work-order/corrective-work-order",
		preventiveWorkOrder: "/work-order/preventive-work-order",
		preventiveWorkOrderCreateEdit: "/work-order/preventive-work-order/create-edit",
		projectWorkOrder: "/work-order/project-work-order",
		workOrderAll: "/work-order/work-order-all",
		workOrderCreateEdit: "/work-order/work-order-create-edit/"
	},
	workCompletionReport: {
		workCompletionReport: "/work-completion-report/work-completion-report",
		workCompletionReportCreateEdit: "/work-completion-report/work-completion-report-create-edit/"
	},
	customer: {
		customers: "/customer/customers",
		customerConfiguration: "/customer/configuration",
		customerCreateEdit: "/customer/customer-create-edit/",
		customerEditAsset: "/customer/customer-edit-asset/",
		customerCreateEditContract: "/customer/customer-create-edit-contract/",
		customerCreateEditSite: "/customer/customer-create-edit-site/",
		mobileAppCreateEdit: "/customer/mobile-app-create-edit/",
		enquiries: "/customer/enquiries",
		enquiriesCreateEdit: "/customer/enquiries-create-edit/"
	},
	inventory: {
		inventories: "/inventories",
		inventoryHistory: "/inventory/history",
		inventoryCreateEdit: "/inventory/create-edit/",
		inventoryConfiguration: "/inventory/configuration"
	},
	payment: {
		quotations: "/payment/quotations",
		quotationCreateEdit: "/payment/quotation/create-edit/",
		salesOrders: "/payment/sales-orders",
		salesOrderCreateEdit: "/payment/sales-order/create-edit/",
		salesOrderDeliveryOrderCreateEdit: "/payment/sales-order/delivery-order/create-edit/",
		salesOrderInvoicesCreateEdit: "/payment/sales-order/invoices/create-edit/",
		invoice: "/payment/invoice",
		invoiceCreateEdit: "/payment/invoice/create-edit/",
		deliveryOrders: "/payment/delivery-orders",
		deliveryOrdersViewEdit: "/payment/delivery-orders/view-edit/"
	},
	reportExtraction: "/reports-and-inquiry/report-extraction",
	dataInquiry: "/reports-and-inquiry/data-inquiry",
	bulkDataHandlingCustomer: "/bulk-data-handling/customer",
	bulkDataHandlingHumanResources: "/bulk-data-handling/human-resources",
	bulkDataHandlingInventory: "/bulk-data-handling/inventory"
};

export default pathnames;
