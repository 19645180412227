import React, { memo } from "react";

const AppReportsInquiryIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path d="M12.1197 20.5C16.8097 20.43 20.5697 16.58 20.4997 11.88C20.4297 7.17998 16.5797 3.43998 11.8797 3.49998C7.17974 3.55998 3.43974 7.41998 3.49974 12.12C3.55974 16.82 7.41974 20.57 12.1197 20.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M11.7998 13.8V13.33C11.7998 12.37 12.4298 11.66 13.1498 11.18C14.3998 10.11 14.1898 9.37 14.1898 9.37C14.1798 8.02 12.6598 7.28 12.1898 7.22C11.7198 7.16 11.1798 7.22 10.7598 7.52C10.3398 7.76 9.9798 8.18 9.7998 8.65" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M11.9401 16.82C12.4201 16.82 12.8001 16.43 12.8001 15.96C12.8001 15.49 12.4101 15.1 11.9401 15.1C11.4701 15.1 11.0801 15.49 11.0801 15.96C11.0801 16.43 11.4701 16.82 11.9401 16.82Z" fill="white" />
		</svg>
	);
};

export default memo(AppReportsInquiryIcon);
