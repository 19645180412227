import React, { Fragment, useCallback, useMemo } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import validateFileSize from "common/validate-file-size";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppReportDetailsAssetTable from "components/pages/work-inspection-listing/app-report-details-asset-table";
import AppReportDetailsTeamPicTable from "components/pages/work-inspection-listing/app-report-details-team-pic-table";

import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";

const AppReportDetails = () => {
	const navigate = useNavigate();
	const signatureTypes = useMemo(() => ({ preparedBys: "preparedBys", verifiedBys: "verifiedBys", acknowledgedBys: "acknowledgedBys" }), []);

	//prettier-ignore
	const decisionOptions = useMemo(() => [
		{ label: "Proceed", value: "PROCEED" },
		{ label: "Terminate", value: "TERMINATE" },
	], []);

	const initialValues = useMemo(() => {
		const values = {
			city: "",
			email: "",
			state: "",
			status: "",
			picName: "",
			postcode: "",
			mobileNo: "",
			reportId: "",
			customerPic: "",
			reportTitle: "",
			qoutationId: "",
			workOrderId: "",
			verifiedType: "",
			customerName: "",
			addressLine1: "",
			addressLine2: "",
			inspectionDate: "",
			inspectionTime: "",
			planningAction: "",
			inspectionDetails: "",
			acknowledgementType: "",
			preparedBys: [{ name: "", signature: null }],
			verifiedBys: [{ name: "", signature: null }],
			acknowledgedBys: [{ name: "", signature: null }]
		};

		return values;
	}, []);

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			city: yup.string().required(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			picName: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED),
			reportTitle: yup.string().required(ERRORS.REQUIRED),
			qoutationId: yup.string().required(ERRORS.REQUIRED),
			workOrderId: yup.string().required(ERRORS.REQUIRED),
			customerName: yup.string().required(ERRORS.REQUIRED),
			addressLine1: yup.string().required(ERRORS.REQUIRED),
			addressLine2: yup.string().required(ERRORS.REQUIRED),
			planningAction: yup.string().required(ERRORS.REQUIRED),
			inspectionDetails: yup.string().required(ERRORS.REQUIRED),
			inspectionDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			inspectionTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			preparedBys: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			verifiedBys: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			),
			acknowledgedBys: yup.array().of(
				yup.object({
					name: yup.string().required(ERRORS.REQUIRED),
					signature: yup.mixed().nullable().test("fileSize", ERRORS.FILE_SIZE, validateFileSize)
				})
			)
		}),
		onSubmit: onHandleSubmit
	});

	const confirmByButtonVisible = useMemo(() => !formik.values.preparedBys.some((o) => !o.signature) && formik.values.preparedBys.length < 3, [formik.values.preparedBys]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workInspectionListing.workInspectionListing);
	}, [navigate]);

	//prettier-ignore
	const onHandleRemoveConfirmBy = useCallback((type, removeIndex) => {
        const nextFields = [...formik.values[type]].filter((_, i) => i !== removeIndex);

        formik.setFieldValue(type, nextFields);
    }, [formik]);

	//prettier-ignore
	const onHandleAddConfirmBy = useCallback((type) => {
        const field = { name: "", signature: null };
        const fields = [...formik.values[type]];

        fields.push(field);

        formik.setFieldValue(type, fields);
    }, [formik]);

	return (
		<div className="app-report-details">
			<div className="report-details">
				<form className="report-details__form" onSubmit={formik.handleSubmit}>
					<div className="report-details__container">
						<p className="report-details__label">General Details</p>

						<div className="report-details__row report-details__row--divider">
							{/* prettier-ignore */}
							<AppInput disabled type="text" name="reportId" label="Report ID" placeholder="" value={formik.values.reportId} error={formik.errors.reportId} touched={formik.touched.reportId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput required name="status" label="Status" placeholder="Select..." options={[]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<p className="report-details__label">Customer Details</p>

						<div className="report-details__row">
							{/* prettier-ignore */}
							<AppInput type="text" name="customerName" label="Customer Name" placeholder="Customer Name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled type="text" name="customerPic" label="Customer PIC" placeholder="" value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row">
							{/* prettier-ignore */}
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							{/* prettier-ignore */}
							<AppInput disabled type="text" name="email" label="Email" placeholder="" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row">
							{/* prettier-ignore */}
							<AppInput type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row">
							{/* prettier-ignore */}
							<AppSelectInput name="state" label="State" placeholder="State" options={[]} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput name="city" label="City" placeholder="City" options={[]} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row report-details__row--divider">
							{/* prettier-ignore */}
							<AppSelectInput name="postcode" label="Postcode" placeholder="Postcode" options={[]} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>

						<p className="report-details__label">Assets</p>

						<AppReportDetailsAssetTable />
					</div>

					<div className="report-details__container">
						<div className="report-details__row">
							{/* prettier-ignore */}
							<AppInputDate required name="inspectionDate" label="Inspection Date"  placeholder="DD/MM/YYYY" value={formik.values.inspectionDate} error={formik.errors.inspectionDate} touched={formik.touched.inspectionDate} onChange={formik.setFieldValue} />

							{/* prettier-ignore */}
							<AppInputTime required name="inspectionTime" label="Inspection Time" placeholder="HH:MM" value={formik.values.inspectionTime} error={formik.errors.inspectionTime} touched={formik.touched.inspectionTime} onChange={formik.setFieldValue} />
						</div>

						<div className="report-details__row report-details__row--divider">
							{/* prettier-ignore */}
							<AppSelectInput required name="picName" label="Team PIC" placeholder="Select..." options={[]} value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
						</div>

						<p className="report-details__label">Team PIC</p>

						<AppReportDetailsTeamPicTable />
					</div>

					<div className="report-details__container">
						<div className="report-details__row">
							<div className="report-details__column">
								{/* prettier-ignore */}
								<AppInput required type="text" name="reportTitle" label="Report Title" placeholder="Enter Report Title" value={formik.values.reportTitle} error={formik.errors.reportTitle} touched={formik.touched.reportTitle} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput required name="workOrderId" label="Work Order Id" placeholder="Select..." options={[]} value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />
							</div>

							{/* prettier-ignore */}
							<AppInput required multiline type="textarea" name="inspectionDetails" label="Inspection Procedure/Details" placeholder="Enter Inspection Procedure/Details" value={formik.values.inspectionDetails} error={formik.errors.inspectionDetails} touched={formik.touched.inspectionDetails} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row report-details__row--divider">
							{/* prettier-ignore */}
							<AppSelectInput required name="qoutationId" label="Qoutation Id" placeholder="Select..." options={[]} value={formik.values.qoutationId} error={formik.errors.qoutationId} touched={formik.touched.qoutationId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput required multiline type="textarea" name="planningAction" label="Planning of Corrective Action" placeholder="Enter Planning of Corrective Action" value={formik.values.planningAction} error={formik.errors.planningAction} touched={formik.touched.planningAction} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row report-details__row--signature">
							<div className="report-details__column report-details__column--signature">
								{formik.values.preparedBys.map((o, i) => {
									const deleteButtonVisible = formik.values.preparedBys.length > 1;
									const error = formik.errors.preparedBys?.[i];
									const touched = formik.touched.preparedBys?.[i];

									return (
										<Fragment key={i}>
											{/* prettier-ignore */}
											<AppSelectInput required name={`preparedBys[${i}]name`} label="Prepared By" placeholder="Search by User ID or Name" options={[]} value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`preparedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{deleteButtonVisible && <AppButton className="report-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.preparedBys, i)} />}
										</Fragment>
									);
								})}
								{confirmByButtonVisible && <AppButton className="report-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.preparedBys)} />}
							</div>

							<div className="report-details__column report-details__column--signature">
								{formik.values.acknowledgedBys.map((o, i) => {
									//prettier-ignore
									const deleteButtonVisible = formik.values.acknowledgedBys.length > 1;
									const error = formik.errors.acknowledgedBys?.[i];
									const touched = formik.touched.acknowledgedBys?.[i];

									return (
										<Fragment key={i}>
											<AppInput required type="text" name={`acknowledgedBys[${i}]name`} label="Acknowledged By" placeholder="Acknowledged By" value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`acknowledgedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											<AppRadioInput required label="Acknowledgement Decision" options={decisionOptions} value={formik.values.acknowledgementType} error={formik.errors.acknowledgementType} touched={formik.touched.acknowledgementType} onChange={(v) => formik.setFieldValue("acknowledgementType", v)} />

											{deleteButtonVisible && /* prettier-ignore */ <AppButton className="report-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.acknowledgedBys, i)} />}
										</Fragment>
									);
								})}

								{confirmByButtonVisible && <AppButton className="report-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.acknowledgedBys)} />}
							</div>

							<div className="report-details__column report-details__column--signature">
								{formik.values.verifiedBys.map((o, i) => {
									const error = formik.errors.verifiedBys?.[i];
									const touched = formik.touched.verifiedBys?.[i];
									const deleteButtonVisible = formik.values.verifiedBys.length > 1;

									return (
										<Fragment key={i}>
											<AppInput required type="text" name={`verifiedBys[${i}]name`} label="Verified By" placeholder="Verified By" value={o.name} error={error?.name} touched={touched} onChange={formik.handleChange} />

											<AppInputDragAndDrop name={`verifiedBys[${i}]signature`} accept="image/png, image/jpeg, image/jpg" onChange={formik.setFieldValue} error={error && touched} />

											{/* prettier-ignore */}
											<AppRadioInput required label="Verified Decision" options={decisionOptions} value={formik.values.verifiedType} error={formik.errors.verifiedType} touched={formik.touched.verifiedType} onChange={(v) => formik.setFieldValue("verifiedType", v)} />

											{deleteButtonVisible && <AppButton className="report-details__delete-signature-button" outline type="button" label="Remove Signature" icon={trashIcon} onClick={() => onHandleRemoveConfirmBy(signatureTypes.verifiedBys, i)} />}
										</Fragment>
									);
								})}

								{confirmByButtonVisible && <AppButton className="report-details__add-signature-button" outline type="button" label="Add Signature" icon={addIcon} onClick={() => onHandleAddConfirmBy(signatureTypes.verifiedBys)} />}
							</div>
						</div>
					</div>

					<div className="report-details__button-container">
						{/* prettier-ignore */}
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						{/* prettier-ignore */}
						<AppButton type="submit" label="Next" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</div>
	);
};
export default AppReportDetails;
