import React, { useCallback, useRef } from "react";

import PAGE from "constants/page";

import AppTable from "components/app-table/app-table";

import cancelIcon from "assets/images/pages/human-resources/cancel-icon.svg";
import expandIcon from "assets/images/pages/human-resources/expand-icon.svg";

import AppPendingClaimCancelModal from "./app-pending-claim-cancel-modal";

const pendingData = [{ paginationNumbers: 1, name: "Package A", description: "High-level management package" }];

const AppPendingClaim = () => {
	const appClaimManagementModalRef = useRef();

	const onHandlePendingClaim = useCallback(() => {
		appClaimManagementModalRef.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	const tableColumns = [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Package Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false
			}
		},
		{
			name: "attachment",
			label: "Attachment",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action">
							<img src={expandIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		},
		{
			name: "cancel",
			label: "Cancel",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={onHandlePendingClaim}>
							<img src={cancelIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	];

	return (
		<div className="app-pending-claims-packages">
			<div className="pending-claims-packages">
				<div className="pending-claims-packages__body">
					<AppTable data={pendingData} columns={tableColumns} />
				</div>
			</div>

			<AppPendingClaimCancelModal ref={appClaimManagementModalRef} />
		</div>
	);
};

export default AppPendingClaim;
