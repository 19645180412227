import { useCallback, useMemo, useRef } from "react";

import { setProfile } from "store/slices/profile";
import { useDispatch, useSelector } from "react-redux";
import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import api from "services/api";

import validateFileSize from "common/validate-file-size";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import STATUS from "constants/status";

import AppTabs from "components/app-tabs";
import AppUserInformation from "components/pages/user-account/app-user-information";
import AppUserChangePassword from "components/pages/user-account/app-user-change-password";
import AppAlertConfiguration from "components/pages/user-account/app-user-alert-configuration";

import camera from "assets/images/camera-icon.png";
import personIcon from "assets/images/person-icon.svg";
import alertIcon from "assets/images/pages/user/alert-icon.svg";
import personActiveIcon from "assets/images/person-active-icon.svg";
import passLockIcon from "assets/images/pages/user/pass-lock-icon.svg";
import alertActiveIcon from "assets/images/pages/user/alert-active-icon.svg";
import defaultProfilePicture from "assets/images/default-profile-picture.png";
import passLockActiveIcon from "assets/images/pages/user/pass-lock-active-icon.svg";

const PageUserAccount = () => {
	const dispatch = useDispatch();
	const tabRef = useRef();
	const profile = useSelector((state) => state.profile);
	const userAvatar = useMemo(() => (profile.avatar ? profile.avatar : defaultProfilePicture), [profile]);
	const userRole = useMemo(() => (profile.roles?.length ? profile.roles[0].roleNameEn : ""), [profile]);
	const isForceChangePassword = useMemo(() => profile.forceChangePassword === STATUS.FORCE_CHANGE_PASSWORD, [profile]);
	const isSubmitting = useRef(false);
	const uploadImageRef = useRef();

	// prettier-ignore
	const onHandleChangeAvatar = useCallback(async (file) => {
		if (isSubmitting.current) return;

		isSubmitting.current = true;

		let response = null;

		try {
			const payload = {
				file: file,
			};

			await api.post.userAccess.updateAvatar(payload);

			response = await api.get.profile.profile();

		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			isSubmitting.current = false;
		}

		if (response) {
			dispatch(setProfile(response));

			dispatch(promptLayoutAlertMessage({ message: "Profile picture was updated successfully!" }));
		}
	}, [dispatch]);

	const onHandleChangeProfile = useCallback(() => {
		uploadImageRef.current.click();
	}, []);

	// prettier-ignore
	const onHandleChange = useCallback((event) => {
		let isValidFileSize = false;
		const file = event.target.files[0];

		if (!file) return;

		isValidFileSize = validateFileSize(file);

		if (isValidFileSize) {
			onHandleChangeAvatar(file);
		}
	}, [onHandleChangeAvatar]);

	// prettier-ignore
	const userAccountTabs = useMemo(() => {
		const data =  [
			{ label: "Information", icon: personIcon, activeIcon: personActiveIcon, name: "INFORMATION", component: <AppUserInformation/>, accessible: profile?.permissions?.[ROLES.INPUT_CONFIG]?.view, disabled: isForceChangePassword },
			{ label: "Reset Password", icon: passLockIcon, activeIcon: passLockActiveIcon, name: "RESET-PASSWORD", component: <AppUserChangePassword />, accessible: profile?.permissions?.[ROLES.INPUT_CONFIG]?.view, disabled: isForceChangePassword },
			{ label: "Alert Configuration", icon: alertIcon, activeIcon: alertActiveIcon, name: "ALERT-CONFIGURATION", component: <AppAlertConfiguration />, accessible: profile?.permissions?.[ROLES.INPUT_CONFIG]?.view, disabled: isForceChangePassword },
		];

		return data.filter(o => o.accessible);
	}, [profile?.permissions, isForceChangePassword]);

	return (
		<div className="page-user-account">
			<div className="user-account">
				<div className="user-account__details">
					<div className="user-account__image">
						<img className="user-account__picture" src={userAvatar} alt="profile" />

						<button className="user-account__camera">
							<img className="user-account__icon" src={camera} alt="camera" onClick={onHandleChangeProfile} />
						</button>
					</div>

					<div className="user-account__description">
						<div className="user-account__name">{profile?.fullName}</div>
						<div className="user-account__role">{userRole}</div>
					</div>
				</div>

				<div className="user-account__body">
					<AppTabs ref={tabRef} tabs={userAccountTabs} />
				</div>
			</div>

			<input type="file" hidden ref={uploadImageRef} name="avatart" onChange={onHandleChange} accept="image/png, image/jpeg, image/jpg" />
		</div>
	);
};

export default PageUserAccount;
