import React, { useCallback, useMemo, useRef } from "react";

import PAGE from "constants/page";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppClaimHistory from "components/pages/human-resources/app-claim-history-table";
import AppClaimSummary from "components/pages/human-resources/app-claim-summary-table";
import AppPendingClaim from "components/pages/human-resources/app-pending-claim-table";
import AppClaimSummaryModal from "components/pages/human-resources/app-claim-summary-modal";

import addIcon from "assets/images/add-icon.png";
import claimIcon from "assets/images/pages/human-resources/claim.svg";
import clockIcon from "assets/images/pages/human-resources/clock-icon.svg";
import activeClaimIcon from "assets/images/pages/human-resources/claim-active.svg";
import activeClockIcon from "assets/images/pages/human-resources/clock-active-icon.svg";
import claimApprovalIcon from "assets/images/pages/human-resources/claim-approval-icon.svg";
import activeClaimApprovalIcon from "assets/images/pages/human-resources/claim-approval-active-icon.svg";

const PageViewClaims = () => {
	const appClaimManagementModalRef = useRef();

	const onHandleClaimManagement = useCallback(() => {
		appClaimManagementModalRef.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	//prettier-ignore
	const viewClaimTabs = useMemo(() => [
		{ label: "Claim Summary", icon: claimIcon, activeIcon: activeClaimIcon, name: "CLAIM_SUMMARY", component: <AppClaimSummary />, accessible: true },
		{ label: "Claim History", icon: clockIcon, activeIcon: activeClockIcon, name: "CLAIM_HISTORY", component: <AppClaimHistory />, accessible: true },
		{ label: "Pending Claim", icon: claimApprovalIcon, activeIcon: activeClaimApprovalIcon, name: "PENDING_CLAIM", component: <AppPendingClaim />, accessible: true },
	], []);

	return (
		<div className="page-view-claim ">
			<div className="view-claim">
				<div className="view-claim__header">
					<h1 className="view-claim__title">My Claims</h1>

					<div className="view-claim__add-button">
						<AppButton type="button" label="New Claim" icon={addIcon} onClick={onHandleClaimManagement} />
					</div>
				</div>

				<div className="view-claim__body">
					<AppTabs tabs={viewClaimTabs} />
				</div>
			</div>

			<AppClaimSummaryModal ref={appClaimManagementModalRef} />
		</div>
	);
};

export default PageViewClaims;
