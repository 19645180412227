import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { promptAlertMessage } from "store/slices/alert";
import { useNavigate, useParams } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";

import serveRequestErrors from "common/serve-request-errors";
import formatCurrencyPattern from "common/format-currency-pattern";

import PAGE from "constants/page";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";

const PageClaimPackageCreateEdit = (props) => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [specialClaimTypes, setSpecialClaimTypes] = useState([]);
	const extraClaimBenefit = useMemo(() => ({ type: "", amount: "", approval: true }), []);

	//prettier-ignore
	const initialValues = useMemo(() => ({
		name: "",
		description: "",
		claimBenefits: [
			{ amount: "", approval: true },
			{ amount: "", approval: true },
			{ amount: "", approval: true },
			{ amount: "", approval: true },
		],
		extraClaimBenefits: []
	}), []);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			claimBenefits: yup.array().of(yup.object({ amount: yup.string().required(ERRORS.REQUIRED) })),
			extraClaimBenefits: yup.array().of(
				yup.object({
					amount: yup.string().when("type", {
						is: (type) => !!type,
						then: () => yup.string().required(ERRORS.REQUIRED)
					})
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isDisabled = useMemo(() => formik.isSubmitting, [formik.isSubmitting]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const title = useMemo(() => (isCreate ? "Add Claim Package" : "Edit Claim Package"), [isCreate]);

	const claimBenefitsField = useMemo(() => [{ label: "Medical Claim" }, { label: "Transport Claim" }, { label: "OverTime Claim" }, { label: "Petrol Claim" }], []);

	const onHandleClaimBenefitsPayload = (claims) => {
		//prettier-ignore
		const payload = claims.map((o) => {
			if (!o.type) return null;

			return {
				claimType: o.type,
				value: parseFloat(o.amount.replace(/,/g,'')),
				approvalFlag: o.approval,
			};
		});

		return payload;
	};

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				description: values.description,
				companyBenefitClaims: onHandleClaimBenefitsPayload([...values.claimBenefits, ...values.extraClaimBenefits])
			};

			if (isCreate) {
				// await api.post.benefits.create(payload);
			} else {
				payload.id = id;
				// await api.post.benefits.update(payload);
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);

			dispatch(promptAlertMessage({ message: isCreate ? "Claim Benefit has been created successfully" : "Claim Benefit has been updated successfully" }));
		}
	};

	//prettier-ignore
	const onHandleSetApproval = useCallback((obj) => {
		formik.setFieldValue(obj.name, !obj.value);
	}, [formik]);

	//prettier-ignore
	const onHandleAddExtraBenefit = useCallback((arrayHelpers, data) => {
        arrayHelpers.push(data);
    }, []);

	//prettier-ignore
	const onHandleRemoveExtraBenefit = useCallback((arrayHelpers, index) => {
        arrayHelpers.remove(index);
    }, []);

	//prettier-ignore
	const onHandleGetBenefitOptions = useCallback((v, extraBenefits, list) => {
		let options = [];
		const extraBenefitTypes = extraBenefits.map(o => o.type)
		
		options = list.filter(o => !(extraBenefitTypes.includes(o.value) && o.value !== v));

		return options;
	}, []);

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const FeatureBenefitInput = useCallback((obj) => {
		
		const onHandleKeyDown = (event) => {
			const cursorPosition = event.target.selectionEnd;
			const value = event.target.value;
			
			if(cursorPosition !== value.length) {
				event.target.selectionEnd = value.length;
				event.target.selectionStart = value.length;
				event.preventDefault();
			}

			if (event.key.length === 1 && !event.key.match(/\d/i)) {
				event.preventDefault();
			}
		}
		
		return (
			<div className="claim-package-create-edit__input">
				<div className="claim-package-create-edit__input-header">
					<label className="app-input__label" htmlFor={obj.name}>{obj.label}
						<span className="app-input__required">*</span>
					</label>
					
					<button type="button" className="claim-package-create-edit__remove-button" disabled={isDisabled} onClick={obj.onRemoveExtraBenefit}>
						<img src={removeIcon} alt="remove-extra-benefit" />
					</button>
				</div>

				{obj.amount && <AppSelectInput required type="text" label= "Quantity Per Month" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onKeyDown={onHandleKeyDown} onFormat={formatCurrencyPattern} />}
			
				{obj.amount && <AppInput required type="text" label= "Limit per Claim" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onFormat={formatCurrencyPattern} />}

				{obj.amount && <AppInput required type="text" label= "Limit Per Month" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onFormat={formatCurrencyPattern} />}

				<div className="claim-package-create-edit__input-switch">
					<p className="claim-package-create-edit__input-label">Needs Attachment</p>
					<Switch checked={obj.approval.value} onChange={() => obj.approval.onChange(obj.approval)} disabled={isDisabled} />
				
					<p className="claim-package-create-edit__input-label">Approval</p>
					<Switch checked={obj.approval.value} onChange={() => obj.approval.onChange(obj.approval)} disabled={isDisabled} />
				</div>
			</div>
		);
	}, [isDisabled]);

	//prettier-ignore
	const FeatureClaimBenefitInput = useCallback((obj) => {
		const values = obj.values;
		const options = onHandleGetBenefitOptions(obj.types.value, values, specialClaimTypes);

		return (
			<div className="claim-package-create-edit__input">
				<div className="claim-package-create-edit__input-header">
					<p className="claim-package-create-edit__category">New Claim</p>

					<div className="claim-package-create-edit__input-switch">
						<p className="claim-package-create-edit__input-label">Needs Attachment</p>

						<Switch checked={obj.approval.value} onChange={() => obj.approval.onChange(obj.approval)} disabled={isDisabled} />
					
						<p className="claim-package-create-edit__input-label">Approval</p>

						<Switch checked={obj.approval.value} disabled={isDisabled} onChange={() => obj.approval.onChange(obj.approval)} />
					
						<button type="button" className="claim-package-create-edit__remove-button" disabled={isDisabled} onClick={obj.onRemoveExtraBenefit}>
							<img src={removeIcon} alt="remove-extra-benefit" />
						</button>
					</div>
				</div>

				<AppSelectInput required type="text" label="Claim Type" name={obj.types.name} placeholder="Please Select" value={obj.types.value} error={obj.types.error} touched={obj.types.touched} options={options} disabled={isDisabled} onChange={obj.types.onChange} />

				<AppSelectInput required type="text" label= "Quantity Per Month" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onFormat={formatCurrencyPattern} />

				<AppInput required type="text" label="Limit per Month" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onFormat={formatCurrencyPattern} />
				
				<AppInput required type="text" label="Limit per CLaim" name={obj.amount.name} placeholder="Eg: 1000" value={obj.amount.value} error={obj.amount.error} touched={obj.amount.touched} disabled={isDisabled} onChange={obj.amount.onChange} onFormat={formatCurrencyPattern} />
			</div>
		);
	}, [onHandleGetBenefitOptions, isDisabled, specialClaimTypes]);

	useEffect(() => {
		setSpecialClaimTypes([]);

		return () => {
			cancelRequest();
		};
	}, [cancelRequest]);

	return (
		<FormikProvider value={formik}>
			<div className="page-claim-package-create-edit">
				<div className="claim-package-create-edit">
					<h1 className="claim-package-create-edit__title">{title}</h1>

					<form className="claim-package-create-edit__form" onSubmit={formik.handleSubmit}>
						<div className="claim-package-create-edit__container">
							<div className="claim-package-create-edit__box">
								<div className="claim-package-create-edit__wrapper">
									<div className="claim-package-create-edit__box-body">
										{/* prettier-ignore */}
										<AppInput required type="text" name="name" label="Renumeration Package Name" placeholder="e.g. Package A" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />

										{/*prettier-ignore*/}
										<AppInput multiline maxLength={5000} type="text" name="description" label="Description" placeholder="Please describe this benefit package" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={isDisabled} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="claim-package-create-edit__container">
							<div className="claim-package-create-edit__box">
								<div className="claim-package-create-edit__wrapper">
									<div className="claim-package-create-edit__box-body">
										{/* prettier-ignore */}
										<AppInput required type="text" name="employedFor" label="Employed for" placeholder="e.g. 3 Years" value={formik.values.employedFor} error={formik.errors.name} touched={formik.touched.name} disabled={isDisabled} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<FieldArray
							name="extraClaimBenefits"
							render={(arrayHelpers) => {
								const errors = formik.errors;
								const touched = formik.touched;
								const claimBenefits = formik.values.claimBenefits;
								const extraClaimBenefits = formik.values.extraClaimBenefits;

								return (
									<div className="claim-package-create-edit__container">
										<div className="claim-package-create-edit__box">
											<div className="claim-package-create-edit__header">
												<p className="claim-package-create-edit__input-title">Claim Benefit</p>

												<AppButton type="button" label="Add Claim" icon={addIcon} onClick={() => onHandleAddExtraBenefit(arrayHelpers, extraClaimBenefit)} />
											</div>

											<div className="claim-package-create-edit__wrapper">
												<div className="claim-package-create-edit__box-body">
													{claimBenefitsField.map((a, i) => {
														const amount = {
															name: `claimBenefits.${i}.amount`,
															value: claimBenefits[i]["amount"],
															error: errors.claimBenefits?.[i]?.["amount"],
															touched: touched.claimBenefits?.[i]?.["amount"],
															onChange: formik.handleChange
														};
														const approval = {
															name: `claimBenefits.${i}.approval`,
															value: claimBenefits[i]["approval"],
															onChange: onHandleSetApproval
														};

														return <FeatureBenefitInput key={i} {...a} amount={amount} approval={approval} />;
													})}
												</div>
											</div>

											{!!extraClaimBenefits.length && (
												<Fragment>
													<div className="claim-package-create-edit__divider" />

													<div className="claim-package-create-edit__extra-benefit">
														<div className="claim-package-create-edit__box-body">
															{extraClaimBenefits?.map((a, i) => {
																const onRemoveExtraBenefit = () => onHandleRemoveExtraBenefit(arrayHelpers, i);
																const types = {
																	name: `extraClaimBenefits.${i}.type`,
																	value: a["type"],
																	onChange: formik.handleChange
																};
																const amount = {
																	name: `extraClaimBenefits.${i}.amount`,
																	value: a["amount"],
																	error: errors.extraClaimBenefits?.[i]?.["amount"],
																	touched: touched.extraClaimBenefits?.[i]?.["amount"],
																	onChange: formik.handleChange
																};
																const approval = {
																	name: `extraClaimBenefits.${i}.approval`,
																	value: a["approval"],
																	onChange: onHandleSetApproval
																};

																return <FeatureClaimBenefitInput key={i} {...a} types={types} amount={amount} approval={approval} values={extraClaimBenefits} onRemoveExtraBenefit={onRemoveExtraBenefit} />;
															})}
														</div>
													</div>
												</Fragment>
											)}
										</div>
									</div>
								);
							}}
						/>

						<div className="claim-package-create-edit__button-container">
							<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />

							<AppButton type="submit" disabled={isDisabled} label="Update" />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default memo(PageClaimPackageCreateEdit);

PageClaimPackageCreateEdit.propTypes = {
	onHandleCancelRequest: PropTypes.func
};
