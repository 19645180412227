import React, { useCallback, useMemo, useRef } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import PAGE from "constants/page";
import ROLES from "constants/roles";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const renumerationData = [{ paginationNumber: 1, name: "Leave Package A", description: "High-level management package" }];

const AppLeavePackagesTable = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tab: "LEAVE_PACKAGES" });
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const onHandleEdit = () => {
		navigate(pathnames.humanResources.leavePackageCreateEdit + PAGE.EDIT);
	};

	const onHandleCreate = () => {
		navigate(pathnames.humanResources.leavePackageCreateEdit + PAGE.CREATE);
	};

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;
	}, []);

	const tableColumns = [
		{
			name: "paginationNumber",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Package Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "description",
			label: "Description",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={onHandleEdit}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	];

	return (
		<div className="app-leave-packages-table">
			<div className="leave-packages-table">
				{/* prettier-ignore */}
				<AppTableFilterHeader searchPlaceholder="Search by Package Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreate} />

				<div className="leave-packages-table__body">
					<AppTable data={renumerationData} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default AppLeavePackagesTable;
