import React, { createContext, memo, useCallback, useContext, useMemo, useRef, useState } from "react";

import { isArray } from "lodash";
import { AxiosContext } from "contexts/with-interceptor-provider";

import AppHeader from "components/app-header";
import AppSideNav from "components/app-side-nav";
import AppLayoutAlert from "components/app-layout-alert";
import AppRouteBreadCrumb from "components/app-route-breadcrumb";

export const AppLayoutContext = createContext();

const AppLayout = ({ children }) => {
	const props = useContext(AxiosContext);
	const sideNavRef = useRef();
	const [breadCrumb, setBreadCrumb] = useState([]);
	const [title, setTitle] = useState("");

	const onHandleSetTitle = useCallback((text) => {
		setTitle(text);
	}, []);

	const onHandleSetBreadcrumb = useCallback((value) => {
		let nextValues = [];

		if (isArray(value)) nextValues = value;

		setBreadCrumb(nextValues);
	}, []);

	const onHandleToggleSideNav = useCallback(() => {
		sideNavRef.current.onHandleToggleSideNav(sideNavRef.current.activeIndex);
	}, []);

	const contexts = useMemo(() => ({ onHandleSetBreadcrumb }), [onHandleSetBreadcrumb]);

	return (
		<div className="app-layout">
			<main className="main">
				<AppSideNav ref={sideNavRef} onHandleSetTitle={onHandleSetTitle} />

				<div id="layout-container" className="main__container">
					<AppHeader title={title} onHandleToggleSideNav={onHandleToggleSideNav} />

					<AppLayoutAlert />

					<div className="main__page">
						<AppRouteBreadCrumb data={breadCrumb} />

						<AppLayoutContext.Provider value={contexts}>{React.cloneElement(children, { ...props, onHandleSetBreadcrumb })}</AppLayoutContext.Provider>
					</div>
				</div>
			</main>
		</div>
	);
};

export default memo(AppLayout);
