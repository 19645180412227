import React, { useCallback, useRef } from "react";

import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import AppTable from "components/app-table/app-table";

import eyeIcon from "assets/images/eye-open-icon.png";

const claimData = [{ paginationNumbers: 1, name: "Giorno Giovanna", employeeId: "MCE0002-24" }];

const AppClaimDirectoryTable = () => {
	const selectedItem = useRef();
	const navigate = useNavigate();

	const onViewClaims = useCallback(() => {
		navigate(pathnames.humanResources.viewClaims, { state: { ...selectedItem.current } });
	}, [navigate]);

	const tableColumns = [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action">
							<img src={eyeIcon} alt="expand-icon" onClick={onViewClaims} />
						</button>
					);
				}
			}
		}
	];

	return (
		<div className="app-claim-directory-table">
			<div className="claim-directory-table">
				<div className="claim-directory-table__body">
					<AppTable data={claimData} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default AppClaimDirectoryTable;
