import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import formatCurrencyPattern from "common/format-currency-pattern";

import ERRORS from "constants/errors";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppQuotationAddItemModal = (props, ref) => {
	const createSubItem = useRef(false);
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = {
			title: "ABC",
			description: "As per ducting layout with compressed air blade with a minimum of 180 PSI of air pressure and 185 CFM of air velocity to break up debris, dust, clump, mold, and contamination inside the duct. *No brush or any mechanical method will be used to reduce the chance of CROSS-CONTAMINATION. *The Air-Knife Nozzle is able to traverse vertically to ensure that vertical risers will be properly cleaned as well.  *The system have an effectiveness range of a minimum of 100 ft, therefore only a minimum access doors will be required.",
			serviceId: "ABC",
			serviceType: "ABC",
			quantity: "1000",
			price: "1000",
			unit: "1000",
			discount: "1",
			amount: "10000"
		};

		return values;
	}, []);

	const defaultServiceIdOption = useRef();

	const onHandleSubmit = (values) => {
		if (createSubItem.current) {
			props.onConfirmSubItem(values);
		} else {
			props.onConfirm(values);
		}

		onHandleDismiss();
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			unit: yup.string().required(ERRORS.REQUIRED),
			title: yup.string().required(ERRORS.REQUIRED),
			price: yup.string().required(ERRORS.REQUIRED),
			amount: yup.string().required(ERRORS.REQUIRED),
			quantity: yup.string().required(ERRORS.REQUIRED),
			discount: yup.string().required(ERRORS.REQUIRED),
			serviceId: yup.string().required(ERRORS.REQUIRED),
			serviceType: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: onHandleSubmit
	});

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	//prettier-ignore
	const onHandleShowSubItem = useCallback(() => {
		createSubItem.current = true;

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		createSubItem.current = false;

		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleShowSubItem: onHandleShowSubItem,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-quotation-add-item-modal" }} open={visible}>
			<div className="quotation-add-item-modal">
				<button type="button" className="quotation-add-item-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="quotation-add-item-modal__title">Add Item</h1>

				<form className="quotation-add-item-modal__form" onSubmit={formik.handleSubmit}>
					{/* prettier-ignore */}
					<AppInput required type="text" name="title" label="Title" placeholder="Please input title" value={formik.values.title} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput multiline type="textarea" name="description" label="Description" placeholder="Enter description (Optional)" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppSelectInput required pagination type="text" name="serviceId" label="Service ID" placeholder="Search with ID from General Configuration Service Listing" defaultOptions={defaultServiceIdOption.current} options={[]} value={formik.values.serviceId} error={formik.errors.serviceId} touched={formik.touched.serviceId} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppSelectInput required name="serviceType" label="Service Type" placeholder="Select..." options={[]} value={formik.values.serviceType} error={formik.errors.serviceType} touched={formik.touched.serviceType} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required type="number" name="quantity" label="Quantity" placeholder="Please input Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

					<div className="quotation-add-item-modal__row">
						{/* prettier-ignore */}
						<AppInput required type="text" name="price" label="Price Per Unit (MYR)" placeholder="Please input per unit amount" value={formik.values.price} error={formik.errors.price} touched={formik.touched.price} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

						{/* prettier-ignore */}
						<AppInput required type="number" name="unit" label="Unit" placeholder="Please input unit" value={formik.values.unit} error={formik.errors.unit} touched={formik.touched.unit} onChange={formik.handleChange} />
					</div>

					{/* prettier-ignore */}
					<AppInput required type="number" name="discount" label="Discount" placeholder="Please input discount" value={formik.values.discount} error={formik.errors.discount} touched={formik.touched.discount} onChange={formik.handleChange} />

					{/* prettier-ignore */}
					<AppInput required type="text" name="amount" label="Amount (MYR)" placeholder="Please input discount" value={formik.values.amount} error={formik.errors.amount} touched={formik.touched.amount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

					<div className="quotation-add-item-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Add" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppQuotationAddItemModal));

AppQuotationAddItemModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
