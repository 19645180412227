import React, { useMemo } from "react";

import dayjs from "dayjs";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";

import AppStatus from "components/app-status";
import AppTable from "components/app-table/app-table";

const data = [
	{ leaveType: "Medical Claim", days: "1000", startDate: new Date("2023-03-27"), endDate: new Date("2023-03-27"), timeOff: "Half Day", status: STATUS.PENDING },
	{ leaveType: "Parking Claim", days: "500", startDate: new Date("2023-03-27"), endDate: new Date("2023-03-27"), timeOff: "Full Day", status: STATUS.APPROVED },
	{ leaveType: "Overtime Claim", days: "1500", startDate: new Date("2023-03-27"), endDate: new Date("2023-03-27"), timeOff: "Full Day", status: STATUS.PENDING }
];

const AppVIewUpcomingOngoingEmployeeLeaveTable = () => {
	const tableColumns = [
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: false
			}
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "timeOff",
			label: "Time Off",
			options: {
				sort: false
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		}
	];

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null,
	}), []);

	return (
		<div className="app-view-upcoming-ongoing-employee-leave-table">
			<div className="view-upcoming-ongoing-employee-leave-table">
				<div className="view-upcoming-ongoing-employee-leave-table__container">
					<p className="view-upcoming-ongoing-employee-leave-table__label">Upcoming Leave</p>

					<AppTable data={data} columns={tableColumns} options={tableOptions} />
				</div>

				<div className="view-upcoming-ongoing-employee-leave-table__container">
					<p className="view-upcoming-ongoing-employee-leave-table__label">Ongoing Leave</p>

					<AppTable data={data} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default AppVIewUpcomingOngoingEmployeeLeaveTable;
