import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { promptAlertMessage } from "store/slices/alert";
import { useLocation, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

import TIMER from "constants/timer";

import AppButton from "components/app-button";

import logo from "assets/images/logo.png";
import emailSent from "assets/images/pages/forgot-password/email-sent.png";

const PageForgotPasswordEmailSent = () => {
	const dispatch = useDispatch();

	const location = useLocation();

	const navigate = useNavigate();

	const timerInterval = useRef();

	const [timer, setTimer] = useState(TIMER);

	const timerEnd = useMemo(() => timer.minutes <= 0 && timer.seconds <= 0, [timer]);

	const onHandleStartTimer = useCallback(() => {
		timerInterval.current = setInterval(() => {
			setTimer((prev) => {
				if (prev.minutes <= 0 && prev.seconds <= 0) {
					return TIMER;
				}

				const values = {
					...prev,
					minutes: prev.seconds === 0 ? prev.minutes - 1 : prev.minutes,
					seconds: prev.seconds > 0 ? prev.seconds - 1 : 59
				};

				return values;
			});
		}, 1500);
	}, []);

	const onHandleResend = useCallback(async () => {
		onHandleStartTimer();

		let response = null;

		try {
			await api.post.forgotPassword.forgotPassword({ email: location.state.email });

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "An email has been sent" }));
		}
	}, [dispatch, onHandleStartTimer, location.state.email]);

	const onHandleBack = () => {
		navigate(pathnames.login.login);
	};

	useEffect(() => {
		onHandleStartTimer();
	}, [onHandleStartTimer]);

	useEffect(() => {
		if (timerEnd) {
			clearInterval(timerInterval.current);
		}
	}, [timerEnd]);

	useEffect(() => {
		return () => {
			clearInterval(timerInterval.current);
		};
	}, []);

	return (
		<div className="page-forgot-password-email-sent">
			<main className="forgot-password-email-sent">
				<div className="forgot-password-email-sent__logo">
					<img src={logo} alt="logo" />
				</div>

				<h1 className="forgot-password-email-sent__title">Email Sent </h1>

				<p className="forgot-password-email-sent__description">
					An email has been sent to <b>{location.state.email}</b>, please follow the instruction in the email to reset your password.
				</p>

				<div className="forgot-password-email-sent__hero">
					<img src={emailSent} alt="hero" />
				</div>

				<div className="forgot-password-email-sent__button-container">
					<AppButton type="button" label="Back to Log In" onClick={onHandleBack} />
				</div>

				<div className="forgot-password-email-sent__resend-code">
					{!timerEnd && (
						<p className="forgot-password-email-sent__timer">
							Resend Email in {timer.minutes.toString().padStart(2, "0")}:{timer.seconds.toString().padStart(2, "0")}
						</p>
					)}

					{timerEnd && (
						<button type="button" className="forgot-password-email-sent__resend-button" onClick={onHandleResend}>
							Resend Email
						</button>
					)}
				</div>
			</main>
		</div>
	);
};

export default PageForgotPasswordEmailSent;
