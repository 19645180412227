import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import trashIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";
import addIconDisabled from "assets/images/add-icon-disabled.png";

export const AppCreateEditServiceModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.INPUT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = { status: "", input: "", serviceType: "", serviceAction: "", options: [] };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			input: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			serviceType: yup.string().required(ERRORS.REQUIRED),
			serviceAction: yup.string().required(ERRORS.REQUIRED),
			options: yup.array().of(
				yup.object().shape({
					name: yup.string().required(ERRORS.REQUIRED),
					seq: yup.number().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const optionSequenceList = useMemo(() => formik.values.options.length ? formik.values.options.map((o, i) => { return { label: i + 1, value: i }; }) : [{ label: 1, value: 0 }], [formik.values.options]);

	const isCreate = useMemo(() => formik.values.status === PAGE.CREATE, [formik.values.status]);

	//prettier-ignore
	const addButtonClassNames = useMemo(() => classNames({
		"input-create-edit-modal__add": true,
		"input-create-edit-modal__add--disabled": restricted
	}), [restricted]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { name: values.name, type: values.type, options: values.options };

			if (isCreate) {
				await api.post.input.createInput(payload);
			}

			if (!isCreate) {
				payload.id = values.id;

				await api.post.input.updateInput(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Input was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Input was updated successfully!" }));
			}

			props.onHandleGetList();
		}
	}, [formik, isCreate, props, dispatch]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ status: obj?.id ? PAGE.EDIT : PAGE.CREATE, id: obj?.id, name: obj?.name || "", type: obj?.type || "", options: obj?.options || [] });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleOptionSequence = useCallback((event, index, moveOption) => {
		const nextSeq = parseInt(event.target.value);

		moveOption(index, nextSeq);
	}, []);

	//prettier-ignore
	const onHandleAddOption = useCallback(async (renderProps) => {
		const fieldErrors = await renderProps.form.validateForm();

		if (fieldErrors.options) {
			renderProps.form.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		const nextOption = { name: "", seq: formik.values.options.length };

		renderProps.push(nextOption);
	}, [formik]);

	const buttonIcon = useMemo(() => {
		if (restricted) {
			return addIconDisabled;
		} else {
			return addBlueIcon;
		}
	}, [restricted]);

	const InputOptions = useCallback((obj) => {
		return (
			<div className="create-edit-service-modal__options">
				{obj.options.map((o, i) => {
					const firstOption = i === 0;
					const error = obj.optionsErrors?.[i];
					const touched = obj.optionsTouched?.[i];
					const sequenceLabel = firstOption ? "#" : "";
					const inputLabel = firstOption ? "Input" : "";
					const optionLabel = firstOption ? "Sub Task Action" : "";
					const trashClassNames = classNames({ "create-edit-service-modal__delete": true, "create-edit-service-modal__delete--first": firstOption, "create-edit-service-modal__delete--disabled": obj.restricted });

					return (
						<div className="create-edit-service-modal__option" key={i}>
							<AppSelectInput searchable={false} type="number" name={`options[${i}].seq`} label={sequenceLabel} placeholder="" options={obj.optionSequenceList} value={i} onChange={(e) => obj.onHandleOptionSequence(e, i, obj.move)} />

							<AppInput required type="text" name={`options[${i}].name`} label={optionLabel} placeholder="Enter Option Name" value={o.name} error={error?.name} touched={touched} onChange={obj.formik.handleChange} className="custom-app-input" />

							<AppInput required type="text" name={`options[${i}].input`} label={inputLabel} placeholder="Enter Input" value={o.input} error={error?.input} touched={touched} onChange={obj.formik.handleChange} className="custom-app-input" />

							{obj.options.length > 1 && (
								<button type="button" className={trashClassNames} disabled={obj.restricted} onClick={() => obj.remove(i)}>
									<img src={trashIcon} alt="trash-icon" />
								</button>
							)}
						</div>
					);
				})}
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-create-edit-service-modal" }} open={visible}>
			<FormikProvider value={formik}>
				<div className="create-edit-service-modal">
					<h1 className="create-edit-service-modal__title">New Service</h1>

					<FieldArray
						name="options"
						render={(f) => {
							const values = f.form.values?.options;
							const errors = f.form.errors?.options;
							const touched = f.form.touched?.options;

							return (
								<form className="create-edit-service-modal__form" onSubmit={formik.handleSubmit}>
									<div className="create-edit-service-modal__wrapper">
										<div className="create-edit-service-modal__row">
											<AppSelectInput disabled required name="serviceType" label="Service Type" placeholder="Select..." options={[]} value={formik.values.serviceType} error={formik.errors.serviceType} touched={formik.touched.serviceType} onChange={formik.handleChange} />

											<AppSelectInput disabled name="status" label="Status" placeholder="Select..." options={[]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
										</div>

										<div className="create-edit-service-modal__row">
											<AppSelectInput required name="input" label="Input" placeholder="Select..." options={[]} value={formik.values.input} error={formik.errors.input} touched={formik.touched.input} onChange={formik.handleChange} />
										</div>

										<AppInput type="text" name="serviceAction" label="Service Action" placeholder="Enter Service Action" value={formik.values.serviceAction} error={formik.errors.serviceAction} touched={formik.touched.serviceAction} onChange={formik.handleChange} />

										<AppInput multiline type="textarea" name="remarks" label="Service Remarks" placeholder="Enter Service Remarks" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

										<InputOptions restricted={restricted} optionSequenceList={optionSequenceList} formik={formik} options={values} optionsErrors={errors} optionsTouched={touched} remove={f.remove} move={f.move} onHandleOptionSequence={onHandleOptionSequence} />
									</div>

									<div className="create-edit-service-modal__add-service-button">
										<div className={addButtonClassNames}>
											<AppButton outline type="button" label="Add" icon={buttonIcon} disabled={restricted} onClick={() => onHandleAddOption(f)} />
										</div>
									</div>

									<div className="create-edit-service-modal__button-container">
										<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

										<AppButton type="submit" label="Create" disabled={formik.isSubmitting || restricted} onClick={onHandleSubmit} />
									</div>
								</form>
							);
						}}
					/>
				</div>
			</FormikProvider>
		</Modal>
	);
};

export default memo(forwardRef(AppCreateEditServiceModal));

AppCreateEditServiceModal.propTypes = {
	ref: PropTypes.object
};
