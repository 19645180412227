import React, { memo, useCallback, useMemo } from "react";

import { FormControl, FormHelperText } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import classNames from "common/class-names";

import clockIcon from "assets/images/clock-icon.svg";

const AppInputTime = (props) => {
	const className = useMemo(() => {
		return classNames({
			"app-input-time": true,
			"app-input-time--disabled": props.disabled,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className, props.disabled]);

	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);

	const clockIconSvg = useCallback(() => <img src={clockIcon} alt="clockIcon" />, []);

	//prettier-ignore
	const onHandleChange = useCallback((value) => {
        props.onChange(props.name, value);
    }, [props]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				{props.label && (
					<label className="app-input-time__label" htmlFor={props.name}>
						{props.label}
						{props.required && <span className="app-input-time__required">*</span>}
					</label>
				)}

				<TimePicker value={props.value || null} viewRenderers={{ hours: renderTimeViewClock, minutes: renderTimeViewClock, seconds: renderTimeViewClock }} disabled={props.disabled} onChange={onHandleChange} slots={{ openPickerIcon: clockIconSvg }} slotProps={{ textField: { name: props.name, error: isErrorField } }} />

				{isErrorField ? <FormHelperText>{props.error}</FormHelperText> : null}
			</FormControl>
		</div>
	);
};

export default memo(AppInputTime);
