import React, { useCallback, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import DATE_TIME from "constants/date-time";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppViewPendingLeaveModal from "components/pages/human-resources/app-view-pending-leave-modal";
import AppViewEmployeePendingLeaveRejectionModal from "components/pages/human-resources/app-view-employee-pending-leave-rejection-modal";

import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";
import expandIcon from "assets/images/pages/human-resources/expand-icon.svg";

const dummyData = [
	{ employeeId: 1, name: "Ali", days: 0.5, leaveType: "Annual Leave", submitDate: new Date("2023-03-25"), startDate: new Date("2023-03-27"), endDate: new Date("2023-03-27"), timeOff: "Full Day", description: "N/A", rejectReason: "" },
	{ employeeId: 2, name: "Abu", days: 2, leaveType: "Annual Leave", submitDate: new Date("2023-03-25"), startDate: new Date("2023-03-27"), endDate: new Date("2023-03-29"), timeOff: "Half Day", description: "N/A", rejectReason: "" }
];

const AppViewEmployeePendingLeaveTable = () => {
	const pendingLeaveRef = useRef();
	const rejectionRef = useRef();
	const [data, setData] = useState(dummyData);
	const [selectedPending, setSelectedPending] = useState([]);

	const onHandleSelectPending = useCallback((boolean, idNo) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(idNo);
			} else {
				nextPending = nextPending.filter((employeeId) => employeeId !== idNo);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.employeeId);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const onHandleViewPendingLeave = useCallback((obj) => {
		pendingLeaveRef.current.onHandleShow(data[obj.rowIndex]);
	}, [data]);

	//prettier-ignore
	const onHandleRejectReason = useCallback((values) => {
		const updatedData = data.map((item) => {
			if (selectedPending.includes(item.employeeId)) {
				return { ...item, rejectReason: values };
			}

			return item;
		});

		setData(updatedData);
	}, [data, selectedPending]);

	const onHandleRejectLeave = useCallback(() => {
		rejectionRef.current.onHandleShow({ selectedPending });
	}, [selectedPending]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.length > 0 && selectedPending.length === data.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.employeeId;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},	
		{
			name: "leaveType",
			label: "Leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false,
			},
		},	
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY),
			},
		},
		{
			name: "timeOff",
			label: "Time Off",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "attachment",
			label: "Attachment",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleViewPendingLeave(tableMeta)}>
							<img src={expandIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleSelectAll, onHandleSelectPending, onHandleViewPendingLeave, selectedPending]);

	//prettier-ignore
	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="view-employee-pending-leave-table__header-button">
				<div className="view-employee-pending-leave-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} onClick={onHandleRejectLeave} />
				</div>

				<div className="view-employee-pending-leave-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} />
				</div>
			</div>
		);
	}, [onHandleRejectLeave]);

	return (
		<div className="app-view-employee-pending-leave-table">
			<div className="view-employee-pending-leave-table">
				<div className="view-employee-pending-leave-table__header">
					<HeaderApprovalButton selectedPending={selectedPending} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppViewPendingLeaveModal ref={pendingLeaveRef} />

			<AppViewEmployeePendingLeaveRejectionModal ref={rejectionRef} onConfirm={onHandleRejectReason} />
		</div>
	);
};

export default AppViewEmployeePendingLeaveTable;
