import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import ROLES from "constants/roles";
import classNames from "common/class-names";
import ENDPOINT_PATH from "constants/end-point-path";
import validateFileSize from "common/validate-file-size";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import AppTabs from "components/app-tabs";
import AppEditUserInformation from "components/pages/operations/user-access/app-edit-user-information";
import AppEditUserResetPassword from "components/pages/operations/user-access/app-edit-user-reset-password";

import camera from "assets/images/camera-icon.png";
import personIcon from "assets/images/person-icon.svg";
import personActiveIcon from "assets/images/person-active-icon.svg";
import passLockIcon from "assets/images/pages/user/pass-lock-icon.svg";
import defaultProfilePicture from "assets/images/default-profile-picture.png";
import passLockActiveIcon from "assets/images/pages/user/pass-lock-active-icon.svg";

const PageUserAccessEditUser = (props) => {
	const dispatch = useDispatch();
	const tabRef = useRef();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.USER_ACCESS], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const uploadImageRef = useRef();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { id } = useParams();
	const [user, setUser] = useState();

	const cameraClassName = useMemo(() => {
		return classNames({
			"user-access-edit-user__camera": true,
			"user-access-edit-user__camera--disabled": restricted
		});
	}, [restricted]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.userAccess + "/?tab=USER_LISTING" },
			{ label: "General Configuration", path: pathnames.operations.userAccess + "/?tab=USER_LISTING" },
			{ label: `${user?.userId} ${user?.fullName}`, path: pathnames.operations.userAccessEditUser + id + "/?tab=INFORMATION" }
		];
		return data;
	}, [id, user]);

	useBreadcrumb({ breadCrumb });

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.userAccess.user(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setUser({
				id: response.id,
				avatar: response.avatar,
				fullName: response.fullName,
				userId: response.userId,
				roles: response.roleIds,
				status: response.status,
				prefixNo: response.mobileNoPrefix,
				mobileNo: response.mobileNo,
				email: response.email,
			});
		}
	}, []);

	// prettier-ignore
	const onHandleCheckRoles = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.general.roles();
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const isSuperAdmin = response.filter((o) => o.code === ROLES.SUPER_ADMINISTRATOR && user?.roles?.[0] === o.id);

			setIsSuperAdmin(!!isSuperAdmin.length);
		}
	}, [user?.roles]);

	// prettier-ignore
	const onHandleChangeAvatar = useCallback(async (image) => {
		if (isSubmitting) return;

		let response = null;

		try {
			const payload = {
				id: id,
				file: image,
			};

			await api.post.userAccess.updateAvatar(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Profile picture was updated successfully!" }));
		}
	}, [isSubmitting, id, dispatch]);

	const onHandleChangeProfile = useCallback(() => {
		if (restricted) return;

		uploadImageRef.current.click();
	}, [restricted]);

	// prettier-ignore
	const onHandleChange = useCallback((event) => {
		let isValidFileSize = false;
		const file = event.target.files[0];

		if (file) {
			isValidFileSize = validateFileSize(file);
		}

		if (file && isValidFileSize) {
			const image = URL.createObjectURL(file);
			onHandleChangeAvatar(image);
		}
	}, [onHandleChangeAvatar]);

	// prettier-ignore
	const editUserTabs = useMemo(() => {
		const data =  [
			{ label: "Information", icon: personIcon, activeIcon: personActiveIcon, name: "INFORMATION", component: <AppEditUserInformation user={user} isSuperAdmin={isSuperAdmin} />, accessible: profile?.permissions?.[ROLES.USER_ACCESS]?.view },
			{ label: "Reset Password", icon: passLockIcon, activeIcon: passLockActiveIcon, name: "RESET_PASSWORD", component: <AppEditUserResetPassword user={user} isSuperAdmin={isSuperAdmin} />, accessible: profile?.permissions?.[ROLES.USER_ACCESS]?.view },
		];

		return data.filter(o => o.accessible);
	}, [isSuperAdmin, profile?.permissions, user]);

	useEffect(() => {
		onHandleCheckRoles();

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.GENERAL.ROLES);
		};
	}, [id, memoCancelRequest, onHandleCheckRoles]);

	useEffect(() => {
		onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.USER_ACCESS.USER);
		};
	}, [id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-user-access-edit-user">
			<div className="user-access-edit-user">
				<div className="user-access-edit-user__details">
					<div className="user-access-edit-user__image">
						<img className="user-access-edit-user__picture" src={user?.avatar || defaultProfilePicture} alt="profile" />

						<button className={cameraClassName} disabled={restricted}>
							<img className="user-access-edit-user__icon" src={camera} alt="camera" onClick={onHandleChangeProfile} />
						</button>
					</div>

					<div className="user-access-edit-user__name">{user?.fullName}</div>
				</div>

				<div className="user-access-edit-user__body">
					<AppTabs ref={tabRef} tabs={editUserTabs} />
				</div>
			</div>

			<input type="file" hidden ref={uploadImageRef} name="avatart" onChange={onHandleChange} accept="image/png, image/jpeg, image/jpg" />
		</div>
	);
};

export default PageUserAccessEditUser;
