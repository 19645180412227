import COMMON from "common";
import alertSlice from "store/slices/alert";
import storage from "redux-persist/lib/storage";
import profileSlice from "store/slices/profile";
import layoutAlertSlice from "store/slices/layout-alert";
import versionAlertSlice from "store/slices/version-alert";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
	key: COMMON.REDUX_PRESIST_KEY,
	stateReconciler: autoMergeLevel2,
	storage
};

const dev = process.env.NODE_ENV === "development";
const middlewares = [];

if (dev) {
	const { createLogger } = require("redux-logger");
	const reduxLogger = createLogger({ collapsed: true });
	middlewares.push(reduxLogger);
}

const reducers = {
	alert: alertSlice,
	layoutAlert: layoutAlertSlice,
	profile: profileSlice,
	versionAlert: versionAlertSlice
};

const combinedReducers = combineReducers(reducers);

const rootReducer = (state, action) => {
	if (action.type === COMMON.REDUX_ACTION.LOGOUT) state = undefined;
	return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
});

export const persistor = persistStore(store);

export default store;
