const capitalizeCharacter = (string = "") => {
	const s = string.toLowerCase();

	return s
		.split(" ")
		.map((o) => o.charAt(0).toUpperCase() + o.slice(1))
		.join(" ");
};

export default capitalizeCharacter;
