const DATE_TIME = {
	DD_MM_YYYY: "DD-MM-YYYY",
	YYYY_MM_DD: "YYYY-MM-DD",
	DD_MM_YYYY_HH_MM_A: "DD/MM/YYYY HH:MM A",
	YYYY_MM_DD_HH_MM_SS: "YYYY-MM-DD HH:mm:ss",
	LAST_UPDATED_BY_DATE: "DD/MM/YYYY, HH:MM A",
	CREATED_BY_DATE: "DD/MM/YYYY"
};

export default DATE_TIME;
