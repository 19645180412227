// TODO: Replace dummy data with API data/redux store
import defaultPfp from "assets/images/default-profile-picture.png";

export const pageActivityLogsTableData = [
	{
		number: 1,
		activityId: "ACT0000001-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "User Access",
		details: "Added a New Role",
		date: "2022-09-05:21:11:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 2,
		activityId: "ACT0000002-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Notification",
		details: "Scheduled New Notification",
		date: "2022-09-14:10:40:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 3,
		activityId: "ACT0000003-23",
		user: "MCE0002-24 Vivienne Tang Jia Yi",
		role: "Engineer",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Updated Existing Asset GC-ASS000001",
		date: "2022-09-14:09:40:00:00",
		view: true,
		action: "edit",
		extraData: {
			previousData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN U-S Fan",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			},
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology and hydroxyl radicals. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour*.",
				remarks: "Need Wifi Connection to Operate",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 4,
		activityId: "ACT0000004-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Deleted Spare Part GC-SP0000001 to Asset GC-ASS000001",
		date: "2023-05-09:11:00:00:00",
		view: false,
		action: "delete"
	},
	{
		number: 5,
		activityId: "ACT0000005-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Human Resources",
		details: "Applied for Annual Leave on 15/9/2023",
		date: "2022-09-14:10:20:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 6,
		activityId: "ACT0000006-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Updated Existing Service Checklist GC-SCL000001",
		date: "2022-09-14:14:30:00:00",
		view: true,
		action: "edit",
		extraData: {
			previousData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN U-S Fan",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			},
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology and hydroxyl radicals. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour*.",
				remarks: "Need Wifi Connection to Operate",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 7,
		activityId: "ACT0000007-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Customer List",
		details: "Assigned Asset GC-ASS000001 to MCE0001-23 to Contract CONC0001-23",
		date: "2022-09-14:10:00:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 8,
		activityId: "ACT0000008-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Customer List",
		details: "Terminated Existing Contract CONC0001-22",
		date: "2022-09-05:11:00:00:00",
		view: false,
		action: "delete"
	},
	{
		number: 9,
		activityId: "ACT0000009-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "User Access",
		details: "Added a New Role",
		date: "2022-09-05:11:00:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 10,
		activityId: "ACT0000010-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Notification",
		details: "Scheduled New Notification",
		date: "2022-09-14:10:40:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 11,
		activityId: "ACT0000011-23",
		user: "MCE0002-24 Vivienne Tang Jia Yi",
		role: "Engineer",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Updated Existing Asset GC-ASS000001",
		date: "2022-09-14:09:40:00:00",
		view: true,
		action: "edit",
		extraData: {
			previousData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN U-S Fan",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			},
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology and hydroxyl radicals. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour*.",
				remarks: "Need Wifi Connection to Operate",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 12,
		activityId: "ACT0000012-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Deleted Spare Part GC-SP0000001 to Asset GC-ASS000001",
		date: "2023-05-09:11:00:00:00",
		view: false,
		action: "delete"
	},
	{
		number: 13,
		activityId: "ACT0000013-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Deleted Spare Part GC-SP0000001 to Asset GC-ASS000001",
		date: "2023-05-08:00:00:00:00",
		view: false,
		action: "delete"
	},
	{
		number: 14,
		activityId: "ACT0000014-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "General Configuration",
		details: "Updated Existing Service Checklist GC-SCL000001",
		date: "2022-09-14:14:30:00:00",
		view: true,
		action: "edit",
		extraData: {
			previousData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN U-S Fan",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			},
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology and hydroxyl radicals. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour*.",
				remarks: "Need Wifi Connection to Operate",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 15,
		activityId: "ACT0000015-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Customer List",
		details: "Assigned Asset GC-ASS000001 to MCE0001-23 to Contract CONC0001-23",
		date: "2022-09-14:10:00:00:00",
		view: true,
		action: "add",
		extraData: {
			currentData: {
				assetName: "Panasonic X-Premium Inverter XU Series ZKH-1",
				assetAlias: "PAN X-PREM AC",
				brand: "Panasonic",
				product: "X-Premium Inverter XU Series",
				model: "ZKH-1",
				description: "Has nanoe™ X technology with the benefits of hydroxyl radicals effectively inhibits adhered and airborne pollutants while reducing frequently encountered odours for clean and fresh air. nanoe™ X mode can be switched ON independently (without cooling operation) with low energy consumption at 25W/hour* to continually purify your living spaces, providing a cleaner and comfortable space for you and your loved ones - making your home a safe haven 24/7.",
				remarks: "Has built in Wifi and controlled using Panasonic Comfort Cloud App",
				sparePartsLinked: "GC-SP0000001 Kobei Narrow Bando Belt, GC-SP0000002 Panasonic Copper Coil Large, GC-SP0000002 York Fan Motor 220-240v 8P 555W, GC-SP0000001 Bitzer Semi Hermetic Condensing Unit"
			}
		}
	},
	{
		number: 16,
		activityId: "ACT0000016-23",
		user: "ADM0001 Ahmad Muhammad Ali",
		role: "Admin",
		profilePic: defaultPfp,
		activityType: "Customer List",
		details: "Terminated Existing Contract CONC0001-22",
		date: "2022-09-05:11:00:00:00",
		view: false,
		action: "delete"
	}
];
