import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

const AppDeleteServiceListingModal = forwardRef((props, ref) => {
	const [data, setData] = useState(null);
	const [visible, setVisible] = useState(false);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,

		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-delete-service-listing-modal" }} open={visible}>
			<div className="delete-service-listing-modal">
				<button type="button" className="delete-service-listing-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="delete-service-listing-modal__title">Are you sure?</h1>

				<p className="delete-service-listing-modal__description">
					After clicking confirm, the service <b>{data?.serviceId}</b> will be deleted.
				</p>

				<div className="delete-service-listing-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
});

AppDeleteServiceListingModal.propTypes = {
	onConfirm: PropTypes.func.isRequired
};

export default memo(AppDeleteServiceListingModal);
