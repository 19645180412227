import React, { useCallback, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppTeamPicCreateEditModal from "components/pages/work-completion-report/app-team-pic-create-edit-modal";

import addIcon from "assets/images/add-icon.png";
import deleteIcon from "assets/images/trash-icon.png";
import searchIcon from "assets/images/search-icon.png";

const teamPicData = [{ paginationNumbers: 1, id: "MC-001", name: "Henry" }];

const AppWorkCompletionReportTeamPicTable = () => {
	const teamPicRef = useRef();
	const [data, setData] = useState(teamPicData);
	const [searchParams] = useSearchParams();
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "", tabs: "REPORT_DETAILS" });

	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleAddReportDetailTeamPic = useCallback(() => {
		teamPicRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const onHandleGetList = useCallback((values) => {
		const nextItems = [...data, ...values];

		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const onHandleDeleteTeamPic = useCallback((obj) => {
		let nextItems = [...data];
		nextItems = nextItems.filter((_, i) => i !== obj.rowIndex);

		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "delete",
			label: "Delete",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__delete" onClick={() => onHandleDeleteTeamPic(tableMeta)}>
							<img src={deleteIcon} alt="delete-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleDeleteTeamPic]);

	return (
		<div className="app-work-completion-report-team-pic-table">
			<div className="work-completion-report-team-pic-table">
				<p className="work-completion-report-team-pic-table__label">Team Members</p>

				<div className="work-completion-report-team-pic-table__header">
					<div className="work-completion-report-team-pic-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.value} onChange={onHandleDebounceSearch} onBlur={() => {}} />
					</div>

					<div className="work-completion-report-team-pic-table__create-button">
						<AppButton outline type="button" label="Add Team Members" icon={addIcon} onClick={onHandleAddReportDetailTeamPic} />
					</div>
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppTeamPicCreateEditModal ref={teamPicRef} onConfirm={onHandleGetList} />
		</div>
	);
};

export default AppWorkCompletionReportTeamPicTable;
