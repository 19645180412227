import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

const temp = [{ paginationNumbers: 1, name: "Kiryu Kazuma", id: "MCE0001-24" }];

export const AppSiteDetailsCreateEditTeamMembersModal = (props, ref) => {
	const [data, setData] = useState(temp);
	const [searchParams] = useSearchParams();
	const [visible, setVisible] = useState(false);
	const [selectedServices, setSelectedServices] = useState([]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "" });

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleAddTeamMember = useCallback((boolean, obj) => {
		let nextId = [...selectedServices];

		const index = nextId.findIndex((o) => o.id === obj.id);

		if (!boolean && index > -1) {
			nextId = nextId.filter((o) => o.id !== obj.id);
		} else {
			nextId.push(obj);
		}

		setSelectedServices(nextId);
	}, [selectedServices]);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;
	}, []);

	const onHandleSubmit = useCallback(() => {
		props.onConfirm(selectedServices);

		setVisible(false);
	}, [props, selectedServices]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = data[rowIndex];
					const selected = selectedServices.findIndex((o) => o.id === obj.id) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleAddTeamMember(v, obj)} value={selected} />;
				}
			}
		}
	], [data, selectedServices, onHandleAddTeamMember]);

	useEffect(() => {
		setData(temp);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-site-details-create-edit-team-members-modal" }} open={visible}>
			<div className="site-details-create-edit-team-members-modal">
				<h1 className="site-details-create-edit-team-members-modal__title">Add Team Member</h1>

				<form className="site-details-create-edit-team-members-modal__form" onSubmit={onHandleSubmit}>
					<p className="site-details-create-edit-team-members-modal__description">Select Employees from Human Resource Module</p>

					<AppTableFilterHeader searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

					<AppTable data={data} columns={tableColumns} />

					<div className="site-details-create-edit-team-members-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Add" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppSiteDetailsCreateEditTeamMembersModal));

AppSiteDetailsCreateEditTeamMembersModal.propTypes = {
	ref: PropTypes.object
};
