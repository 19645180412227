import React, { useCallback, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import useBreadcrumb from "hooks/use-breadcrumb";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppHolidaysCreateEditModal from "components/pages/human-resources/app-hr-view-holidays-create-edit-modal";

import addIcon from "assets/images/add-icon.png";
import editIcon from "assets/images/edit-icon.png";
import searchIcon from "assets/images/search-icon.png";
import closeIcon from "assets/images/add-blue-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const holidayData = [{ paginationNumbers: 1, id: 1, name: "Christmas", date: "12/12/2001", description: "description", edit: "" }];

const PageHrViewHolidays = () => {
	const holidayRef = useRef();
	const selectedItem = useRef();
	const [searchParams] = useSearchParams();
	const [holidayAnchor, setHolidayAnchor] = useState(null);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", sort: searchParams.get("sort") || "" });
	const [data, setData] = useState(holidayData);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Human Resources", path: pathnames.humanResources.humanResources },
			{ label: "Leave Management", path: pathnames.humanResources.leaveManagement },
			{ label: "Configure Holidays", path: pathnames.humanResources.hrViewHolidays }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const onHandleGetList = useCallback((values) => {
		let nextItems = [...data];

		if (!selectedItem.current) {
			const newData = { paginationNumbers: data.length + 1, ...values };
			nextItems.push(newData);
		} else {
			const indexToUpdate = selectedItem.current.rowIndex;

			nextItems[indexToUpdate] = { ...nextItems[indexToUpdate], ...values };
		}
		
		setData(nextItems);
	}, [data]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleCloseHolidayTableMenu = useCallback(() => {
		selectedItem.current = null;
		setHolidayAnchor(null);
	}, []);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setHolidayAnchor(event.currentTarget);
	}, [data]);

	//prettier-ignore
	const onHandleEditHoliday = useCallback(() => {
		holidayRef.current.onHandleShow({ ...selectedItem.current });
	}, []);

	const onHandleAddHoliday = useCallback(() => {
		holidayRef.current.onHandleShow();
	}, []);

	const onHandleDeleteHoliday = useCallback(() => {
		let nextItems = [...data];
		nextItems = nextItems.filter((_, i) => i !== selectedItem.current.rowIndex);
		setData(nextItems);
		setHolidayAnchor(null);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "date",
			label: "Date",
			options: {
			  sort: true,
			  sortThirdClickReset: true,
			  customBodyRender: (value) => value.split(" ").join(" - "),
			},
		  },
		{
			name: "description",
			label: "Description",
			options: {
				sort: false,
			},
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onToggleTableAction]);

	return (
		<div className="page-hr-view-holidays">
			<div className="hr-view-holidays">
				<h1 className="hr-view-holidays__title">Holidays</h1>

				<div className="hr-view-holidays__header">
					<div className="hr-view-holidays__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.value} onChange={onHandleDebounceSearch} />
					</div>

					<div className="hr-view-holidays__create-button">
						<AppButton type="button" label="Add Holiday" icon={addIcon} onClick={onHandleAddHoliday} />
					</div>
				</div>

				<div className="hr-view-holidays__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "hr-view-holidays-table-menu" }} anchorEl={holidayAnchor} open={!!holidayAnchor} onClose={onHandleCloseHolidayTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditHoliday}><img src={editIcon} alt="hr-view-holidays-edit"/>Edit</MenuItem>
				<MenuItem onClick={onHandleDeleteHoliday}><img className="hr-view-holidays-table-menu__close-icon" src={closeIcon} alt="hr-view-holidays-close"/>Delete</MenuItem>
			</Menu>

			<AppHolidaysCreateEditModal ref={holidayRef} onConfirm={onHandleGetList} />
		</div>
	);
};

export default PageHrViewHolidays;
