import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";
import useBeforeUnload from "hooks/use-before-unload";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppCustomerAssetList from "components/pages/customer/app-customer-asset-list";
import AppCustomerSiteDetails from "components/pages/customer/app-customer-site-details";

const TABS = {
	SITE_DETAILS: "SITE_DETAILS",
	ASSET_LIST: "ASSET_LIST"
};

const PageCustomerCreateEditSite = (props) => {
	useBeforeUnload();
	const { id, contractId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tabRef = useRef();
	const siteDetailsInfoRef = useRef();
	const assetListInfoRef = useRef();
	const nextTab = useRef();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Site" : "Edit Site"), [isCreate]);
	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [siteDetails, setSiteDetails] = useState();
	const [assetList, setAssetList] = useState(convertPaginationTableData());
	const [updatedBy, setUpdatedBy] = useState({});
	const retrievedContractId = useMemo(() => (contractId ? contractId : siteDetails?.customerContractId), [contractId, siteDetails]);

	const breadCrumb = useMemo(() => {
		const data = [];

		data.push({ label: "Customer Listing", path: pathnames.customer.customers }, { label: "Edit Contract ", path: pathnames.customer.customerCreateEditContract + retrievedContractId });

		if (isCreate) {
			data.push({ label: "Add Site", path: pathnames.customer.customerCreateEditSite + `${retrievedContractId}/` + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Site " + siteDetails?.referenceNo || "", path: pathnames.customer.customerCreateEditSite + id });
		}

		return data;
	}, [retrievedContractId, isCreate, siteDetails?.referenceNo, id]);

	useBreadcrumb({ breadCrumb });

	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.customerSiteMaintenance.site(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			response.id = uniqueId;

			setSiteDetails(response);

			setUpdatedBy({
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedByName: response.lastModifiedByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE)
			});
		}
	}, []);

	const onHandleGetAssetList = useCallback(async (uniqueId, queryParams) => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams, "customer-contract-site-id": uniqueId, size: 10 });

			response = await api.get.customerSiteMaintenance.assets(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setAssetList(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSubmitSiteDetails = useCallback(async (values) => {
		let response = null;

		try {
			const { picContacts, ...payload } = values;

			if (isCreate) {
				response = await api.post.customerSiteMaintenance.createSite(payload);
			}

			if (!isCreate) {
				const { picContacts, picContactIds, ...payload } = values;

				payload.id = values.id;

				response = await api.post.customerSiteMaintenance.updateSite(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Site was added successfully!" }));
				
				navigate(pathnames.customer.customerCreateEditSite + response.id);
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Site was updated successfully!" }));
			}
		}
	}, [isCreate, navigate, dispatch]);

	// prettier-ignore
	const onHandleSubmitAssets = useCallback(async (payload) => {
		let response = null;
		let params = { "customer-contract-site-id": id };

		try {
			await api.post.customerSiteMaintenance.uploadAssets(params, payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Asset was added successfully!" }));

			onHandleGetAssetList(id, { page: 0, sort: "" });
		}
	}, [dispatch, id, onHandleGetAssetList]);

	//prettier-ignore
	const onHandleSubmit = useCallback((obj) => {
		const currentTab = tabRef.current?.onHandleGetCurrentTab();

		switch (currentTab) {
			case TABS.SITE_DETAILS:
				onHandleSubmitSiteDetails(obj);

				if (!nextTab.current) nextTab.current = TABS.ASSET_LIST;
				break;

			case TABS.ASSET_LIST:
				if (obj.files.get("file")) onHandleSubmitAssets(obj);

				nextTab.current = TABS.SITE_DETAILS;
				break;

			default:
				break;
		}

		tabRef.current?.onHandleSelectTab(nextTab.current);

		nextTab.current = null;
	}, [onHandleSubmitAssets, onHandleSubmitSiteDetails]);

	const onHandleTabChange = useCallback((obj, currentTab) => {
		const tabSite_Details = currentTab === TABS.SITE_DETAILS;
		const tabAsset_List = currentTab === TABS.ASSET_LIST;

		if (tabAsset_List) assetListInfoRef.current?.onHandleSubmit();

		if (tabSite_Details) siteDetailsInfoRef.current?.onHandleSubmit();

		nextTab.current = obj.name;
	}, []);

	//prettier-ignore
	const tabs = useMemo(() => [
		{ label: "Site Details", name: TABS.SITE_DETAILS, component: <AppCustomerSiteDetails ref={siteDetailsInfoRef} siteDetails={siteDetails} onSubmit={onHandleSubmit} /> },
		{ label: "Asset List", name: TABS.ASSET_LIST, component: <AppCustomerAssetList ref={assetListInfoRef} assetList={assetList} onSubmit={onHandleSubmit} onHandleGetAssetList={onHandleGetAssetList} /> }
	], [siteDetails, onHandleSubmit, assetList, onHandleGetAssetList]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);

			onHandleGetAssetList(id, { page: 0, sort: "" });
		}

		return () => {
			if (!isCreate) {
				memoCancelRequest(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE);

				memoCancelRequest(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ASSETS);
			}
		};
	}, [isCreate, id, onHandleGetDetails, memoCancelRequest, onHandleGetAssetList]);

	return (
		<div className="page-customer-create-edit-site">
			<div className="customer-create-edit-site">
				<div className="customer-create-edit-site__header">
					<h1 className="customer-create-edit-site__title">{title}</h1>

					{!isCreate && (
						<p className="customer-create-edit-site__last-update">
							<span>Last Updated By</span> {updatedBy?.lastModifiedByName}, {updatedBy?.lastModifiedDate}
						</p>
					)}
				</div>

				<div className="customer-create-edit-site__body">
					<AppTabs ref={tabRef} tabs={tabs} onChange={onHandleTabChange} />
				</div>
			</div>
		</div>
	);
};

export default PageCustomerCreateEditSite;
