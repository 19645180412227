import services from "services";

import encodeQueryParams from "common/encode-query-params";
import stringParamFormatter from "common/string-param-formatter";

import ENDPOINT_PATH from "constants/end-point-path";

const publicToken = { token: false };
const multipart = { headers: { "Content-Type": "multipart/form-data" } };

const api = {
	get: {
		general: {
			gender: () => services.get(ENDPOINT_PATH.GENERAL.GENDER),
			idType: () => services.get(ENDPOINT_PATH.GENERAL.ID_TYPE),
			nationality: () => services.get(ENDPOINT_PATH.GENERAL.NATIONALITY),
			maritalStatus: () => services.get(ENDPOINT_PATH.GENERAL.MARITAL_STATUS),
			relationship: () => services.get(ENDPOINT_PATH.GENERAL.RELATIONSHIP),
			employeeFamilyMemberRelationship: () => services.get(ENDPOINT_PATH.GENERAL.EMPLOYEE_FAMILY_MEMBER_RELATIONSHIP),
			employeeFamilyMemberWorkingStatus: () => services.get(ENDPOINT_PATH.GENERAL.EMPLOYEE_FAMILY_MEMBER_WORKING_STATUS),
			malaysiaBank: () => services.get(ENDPOINT_PATH.GENERAL.MALAYSIA_BANK),
			weekdays: () => services.get(ENDPOINT_PATH.GENERAL.WEEK_DAYS),
			states: (params) => services.get(ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			region: (params) => services.get(ENDPOINT_PATH.GENERAL.REGION + encodeQueryParams(params)),
			postcode: (params) => services.get(ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
			userStatus: () => services.get(ENDPOINT_PATH.GENERAL.USER_STATUS),
			serviceStatus: (params) => services.get(ENDPOINT_PATH.GENERAL.SERVICE_STATUS + encodeQueryParams(params)),
			serviceType: (params) => services.get(ENDPOINT_PATH.GENERAL.SERVICE_TYPE + encodeQueryParams(params)),
			serviceInput: (params) => services.get(ENDPOINT_PATH.GENERAL.SERVICE_INPUT + encodeQueryParams(params)),
			contractType: () => services.get(ENDPOINT_PATH.GENERAL.CONTRACT_TYPE),
			contractStatus: () => services.get(ENDPOINT_PATH.GENERAL.CONTRACT_STATUS),
			contractTemplate: (params) => services.get(ENDPOINT_PATH.GENERAL.CONTRACT_TEMPLATE + encodeQueryParams(params)),
			inputType: () => services.get(ENDPOINT_PATH.GENERAL.INPUT_TYPE),
			siteStatus: () => services.get(ENDPOINT_PATH.GENERAL.SITE_STATUS),
			enquiryStatus: () => services.get(ENDPOINT_PATH.GENERAL.ENQUIRY_STATUS),
			employmentStatus: () => services.get(ENDPOINT_PATH.GENERAL.EMPLOYEMENT_STATUS),
			permissions: (params) => services.get(ENDPOINT_PATH.GENERAL.PERMISSIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.GENERAL.PERMISSIONS }),
			roles: () => services.get(ENDPOINT_PATH.GENERAL.ROLES),
			frequency: () => services.get(ENDPOINT_PATH.GENERAL.FREQUENCY),
			template: (params) => services.get(ENDPOINT_PATH.GENERAL.TEMPLATE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.GENERAL.TEMPLATE })
		},
		profile: {
			profile: () => services.get(ENDPOINT_PATH.PROFILE.PROFILE)
		},
		customerConfiguration: {
			buildingType: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.BULDING_TYPE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.BULDING_TYPE }),
			workspace: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.WORK_SPACE }),
			assetType: (params) => services.get(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.ASSET_TYPE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_CONFIGURATION.ASSET_TYPE })
		},
		customerEnquiry: {
			enquiry: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.ENQUIRY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.ENQUIRY }),
			openEnquiries: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.OPEN_ENQUIRIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.OPEN_ENQUIRIES }),
			closedEnquiries: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CLOSED_ENQUIRIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.CLOSED_ENQUIRIES }),
			configuration: (params) => services.get(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION })
		},
		assetMaintenance: {
			asset: (params) => services.get(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET }),
			assetService: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICE, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICE }),
			assetServices: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES }),
			assetExclusiveServicesSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICES_SEARCH, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICES_SEARCH }),
			// prettier-ignore
			assetExclusiveServiceChecklistSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICE_CHECKLIST_SEARCH, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_EXCLUSIVE_SERVICE_CHECKLIST_SEARCH })
		},
		customer: {
			docs: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DOCS, params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DOCS, params) }),
			customer: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CUSTOMER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CUSTOMER }),
			customers: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CUSTOMERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CUSTOMERS }),
			// prettier-ignore
			customerPic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC, params) }),
			contract: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CONTRACT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACT }),
			contracts: (params) => services.get(ENDPOINT_PATH.CUSTOMER.CONTRACTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACTS }),
			// prettier-ignore
			contractsPic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC, params) }),
			// prettier-ignore
			contractSites: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_SITES, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_SITES, params) }),
			// prettier-ignore
			exclusivePicSearch: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.CONTRACTS_EXCLUSIVE_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: ENDPOINT_PATH.CUSTOMER.CONTRACTS_EXCLUSIVE_PIC })
		},
		customerSiteMaintenance: {
			site: (params) => services.get(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE }),
			// prettier-ignore
			assets: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ASSETS, params) + encodeQueryParams(params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ASSETS, params) }),
			// prettier-ignore
			sitePic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE_PIC, params) + encodeQueryParams(params), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.SITE_PIC, params) }),
			// prettier-ignore
			exclusiveSitePic: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.EXCLUSIVE_SITE_PIC, params) + encodeQueryParams(params.queryParams), { cancelId: stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.EXCLUSIVE_SITE_PIC, params) })
		},
		mobile: {
			user: (params) => services.get(ENDPOINT_PATH.MOBILE.USER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.MOBILE.USER }),
			users: (params) => services.get(ENDPOINT_PATH.MOBILE.USERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.MOBILE.USERS })
		},
		sparePart: {
			sparePart: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SPARE_PART + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SPARE_PART }),
			spareParts: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SPARE_PARTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SPARE_PARTS }),
			serviceConfigs: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS }),
			serviceConfigsList: (params) => services.get(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS_LIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIGS_LIST }),
			exclusiveServiceListings: (params) => services.get(ENDPOINT_PATH.SPARE_PART.EXCLUSIVE_SERVICE_LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART.EXCLUSIVE_SERVICE_LISTINGS })
		},
		serviceListing: {
			serviceListing: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTING + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTING }),
			serviceListings: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS }),
			subtask: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SUBTASK + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SUBTASK }),
			subtasks: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SUBTASKS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SUBTASKS }),
			subtaskList: (params) => services.get(ENDPOINT_PATH.SERVICE_LISTING.SUBTASK_LIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_LISTING.SUBTASK_LIST })
		},
		serviceChecklist: {
			serviceChecklist: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLIST }),
			serviceChecklists: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLISTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CHECKLISTS }),
			serviceConfigs: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS }),
			serviceConfigsList: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS_LIST + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIGS_LIST }),
			exclusiveServiceListings: (params) => services.get(ENDPOINT_PATH.SERVICE_CHECKLIST.EXCLUSIVE_SERVICE_LISTINGS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SERVICE_CHECKLIST.EXCLUSIVE_SERVICE_LISTINGS })
		},
		contract: {
			contract: (params) => services.get(ENDPOINT_PATH.CONTRACT.CONTRACT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CONTRACT.CONTRACT }),
			contracts: (params) => services.get(ENDPOINT_PATH.CONTRACT.CONTRACTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.CONTRACT.CONTRACTS })
		},
		userAccess: {
			role: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.ROLE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.ROLE }),
			roles: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.ROLES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.ROLES }),
			user: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.USER + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.USER }),
			users: (params) => services.get(ENDPOINT_PATH.USER_ACCESS.USERS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.USER_ACCESS.USERS })
		},
		input: {
			input: (params) => services.get(ENDPOINT_PATH.INPUT.INPUT + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INPUT.INPUT }),
			inputs: (params) => services.get(ENDPOINT_PATH.INPUT.INPUTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INPUT.INPUTS })
		},
		sparePartCategory: {
			sparePartCategory: (params) => services.get(ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORY }),
			sparePartCategories: (params) => services.get(ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_CATEGORY.SPARE_PART_CATEGORIES })
		},
		sparePartSubCategory: {
			sparePartSubCategory: (params) => services.get(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORY + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORY }),
			sparePartSubCategories: (params) => services.get(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORIES })
		},
		humanResource: {
			positions: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.POSITIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.POSITIONS }),
			leaveTypes: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_TYPES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_TYPES }),
			levels: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.LEVELS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.LEVELS }),
			departments: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.DEPARTMENTS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.DEPARTMENTS }),
			employees: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEES }),
			employee: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE }),
			claimTypes: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_TYPES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.CLAIM_TYPES }),
			unassignedUserId: (params) => services.get(ENDPOINT_PATH.HUMAN_RESOURCE.UNASSIGNED_USER_ID + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.HUMAN_RESOURCE.UNASSIGNED_USER_ID })
		},
		inventory: {
			inventory: (params) => services.get(stringParamFormatter(ENDPOINT_PATH.INVENTORY.INVENTORY, params) + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORY }),
			inventories: (params) => services.get(ENDPOINT_PATH.INVENTORY.INVENTORIES + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.INVENTORIES }),
			configurations: (params) => services.get(ENDPOINT_PATH.INVENTORY.CONFIGURATIONS + encodeQueryParams(params), { cancelId: ENDPOINT_PATH.INVENTORY.CONFIGURATIONS })
		}
	},
	post: {
		authenticate: {
			login: (payload) => services.post(ENDPOINT_PATH.AUTHENTICATE.SIGN_IN, payload),
			refreshToken: (payload) => services.post(ENDPOINT_PATH.AUTHENTICATE.REFRESH_TOKEN, payload, { ...publicToken })
		},
		forgotPassword: {
			forgotPassword: (payload) => services.post(ENDPOINT_PATH.FORGOT_PASSWORD.FORGOT_PASSWORD, payload),
			verifyEmail: (payload, headers) => services.post(ENDPOINT_PATH.FORGOT_PASSWORD.VERIFY_EMAIl, payload, { ...headers, cancelId: ENDPOINT_PATH.FORGOT_PASSWORD.VERIFY_EMAIl }),
			updatePassword: (payload, headers) => services.post(ENDPOINT_PATH.FORGOT_PASSWORD.UPDATE_PASSWORD, payload, { ...headers })
		},
		profile: {
			profile: (payload) => services.post(ENDPOINT_PATH.PROFILE.CHANGE_PASSWORD, payload)
		},
		account: {
			otpCodeVerify: (payload) => services.post(ENDPOINT_PATH.ACCOUNT.OTP_CODE_VERIFY, payload),
			otpResend: () => services.post(ENDPOINT_PATH.ACCOUNT.OTP_RESEND)
		},
		customerConfiguration: {
			createBuildingType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.CREATE_BUILDING_TYPE, payload),
			updateBuildingType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.UPDATE_BUILDING_TYPE, payload),
			createWorkspace: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.CREATE_WORKSPACE, payload),
			updateWorkspace: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.UPDATE_WORKSPACE, payload),
			createAssetType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.CREATE_ASSET_TYPE, payload),
			updateAssetType: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_CONFIGURATION.UPDATE_ASSET_TYPE, payload)
		},
		assetMaintenance: {
			updateAsset: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET, payload), payload),
			assetServicesReorder: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES_REORDER, payload), payload.checkLists),
			addAssetServices: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ADD_ASSET_SERVICES, payload), payload.assetServiceIds),
			addAssetServiceChecklist: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.ADD_ASSET_SERVICE_CHECKLIST, payload), payload.serviceChecklistIds),
			createAssetService: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.CREATE_ASSET_SERVICE, payload), payload.assetServices),
			updateAssetServices: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET_SERVICES, payload), payload),
			updateAssetService: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET_SERVICE, payload), payload.assetServices),
			// prettier-ignore
			updateAssetServiceStatus: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.ASSET_MAINTENANCE.UPDATE_ASSET_SERVICE_STATUS, payload), payload)
		},
		customerEnquiry: {
			downloadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_ENQUIRY.DOWNLOAD_DOC, payload)),
			updateEnquiry: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_ENQUIRY.UPDATE_ENQUIRY, payload),
			updateConfiguration: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_ENQUIRY.CONFIGURATION, payload)
		},
		customer: {
			createCustomer: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_CUSTOMER, payload),
			updateCustomer: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.UPDATE_CUSTOMER, payload),
			createCustomerPic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_CUSTOMER_PIC, payload),
			updateCustomerPic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.UPDATE_CUSTOMER_PIC, payload),
			createUser: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_USER, payload),
			createContract: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.CREATE_CONTRACT, payload),
			updateContract: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.UPDATE_CONTRACT, payload),
			addAllContractPic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER.ADD_ALL_CONTRACTS_PIC, payload),
			deleteContractPic: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DELETE_CONTRACT_PIC, payload)),
			uploadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.UPLOAD_DOC, payload.queryParam), payload.files, { ...multipart }),
			deleteDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DELETE_DOC, payload)),
			downloadDoc: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER.DOWNLOAD_DOC, payload))
		},
		customerSiteMaintenance: {
			createSite: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.CREATE_SITE, payload),
			updateSite: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.UPDATE_SITE, payload),
			// prettier-ignore
			uploadAssets: (params, payload) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.UPLOAD_ASSETS, params), payload.files, { ...multipart }),
			addAllSitePic: (payload) => services.post(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.ADD_ALL_SITE_PIC, payload),
			// prettier-ignore
			deleteSitePic: (params) => services.post(stringParamFormatter(ENDPOINT_PATH.CUSTOMER_SITE_MAINTENANCE.DELETE_SITE_PIC, params))
		},
		mobile: {
			approval: (payload) => services.post(ENDPOINT_PATH.MOBILE.APPROVAL, payload),
			changePassword: (payload) => services.post(ENDPOINT_PATH.MOBILE.CHANGE_PASSWORD, payload)
		},
		sparePart: {
			createSparePart: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.CREATE_SPARE_PART, payload),
			updateSparePart: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.UPDATE_SPARE_PART, payload),
			addAllServiceConfig: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.SERVICE_CONFIG_ADD_ALL, payload),
			removeServiceConfig: (payload) => services.post(ENDPOINT_PATH.SPARE_PART.REMOVE_SERVICE_CONFIG, payload)
		},
		serviceListing: {
			createServiceListing: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.CREATE_SERVICE_LISTING, payload),
			updateServiceListing: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.UPDATE_SERVICE_LISTING, payload),
			exclusiveIdSearch: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.EXCLUSIVE_ID_SEARCH, payload),
			createServiceSubtask: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.CREATE_SUBTASK, payload),
			updateServiceSubtask: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.UPDATE_SUBTASK, payload),
			reorderServiceSubtask: (payload) => services.post(stringParamFormatter(ENDPOINT_PATH.SERVICE_LISTING.REORDER_SUBTASK, payload), payload),
			deleteServiceSubtask: (payload) => services.post(ENDPOINT_PATH.SERVICE_LISTING.DELETE_SUBTASK, payload)
		},
		serviceChecklist: {
			createServiceChecklist: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.CREATE_SERVICE_CHECKLIST, payload),
			updateServiceChecklist: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.UPDATE_SERVICE_CHECKLIST, payload),
			addAllServiceConfig: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.SERVICE_CONFIG_ADD_ALL, payload),
			removeServiceConfig: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.REMOVE_SERVICE_CONFIG, payload),
			reorderServiceConfig: (payload) => services.post(ENDPOINT_PATH.SERVICE_CHECKLIST.REORDER_SERVICE_CONFIG, payload)
		},
		contract: {
			createContract: (payload) => services.post(ENDPOINT_PATH.CONTRACT.CREATE_CONTRACT, payload),
			updateContract: (payload) => services.post(ENDPOINT_PATH.CONTRACT.UPDATE_CONTRACT, payload)
		},
		userAccess: {
			createRole: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.CREATE_ROLE, payload),
			updateRole: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.UPDATE_ROLE, payload),
			createUser: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.CREATE_USER, payload),
			updateUser: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.UPDATE_USER, payload),
			changePassword: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.CHANGE_PASSWORD, payload),
			updateAvatar: (payload) => services.post(ENDPOINT_PATH.USER_ACCESS.UPDATE_AVATAR, payload, { ...multipart })
		},
		input: {
			createInput: (payload) => services.post(ENDPOINT_PATH.INPUT.CREATE_INPUT, payload),
			updateInput: (payload) => services.post(ENDPOINT_PATH.INPUT.UPDATE_INPUT, payload)
		},
		sparePartCategory: {
			createSparePartCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_CATEGORY.CREATE_SPARE_PART_CATEGORY, payload),
			updateSparePartCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_CATEGORY.UPDATE_SPARE_PART_CATEGORY, payload)
		},
		sparePartSubCategory: {
			createSparePartSubCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.CREATE_SPARE_PART_SUB_CATEGORY, payload),
			updateSparePartSubCategory: (payload) => services.post(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.UPDATE_SPARE_PART_SUB_CATEGORY, payload)
		},
		humanResource: {
			createPosition: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_POSITION, payload),
			updatePosition: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_POSITION, payload),
			createLeaveType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_LEAVE_TYPE, payload),
			updateLeaveType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_LEAVE_TYPE, payload),
			createLevel: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_LEVEL, payload),
			updateLevel: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_LEVEL, payload),
			createDepartment: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_DEPARTMENT, payload),
			updateDepartment: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_DEPARTMENT, payload),
			createEmployee: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_EMPLOYEE, payload),
			updateEmployee: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_EMPLOYEE, payload),
			createClaimType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.CREATE_CLAIM_TYPE, payload),
			updateClaimType: (payload) => services.post(ENDPOINT_PATH.HUMAN_RESOURCE.UPDATE_CLAIM_TYPE, payload)
		},
		inventory: {
			createInventory: (payload) => services.post(ENDPOINT_PATH.INVENTORY.CREATE_INVENTORY, payload),
			updateInventory: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_INVENTORY, payload),
			transferInventory: (payload) => services.post(ENDPOINT_PATH.INVENTORY.TRANSFER_INVENTORY, payload),
			createConfiguration: (payload) => services.post(ENDPOINT_PATH.INVENTORY.CREATE_CONFIGURATION, payload),
			updateConfiguration: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_CONFIGURATION, payload),
			updateStock: (payload) => services.post(ENDPOINT_PATH.INVENTORY.UPDATE_STOCK, payload)
		}
	},
	patch: {},
	put: {}
};

export default api;
