import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getServiceInputListing = async (payload) => {
	let response = null;

	let errorMessage = null;

	try {
		const params = { page: payload?.page || 0, size: 10, label: payload?.keyword || "" };

		const res = await api.get.general.serviceInput(params);

		const content = res.content.map(({ id, label, ...o }) => ({ label: label, value: id, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getServiceInputListing;
