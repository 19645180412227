import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

const AppMobileAppConfirmDeactivateAccountModal = (props, ref) => {
	const [data, setData] = useState([]);
	const [visible, setVisible] = useState(false);

	const onHandleConfirm = useCallback(() => {
		// SET DEACTIVATE API HERE
		const payload = data;
		console.log(payload); //temp

		setVisible(false);
	}, [data]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-mobile-app-confirm-deactivate-account-modal" }} open={visible}>
			<div className="mobile-app-confirm-deactivate-account-modal">
				<button type="button" className="mobile-app-confirm-deactivate-account-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="mobile-app-confirm-deactivate-account-modal__title">Are you sure?</h1>

				<p className="mobile-app-confirm-deactivate-account-modal__description">
					After clicking confirm, this Mobile App Account will be <span>deactivated</span>
				</p>

				<p className="mobile-app-confirm-deactivate-account-modal__description mobile-app-confirm-deactivate-account-modal__description--final-line">The user will no longer be able to access this account.</p>

				<div className="mobile-app-confirm-deactivate-account-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppMobileAppConfirmDeactivateAccountModal));

AppMobileAppConfirmDeactivateAccountModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
