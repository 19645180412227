import { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Menu, Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import SERVICE_TYPE from "constants/service-type";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";

import filter from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";

const AppAddServicesLinkageModal = (props, ref) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [isSubmiting, setIsSubmiting] = useState(false);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const defaultServiceType = useMemo(() => [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET], []);
	const queryParams = useRef({ page: 0, "service-action": "", sort: "", status: "", serviceTypes: defaultServiceType });
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isSparePartConfig = useMemo(() => props.isSparePartConfig, [props.isSparePartConfig]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { serviceAction: "", configServices: [], configServiceIds: [], serviceTypes: [SERVICE_TYPE.ASSET, SERVICE_TYPE.NON_ASSET], status: [] },
		onSubmit: (values) => {
			onHandleFilterSubmit(values);
		}
	});

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);

		formik.resetForm();
	}, [formik]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const [visible, setVisible] = useState(false);

	// prettier-ignore
	const onHandleShow = useCallback((configServiceIds, configServices) => {
		formik.setFieldValue("configServiceIds", configServiceIds);
		formik.setFieldValue("configServices", configServices);

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleSelectServices = useCallback((value, service, id) => {
		const currentServiceIds = structuredClone(formik.values.configServiceIds);
		const currentServices = structuredClone(formik.values.configServices);

		if (value) {
			formik.setFieldValue("configServiceIds", [...currentServiceIds, id]);
			formik.setFieldValue("configServices", [...currentServices, { ...service, paginationNumbers: currentServices.length + 1,  seq: currentServices.length + 1 }]);
		} else {
			const nextServiceIds = currentServiceIds.filter((o) => o !== id);
			const nextServices = currentServices.filter((o) => o.id !== service.id);

			formik.setFieldValue("configServiceIds", nextServiceIds);
			formik.setFieldValue("configServices", nextServices);
		}
	}, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			if (isCreate) {
				response = await api.get.serviceListing.serviceListings(params);

				if (props.formValues.configServiceIds.length) {
					response.content = response.content?.filter((o) => !props.formValues.configServiceIds.includes(o.id));
				}
			} else {
				const nextParams = { ...params, [isSparePartConfig ? "config-spare-part-id" : "config-service-check-list-id"]: id };

				if (isSparePartConfig) {
					response = await api.get.sparePart.exclusiveServiceListings(nextParams);
				} else {
					response = await api.get.serviceChecklist.exclusiveServiceListings(nextParams);
				}
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id, isCreate, props.formValues.configServiceIds, isSparePartConfig]);

	// prettier-ignore
	const onHandleFilterSubmit = useCallback((values) => {
		queryParams.current.serviceTypes = values.serviceTypes;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current["service-action"] = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.serviceTypes = defaultServiceType;

		onHandleGetList();
	}, [formik, defaultServiceType, onHandleGetList]);

	//prettier-ignore
	const onHandleSelectServiceTypes = useCallback((value, name) => {
		let values = [...formik.values.serviceTypes];

		if (formik.values.serviceTypes.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("serviceTypes", values);
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Asset", name: SERVICE_TYPE.ASSET },
			{ label: "Non-Asset", name: SERVICE_TYPE.NON_ASSET }
		];

		return data;
	}, []);

	const onHandleSubmit = useCallback(async () => {
		let response = null;

		setIsSubmiting(true);

		try {
			const nextConfigServiceIds = formik.values.configServiceIds;
			const nextConfigServices = formik.values.configServices;
			const payload = isSparePartConfig ? { configServiceIds: nextConfigServiceIds } : { configServiceLinkages: nextConfigServiceIds.map((o) => ({ id: o })) };

			if (isCreate) {
				props.onHandleUpdateConfigServiceIds(nextConfigServiceIds);

				props.onHandleUpdateConfigServices(nextConfigServices);
			}

			if (!isCreate) {
				if (isSparePartConfig) {
					payload.configSparePartId = id;

					await api.post.sparePart.addAllServiceConfig(payload);
				} else {
					payload.configServiceChecklistId = id;

					await api.post.serviceChecklist.addAllServiceConfig(payload);
				}
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			setIsSubmiting(false);
		}

		if (response) {
			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service was linked successfully!" }));

				props.onHandleGetDetails(id);
			}

			setVisible(false);
		}
	}, [dispatch, formik, id, isCreate, isSparePartConfig, props]);

	const tableColumns = [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Service ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const service = data.content[rowIndex];
					const serviceId = service.id;
					const selected = formik.values.configServiceIds?.includes(serviceId);

					return <AppCheckbox label="" onClick={(v) => onHandleSelectServices(v, service, serviceId)} value={selected} />;
				}
			}
		}
	];

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			if (isCreate) {
				cancelRequest(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTINGS);
			} else {
				const endpoint = isSparePartConfig ? ENDPOINT_PATH.SPARE_PART.EXCLUSIVE_SERVICE_LISTINGS : ENDPOINT_PATH.SERVICE_CHECKLIST.EXCLUSIVE_SERVICE_LISTINGS;

				cancelRequest(endpoint);
			}
		};
	}, [cancelRequest, isCreate, isSparePartConfig]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-add-services-linkage-modal" }} open={visible}>
			<div className="add-services-linkage-modal">
				<div className="add-services-linkage-modal__title">Add Services</div>

				<div className="add-services-linkage-modal__description">
					Select from <span className="add-services-linkage-modal__highlight">In-Use</span> Services
				</div>

				<div className="add-services-linkage-modal__header">
					{/* prettier-ignore */}
					<div className="add-services-linkage-modal__search-input">
						<AppInput type="text" name="serviceAction" placeholder="Search by Service ID or Service Action" endIcon={searchIcon} iconInField={true} defaultValue={queryParams.current["service-action"]} onChange={onHandleDebounceSearch} onBlur={() => {}} />
					</div>

					<div className="add-services-linkage-modal__filter-button">
						<AppButton className="app-button-light" type="button" label="Filter" icon={filter} onClick={onToggleFilterMenu} />
					</div>
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

				<div className="add-services-linkage-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Add" onClick={onHandleSubmit} disabled={!formik.values.configServiceIds?.length || isSubmiting} />
				</div>

				{/* prettier-ignore */}
				<Menu classes={{ root: "add-services-linkage-modal-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }} >
					<div className="filter-menu">
						<div className="filter-menu__body">
							<div className="filter-menu__content">
								<p className="filter-menu__label">Service Type</p>

								{menuFilterStatus.map((o) => {
									const isActive = formik.values.serviceTypes.findIndex(i => i === o.name) > -1;

									return (
										<div className="filter-menu__checkbox" key={o.label}>
											<AppCheckbox key={o.label} onClick={(v) => onHandleSelectServiceTypes(v, o.name)} label={o.label} value={isActive} />
										</div>
									);
								})}
							</div>
						</div>

						<div className="filter-menu__footer">
							<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>
								Clear All Filters
							</button>

							{/* prettier-ignore */}
							<div className="filter-menu__buttons">
								<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>Cancel</button>
								
								<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>Apply</button>
							</div>
						</div>
					</div>
				</Menu>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddServicesLinkageModal));
