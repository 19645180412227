import React, { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNestedObjectValues, useFormik } from "formik";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppInventoryAdjustStockModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultValues = useMemo(() => props.defaultValues, [props.defaultValues]);
	const [isReview, setIsReview] = useState(false);
	const [visible, setVisible] = useState(false);
	const title = useMemo(() => (isReview ? "Review Changes" : "Adjust Stock"), [isReview]);
	const subText = useMemo(() => (isReview ? "After clicking Confirm, the Prices and Quantity will be updated according to these changes. " : `Current Quantity ${defaultValues.quantity}`), [defaultValues.quantity, isReview]);

	const initialValues = useMemo(() => {
		const values = {
			nextCostPrice: defaultValues.costPrice,
			nextSellPrice: defaultValues.sellPrice,
			nextSupplier: defaultValues.supplier,
			quantityToAdd: ""
		};

		return values;
	}, [defaultValues]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			nextCostPrice: yup
				.string()
				.test("nextCostPriceNotZero", "Cost price cannot be 00.00", function (value) {
					return value !== "00.00";
				})
				.required(ERRORS.REQUIRED),
			nextSellPrice: yup
				.string()
				.test("nextSellPriceNotZero", "Sell price cannot be 00.00", function (value) {
					return value !== "00.00";
				})
				.required(ERRORS.REQUIRED),
			nextSupplier: yup.string().required(ERRORS.REQUIRED),
			quantityToAdd: yup.number().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		}
	});

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		if (!isReview) {
			setVisible(false);

			formik.resetForm();
		}

		setIsReview(false);
	}, [formik, isReview]);

	const onHandleNext = useCallback(async () => {
		const fieldErrors = await formik.validateForm();

		if (Object.keys(fieldErrors).length) {
			formik.setTouched(setNestedObjectValues(fieldErrors, true));
		} else {
			setIsReview(true);
		}
	}, [formik]);

	const ReviewChangesComponent = useCallback(() => {
		return (
			<div className="inventory-adjust-stock-modal__table-container">
				<table className="inventory-adjust-stock-modal__table">
					<thead>
						<tr>
							<th></th>

							<th>Before</th>

							<th>After</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>Quantity</td>

							<td>{defaultValues.quantity}</td>

							<td>{defaultValues.quantity + formik.values.quantityToAdd}</td>
						</tr>

						<tr>
							<td>Cost Price (MYR)</td>

							<td>{formik.values.costPrice}</td>

							<td>{formik.values.nextCostPrice}</td>
						</tr>

						<tr>
							<td>Sell Price (MYR)</td>

							<td>{formik.values.sellPrice}</td>

							<td>{formik.values.nextSellPrice}</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}, [formik.values, defaultValues]);

	const onHandleConfirm = useCallback(async () => {
		let response = null;

		try {
			// ADD API WHEN READY
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			onHandleDismiss();

			dispatch(promptLayoutAlertMessage({ message: "Stock count was updated successfully!" }));

			// WILL ADD PROPS WHEN API READY
			props.onHandleGetDetails(id);
		}
	}, [formik, onHandleDismiss, dispatch, props, id]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow
	}));

	return (
		<Modal classes={{ root: "app-inventory-adjust-stock-modal" }} open={visible}>
			<div className="inventory-adjust-stock-modal">
				<h1 className="inventory-adjust-stock-modal__title">{title}</h1>

				<div className="inventory-adjust-stock-modal__text">{subText}</div>

				<form className="inventory-adjust-stock-modal__form" onSubmit={formik.handleSubmit}>
					{!isReview && (
						<Fragment>
							<div className="inventory-adjust-stock-modal__row">
								{/* prettier-ignore */}
								<AppInput type="text" required disabled={restricted} name="nextSupplier" label="Supplier" placeholder="Enter Supplier Name" value={formik.values.nextSupplier} error={formik.errors.nextSupplier} touched={formik.touched.nextSupplier} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput type="number" required disabled={restricted} name="quantityToAdd" label="Quantity to Add" placeholder="Enter Quantity to Add" value={formik.values.quantityToAdd} error={formik.errors.quantityToAdd} touched={formik.touched.quantityToAdd} onChange={formik.handleChange} />
							</div>

							<div className="inventory-adjust-stock-modal__row">
								{/* prettier-ignore */}
								<AppInput type="text" required disabled={restricted} name="nextCostPrice" label="Cost Price (MYR)" placeholder="Enter Cost Price" value={formik.values.nextCostPrice} error={formik.errors.nextCostPrice} touched={formik.touched.nextCostPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

								{/* prettier-ignore */}
								<AppInput type="text" required disabled={restricted} name="nextSellPrice" label="Sell Price (MYR)" placeholder="Enter Sell Price" value={formik.values.nextSellPrice} error={formik.errors.nextSellPrice} touched={formik.touched.nextSellPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
							</div>
						</Fragment>
					)}

					{isReview && <ReviewChangesComponent />}

					<div className="inventory-adjust-stock-modal__button-container">
						<AppButton outline type="button" label="Back" onClick={onHandleDismiss} />

						{!isReview && <AppButton type="button" label="Next" disabled={restricted} onClick={onHandleNext} />}

						{isReview && <AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />}
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryAdjustStockModal));

AppInventoryAdjustStockModal.propTypes = {
	defaultValues: PropTypes.object
};
