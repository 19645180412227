import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";
import getServiceInputListing from "services/get-service-input-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import serveRequestErrors from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

export const AppCreateEditSubtaskModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { id: "", current: "", subtaskAction: "", input: "", inputLabel: "", seq: 0, status: PAGE.CREATE, subtasks: [] };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			subtaskAction: yup.string().required(ERRORS.REQUIRED),
			input: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => onHandleSubmit(values)
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const isCreateService = useMemo(() => id === PAGE.CREATE, [id]);
	const isCreateSubtask = useMemo(() => formik.values.status === PAGE.CREATE, [formik.values.status]);
	const subTaskLabel = useMemo(() => (isCreateSubtask ? "Subtask Action" : "New Subtask Action"), [isCreateSubtask]);
	const title = useMemo(() => (isCreateSubtask ? "Add Sub-Task" : "Edit Sub-Task"), [isCreateSubtask]);

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		formik.setValues({ id: values.id || "", subtaskIndex: values.subtaskIndex, current: values.subtaskAction || "", subtaskAction: "", input: values.inputId || "", seq: values.seq || 0, status: values.status, subtasks: values.subtasks || [] });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleChangeInput = useCallback((event, child) => {
		formik.handleChange(event);

		formik.setFieldValue("inputLabel", child.props.children);
	}, [formik]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (subtaskId) => {
		let response = null;

		try {
			response = await api.get.serviceListing.subtask(subtaskId);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				current: response.subtaskAction,
				input: response.input.id,
				seq: response.seq,
				subtaskAction: "",
				status: "",
				subtasks: []
			});
		}
	}, [memoSetFormValues]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		if (isCreateService) {
			const payload = {
				subtaskAction: values.subtaskAction,
				inputId: values.input,
				input: { label: values.inputLabel }
			};

			const nextSubtasks = structuredClone(values.subtasks);

			if (isCreateSubtask) {
				payload.seq = nextSubtasks.length + 1;

				nextSubtasks.push(payload);
			} else {
				nextSubtasks[values.subtaskIndex] = payload;
			}

			props.onHandleUpdateSubtasks(nextSubtasks);

			formik.resetForm();

			setVisible(false);
		}

		if (!isCreateService) {
			let response = null;

			try {
				if (isCreateSubtask) {
					const payload = {
						configServiceId: id,
						subtaskAction: values.subtaskAction,
						inputId: values.input
					};

					await api.post.serviceListing.createServiceSubtask(payload);
				} else {
					const payload = {
						configServiceId: id,
						subtasks: [{ id: values.id, subtaskAction: values.subtaskAction, inputId: values.input }]
					};

					await api.post.serviceListing.updateServiceSubtask(payload);
				}

				response = true;
			} catch (error) {
				serveRequestErrors(error);
			} finally {
				formik.setSubmitting(false);
			}

			if (response) {
				if (isCreateSubtask) dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));

				if (!isCreateSubtask) dispatch(promptLayoutAlertMessage({ message: "Service was updated successfully!" }));

				props.onHandleGetList();

				props.onHandleGetDetails(id);

				formik.resetForm();

				setVisible(false);
			}
		}
	}, [dispatch, formik, id, isCreateService, isCreateSubtask, props]);

	useEffect(() => {
		if (!isCreateService && !isCreateSubtask) onHandleGetDetails(formik.values.id);

		return () => {
			if (formik.values.id) cancelRequest(ENDPOINT_PATH.SERVICE_LISTING.SUBTASK);
		};
	}, [isCreateSubtask, onHandleGetDetails, cancelRequest, isCreateService, formik.values.id]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-create-edit-subtask-modal" }} open={visible}>
			<div className="create-edit-subtask-modal">
				<h1 className="create-edit-subtask-modal__title">{title}</h1>

				<form className="create-edit-subtask-modal__form" onSubmit={formik.handleSubmit}>
					{!isCreateSubtask && /* prettier-ignore */ <AppInput disabled type="text" name="current" label="Current Subtask Action" placeholder="Current Subtask Action" value={formik.values.current} error={formik.errors.current} touched={formik.touched.current} onChange={formik.handleChange} />}

					<AppInput required type="text" name="subtaskAction" label={subTaskLabel} placeholder="Subtask Action" value={formik.values?.subtaskAction} error={formik.errors.subtaskAction} touched={formik.touched.subtaskAction} onChange={formik.handleChange} />

					<AppSelectInput searchable pagination required type="text" name="input" label="Input" placeholder="Select..." loadOptions={getServiceInputListing} value={formik.values?.input} error={formik.errors.input} touched={formik.touched.input} onChange={(e, c) => onHandleChangeInput(e, c)} />

					<div className="create-edit-subtask-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppCreateEditSubtaskModal));

AppCreateEditSubtaskModal.propTypes = {
	ref: PropTypes.object
};
