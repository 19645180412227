import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { Menu, Table, TableBody, TableCell, TableRow } from "@mui/material";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";

import filerIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const dummyData = [
	{ fullName: "Vivienne Tang Jia Yi", employeeId: "123", submitDate: "25/03/2023", previousClockOut: "10.00 a.m.", correctedClockout: "7.00 p.m." },
	{ fullName: "Zakaria bin Leman", employeeId: "124", submitDate: "26/04/2023", previousClockOut: "11.00 a.m.", correctedClockout: "8.30 p.m." },
	{ fullName: "Suraj Raghavan", employeeId: "125", submitDate: "27/05/2023", previousClockOut: "12.00 p.m.", correctedClockout: "9.00 p.m." }
];

const AppHrViewPendingClockoutTable = () => {
	const [data, setData] = useState(dummyData);
	const [selectedPending, setSelectedPending] = useState([]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [searchParams] = useSearchParams();
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "PENDINGCLOCKOUT" });

	const formik = useFormik({
		initialValues: { submitDate: "" }
	});

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleSelectPending = useCallback((boolean, id) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(id);
			} else {
				nextPending = nextPending.filter((pendingID) => pendingID !== id);
			}

			return nextPending;
		});
	}, []);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.map((item) => item.employeeId);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = selectedPending.length === data?.length;

					return (
						<TableCell key="table-key-checkbox" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data[rowIndex]?.employeeId;
					const selected = selectedPending.includes(tablePendingID);

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				},
			},
		},
		{
			name: "fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false,
			},
		},
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "previousClockOut",
			label: "Previous Clock Out",
			options: {
				sort: false,
			},
		},
        {
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />					
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			},
		},
		{
			name: "correctedClockout",
			label: "Corrected Clock Out",
			options: {
				sort: false,
			},
		},
	], [data, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="hr-view-pending-clockout-table__header-button">
				<div className="hr-view-pending-clockout-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} />
				</div>

				<div className="hr-view-pending-clockout-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} />
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-hr-view-pending-clockout-table">
			<div className="hr-view-pending-clockout-table">
				<div className="hr-view-pending-clockout-table__header">
					<div className="hr-view-pending-clockout-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name or Email Address" endIcon={searchIcon} iconPosition="end" onChange={onHandleDebounceSearch} />

						<div className="hr-view-pending-clockout-table__filter-button">
							<AppButton outline type="button" label="Filter" icon={filerIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>

					<HeaderApprovalButton selectedPending={selectedPending} />
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-hr-view-pending-clockout-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<div className="filter-menu__inputs">
							<AppInputDate name="startDate" label="Submit Date" placeholder="Start Date" value={formik.values.submitDate} onChange={formik.setFieldValue} />
						</div>
					</div>

					<div className="filter-menu__footer">
						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>
								Cancel
							</button>

							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>
								Apply
							</button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default AppHrViewPendingClockoutTable;
