import { memo, useMemo } from "react";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, TableCell, TableFooter, TableRow } from "@mui/material";

const AppTableFooter = (props) => {
	const count = useMemo(() => props.count, [props.count]);
	const page = useMemo(() => props.page, [props.page]);
	const rowsPerPage = useMemo(() => props.rowsPerPage, [props.rowsPerPage]);
	const changePage = useMemo(() => props.changePage, [props.changePage]);

	const startingRow = useMemo(() => {
		return rowsPerPage * page + 1 < count ? rowsPerPage * page + 1 : count;
	}, [count, page, rowsPerPage]);

	const endingRow = useMemo(() => {
		return rowsPerPage * (page + 1) > count ? count : rowsPerPage * (page + 1);
	}, [count, page, rowsPerPage]);

	const currentPage = useMemo(() => {
		return page + 1 > Math.ceil(count / rowsPerPage) ? Math.ceil(count / rowsPerPage) : page + 1;
	}, [count, page, rowsPerPage]);

	return (
		<TableFooter>
			<TableRow>
				<TableCell className="app-table-footer" colSpan={100}>
					<span className="app-table-footer__rows">
						{startingRow}-{endingRow} of {count}
					</span>

					<div className="app-table-footer__page">
						<span className="app-table-footer__current">
							Page {currentPage} of {Math.ceil(count / rowsPerPage)}
						</span>

						<div className="app-table-footer__buttons">
							<IconButton onClick={() => changePage(page - 1)} disabled={page === 0}>
								<KeyboardArrowLeft />
							</IconButton>

							<IconButton onClick={() => changePage(page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
								<KeyboardArrowRight />
							</IconButton>
						</div>
					</div>
				</TableCell>
			</TableRow>
		</TableFooter>
	);
};

export default memo(AppTableFooter);
