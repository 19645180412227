import { memo, useMemo } from "react";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const AppToggleSwitch = (props) => {
	const SwitchComponent = useMemo(() => {
		return <Switch checked={props.checked} onChange={props.onChange} />;
	}, [props.checked, props.onChange]);

	return <FormControlLabel className="app-toggle-switch" name={props.name} disabled={props.disabled} label={props.label} control={SwitchComponent} />;
};

export default memo(AppToggleSwitch);
