import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

import classNames from "common/class-names";

import AppIcon from "components/app-icon";
import AppCheckIcon from "components/icons/app-check-icon";

const AppCheckbox = (props) => {
	const [active, setActive] = useState(props.value);

	const className = useMemo(() => {
		return classNames({
			"app-checkbox": true,
			"app-checkbox--active": active,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props.className, active]);

	const onHandleToggle = useCallback(() => {
		if (props.onClick) props.onClick(!active);
	}, [props, active]);

	useEffect(() => {
		if ("value" in props && active !== props.value) {
			setActive(props.value);
		}
	}, [active, props]);

	return (
		<button className={className} type="button" disabled={props.disabled} onClick={onHandleToggle}>
			<div className="app-checkbox__box">{props.icon ? <AppIcon src={props.icon} /> : <AppCheckIcon />}</div>
			<p className="app-checkbox__label">{props.label}</p>
		</button>
	);
};

AppCheckbox.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string
};

export default memo(AppCheckbox);
