import React, { useCallback, useEffect, useMemo, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

import faqIcon from "assets/images/pages/human-resources/faq-icon.png";
import AnnualLeaveIcon from "assets/images/pages/human-resources/annual-leave-icon.svg";
import MedicalLeaveIcon from "assets/images/pages/human-resources/medical-leave-icon.svg";
import HalfDayAmMedicalLeaveIcon from "assets/images/pages/human-resources/half-day-am-medical-leave-icon.svg";
import HalfDayPmMedicalLeaveIcon from "assets/images/pages/human-resources/half-day-pm-medical-leave-icon.svg";

const dummyData = [
	{ observances: "Labor Day", description: "International Workers' Day, also known as Labour Day, is a celebration of labourers and the working classes" },
	{
		observances: [
			{ name: "Annual Leave, Full Day", icon: AnnualLeaveIcon },
			{ name: "Medical Leave, Full Day", icon: MedicalLeaveIcon },
			{ name: "Medical Leave, PM", icon: HalfDayPmMedicalLeaveIcon }
		],
		employeeLeaveObeservances: [{ name: "Gojo Satoru, Yuji Itadori" }, { name: "Yugi Motou, Atem" }, { name: "Sendou Aichi, Toshiki Kai" }]
	},
	{ observances: "Wesak Day", description: "A holiday celebrating Buddha's birth." },
	{ observances: "" },
	{
		observances: [{ name: "Annual Leave, Full Day", icon: AnnualLeaveIcon }],
		employeeLeaveObeservances: [{ name: "Yusuke Urameshi, Gon Freecss, Natsu Dragneel" }]
	},
	{ observances: "" },
	{ observances: "" },
	{
		observances: [{ name: "Medical Leave, AM", icon: HalfDayAmMedicalLeaveIcon }],
		employeeLeaveObeservances: [{ name: "Natsu Dragneel" }]
	},
	{
		observances: [{ name: "Medical Leave, Full Day", icon: MedicalLeaveIcon }],
		employeeLeaveObeservances: [{ name: "Asuka Langley Soryu" }]
	},
	{
		observances: [{ name: "Annual Leave, Full Day", icon: AnnualLeaveIcon }],
		employeeLeaveObeservances: [{ name: "Kudou Taiki, Ash Ketchum" }]
	}
];

const AppLeaveCalendar = () => {
	const [data, setData] = useState(dummyData);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedObservance, setSelectedObservance] = useState("");
	const [selectedDescription, setSelectedDescription] = useState("");

	//prettier-ignore
	const handleMenuOpen = useCallback((event, observance) => {
		const observanceData = data.find((item) => item.observances === observance || (Array.isArray(item.observances) && item.observances.some((o) => o.name === observance)));
		
		setAnchorEl(event.currentTarget);
		setSelectedObservance(observance);
		setSelectedDescription(observanceData?.description || "");
	}, [data]);

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedObservance("");
		setSelectedDescription("");
	};

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			label: "Observances",
			name: "observances",
			options: {
				sort: false,
				customBodyRender: (value) => {
					if (Array.isArray(value)) {
						return (
							<div className="leave-calendar__list">
								{value.map((observances, index) => (
									<div key={index} className="leave-calendar__item">
										<img src={observances.icon} alt={observances.name} className="leave-calendar__image" />

										{observances.name}
									</div>
								))}
							</div>
						);
					} else if (typeof value === "string" && value) {
						return (
							<div className="leave-calendar__holiday">
								{value}

								<img src={faqIcon} alt="chevron icon" className="leave-calendar__faq-icon" onClick={(event) => handleMenuOpen(event, value)} />
							</div>
						);
					}

					return value;
				}
			}
		},
		{
			label: "Employees on Leave Observances",
			name: "employeeLeaveObeservances",
			options: {
				sort: false,
				customBodyRender: (value) => {
					if (Array.isArray(value)) {
						return (
							<div className="leave-calendar__employee-leave-obeservances">
								{value.map((employeeLeaveObeservances, index) => (
									<div key={index} className="leave-calendar__employee-leave-obeservances">
										{employeeLeaveObeservances.name}
									</div>
								))}
							</div>
						);
					}

					return value;
				}
			}
		}
	], [handleMenuOpen]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null
	}), []);

	useEffect(() => {
		setData(dummyData);
	}, []);

	return (
		<div className="app-leave-calendar">
			<div className="leave-calendar">
				<AppCalendarSchedule data={data} columns={tableColumns} options={tableOptions} />
			</div>

			<Menu classes={{ root: "leave-calendar-table-menu" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem onClick={handleMenuClose}>{selectedObservance}</MenuItem>

				{selectedDescription && <MenuItem onClick={handleMenuClose}>{selectedDescription}</MenuItem>}
			</Menu>
		</div>
	);
};

export default AppLeaveCalendar;
