import { useState } from "react";

import dayjs from "dayjs";
import theme from "themes/theme";
import utc from "dayjs/plugin/utc";
import { Provider } from "react-redux";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { PersistGate } from "redux-persist/integration/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InterceptorProvider from "contexts/with-interceptor-provider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import enrichedRoutes from "routes/routes";

import store, { persistor } from "store/store";

import AppAlert from "components/app-alert";
import AppModalAlert from "components/app-modal-alert";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

function App() {
	const [routes, setRoutes] = useState(null);

	const onHandleInitialize = () => {
		const nextRouter = createBrowserRouter(enrichedRoutes);
		setRoutes(nextRouter);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Provider store={store}>
					<AppAlert />

					<AppModalAlert />

					<PersistGate persistor={persistor} onBeforeLift={onHandleInitialize}>
						<InterceptorProvider routes={routes}>{routes && <RouterProvider router={routes} />}</InterceptorProvider>
					</PersistGate>
				</Provider>
			</ThemeProvider>
		</LocalizationProvider>
	);
}

export default App;
