import React, { useCallback, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppSalesOrderDetails = () => {
	const navigate = useNavigate();
	const [selectedRowItem, setSelectedRowItem] = useState(null);

	const initialValues = useMemo(() => {
		const values = {
			items: [],
			tax: "",
			city: "",
			type: "",
			email: "",
			title: "",
			state: "",
			total: "",
			status: "",
			remarks: "",
			picName: "",
			quantity: "",
			mobileNo: "",
			prefixNo: "",
			postcode: "",
			subtotal: "",
			serviceId: "",
			description: "",
			qoutationId: "",
			customerName: "",
			salesOrderId: "",
			addressLine1: "",
			addressLine2: "",
			internalNote: "",
			salesOrderTitle: "",
			workCompletionId: "",
			workInspectionId: ""
		};

		return values;
	}, []);

	const onHandleSubmit = (values) => {};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			salesOrderTitle: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: onHandleSubmit
	});

	const onHandleBack = useCallback(() => {
		navigate(pathnames.payment.salesOrders);
	}, [navigate]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				sort: false,
				sortThirdClickReset: true,
			},
		},
		{
			name: "title",
			label: "Title",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "serviceId",
			label: "Service ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "quantity",
			label: "Quantity",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "unit",
			label: "Unit",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "price",
			label: "Price Per Unit (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				},
			},
		},
		{
			name: "discount",
			label: "Discount (%)",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "amount",
			label: "Amount (MYR)",
			options: {
				sort: true,
				sortThirdClickReset: true,
				setCellHeaderProps: () => ({ className: "table__price" }),
				setCellProps: () => ({ className: "table__price" }),
				customBodyRender: (value, tableMeta) => {
					return formatCurrency(value);
				},
			},
		},
	], []);

	const emptyState = useMemo(() => {
		if (formik.values.items.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">No Items Added.</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [formik.values.items, tableColumns]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.items.length === obj.selectedRowItem.number;

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.items[obj.selectedRowItem.number - 2]);
		};

		return (
			<div className="sales-order-details__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value item-table__value--underline">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceId}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Type</p>

							<p className="item-table__value">{obj.selectedRowItem.type}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							item of {obj.selectedRowItem.number} of {obj.items.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	return (
		<div className="app-sales-order-details">
			<div className="sales-order-details">
				<form className="sales-order-details__form" onSubmit={formik.handleSubmit}>
					<div className="sales-order-details__container">
						<div className="sales-order-details__wrapper sales-order-details__wrapper--divider">
							<p className="sales-order-details__label">General Details</p>

							<div className="sales-order-details__row sales-order-details__row--general-details">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="salesOrderId" label="Sales Order ID" placeholder="Sales Order ID" value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput disabled name="status" label="Status" placeholder="Select..." options={[]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="workInspectionId" label="Work Inspection ID" placeholder="Work Inspection ID" value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="workCompletionId" label="Work Completion ID" placeholder="Work Completion ID" value={formik.values.workCompletionId} error={formik.errors.workCompletionId} touched={formik.touched.workCompletionId} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="qoutationId" label="Qoutation ID" placeholder="Qoutation ID" value={formik.values.qoutationId} error={formik.errors.qoutationId} touched={formik.touched.qoutationId} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="sales-order-details__wrapper">
							<p className="sales-order-details__label">Customer Details</p>

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="customerName" label="Customer Name" placeholder="Please input customer name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="picName" label="PIC Name" placeholder="Please input PIC name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="email" label="Email" placeholder="Please input email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppSelectInput disabled name="state" label="State" placeholder="Select..." options={[]} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								{/* prettier-ignore */}
								<AppSelectInput disabled name="city" label="City" placeholder="Select..." options={[]} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppSelectInput disabled name="postcode" label="Postcode" placeholder="Select..." options={[]} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="sales-order-details__container">
						<div className="sales-order-details__wrapper">
							{/* prettier-ignore */}
							<AppInput required type="text" name="salesOrderTitle" label="Sales Order Title" placeholder="Please input sales order title" value={formik.values.salesOrderTitle} error={formik.errors.salesOrderTitle} touched={formik.touched.salesOrderTitle} onChange={formik.handleChange} />

							<div className="sales-order-details__table-header">
								<p className="sales-order-details__label">Item Lines</p>
							</div>

							<AppTable data={formik.values.items} columns={tableColumns} components={emptyState} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={formik.values.items} />

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppInput disabled multiline type="textarea" name="internalNote" label="Internal Note" placeholder="Type Note here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="sales-order-details__column sales-order-details__column--divider">
									{/* prettier-ignore */}
									<AppInput disabled type="text" name="subtotal" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotal} error={formik.errors.subtotal} touched={formik.touched.subtotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									{/* prettier-ignore */}
									<AppInput disabled type="number" maxLength={1} name="tax" label="Tax (%)" placeholder="Please input tax percent" value={formik.values.tax} error={formik.errors.tax} touched={formik.touched.tax} onChange={formik.handleChange} />
								</div>
							</div>

							<div className="sales-order-details__row">
								{/* prettier-ignore */}
								<AppInput disabled multiline type="textarea" name="remarks" label="Customer Note" placeholder="Type Note here" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />

								<div className="sales-order-details__column sales-order-details__column--divider">
									{/* prettier-ignore */}
									<AppInput disabled type="text" name="discount" label="Discount (MYR)" value={formik.values.discount} error={formik.errors.discount} touched={formik.touched.discount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									{/* prettier-ignore */}
									<AppInput disabled type="text" name="grandTotal" label="Grand Total (MYR)" value={formik.values.grandTotal} error={formik.errors.grandTotal} touched={formik.touched.grandTotal} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>
					</div>

					<div className="sales-order-details__button-container">
						<AppButton outline className="sales-order-details__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton outline type="button" label="Update Draft" onClick={() => {}} />

						<AppButton type="submit" label="Create" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default AppSalesOrderDetails;
