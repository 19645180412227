import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppReportDetailsTeamPicCreateEditModal from "components/pages/work-inspection-listing/app-report-details-team-pic-create-edit-modal";

import addIcon from "assets/images/add-icon.png";
import deleteIcon from "assets/images/trash-icon.png";
import searchIcon from "assets/images/search-icon.png";

const teamPicData = [{ paginationNumbers: 1, id: "MC-001", name: "Henry" }];

const AppReportDetailsTeamPicTable = () => {
	const reportDetailsTeamPicRef = useRef();
	const [data, setData] = useState(teamPicData);

	const onHandleAddReportDetailTeamPic = useCallback(() => {
		reportDetailsTeamPicRef.current.onHandleShow();
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "id",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "delete",
			label: "Delete",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" >
							<img src={deleteIcon} alt="delete-icon" />
						</button>
					);
				},
			},
		},
	], []);

	useEffect(() => {
		setData(teamPicData);
	}, []);

	return (
		<div className="app-report-details-team-pic-table">
			<div className="report-details-team-pic-table">
				<div className="report-details-team-pic-table__header">
					<div className="report-details-team-pic-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name" endIcon={searchIcon} iconPosition="end" onBlur={() => {}} />
					</div>

					<div className="report-details-team-pic-table__create-button">
						<AppButton outline type="button" label="Add Team Members" icon={addIcon} onClick={onHandleAddReportDetailTeamPic} />
					</div>
				</div>

				<AppTable data={data} columns={tableColumns} />
			</div>

			<AppReportDetailsTeamPicCreateEditModal ref={reportDetailsTeamPicRef} />
		</div>
	);
};

export default AppReportDetailsTeamPicTable;
