import { useCallback, useMemo, useRef } from "react";

import * as yup from "yup";
import COMMON from "common";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import ERRORS from "constants/errors";

import AppButton from "components/app-button";
import OtpVerificationModal from "components/pages/login/app-otp-verification-modal";

import logo from "assets/images/logo.png";
import hero from "assets/images/pages/login/hero.png";

const PageOtpEmailConfirmation = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const email = useMemo(() => location.state?.email, [location]);

	const modalRef = useRef();

	const formik = useFormik({
		initialValues: { email },
		validationSchema: yup.object({
			email: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSendOtp(values);
		}
	});

	const onHandleSendOtp = useCallback((values) => {
		modalRef.current.onHandleShow(values);
	}, []);

	const onHandleBack = () => {
		localStorage.removeItem(COMMON.AUTH_TOKEN);
		localStorage.removeItem(COMMON.REFRESH_TOKEN);
		sessionStorage.removeItem(COMMON.AUTH_TOKEN);
		sessionStorage.removeItem(COMMON.REFRESH_TOKEN);

		navigate(pathnames.login.login);
	};

	return (
		<div className="page-otp-email-confirmation">
			<main className="otp-email-confirmation">
				<form className="otp-email-confirmation__form" onSubmit={formik.handleSubmit}>
					<div className="otp-email-confirmation__logo">
						<img src={logo} alt="logo" />
					</div>

					<h1 className="otp-email-confirmation__title">Welcome Back!</h1>

					<p className="otp-email-confirmation__description">
						Send a One-Time Password (OTP) to <b>{formik.values.email}</b> to verify your login. The OTP will expire in 30 minutes.
					</p>

					<div className="otp-email-confirmation__hero">
						<img src={hero} alt="hero" />
					</div>

					<div className="otp-email-confirmation__button-container">
						<AppButton type="submit" label="Send OTP" />

						<AppButton outline className="otp-email-confirmation__back-button" type="button" label="Back" onClick={onHandleBack} />
					</div>
				</form>

				<OtpVerificationModal ref={modalRef} userEmail={formik.values.email} />
			</main>
		</div>
	);
};

export default PageOtpEmailConfirmation;
