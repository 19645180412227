import React from "react";

import AppToggleSwitch from "components/app-toggle-switch";

const AppUserAlertConfiguration = () => {
	const defaultRulesList = [
		{ id: 1, label: "Work Order Assignment Alert", description: "When a new work order is created and requires assignment to a technician or a team", boPortal: true, emplApp: true },
		{ id: 2, label: "Work Order Delay Warning", description: "When a work order exceeds its estimated completion time", boPortal: true, emplApp: true },
		{ id: 3, label: "Inventory Restocking Alert", description: "When the inventory levels for the spare part falls below the minimum threshold [ __ units]", boPortal: true, emplApp: true },
		{ id: 4, label: "Client Appointment Confirmation", description: "When a client schedules a service appointment, send a confirmation and a reminder ahead of scheduled date and time", boPortal: true, emplApp: true },
		{ id: 5, label: "Billing and Invoice Generation", description: "When Work Order is complete, remind PIC to generate an invoice for the client", boPortal: true, emplApp: true },
		{ id: 6, label: "Outstanding Payment Reminder", description: "When a client has a overdue payment or outstanding invoice", boPortal: true, emplApp: true },
		{ id: 7, label: "Service Contract Renewal Notification", description: "When the service contract is nearing expiration date", boPortal: true, emplApp: true },
		{ id: 8, label: "Employee Leave Request Approval", description: "When an employee submits a leave request", boPortal: true, emplApp: true },
		{ id: 9, label: "Service Request Escalation", description: "When a service request has not been addressed within the expected time frame", boPortal: true, emplApp: true },
		{ id: 10, label: "Abnormal Login Activities", description: "When failed attempt to login exceeds 5 times after 8:00 PM", boPortal: true, emplApp: true },
		{ id: 11, label: "Server Storage", description: "When the server's storage is almost full and maintenance needs to be done", boPortal: true, emplApp: true }
	];

	return (
		<div className="app-user-alert-configuration">
			<div className="user-alert-configuration">
				<div className="user-alert-configuration__body">
					<div className="user-alert-configuration__header">
						<p className="user-alert-configuration__label">Rules</p>

						<div className="user-alert-configuration__actions">
							<p className="user-alert-configuration__action">Back Office Portal</p>

							<p className="user-alert-configuration__action">Employee App</p>
						</div>
					</div>

					<div className="user-alert-configuration__rules">
						{defaultRulesList.map((o, i) => {
							return (
								<div className="user-alert-configuration__box" key={i}>
									<div className="user-alert-configuration__rule">
										<p className="user-alert-configuration__title">{o.label}</p>

										<p className="user-alert-configuration__description">{o.description}</p>
									</div>

									<div className="user-alert-configuration__switches">
										<div className="user-alert-configuration__switch">
											<AppToggleSwitch checked={o.boPortal} name={o.id} />
										</div>

										<div className="user-alert-configuration__switch">
											<AppToggleSwitch checked={o.emplApp} name={o.id} />
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppUserAlertConfiguration;
