import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import sanitizeObject from "common/sanitize-object";
import { formatCurrency } from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";

import AppInput from "components/app-input";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const salesOrderData = [{ number: 1, id: "SO000001-23", customerName: "PAN X-PREM AC", location: "HQ", total: 20, createdBy: "ADM0001 Ahmad Muhammad Ali", status: "In-Use" }];

const PageSalesOrders = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(salesOrderData);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, name: searchParams.get("name") || "", status: searchParams.get("status") });

	const onHandleSubmit = () => {};

	const formik = useFormik({
		initialValues: {
			draft: false,
			in_progress: false,
			completed: false,
			completed_with_exception: false,
			overdue: false,
			drop_off: false
		},
		onSubmit: onHandleSubmit
	});

	const onHandleEditSalesOrder = useCallback(() => {
		navigate(pathnames.payment.salesOrderCreateEdit + "SO000001-23");
	}, [navigate]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();
	}, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.name = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: "draft" },
			{ label: "In Progress", name: "in_progress" },
			{ label: "Completed", name: "completed" },
			{ label: "Complete with Execption", name: "complete_with_exception" },
			{ label: "Overdue", name: "overdue" },
			{ label: "Drop Off", name: "drop_off" }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "number",
			label: "#",
			options: {
				filter: false,
				sort: false,
				sortThirdClickReset: true,
			},
		},
		{
			name: "id",
			label: "Sales Order ID",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerName",
			label: "Customer Name",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "location",
			label: "Location",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "total",
			label: "Total (MYR)",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: "table__price" }),
                setCellProps: () => ({ className: "table__price" }),
                customBodyRender: (value, tableMeta) => {
                    return formatCurrency(value);
                },
			},
		},
		{
			name: "createdBy",
			label: "Created By",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: false,
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={onHandleEditSalesOrder}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [onHandleEditSalesOrder]);

	useEffect(() => {
		setData(salesOrderData);
	}, []);

	return (
		<div className="page-sales-orders">
			<div className="sales-orders">
				<h1 className="sales-orders__title">Sales Order</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Sales Order ID or Customer Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} restricted={restricted} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="sales-orders-filter">
						<div className="sales-orders-filter__container">
							<div className="sales-orders-filter__row">
								<AppInput type="text" label="Location" name="location" placeholder="Location" value={formik.values.location} onChange={formik.handleChange} />

								<AppInput type="text" label="Created by" name="createdBy" placeholder="User ID or Name" value={formik.values.createdBy} onChange={formik.handleChange} />
							</div>

							<p className="sales-orders-filter__label">Status</p>

							{menuFilterStatus.map((o) => {
								const isActive = (formik.values.status || []).findIndex((i) => i === o.name) > -1;

								return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
							})}
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="sales-orders__body">
					<AppTable data={data} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default PageSalesOrders;
