import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppLevelsTable from "components/pages/human-resources/app-levels-table";
import AppPositionsTable from "components/pages/human-resources/app-positions-table";
import AppClaimTypeTable from "components/pages/human-resources/app-claim-type-table";
import AppDepartmentTable from "components/pages/human-resources/app-department-table";
import AppLeaveTypesTable from "components/pages/human-resources/app-leave-types-table";

import claimIcon from "assets/images/pages/human-resources/claim.svg";
import levelIcon from "assets/images/pages/human-resources/level.svg";
import positionIcon from "assets/images/pages/human-resources/position.svg";
import activeClaimIcon from "assets/images/pages/human-resources/claim-active.svg";
import activeLevelIcon from "assets/images/pages/human-resources/level-active.svg";
import buildingTypesIcon from "assets/images/pages/customer/building-types-icon.svg";
import departmentIcon from "assets/images/pages/human-resources/family-info-icon.svg";
import activePositionIcon from "assets/images/pages/human-resources/position-active.svg";
import activeBuildingTypesIcon from "assets/images/pages/customer/building-types-active-icon.svg";
import activeDepartmentIcon from "assets/images/pages/human-resources/family-info-active-icon.svg";

const PageHrConfiguration = () => {
	const profile = useSelector((state) => state.profile);

	//prettier-ignore
	const humanResourceTabs = useMemo(() => [
		{ label: "Leave Types", icon: buildingTypesIcon, activeIcon: activeBuildingTypesIcon, name: "LEAVE_TYPES", component: <AppLeaveTypesTable />, accessible: profile?.permissions?.[ROLES.LEAVE_TYPE_CONFIG]?.view },
		{ label: "Claim Types", icon: claimIcon, activeIcon: activeClaimIcon, name: "CLAIM_TYPES", component: <AppClaimTypeTable />, accessible: profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG]?.view },
		{ label: "Positions", icon: positionIcon, activeIcon: activePositionIcon, name: "POSITIONS", component: <AppPositionsTable />, accessible: profile?.permissions?.[ROLES.POSITION_CONFIG]?.view },
		{ label: "Levels", icon: levelIcon, activeIcon: activeLevelIcon, name: "LEVELS", component: <AppLevelsTable />, accessible: profile?.permissions?.[ROLES.POSITION_LEVEL_CONFIG]?.view },
		{ label: "Department", icon: departmentIcon, activeIcon: activeDepartmentIcon, name: "DEPARTMENT", component: <AppDepartmentTable />, accessible: profile?.permissions?.[ROLES.DEPARTMENT_CONFIG]?.view },
	], [profile?.permissions] );

	return (
		<div className="page-hr-configuration">
			<div className="hr-configuration">
				<h1 className="hr-configuration__title">HR Configuration</h1>

				<div className="hr-configuration__body">
					<AppTabs tabs={humanResourceTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageHrConfiguration;
