import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getFrequencyListing from "services/get-frequency-listing";
import getWorkSpaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppCustomerEditAssetDeleteModal from "components/pages/customer/app-customer-edit-asset-delete-modal";
import AppCustomerEditAssetReorderModal from "components/pages/customer/app-customer-edit-asset-reorder-modal";
import AppCustomerEditAssetAddServiceModal from "components/pages/customer/app-customer-edit-asset-add-service-modal";
import AppCustomerEditAssetCreateEditServiceModal from "components/pages/customer/app-customer-edit-asset-create-edit-service-modal";
import AppCustomerEditAssetAddServiceChecklistModal from "components/pages/customer/app-customer-edit-asset-add-service-checklist-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import chevronUpIcon from "assets/images/chevron-up-icon.png";
import reorderingIcon from "assets/images/reordering-icon.svg";
import chevronDownGreyIcon from "assets/images/chevron-down-grey-icon.png";
import verticalBreadCrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";

const PageCustomerEditAsset = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedItem = useRef();
	const addServicesModal = useRef();
	const createEditServiceModalRef = useRef();
	const addServiceChecklistModalRef = useRef();
	const reorderServiceLinesModalRef = useRef();
	const deleteServiceListingModalRef = useRef();
	const [serviceLineData, setServiceLineData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [inventoryTableAnchor, setInventoryTableAnchor] = useState(null);
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", status: searchParams.get("status") });
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const initialValues = useMemo(() => {
		const values = {
			search: "",
			id: "",
			referenceNo: "",
			customerContractSiteId: "",
			createdDate: "",
			customerAssetName: "",
			description: "",
			assetName: "",
			assetTypeId: "",
			remarks: "",
			frequency: "",
			lastService: "",
			workspaceId: "",
			serviceRemarks: "",
			firstService: "",
			completionTimeWindow: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			customerContractSiteId: yup.string().required(ERRORS.REQUIRED),
			customerAssetName: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			assetName: yup.string().required(ERRORS.REQUIRED),
			assetTypeId: yup.string().required(ERRORS.REQUIRED),
			remarks: yup.string().required(ERRORS.REQUIRED),
			frequency: yup.string().required(ERRORS.REQUIRED),
			lastService: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			workspaceId: yup.string().required(ERRORS.REQUIRED),
			firstService: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			completionTimeWindow: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const breadCrumb = useMemo(() => {
		return [
			{ label: "Customer Listing ", path: pathnames.customer.customers },
			{ label: "Edit Site ", path: pathnames.customer.customerCreateEditSite + formik.values.customerContractSiteId },
			{ label: "Edit Asset", path: pathnames.customer.customerEditAsset + id }
		];
	}, [id, formik.values.customerContractSiteId]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.assetMaintenance.asset(uniqueId);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			memoSetFormValues({
				referenceNo: response.referenceNo || "",
				customerContractSiteId: response.customerContractSiteId,
				createdDate: dayjs(response.createdDate),
				customerAssetName: response.customerAssetName,
				description: response.description,
				assetName: response.assetName,
				assetTypeId: response.assetType.id,
				remarks: response.remark,
				frequency: response.frequency,
				lastService: dayjs(response.lastService),
				workspaceId: response.workspace.id,
				serviceRemarks: response.serviceRemarks,
				firstService: dayjs(response.firstService),
				completionTimeWindow: response.completionTimeWindow || ""
			});
		}
	}, [memoSetFormValues]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.assetMaintenance.assetServices({ "asset-id": id, ...params });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setServiceLineData(obj);
		}
	}, [id]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				id: id,
				customerContractSiteId: values.customerContractSiteId,
				customerAssetName: values.customerAssetName,
				description: values.description,
				assetName: values.assetName,
				assetTypeId: values.assetTypeId,
				remarks: values.remarks,
				frequency: values.frequency,
				lastService: values.lastService,
				workspaceId: values.workspaceId,
				firstService: values.firstService,
				completionTimeWindow: values.completionTimeWindow
			};

			await api.post.assetMaintenance.updateAsset({ "asset-id": id, ...payload });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Asset was updated successfully!" }));

			onHandleGetDetails(id);
		}
	}, [dispatch, formik, id, onHandleGetDetails]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customerCreateEditSite + formik.values.customerContractSiteId);
	}, [navigate, formik]);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...serviceLineData.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setInventoryTableAnchor(event.currentTarget);
	}, [serviceLineData]);

	const onHandleAddChecklist = () => {
		addServiceChecklistModalRef.current.onHandleShow();
	};

	const onHandleReorderServiceLines = useCallback(() => {
		reorderServiceLinesModalRef.current.onHandleShow(serviceLineData.content);
	}, [serviceLineData]);

	const onHandleAddServices = () => {
		addServicesModal.current.onHandleShow();
	};

	const onHandleCreateService = useCallback(() => {
		createEditServiceModalRef.current.onHandleShow(null, serviceLineData);
	}, [serviceLineData]);

	const onHandleShowEditService = useCallback(() => {
		setInventoryTableAnchor(null);

		createEditServiceModalRef.current.onHandleShow(selectedItem.current);
	}, []);

	const onHandleCloseInventoryTableMenu = useCallback(() => {
		selectedItem.current = null;

		setInventoryTableAnchor(null);
	}, []);

	const onHandleConfirmDeleteServiceListing = useCallback(() => {
		const selectedData = selectedItem.current;

		setInventoryTableAnchor(null);

		deleteServiceListingModalRef.current.onHandleShow(selectedData);
	}, []);

	const onHandleDeleteServiceListing = useCallback(async () => {
		let response = null;

		try {
			const payload = { "asset-id": id, "service-id": selectedItem.current.id, "status-update": STATUS.RETIRED };

			await api.post.assetMaintenance.updateAssetServiceStatus(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service was updated successfully!" }));

			onHandleGetList();
		}
	}, [dispatch, formik, id, onHandleGetList]);

	// prettier-ignore
	const onHandleAddServiceChecklist = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { "asset-id": id, serviceChecklistIds: { serviceChecklistIds: values } };

			await api.post.assetMaintenance.addAssetServiceChecklist(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));

			onHandleGetList();

			addServiceChecklistModalRef.current.onHandleGetExclusiveChecklist();
		}
	}, [dispatch, formik, id, onHandleGetList]);

	//prettier-ignore
	const onHandleAddService = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { "asset-id": id, assetServiceIds: { assetServiceIds: values } };

			await api.post.assetMaintenance.addAssetServices(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));

			onHandleGetList();

			addServicesModal.current.onHandleGetExclusiveServiceList();
		}
	}, [dispatch, formik, id, onHandleGetList]);

	// prettier-ignore
	const onHandleReorderItem = useCallback(async (values) => {
		let response = null;
		const checkLists = [...values].map((o) => ({ id: o.id, seq: o.seq }));
		const payload = { "asset-id": id, checkLists: { checkLists } };

		try {
			await api.post.assetMaintenance.assetServicesReorder(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Service Checklist was reordered successfully!" }));

			onHandleGetList();
		}
	}, [id, dispatch, onHandleGetList]);

	// prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = serviceLineData.content[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [serviceLineData]);

	// prettier-ignore
	const onHandleSwitchService = useCallback((value) => {
		const currentRowNumber = selectedRowItem?.paginationNumbers - 1;

		const item = serviceLineData.content[currentRowNumber + value];

		if (item) setSelectedRowItem(item);
	}, [selectedRowItem, serviceLineData.content]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: false
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return;

					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={verticalBreadCrumbsIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction, restricted]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		enableNestedDataAccess: ".",
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		onCellClick: onHandleCellSelect
	}), [onHandleCellSelect]);

	//prettier-ignore
	const SelectedItemDetails = useCallback((obj) => {
		if (obj.selectedRowItem?.subtasks) {
			const reorderedSubtasks = obj.selectedRowItem.subtasks.sort((a, b) => a.seq - b.seq);
			
			obj.selectedRowItem.subtasks = reorderedSubtasks;
		}

		return (
			<div className="customer-edit-asset__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Service Action</p>

							<p className="item-table__value">{obj.selectedRowItem?.serviceAction || "Select an Item from the table to see the detailed view here"}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Input</p>

							<p className="item-table__value">{obj.selectedRowItem?.input?.label}</p>
						</div>

						<div className="item-table__item item-table__item--subtasks">
							<p className="item-table__label">#</p>

							<p className="item-table__label item-table__label--subtasks">Sub-Tasks</p>

							<p className="item-table__label item-table__label--subtasks">Subtask Input</p>
						</div>

						{obj.selectedRowItem?.subtasks?.map((o) => {
							return (
								<div key={o.id} className="item-table__item item-table__item--subtasks">
									<p className="item-table__label">{o.seq}</p>

									<p className="item-table__label item-table__label--subtasks">{o.subtaskAction}</p>

									<p className="item-table__label item-table__label--subtasks">{o.input?.label}</p>
								</div>
							);
						})}
					</div>

					<div className="item-table__switch-service">
						<AppButton label="" type="button" icon={chevronUpIcon} onClick={() => onHandleSwitchService(-1)} />

						<AppButton label="" type="button" icon={chevronDownGreyIcon} onClick={() => onHandleSwitchService(1)} />
					</div>
				</div>
			</div>
		);
	}, [onHandleSwitchService]);

	useEffect(() => {
		onHandleGetDetails(id);
		onHandleGetList();

		return () => {
			if (id) {
				memoCancelRequest(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET);

				memoCancelRequest(ENDPOINT_PATH.ASSET_MAINTENANCE.ASSET_SERVICES);
			}
		};
	}, [id, memoCancelRequest, onHandleGetDetails, onHandleGetList]);

	return (
		<div className="page-customer-edit-asset">
			<div className="customer-edit-asset">
				<div className="customer-edit-asset__header">
					<h1 className="customer-edit-asset__title">Edit Asset</h1>

					<div>
						<p className="customer-edit-asset__last-update">
							<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
						</p>
					</div>
				</div>

				<form className="customer-edit-asset__form" onSubmit={formik.handleSubmit}>
					<div className="customer-edit-asset__container">
						<p className="customer-edit-asset__label">Asset Details</p>

						<div className="customer-edit-asset__row">
							<AppInput disabled type="text" name="referenceNo" label="Asset ID" placeholder="Asset ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

							<AppInputDate disabled name="createdDate" label="Date Added" value={formik.values.createdDate} />
						</div>

						<div className="customer-edit-asset__row">
							<div className="customer-edit-asset__column">
								<AppInput required type="text" name="customerAssetName" disabled={restricted} label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />

								<AppInput required type="text" name="assetName" disabled={restricted} label="Asset Name" placeholder="Enter Asset Name" value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />
							</div>

							<AppInput multiline type="textarea" disabled={restricted} label="Description" maxLength={500} name="description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset__row">
							<div className="customer-edit-asset__column">
								<AppSelectInput required name="assetTypeId" disabled={restricted} label="Asset Type" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.assetTypeId} error={formik.errors.assetTypeId} touched={formik.touched.assetTypeId} onChange={formik.handleChange} />

								<AppSelectInput required name="frequency" disabled={restricted} label="Frequency" placeholder="Select..." loadOptions={getFrequencyListing} value={formik.values.frequency} error={formik.errors.frequency} touched={formik.touched.frequency} onChange={formik.handleChange} />
							</div>

							<AppInput multiline type="textarea" disabled={restricted} label="Remarks" maxLength={500} name="remarks" placeholder="Enter Remarks" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset__row customer-edit-asset__row--divider">
							<AppInputDate required name="lastService" disabled={restricted} label="Last Service" value={formik.values.lastService} error={formik.errors.lastService} touched={formik.touched.lastService} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />

							<AppSelectInput required name="workspaceId" disabled={restricted} label="Workplace" placeholder="Select..." loadOptions={getWorkSpaceListing} value={formik.values.workspaceId} error={formik.errors.workspaceId} touched={formik.touched.workspaceId} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset__row customer-edit-asset__row--divider">
							<div className="customer-edit-asset__column">
								<p className="customer-edit-asset__label customer-edit-asset__label--services">
									Add Service Checklists <span className="customer-edit-asset__required">*</span>
								</p>

								<AppButton outline type="button" disabled={restricted} label="Add Existing Checklist(s)" onClick={onHandleAddChecklist} />
							</div>

							<div className="customer-edit-asset__column">
								<p className="customer-edit-asset__label customer-edit-asset__label--services">
									Add Services <span className="customer-edit-asset__required">*</span>
								</p>

								<AppButton outline type="button" disabled={restricted} label="Add Existing Service(s)" onClick={onHandleAddServices} />

								<p className="customer-edit-asset__text">Or</p>

								<AppButton outline type="button" disabled={restricted} label="Add New Services" icon={addIcon} onClick={onHandleCreateService} />
							</div>
						</div>

						<p className="customer-edit-asset__label">Service Listing</p>

						<div className="customer-edit-asset__table-column customer-edit-asset__table-column--divider">
							<div className="customer-edit-asset__header">
								<AppTableFilterHeader searchPlaceholder="Search by Service ID or Service Name" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} />

								<div className="customer-edit-asset__header-button">
									<AppButton outline type="button" disabled={restricted} label="Reorder" icon={reorderingIcon} onClick={onHandleReorderServiceLines} />
								</div>
							</div>

							<AppTable data={serviceLineData.content} columns={tableColumns} options={tableOptions} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} />
						</div>

						<p className="customer-edit-asset__label">Work Order Configuration</p>

						<div className="customer-edit-asset__row">
							<AppInputDate required name="firstService" disabled={restricted} label="First Service" value={formik.values.firstService} error={formik.errors.firstService} touched={formik.touched.firstService} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />

							<AppInput required type="number" name="completionTimeWindow" disabled={restricted} label="Completion Time Window" placeholder="Input a No. of days e.g: 5" value={formik.values.completionTimeWindow} error={formik.errors.completionTimeWindow} touched={formik.touched.completionTimeWindow} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="customer-edit-asset__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label="Update" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>

			<Menu classes={{ root: "services-table-menu" }} anchorEl={inventoryTableAnchor} open={!!inventoryTableAnchor} onClose={onHandleCloseInventoryTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleShowEditService}>
					<img src={editIcon} alt="services-edit" />
					Edit
				</MenuItem>

				<MenuItem onClick={onHandleConfirmDeleteServiceListing}>
					<img className="services-table-menu__close-icon" src={trashIcon} alt="services-close" />
					Delete
				</MenuItem>
			</Menu>

			<AppCustomerEditAssetAddServiceModal ref={addServicesModal} onConfirm={onHandleAddService} />

			<AppCustomerEditAssetAddServiceChecklistModal ref={addServiceChecklistModalRef} onConfirm={onHandleAddServiceChecklist} />

			<AppCustomerEditAssetCreateEditServiceModal ref={createEditServiceModalRef} onHandleGetList={onHandleGetList} />

			<AppCustomerEditAssetDeleteModal ref={deleteServiceListingModalRef} onConfirm={onHandleDeleteServiceListing} />

			<AppCustomerEditAssetReorderModal ref={reorderServiceLinesModalRef} onConfirm={onHandleReorderItem} />
		</div>
	);
};

export default PageCustomerEditAsset;
