import AppTable from "components/app-table/app-table";

const claimData = [
	{ paginationNumbers: 1, claimType: "Medical Claim", entitled: "1000.00", balance: "900.00" },
	{ paginationNumbers: 2, claimType: "Parking Claim", entitled: "500.00", balance: "450.00" },
	{ paginationNumbers: 3, claimType: "Overtime Claim", entitled: "1500.00", balance: "1000.00" }
];

const AppClaimSummaryTable = () => {
	const tableColumns = [
		{
			name: "claimType",
			label: "Claim Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "entitled",
			label: "Entitled Amount",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "balance",
			label: "Balance Amount (MYR)",
			options: {
				sort: false
			}
		}
	];

	return (
		<div className="app-claim-summary-table">
			<div className="claim-summary-table">
				<div className="claim-summary-table__body">
					<AppTable data={claimData} columns={tableColumns} />
				</div>
			</div>
		</div>
	);
};

export default AppClaimSummaryTable;
