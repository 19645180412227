const convertPaginationTableData = (data) => {
	let pagination = { content: [], total: 0, totalElements: 0, page: 0, offset: 0 };

	if (data) {
		pagination.content = data.content.map((o, i) => ({ ...o, paginationNumbers: i + 1 + data.pageable.pageNumber * 10 }));
		pagination.total = data.totalPages;
		pagination.totalElements = data.totalElements;
		pagination.page = data?.pageable?.pageNumber || 0;
		pagination.offset = data?.pageable?.offset || 0;
	}

	return pagination;
};

export default convertPaginationTableData;
