const COMMON = {
	AUTH_TOKEN: "AUTH_TOKEN_MCUBE_134f3611-0366-42c6-955b-f531c2c9e81f",
	REFRESH_TOKEN: "REFRESH_TOKEN_MCUBE_134f3611-0366-42c6-955b-f531c2c9e81f",
	REDUX_PRESIST_KEY: "REDUX_PRESIST_KEY_134f3611-0366-42c6-955b-f531c2c9e81f",
	REDUX_ACTION: {
		LOGOUT: "LOGOUT"
	},
	MONTH_LIST: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
};

export default COMMON;
