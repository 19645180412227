import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const AppCustomerAddContractSiteTable = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_SITE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const defaultStatus = useMemo(() => [STATUS.IN_PROGRESS, STATUS.OPEN, STATUS.INACTIVE, STATUS.ASSIGNED].join(","), []);
	const queryParams = useRef({ page: 0, keyword: "", statuses: defaultStatus, sort: "" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({ initialValues: { keyword: "", statuses: [STATUS.IN_PROGRESS, STATUS.OPEN, STATUS.INACTIVE, STATUS.ASSIGNED] }, onSubmit: (values) => onHandleSubmit(values) });

	const onHandleCloseMenu = useCallback(() => {
		formik.setFieldValue("statuses", queryParams.current.statuses.split(","));
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "In Progress", name: STATUS.IN_PROGRESS },
			{ label: "Open", name: STATUS.OPEN },
			{ label: "Assigned", name: STATUS.ASSIGNED },
			{ label: "Inactive", name: STATUS.INACTIVE }
		];

		return data;
	}, []);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ queryParams: { ...queryParams.current, size: 10 }, "contract-id": id });

			response = await api.get.customer.contractSites(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.statuses = values.statuses.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.statuses = defaultStatus;

		onHandleGetList();
	}, [defaultStatus, formik, onHandleGetList]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.statuses];

		if (formik.values.statuses.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("statuses", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleAddSite = useCallback(() => {
		navigate(pathnames.customer.customerCreateEditSite + `${id}/` + PAGE.CREATE);
	}, [id, navigate]);

	// prettier-ignore
	const onHandleEditSite = useCallback((obj) => {
		const siteId = data.content[obj.rowIndex]?.id;

		navigate(pathnames.customer.customerCreateEditSite + siteId);
	}, [data.content, navigate]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Site Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "sitePic",
			label: "Site PIC",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "mobileNumber",
			label: "Mobile Number",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "emailAddress",
			label: "Email Address",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "nextMaintenance",
			label: "Next Maintenance",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "siteAddress",
			label: "Site Address",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "numberOfAsset",
			label: "No. of Assets",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					if (restricted) return;

					return (
						<button type="button" className="table__action" onClick={() => onHandleEditSite(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditSite, restricted]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text" onClick={onHandleAddSite}>
							No Sites Added<span className="table__link">Add Site?</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data.content, onHandleAddSite, tableColumns.length]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_SITES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-customer-add-contract-site-table">
			<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Customer Alias or PIC Name or Email Address" searchDefaultValue={formik.values.search} onHandleSearch={onHandleDebounceSearch} onHandleSetLastFilter={onHandleCloseMenu} onHandleClearFilter={onHandleClearFilter} onHandleAdd={onHandleAddSite}>
				<div>
					<div className="customer-add-contract-site-table-filter">
						<div className="customer-add-contract-site-table-filter__container">
							<p className="customer-add-contract-site-table-filter__label">Status</p>

							<div className="customer-add-contract-site-table-filter__checkboxes">
								{menuFilterStatus.map((o) => {
									const isActive = formik.values.statuses.findIndex((i) => i === o.name) > -1;

									return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
								})}
							</div>
						</div>
					</div>
				</div>
			</AppTableFilterHeader>

			<div className="customer-add-contract-site-table">
				<AppTable data={data.content} columns={tableColumns} tableOptions={tableOptions} components={emptyState} />
			</div>
		</div>
	);
};

export default AppCustomerAddContractSiteTable;
