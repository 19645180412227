/* eslint-disable no-restricted-globals */

export const workerCode = () => {
	let timerInterval;

	let originTimer = "{timer}";

	let totalMilliseconds = structuredClone(originTimer);

	self.onmessage = function ({ data: { turn, remainedTime } }) {
		if (turn === "off" || timerInterval) {
			clearInterval(timerInterval);
		}

		if (turn === "on") {
			if (totalMilliseconds === 0) {
				totalMilliseconds = originTimer;
			}

			timerInterval = setInterval(() => {
				totalMilliseconds -= 1;
				self.postMessage({ totalMilliseconds });
			}, 1000);
		}

		if (turn === "time") {
			totalMilliseconds = remainedTime;
		}
	};
};

const getTimerServiceWorker = (timer) => {
	let code = workerCode.toString().replace("{timer}", timer);

	code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

	const blob = new Blob([code], { type: "application/javascript" });

	const jsCode = URL.createObjectURL(blob);

	return jsCode;
};

export default getTimerServiceWorker;
